<template>
  <znl-gridmodule
    ref="flexGrid"
    :columns="columns"
    :header="header"
    :is-fields-search="false"
    :is-multi-rows-check="false"
    :item-source="itemSource"
    :on-init="onInit"
    :on-search="onSearch"
    :search-fields="searchModel"
    :show-add-row-right-menu="false"
    :show-delete-row-right-menu="false"
    height="200px"
    tooltip-content="点击当前的待备货型号后选择批次库存进行备货"
    @selection-changed="d=>{this.onSelectionChanged(d)}">
    <div slot="heading-slot">
      &nbsp;&nbsp;
      <span class="m-header-txt">
              <span>销售订单号: <span>{{ this.soBillNo }} </span> </span>
              <span>合同日期: <span>{{ this.soDate }} </span> </span>
              <span>客户名称: <span>{{ this.customerName }} </span></span>
              </span>
      <div class="m-btnright-box">
        <!-- <znl-button style-type="mac"   @click="onAutomatch()" :height='22'>
        <span> 自动匹配库存</span>
        </znl-button> -->
        <el-checkbox v-model="isWaitReserver" @change="onBindData()">仅显示待备货</el-checkbox>&nbsp;
        <znl-button :disabled="isSubmitting" :height='22' style-type="main" @click="onSubmitReserve()">
          <span>提交备货</span>
        </znl-button>
      </div>
    </div>
  </znl-gridmodule>
</template>

<script>
import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'
import {mixin as getCommonDataMixin} from '~assets/scripts/methods/getCommonData'
import {mixin as common} from '~assets/scripts/methods/common'

const CONFIG = {
  PK: 'SOLineGuid',
  GetWaitReserveLine: 'Reserve/GetWaitReserveLine'
}
export default {
  name: 'SalesOrderLine',
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      tempSource: {
        saleline: []
      },
      itemSource: [],
      StkOut: {
        LineList: [] // 出库单详情
      },
      selectedRow: Object,
      // 默认个单号
      soBillNo: 'SO20180101001',
      isSubmitting: false,
      soDate: null,
      customerName: null,
      isWaitReserver: true,
      currentSOGuid: null
    }
  },
  props: {

    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
      {
        type: Object,
        default: ''
      },
    height: {
      type: String,
      default: () => {
        return '45%'
      }
    },
    gridShow: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: () => {
        return ''
      }
    }
  },

  methods: {
    onInit: async function (config) {
      // 列配置
      let gridConfig = [
        {
          CTitle: 'SOGuid',
          BindField: 'SOGuid',
          IsShow: false,
          IsReadonly: true,
          // ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true
        },
        {
          CTitle: 'SOLineGuid',
          BindField: 'SOLineGuid',
          IsShow: false,
          IsReadonly: true,
          // ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true
        },
        {
          CTitle: '客户料号',
          BindField: 'CustomerPartNo',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 90,
          width: 10,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '产品类型',
          BindField: 'ProductCategory',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 90,
          width: 10,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 120,
          width: 10,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 60,
          width: 10,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '年份',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 60,
          width: 10,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: '含税价',
          BindField: 'Price',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: '销售量',
          BindField: 'Qty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },

        {
          CTitle: '已备货总量',
          BindField: 'ReservedQty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: '待备货量',
          BindField: 'UnReserveQty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 13,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: '已发货总量',
          BindField: 'StkOutQty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: '未发货量',
          BindField: 'UnStkOutQty',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        }
      ]

      let columns = this.flexGridColumnsHandler(gridConfig)

      _.each(columns, (column, index) => {
        if (column.binding === 'UnReserveQty') {
          _.extend(column, {
            renderHeader: (h, obj) => {
              return h(
                'span', {},
                [
                  obj.header,
                  h('i', {
                    attrs: {
                      class: 'iconfont icon-table_help',
                      title: '销售量-已备货总量'
                    }
                  })
                ]
              )
            }
          })
        }
        if (column.binding === 'UnStkOutQty') {
          _.extend(column, {
            renderHeader: (h, obj) => {
              return h(
                'span', {},
                [
                  obj.header,
                  h('i', {
                    attrs: {
                      class: 'iconfont icon-table_help',
                      title: '销售量-已发货总量'
                    }
                  })
                ]
              )
            }
          })
        }
      })

      this.columns = columns
    },

    // 从销售单点击备货
    LoadSaleData(val) {
      this.currentSOGuid = val
      this.onBindData()
    },
    onBindData() {
      if (!this.hasValue(this.currentSOGuid)) {
        return
      }
      this.$emit('page-loading', true)
      var param = {
        SOGuid: this.currentSOGuid,
        CanShowWaitReserve: this.isWaitReserver
      }
      this.$post(CONFIG.GetWaitReserveLine, param, (data, logic) => {
        if (logic.code === 200) {
          this.soBillNo = data.BillNo
          this.customerName = data.CustomerName
          this.soDate = data.SODate
          this.$nextTick(function () {
            this.itemSource = data.LineList
          })
        } else {
          this.$message({message: logic.msg, type: 'error'})
        }
      }).finally(() => {
        this.$emit('page-loading', false)
        this.resetTableH();
      })
    },
    resetTableH() {

      let data = this.resetTableHeight({
        dom: ".sales-order-line .znl-grid-component",
        parentDom: ".sales-order-line",
        brother: [
          // ".sales-order-line .znl-gridModuleHeader",
        ],
        heightSet: true,
        surplus: 10,
      });

      this.resetTableHeight({
        dom: ".sales-order-line .el-table",
        parentDom: ".sales-order-line",
        brother: [
          ".sales-order-line .znl-gridModuleHeader",
        ],
        heightSet: true,
        surplus: 0,
      });
    },
    onAutomatch() {
      this.tempSource.saleline = JSON.parse(JSON.stringify(this.itemSource))
      _.each(this.itemSource, (item, index) => {
        if (item.ReserveQty > 0 || item.SelectedReserveQty > 0) {
          this.tempSource.saleline.splice(0, 1)
        }
      })
      if (this.tempSource.saleline.length === 0) {
        return this.$message({message: '没有可以备货的明细', type: 'error'})
      }
      this.$emit('on-automatch', this.tempSource.saleline)
    },
    onSubmitReserve() {
      let vm = this
      this.isSubmitting = true

      this.$emit('on-submit-reserve', this.itemSource, () => {
        vm.isSubmitting = false
      })


    },
    onSelectionChanged(val) {
      this.selectedRow = val
      this.$emit('selection-changed', val)
    },
    changeQty(soLineGUID, qty) {
      _.each(this.itemSource, (item) => {
        if (item.SOLineGuid === soLineGUID) {
          item.ReservedQty = qty
          let unReserveQty = this.toFloat(item.Qty, 2) - this.toFloat(item.ReservedQty, 2)
          if (unReserveQty >= 0) {
            item.UnReserveQty = unReserveQty
          }
        }
      })
    },
    autoChangeQty(source) {
      let reqty = 0
      if (source.length > 0) {
        _.each(this.itemSource, (item) => {
          _.each(source, (data, index) => {
            if (item.SOLineGuid === data.SOLineGUID) {
              reqty += data.Qty
              item.SelectedReserveQty = reqty
            }
          })
          reqty = 0
        })
        _.each(this.itemSource, (item) => {
          if (item.ReserveQty > 0) {
            item.WaitReserveQty = this.toFloat(item.Qty, 2) - item.ReserveQty
          } else {
            item.WaitReserveQty = this.toFloat(item.Qty, 2) - item.SelectedReserveQty
          }
        })
      }
    },
    selectedStockLine(stockLine) {
      let selectedStockQty = 0
      if (stockLine !== undefined) {
        _.each(stockLine, item => {
          selectedStockQty = selectedStockQty + item.Qty
        })
      }
      _.each(this.itemSource, item => {
        if (item.SOLineGuid === this.selectedRow.SOLineGUID) {
          item.SelectedReserveQty = selectedStockQty
        }
      })
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
.znl-gridModuleHeader {
  width: 100%;

  .m-btnright-box {
    position: absolute;
    right: 0px;
  }
}

.m-header-txt > span {
  padding-right: 10px;
  font-size: 12px;
}
</style>
