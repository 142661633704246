<template>
  <div>
    <znl-dialog
      :title="title"
      :visible="editvisible"
      height="640px"
      width="1000px"
      class="dialog-znl"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="
        (val) => {
          $emit('close', val);
        }
      "
    >
      <znl-gridmodule
        ref="flexGrid"
        gridtype="action"
        height="100%"
        class="m-border-box"
        :has-znl-btns="false"
        layout-type="other"
        :from-height="150"
        :from-btns="fromBtns"
        element-loading-text="正在保存,请稍等..."
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :is-alter-ver="true"
        :on-init="onInit"
        :loading="loading"
        from="StkOtherFees"
        :users="$store.state.users"
        @edit:cell-ended="onCellEdited"
        @click.native.stop="autocompleteShow = false"
        @page-loading="
          (d) => {
            $emit('page-loading', d);
          }
        "
        :is-multi-rows-check="true"
      >
        <!-- 编辑内容 -->
        <div
          slot="from-module"
          style="border-top: 1px solid #d8d8d8; margin-bottom: -2px"
        ></div>
        <div slot="from-content">
          <znl-input
            title-width="70px"
            form-type="input"
            placeholder="平摊到所有单 "
            size="mini"
            layout="left"
            :border="true"
            title="总费用："
            v-show="showTotalFees"
            width="100%"
            v-model="totalFees"
            @change="TotalFeesChange"
            type="text"
            style="margin-right: 3px"
          ></znl-input>
          <znl-button
            :height="22"
            @click="stkOtherFeesSave(false)"
            style-type="minor"
            :disabled="isDisabled"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存</span>
          </znl-button>

          <znl-button
            :height="22"
            @click="stkOtherFeesSave(true)"
            style-type="main"
            :disabled="isDisabled"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存并关闭</span>
          </znl-button>
        </div>
      </znl-gridmodule>
    </znl-dialog>
    <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      :companyType="this.InTypes"
      :title="this.companyTitle"
      @close="
        () => {
          dialogCompanySelectVisible = false;
        }
      "
      @update:visible="
        (val) => {
          dialogCompanySelectVisible = val;
        }
      "
      @confirm="companyChecked"
    ></d-company-contact-select>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { renderRequiredColumn } from "~assets/scripts/methods/common";
import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { dateTimeFormat } from "@scripts/methods/common";
import { debuglog } from "util";
const CONFIG = {
  PK: "StkOtherFeesID",
  configURL: "StkOtherFees/GetConfig",
  searchURL: "StkOtherFees/SearchByBillNo",
  stkOtherFeesSaveURL: "StkOtherFees/Save", // 保存
  GetParameterUrl: "PubParameter/GetParameter", // 获取系统参数
};

export default {
  name: "StkOtherFees",
  config: CONFIG,
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
    dateTimeFormat,
  ],
  components: {
    dCompanyContactSelect,
  },
  created() {
    if (this.InTypes == "1,3") {
      this.companyTitle = "我的客户";
    } else {
      this.companyTitle = "我的供应商";
    }

    this.showTotalFees = this.BillNo.length > 1;
  },
  data() {
    return {
      dialogLoading: true,
      loading: false,
      dialogCompanySelectVisible: false,
      ParamJosn: [],
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      columnColors: [],
      rowColors: [],
      fromBtns: [],
      isDisabled: false,
      companyTitle: "",
      showTotalFees: false,
      RmbCurrencyCode: "",
      totalFees: "",
    };
  },
  props: {
    title: {
      type: String,
      default: () => {
        return "其它费用";
      },
    },
    BillNo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    InTypes: {
      type: String,
      default: () => {
        return "";
      },
    },
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    OrderType: {
      type: Number,
    },
  },
  watch: {
    dialogLoading(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.flexGrid.doLayout();
        });
      }
    },
  },
  methods: {
    TotalFeesChange(totalFees) {
      var amount = parseFloat((totalFees / this.itemSource.length).toFixed(2));
      _.each(this.itemSource, (data, index) => {
        //最后一行取剩下的值
        if (index == this.itemSource.length - 1) {
          data.Amount = parseFloat(totalFees.toFixed(2));
        } else {
          data.Amount = amount;
        }
        totalFees = totalFees - amount;
      });
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      if (rowindex == 0 && this.BillNo.length != 1) {
        _.each(this.itemSource, (data) => {
          data[colname] = aftervalue;
        });
      }
    },
    onDeletedRows() {
      _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
        _.each(this.itemSource, (item, i) => {
          if (
            _.isEqual(
              _.pick(item, (value) => {
                return !_.isUndefined(value);
              }),
              _.pick(data, (value) => {
                return !_.isUndefined(value);
              })
            )
          ) {
            this.itemSource.splice(i, 1);
            return;
          }
        });
      });
    },
    onInit: async function () {
      let config = await this.isSaveStorage("StkOtherFees", CONFIG.configURL);

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "FeesType";
        }),
        {
          dataMap: [
            { key: "应收", value: "应收" },
            { key: "应付", value: "应付" },
          ],
          showDropDown: true,
        }
      );

      let currencyCodeList = await this.selectOptionsCurrencyCode;
      // 币种
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "CurrencyCode";
        }),
        { dataMap: currencyCodeList, showDropDown: true }
      );
      _.each(currencyCodeList, (data) => {
        if (data.value === "人民币") {
          this.RmbCurrencyCode = data.key;
        }
      });
      console.log(currencyCodeList, "currencyCodeList");
      if (this.RmbCurrencyCode == "" && currencyCodeList.length != 0) {
        this.RmbCurrencyCode = currencyCodeList[0].key;
      }
      // 快递公司
      let TransportCompany = await this.selectOptionsTransportCompanyAsync;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "FeesBody";
        }),
        { dataMap: TransportCompany, showDropDown: true }
      );
      let sundryFeesType = await this.selectSundryFeesType;
      console.log(sundryFeesType, "selectSundryFeesType");
      // 费用类型
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "FeesName";
        }),
        { dataMap: sundryFeesType, showDropDown: true }
      );
      let UserAllowUsers = _.map(
        this.selectOptionsAllUserURL,
        function (obj, index) {
          return { key: obj.key, value: obj.value };
        }
      );
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "CreatedUserID";
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      );
      this.defaultConfig = config;
      this.columns = this.renderRequiredColumn(columns);
      console.log(this.columns, 112);
      var vm = this;
      this.fromBtns = [
        {
          name: "新增一行",
          iconName: "icon-add_btn_ic",
          isShow: !this.isDisabled && this.BillNo.length == 1,
          click: () => {
            this.InitTableByBillNo();
          },
        },
        {
          name: "删除选择行",
          isShow: !this.isDisabled,
          iconName: "icon-delete_btn_ic",
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
          },
        },
        {
          name: "运费计算器",
          isShow: true,
          iconName: "icon-jisuanqi",
          click: async () => {
            let url = "https://znlscm.com/freightCalculator";
            try {
              const result = await vm.$post(CONFIG.GetParameterUrl, {
                ParamCode: "FreightCalculatorUrl",
              });
              if (result && result.CurrentValue) url = result.CurrentValue;
            } catch (ex) {
              console.log("获取运费计算器地址异常：", ex);
            } finally {
              window.open(url);
            }
          },
        },
      ];
      this.$nextTick(() => {
        this.dialogLoading = false;
      });
      this.onBindData();
    },
    async onBindData() {
      if (this.BillNo.length == 1) {
        return await this.$post(CONFIG.searchURL, this.BillNo[0], (data) => {
          if (data != undefined) {
            _.each(data, (item, i) => {
              if (this.hasValue(item.CreatedTime)) {
                item.CreatedTime = this.formatDate(item.CreatedTime);
              }
              if (!this.hasValue(item.FeesCompanyName)) {
                item.FeesCompanyName = "";
              }
            });
          }
          this.itemSource = data === undefined ? [] : data;
        });
      } else {
        this.InitTableByBillNo();
      }
    },
    InitTableByBillNo() {
      _.each(this.BillNo, (data) => {
        var OFID = Math.floor(Math.random() * (1000000 - 0 + 1) + 0);
        this.itemSource.push({
          OFID: OFID,
          Amount: "",
          FeesNo: "",
          FeesBody: "",
          BillNo: data,
          Remarks: "",
          CurrencyCode: this.RmbCurrencyCode,
          FeesCompanyName: "",
          FeesCompanyID: "",
          CreatedUserID: this.$store.state.accountInfo.UserID,
          CreatedTime: this.formatDateTime(new Date(), "yyyy-MM-dd"),
        });
      });
    },
    suggestionsSelect(item) {
      this.companyChecked([item]);
    },
    save(isClose) {
      // 保存并关闭
      var vm = this;
      if (this.isDisabled) {
        return false;
      }
      this.isDisabled = true;
      this.loading = true;
      return this.$post(
        CONFIG.stkOtherFeesSaveURL,
        {
          BillNo: this.BillNo[0],
          Fees: this.itemSource,
          OrderType: this.OrderType,
          IsBatchSave: this.BillNo.length > 1,
        },
        (data, logic) => {
          vm.isDisabled = false;
          vm.loading = false;
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: "error" });
          } else if (!data) {
            this.$message({ message: "保存失败，请联系管理员", type: "error" });
          } else {
            this.$message({ message: "保存成功", type: "success" });
            this.$emit("save-success");
            if (isClose) {
              this.$emit("close");
            } else {
              if (this.BillNo.length != 1) {
                this.itemSource = [];
                this.totalFees = "";
                this.InitTableByBillNo();
              }
            }
          }
        }
      ).finally(() => {
        this.isDisabled = false;
        vm.loading = false;
      });
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    stkOtherFeesSave(isClose) {
      // 保存并关闭
      if (this.checkData()) {
        this.save(isClose);
      }
    },

    checkData() {
      let ret = true;
      // if (this.itemSource.length != 0) {
      //   var lastCol = this.itemSource.length - 1;
      //   var lastRow = this.itemSource[lastCol];
      //   if (!this.hasValue(lastRow['FeesName']) && !this.hasValue(lastRow['FeesType']) && !this.hasValue(lastRow['FeesCompanyName']) && !this.hasValue(lastRow['Amount']) && !this.hasValue(lastRow['CurrencyCode']) && !this.hasValue(lastRow['Remarks']) && !this.hasValue(lastRow['CreatedByName']) && !this.hasValue(lastRow['CreatedTime']))
      //     this.itemSource = this.itemSource.splice(0, lastCol);
      // }
      let rows = this.itemSource;
      if (rows.length > 0) {
        let rowId = 0;
        _.some(rows, (item) => {
          rowId = rowId + 1;
          if (!this.hasValue(item["FeesName"])) {
            ret = false;
            this.$message({
              message: "杂费详情第" + rowId + "行 费用类型不能为空",
              type: "warning",
            });
          } else if (!this.hasValue(item["Amount"])) {
            ret = false;
            this.$message({
              message: "杂费详情第" + rowId + "行 费用金额不能为空",
              type: "warning",
            });
          } else if (isNaN(item["Amount"])) {
            ret = false;
            this.$message({
              message: "杂费详情第" + rowId + "行 费用金额格式有误",
              type: "warning",
            });
          } else if (!this.hasValue(item["CurrencyCode"])) {
            ret = false;
            this.$message({
              message: "杂费详情第" + rowId + "行 币种不能为空",
              type: "warning",
            });
          }
        });
      } else if (this.BillNo.length > 1) {
        ret = false;
        this.$message({ message: "没有数据可添加", type: "warning" });
      }
      return ret;
    },
    SearchCompany(row, col, cell, grid, noVisibleCols, index) {
      // 弹出公司搜索页面
      if (col.name == "FeesCompanyName") {
        this.dialogCompanySelectVisible = true;
      }
    },
    companyChecked(item) {
      this.$refs.flexGrid.getSelectedRows()[0].FeesCompanyName =
        item.CompanyName;
      this.$refs.flexGrid.getSelectedRows()[0].FeesCompanyID = item.CCompanyID;
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    // 连续录单模式保存到参数配置
    onContinuousRecordValue() {
      // _.each(this.ParamJosn, item => {
      //   if (item.ParamCode === 'IsContinuousRecord') {
      //     item.ParamValue = this.IsContinuousRecord ? 1 : 0
      //   }
      // })
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsContinuousRecord ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkIn");
          }
        );
      }
    },
  },
};
</script>
<style lang="scss">
.dialog-znl-stkinedit {
  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 15px 30px;
  }
  .stkinedit-min-grid-box .has-gutter tr th {
    background: #ebeef5;
  }
  .znl-dialog.el-dialog .el-dialog__header {
    height: auto;
  }
}
</style>

