<template>
  <!-- 批量上传 -->
  <div class="dialog-qdw-batch-upload-box">
    <znl-dialog
      :visible="visible"
      title="批量上传"
      height="306px"
      width="500px"
      v-loading="dialogLoading"
      element-loading-text="上传中,请稍后..."
      :footer-height="30"
      :is-footer-show="false"
      :close-on-click-modal="false"
      @close="()=>{$emit('close')}">

      <el-row class="text-row">
        <ol>
          <li>支持csv, excel格式的文件上传</li>
          <li>系统将<strong>忽略第一行</strong>，请不要将库存信息存放在第一行</li>
          <li>列顺序<strong>务必</strong>按照：型号、品牌、封装、可售量、起订量、价格、发货地、批次号 进行排列</li>
          <li>您也可以下载<a @click="onDownloadExample('http://sz8.znlerp.com:10003/example-files/znl-qdw-import-example.xlsx')">示例文件</a>，编辑后上传</li>
        </ol>
        <hr />
      </el-row>
      <el-row class="item-row">
        <el-checkbox
          v-model="postData.publish"
          tabindex="20"
          style="margin-left:0 !important"
        >
          同时在抢单王上架
        </el-checkbox>
      </el-row>
      <el-row class="item-row">
        <upload
          ref="vUpload"
          accept=".csv,.xls,.xlsx"
          width="265px"
          :refresh="!isRefresh"
          :refresh-click="refreshClick"
          folder="qdw_batch_upload_files"
          @upload-before="onUploadClick"
          @upload-completed="onUploadCompleted"
        />
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import Upload from '@c_modules/StkExt/Upload'
import { isNumber } from 'util'

const CONFIG = {
  uploadUrl: 'TencentQDW/UploadStock'
}

export default {
  name: 'QDW_BatchUploadDialog',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  components: { Upload },
  data () {
    return {
      dialogLoading: false,
      LoadingText: '',
      saveloading: false,
      errorMessage: '',
      isRefresh: false,
      refreshClick: false,
      postData: {
        publish: true
      }
    }
  },
  props: {
    visible: Boolean
  },
  watch: {},
  methods: {
    onInit: async function () {
    },

    onUploadClick (fileName, fileSize) {
      if (fileName.lastIndexOf('.csv') < 1 && fileName.lastIndexOf('.xls') < 1 && fileName.lastIndexOf('.xlsx') < 1) {
        return this.$message({ message: '请选择一个csv格式或者excel文件', type: 'error' })
      }
      if (fileSize > 4 * 1024 * 1024) {
        return this.$message({ message: '文件大小请不要超过4M', type: 'error' })
      }
      this.refreshClick = !this.refreshClick
      this.dialogLoading = true
    },
    async onUploadCompleted (fileName, fileSize, fileUrl, filesArry) {
      console.log(fileName, fileSize, fileUrl, filesArry)
      let isSuccess = false
      let message = ''
      let count = 0
      await this.$post(CONFIG.uploadUrl, { FileUrl: fileUrl, IsPublish: this.postData.publish }, (data, logic) => {
        console.log(data, 'data')
        if (isNumber(data)) {
          isSuccess = true
          count = data
        } else {
          message = logic.msg
        }
      })

      this.dialogLoading = false
      if (isSuccess) {
        if (count > 0) {
          this.$message({message: '上传成功. 共上传了' + count + '条', type: 'success'})
          this.$emit('confirm', true)
        } else {
          this.$message({message: '上传失败. 未能从上传的文件读取到数据', type: 'error'})
        }
      } else {
        this.$message({message: message, type: 'error'})
      }
    },

    onDownloadExample (url) {
      let name = url.substr(url.lastIndexOf('/') + 1)
      this.downFile({FileUrl: url, FileName: name})
    }
  },
  async created () {
    this.$nextTick(() => {
      this.onInit()
    })
  }
}
</script>

<style lang="scss">
.dialog-qdw-batch-upload-box {
  .text-row {
    font-size: 12px;
    ol {
      padding-left: 30px;
      margin-bottom: -5px;
      list-style-type: decimal;

      li {
        list-style-type: decimal;
         list-style-position:outside;
      }
    }
  }
  .item-row {
    margin-left: 18px;
  }
}
</style>
