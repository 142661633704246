<template>
  <znl-dialog :visible="visible"
              height="150px"
              width="300px"
              title="用户密码验证"
              @close="()=>{$emit('close')}">
  <div  slot="znl-dialog" style="height:50px;">
    <!-- <el-row align="middle" type="flex" justify="start">
          <el-col :span="24" class="redTitle">{{warningTitle}}</el-col>
     </el-row> -->
         <el-row>
                 <el-col :span="8">
     <el-row  class="form_Row_W100">
          <!-- <el-col :span="5" class="col-label-right">密码：</el-col>
          <el-col :span="11" class="col-label-right">
             <el-input size="mini" type="password"  v-model="pwd" placeholder="输入完成后回车即可验证" @keyup.enter.native="onCheck"></el-input>
          </el-col> -->

             <znl-input title-width="60px"
                     form-type="input"
                     placeholder="输入完成后回车即可验证"
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="密码："
                     width="250px"
                     :clearable="true"
                     v-model="pwd"
                     @keyup.enter.native="onCheck"
                     type="password">
          </znl-input>
       
          <!-- <el-col :span="1"></el-col>
           <el-col :span="7"><el-button type="primary"  size="mini" @click="onCheck"
           >确认</el-button></el-col> -->
     </el-row>
        </el-col>
         </el-row>
  </div>
    <div slot="footer">
            
      <el-button
        class="znl-btn-auxiliary"
        @click="()=>{$emit('close')}">关闭</el-button>
      <el-button type="primary"
                  size="mini"
                  class="znl-btn-main"
                  @click="onCheck">确认</el-button>
    </div>
    </znl-dialog>
</template>
<script>

export default {
  name: 'ConfirmPassword',
  data () {
    return {
      warningTitle: '',
      pwd: '',
      config: {
        checkURL: 'AccountManage/CheckPwd'
      }
    }
  },
  props: {
    warning: '', // 提示语
    isRefresh: Boolean, // 是否刷新
    visible: Boolean
  },
  watch: {
    isRefresh (value) {
      this.pwd = ''
    },
    warning (value) {
      this.warningTitle = value
    }
  },
  methods: {
    onCheck () { // 保存
      if (this.pwd === '') {
        return this.$message({message: '请输入密码', type: 'error'})
      } else {
        this.$post(this.config.checkURL, {Password: this.pwd}, (datas) => {
          if (datas) {
            this.$emit('confirm', true)
          } else {
            this.$message({message: '密码验证失败，无法继续清除', type: 'error'})
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col-label-right {
  text-align: right;
  line-height: 30px;
}
.redTitle{
color:red;
font-size:16px;
}
</style>
