<!-- 销售退货单 -->
<template>
  <div class="module-wrapper m-sales-return-order">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      v-loading="loading"
      box-class="m-sales-return-order"
      element-loading-text="数据加载中,请稍后..."
      checkboxBindingKey="StkOutGUID"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      @current-row-change="onCurrentRowChangeHandle"
      :is-fields-search="isShowFiedsSearch"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('SalesReturn')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          v-model="fieldsFilter.inputModel"
          :clearable="true"
          :border="true"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          :disabled="false"
          placeholder="销售单号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :border="true"
          :clearable="true"
          v-model="fieldsFilter.soBillNo"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="客户名称"
            class="box_input_checkbox"
            size="mini"
            layout="left"
            width="160px"
            inp-perc="100%"
            :border="true"
            v-model="fieldsFilter.CustomerName"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox v-model="fieldsFilter.accurate" class="accurate-checbox" size="small">精确</el-checkbox>
        </div>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="退货日期从"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="fieldsFilter.startOutOn"
        ></znl-input>
        <span>到</span>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="退货日期止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="fieldsFilter.endOutOn"
        ></znl-input>

        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" v-if="isShowPrint" type="multiple">
          <znl-button-menu @click="onPrint" title="点击右边下三角符号选择打印模板">
            <span>
              <i class="iconfont icon-print_btn_ic"></i>打印
            </span>
          </znl-button-menu>
          <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac" @click="onBatchRec">
          <i class="iconfont icon-table_money_ic"></i>
          <span>批量收款</span>
        </znl-button>
      </div>
    </znl-table>
    <!-- 退货单打印设置 -->
    <znl-print
      :visible.sync="znlPrintVisible"
      :printInfo="printInfo"
      :loading="printLoading"
      :is-get-default-param="printInfo.isGetDefaultParam"
      print-type="STK_Out"
      title="退货单打印设置"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>
    <!--日志dialog-->
    <operate-log
      @close="()=>{operateLogVisible=false}"
      ref="OperateLog"
      :visible="operateLogVisible"
      v-if="operateLogVisible"
      :orderGuid="orderGuid"
      :refreshLog="refreshLog"
      height="50%"
    ></operate-log>
    <!--销售退货-->
    <sales-order-return-edit
      :editvisible="SalesOrderRetrunVisible"
      v-if="SalesOrderRetrunVisible"
      edit-type="edit"
      @save-success="()=>{this.onRefresh()}"
      @confirm="SalesOrderRetrunVisible=false"
      @close="SalesOrderRetrunVisible=false"
    ></sales-order-return-edit>
    <!-- 冲红销售发票 -->
    <fin-so-invoice-edit
      :isReturninv="isReturninvoice"
      v-if="invoiceVisible"
      :accountVisible="invoiceVisible"
      :title="invoiceTitle"
      @close="invoiceVisible=false"
      @save-success="onRefresh"
    ></fin-so-invoice-edit>

    <!-- 批量收款 -->
    <stk-batch-receive
      ref="StkBatchReceive"
      :visible="payVisible"
      v-if="payVisible"
      :custName="custName"
      :isReturn="true"
      :isShowPagination="true"
      @close="()=>{payVisible=false,this.onRefresh()}"
      @confirm="(val)=>{val&&(payVisible=false),this.onRefresh()}"
    ></stk-batch-receive>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import SalesOrderReturnEdit from "@c_modules/Sale/SalesOrderReturnEdit";
import OperateLog from "@c_modules/Log/OperateLog";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
import FinSoInvoiceEdit from "@c_modules/Fin/SalesInvoice/InvoiceEdit";
import StkBatchReceive from "@c_modules/Fin/OutReceive";
import { erpTableSetHandler } from "@scripts/methods/common";
import { isClient } from "~/lib/runtime";
import * as urls from '~/lib/urls'

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "StkOutGUID",
  configURL: "SalesReturn/GetConfig",
  resetParamConfigURL: "SalesReturn/ResetParamConfig",
  searchURL: "SalesReturn/Search",
  inStockUrl: "SalesReturn/InStock", // 入库
  cancelInStockUrl: "SalesReturn/CancelInStock", // 取消入库
  onDeleteURL: "SalesReturn/DeleteList",
  onExamineURL: "SalesReturn/Examine",
  onAntiTrialURL: "SalesReturn/AntiTrial",
  GetPrintTemplatesURL: "PrintTemplate/Search",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  WebPrintURL:'WebPrint/SalesReturnPrint',
  DownloadPrintFile:'WebPrint/DownloadPrintFile',
};
export default {
  name: "SalesOrderReturn",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    OperateLog,
    SalesOrderReturnEdit,
    ZnlPrint,
    FinSoInvoiceEdit,
    StkBatchReceive
  },
  data() {
    return {
      isShowPrint: false,
      isShowFiedsSearch: false,
      SalesOrderRetrunVisible: false,
      isReturninvoice: false,
      invoiceVisible: false, // 销售发票
      invoiceTitle: "",
      stkOutGUID: "", // 需要传递给编辑页面的GUID
      stkoutSelectItem: [], // 选中的数据
      columns: [],
      itemSource: [],
      fieldsFilter: {
        startOutOn: "",
        endOutOn: "",
        inputModel: "",
        CustomerName: "",
        IsAuditedName: "",
        accurate: false,
        soBillNo: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      operateLogVisible: false,
      orderGuid: "",
      refreshLog: true,
      parameterConfig: [],
      //TaxRate: [],
      ParamJosn: [],
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "" // 打印模板类型
      }, // end 打印模块参数
      payVisible: false,
      custName: "",
      isMainAccount: this.$store.state.accountInfo.IsMainAccount, // false 为非主账号
      loading: false,
      currencyList: [],
      userList: [],
      taxRateList: [],
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true }
      ]
    };
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    },
    initSalesReturnBillNo: {
      type: String,
      default: ""
    }
  },

  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(await this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      this.loading = true;
      if (!config || config === undefined) {
        config = await this.isSaveStorage(
          "salesOrderReturn",
          this.$options.config.configURL
        );
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      // 获取参数配置
      this.paramConfiguration();

      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Shipper");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Receiver");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.taxRateList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.auditedOptions
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "未入", value: 1 },
            { key: "已入", value: 3 },
            { key: "已撤", value: 9 }
          ]
        });
      }
      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "编辑",
          name: "Edit",
          click: row => {
            this.onSalesReturnEdit(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: row => {
            this.onPostDelete(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("In")) {
        mouseBtn.push({
          title: "入库",
          name: "In",
          click: row => {
            this.onPostStorage(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Cancel")) {
        mouseBtn.push({
          title: "撤单",
          name: "Cancel",
          click: row => {
            this.onPostCancelAnOrder(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: row => {
            this.onPostExamine(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: row => {
            this.onPostAntiTrial(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        mouseBtn.push({
          title: "日志",
          name: "OperateLog",
          click: row => {
            this.operateLogVisible = true;
            this.$nextTick(() => {
              this.orderGuid = row.StkOutGUID;
              this.refreshLog = !this.refreshLog;
            });
          }
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
          canSearch: false
        }
      ];
      this.columns = tempConfig.concat(columns);
      this.$refs.table.init();
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      if (this.initSalesReturnBillNo != "") {
        this.fieldsFilter.soBillNo = this.initSalesReturnBillNo;
      }
      if (this.hasRes("Print")) {
        this.onInitPrintTemplate();
      }
      if (this.initData) {
        this.onBindData();
      }
      this.isShowPrint = this.hasRes("Print");
      this.parameterConfig = config.ParamJosn;
      this.$nextTick(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },
    setBillNo(billNo) {
      this.fieldsFilter = {
        startOutOn: "",
        endOutOn: "",
        inputModel: "",
        CustomerName: "",
        IsAuditedName: "",
        accurate: false,
        soBillNo: billNo
      };
      this.onBindData(1);
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("salesOrderReturn");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("salesOrderReturn");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      let datas = _.find(this.ParamJosn, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "SalesReturn", ParamJson: [datas] },
          data => {
            localStorage.removeItem("salesOrderReturn");
          }
        );
      }
    },
    // 获取参数配置
    paramConfiguration() {
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(this.ParamJosn, function(paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }
    },
    onResetSetting() {
      localStorage.removeItem("salesOrderReturn");
      return this.$post(CONFIG.resetConfigURL);
    },
    onResetParams(val) {
      localStorage.removeItem("salesOrderReturn");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    onInitPrintTemplate() {
      return this.$post(
        this.$options.config.GetPrintTemplatesURL,
        { Type: "ORD_SalesOrder" },
        data => {
          let res = [];
          _.each(data, item => {
            res.push({
              key: item.TableName,
              value: item.TName
            });
          });
          this.PrintTemplates = res;
        }
      );
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onPrint() {
      //网页版打印
      if(!isClient())
      {
        this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选中要打印的退货单",
          type: "error"
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      var OutGuid = this.onSelectRow(); // 销售单ID
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        this.printStkOut({
          TableName: tableName,
          Guid: row.StkOutGUID,
          SubCompanyId: titleId
        });
      }
    },
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StartOutOn: this.fieldsFilter.startOutOn,
          EndOutOn: this.fieldsFilter.endOutOn,
          InputModel: this.fieldsFilter.inputModel,
          soBillNo: this.fieldsFilter.soBillNo
        }
      );
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if(this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },
    // 编辑
    onSalesReturnEdit(row) {
      this.$store.commit("setParamOrder", { stkOutGuid: row.StkOutGUID });
      this.$nextTick(function() {
        this.SalesOrderRetrunVisible = true;
      });
    },
    // 撤单请求
    onPostCancelAnOrder(row) {
      if (!this.hasValue(row)) {
        return;
      }
      if (this.checkData(row, 2)) {
        this.$post(
          CONFIG.cancelInStockUrl,
          { StkOutGUID: row.StkOutGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 删除
    onPostDelete(row) {
      if (!this.hasValue(row)) {
        return;
      }
      if (this.checkData(row, 1)) {
        this.$confirm("确认删除 单号:" + row.BillNo + " 的退货单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$post(
            CONFIG.onDeleteURL,
            { ListStkoutGuid: [row.StkOutGUID] },
            (data, logic) => {
              if (logic.code === 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.onRefresh();
              } else {
                this.$message({ message: logic.msg, type: "error" });
              }
              return data;
            }
          );
        });
      }
    },
    // 入库
    onPostStorage(row) {
      if (!this.hasValue(row)) {
        return;
      }
      if (this.checkData(row, 5)) {
        this.$post(
          CONFIG.inStockUrl,
          { StkOutGUID: row.StkOutGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 审核
    onPostExamine(row) {
      if (!this.hasValue(row)) {
        return;
      }
      if (this.checkData(row, 3)) {
        this.$post(
          CONFIG.onExamineURL,
          { ListStkoutGuid: [row.StkOutGUID] },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 反审核请求
    onPostAntiTrial(row) {
      if (!this.hasValue(row)) {
        return;
      }
      if (this.checkData(row, 4)) {
        this.$post(
          CONFIG.onAntiTrialURL,
          { ListStkoutGuid: [row.StkOutGUID] },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    checkData(row, state) {
      // 1.删除 2.撤单 3.审核 4.反审 5.出库
      if (state === 1) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:" + row.BillNo + "已审核，不能删除",
            type: "warning"
          });
          return false;
        }
        if (row.Status === 3 || row.Status === 2) {
          this.$message({
            message: "单号:" + row.BillNo + "已入库或部分入库，不能删除",
            type: "warning"
          });
          return false;
        }
      } else if (state === 2) {
        if (row.Status === 9) {
          this.$message({
            message: "单号:" + row.BillNo + "已撤销，不能取消入库",
            type: "warning"
          });
          return false;
        }
        if (row.IsAudited) {
          this.$message({
            message: "单号:" + row.BillNo + "已审核，不能取消入库",
            type: "warning"
          });
          return false;
        }
        if (row.Status === 1) {
          this.$message({
            message: "单号:" + row.BillNo + "未入库，不能取消入库",
            type: "warning"
          });
          return false;
        }
      } else if (state === 3) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:" + row.BillNo + "已审核，不能重复审核",
            type: "warning"
          });
          return false;
        }
      } else if (state === 4) {
        if (!row.IsAudited) {
          this.$message({
            message: "单号:" + row.BillNo + "未审核，不能反审",
            type: "warning"
          });
          return false;
        }
      } else if (state === 5) {
        if (row.Status === 3) {
          this.$message({
            message: "单号:" + row.BillNo + "已全部入库，不能重复入库",
            type: "warning"
          });
          return false;
        }
      } else if (state === 6) {
        if (row.CurrencyCode !== "RMB") {
          this.$message({
            message:
              "单号:【" + row.BillNo + "】币种不是人民币类型，不能开发票",
            type: "warning"
          });
          return false;
        }
        if (row.TaxRate === 0) {
          this.$message({
            message: "单号:【" + row.BillNo + "】税率为0，不能开发票",
            type: "warning"
          });
          return false;
        }
      }
      return true;
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = [];
      return guid;
    },
    onRefresh() {
      this.$refs.table.cleanAllCheck();
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.StkOutGUID)) {
        this.$emit("selection-changed", row);
      }
    },

    async onBatchRec() {
      let rows = this.$refs.table.getCheckedRows();
      console.log(rows, 12345);
      if (!this.hasValue(rows) || rows.length === 0) {
        this.$message({ message: "请勾选上要操作的退货单", type: "warning" });
        return false;
      }
      // 过滤掉已全部收款的订单
      let filterRows = [];
      _.each(rows, row => {
        let amt = row.Amount || 0;
        let gotAmt = row.ReceivedAmount || 0;
        let discountAmount = row.DiscountAmount || 0;
        if (amt !== 0 && amt !== gotAmt + discountAmount) {
          filterRows.push(row);
        }
      });
      if (filterRows.length === 0) {
        this.$message({ message: "您勾选的单据没有未收款项", type: "warning" });
        return false;
      }
      rows = filterRows;
      this.custName = null;
      let isRep = false; // 是否有多个客户名称
      let notOut = false; // 是否有未出库
      let isContinue = true; // 是否继续
      _.each(rows, r => {
        if (this.custName === null) {
          this.custName = r.CustomerName;
        }
        if (this.custName !== r.CustomerName) {
          isRep = true;
        }
        if (r.StatusName === "未入库") {
          notOut = true;
        }
      });
      if (isRep) {
        await this.$confirm(
          "你选择的销售退货单包含多个客户, 是否继续?",
          "提示",
          { confirmButtonText: "是", cancelButtonText: "否", type: "warning" }
        )
          .then(() => {
            isContinue = true;
            // 多个客户时 不显示预收款,把客户名称清空
            this.custName = null;
          })
          .catch(() => {
            isContinue = false;
          });
      }
      if (notOut && isContinue) {
        await this.$confirm(
          "你选择的销售退货单含有未入库的, 是否继续?",
          "提示",
          { confirmButtonText: "是", cancelButtonText: "否", type: "warning" }
        )
          .then(() => {
            isContinue = true;
          })
          .catch(() => {
            isContinue = false;
          });
      }
      if (isContinue) {
        let guids = _.map(rows, item => {
          return item.StkOutGUID;
        });
        let paramOrder = { StkOutGUIDS: guids };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(() => {
          this.payVisible = true;
        });
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("SalesReturn", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("SalesReturn", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("SalesReturn", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("SalesReturn");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选中要打印的退货单",
          type: "error",
        });
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.StkOutGUID
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
          window.open(downloadUrl) // 下载文件
        }
        else{
          this.$message({ message: logic.msg, type: "error" });
        }
      })
    },

  }
};
</script>
<style lang="scss">
</style>

