var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('znl-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],staticClass:"dialog-znl",attrs:{"title":_vm.title,"visible":_vm.editvisible,"height":"640px","width":"1000px","close-on-click-modal":false,"is-footer-show":false,"element-loading-text":"数据加载中,请稍后..."},on:{"close":function (val) {
        _vm.$emit('close', val);
      }}},[_c('znl-gridmodule',{ref:"flexGrid",staticClass:"m-border-box",attrs:{"gridtype":"action","height":"100%","has-znl-btns":false,"layout-type":"other","from-height":150,"from-btns":_vm.fromBtns,"element-loading-text":"正在保存,请稍等...","columns":_vm.columns,"item-source":_vm.itemSource,"hasDefaultBeforeNewRow":true,"edit-row-index":_vm.editRowIndex,"column-colors":_vm.columnColors,"row-colors":_vm.rowColors,"is-alter-ver":true,"on-init":_vm.onInit,"loading":_vm.loading,"from":"StkOtherFees","users":_vm.$store.state.users,"is-multi-rows-check":true},on:{"edit:cell-ended":_vm.onCellEdited,"page-loading":function (d) {
          _vm.$emit('page-loading', d);
        }},nativeOn:{"click":function($event){$event.stopPropagation();_vm.autocompleteShow = false}}},[_c('div',{staticStyle:{"border-top":"1px solid #d8d8d8","margin-bottom":"-2px"},attrs:{"slot":"from-module"},slot:"from-module"}),_c('div',{attrs:{"slot":"from-content"},slot:"from-content"},[_c('znl-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTotalFees),expression:"showTotalFees"}],staticStyle:{"margin-right":"3px"},attrs:{"title-width":"70px","form-type":"input","placeholder":"平摊到所有单 ","size":"mini","layout":"left","border":true,"title":"总费用：","width":"100%","type":"text"},on:{"change":_vm.TotalFeesChange},model:{value:(_vm.totalFees),callback:function ($$v) {_vm.totalFees=$$v},expression:"totalFees"}}),_c('znl-button',{attrs:{"height":22,"style-type":"minor","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.stkOtherFeesSave(false)}}},[_c('i',{staticClass:"iconfont icon-save_btn_ic"}),_c('span',[_vm._v("保存")])]),_c('znl-button',{attrs:{"height":22,"style-type":"main","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.stkOtherFeesSave(true)}}},[_c('i',{staticClass:"iconfont icon-save_btn_ic"}),_c('span',[_vm._v("保存并关闭")])])],1)])],1),_c('d-company-contact-select',{attrs:{"visible":_vm.dialogCompanySelectVisible,"companyType":this.InTypes,"title":this.companyTitle},on:{"close":function () {
        _vm.dialogCompanySelectVisible = false;
      },"update:visible":function (val) {
        _vm.dialogCompanySelectVisible = val;
      },"confirm":_vm.companyChecked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }