<template>
  <div>
    <template>
      <znl-gridmodule
        ref="flexGrid"
        header="待发货清单"
        :columns="columns"
        :grid-show="gridShow"
        :item-source="itemSource"
        :is-btn="false"
        height="100%"
        :action-btns="actionBtns"
        :has-znl-actions="true"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-multi-rows-check="true"
        :is-fields-search="isShowFiedsSearch"
        :search-fields="searchModel"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :users="$store.state.users"
        :on-init="onInit"
        :is-init="false"
        :on-refresh="onRefresh"
        :on-save-setting="onSaveSetting"
        :on-save-config="onSaveConfig"
        :on-reset-setting="onResetSetting"
        :show-add-row-right-menu="false"
        :show-delete-row-right-menu="false"
        :on-search="onSearch"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
      >
        <div slot="action-form">
          <znl-input
            form-type="input"
            :disabled="false"
            placeholder="请输入型号"
            size="mini"
            layout="left"
            width="120px"
            inp-perc="100%"
            v-model="inputModel"
            :clearable="true"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text"
          ></znl-input>
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            width="120px"
            inp-perc="100%"
            placeholder="--请选择超期天数--"
            popper-class="popperClass"
            :clearable="true"
            :select-options="PrintTemplates"
            @change="onChangeOverdueDay"
            v-model="OverdueDay"
          ></znl-input>
          <znl-button type="multiple" :height="22" style-type="main">
            <znl-button-menu @click="$refs.flexGrid.search()">
              <span>
                <i class="iconfont icon-search_ic"></i>搜索
              </span>
            </znl-button-menu>
            <znl-button-menu :width="7" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
        </div>
      </znl-gridmodule>
      <stk-out-edit
        :title="StkOutAddTitle"
        :editvisible="stkOutEditVisible"
        v-if="stkOutEditVisible"
        edit-type="edit"
        @confirm="stkOutEditVisible=false"
        @close="stkOutEditVisible=false"
      ></stk-out-edit>
    </template>
  </div>
</template>
</div>
</template>

<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'

const CONFIG = {
  configURL: 'WaitStkOutList/GetConfig',
  saveConfigURL: 'WaitStkOutList/SaveConfig',
  resetConfigURL: 'WaitStkOutList/ResetConfig',
  default: null,
  searchURL: 'WaitStkOutList/Search',
  DropDownURL: 'DictItem/GetItemValue',
  GetStkOutBySOLineGuids: 'StkOutAdd/GetStkOutBySOLineGuids'
}
export default {
  name: 'WaitStkOutList',
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {
    StkOutEdit
  },
  data() {
    return {
      StkOutAddTitle: '编辑发货单',
      actionBtns: [
      ],
      stkOutEditVisible: false, // 是否显示发货页面
      inputModel: '', // 需要查询的型号
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      isShowFiedsSearch: false,
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false,
      resourceList: [],
      OverdueDay: 0, // 超期天数
      PrintTemplates: [{
        value: '三天以上',
        key: 3
      },
      {
        value: '一周以上',
        key: 7
      }, {
        value: '一个月以上',
        key: 30
      }]
    }
  },
  computed: {
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
      {
        type: Object,
        default: () => {
          return {
            data: {},
            extra: {}
          }
        }
      },
    height: String,
    isRefresh: Boolean // 刷新页面
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        // config = await this.$post(CONFIG.configURL)
        config = await this.isSaveStorage('waitStkOutList', CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.resourceList = config.ResourceList
      this.actionBtns = [
        {
          name: '发货',
          isShow: this.hasRes('Out'),
          iconName: 'iconfont icon-EX-wh_btn_ic',
          click: () => { this.onStkOut() }
        }]
      if (_.find(columns, function (column) { return column.binding === 'IsCompletedLable' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsCompletedLable' }),
          {
            dataMap: [{ 'key': '0', 'value': '否' },
            { 'key': '1', 'value': '是' }],
            showDropDown: true
          })
      }
      if (_.find(columns, function (column) { return column.binding === 'IsAuditedName' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsAuditedName' }),
          {
            dataMap: [{ 'key': '未审核', 'value': '未审核' },
            { 'key': '已审核', 'value': '已审核' }],
            showDropDown: true
          })
      }
      // 销售员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'SalerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      // 添加当前库存量列
      let qtyIndex = _.findIndex(columns, item => item.name === 'InvQty')
      columns.splice(qtyIndex, 0, {
        'name': 'InvQty',
        'dataType': 5,
        'binding': 'InvQty',
        'width': 80,
        'header': '当前库存量',
        'visible': false,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'buttons': [
          {
            'content': (d) => '',
            'className': 'wj-link',
            'click': (row) => {
            }
          }
        ]
      })
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onBindData(pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
          Model: this.inputModel,
          OverdueDay: this.OverdueDay
        })
      return this.$post(CONFIG.searchURL, data, (data) => {
        for (let i = 0; i < data.ResultList.length; i++) {
          data.ResultList[i].SODate = this.dateFormat(data.ResultList[i].SODate)
        }
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onSearch(params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem('waitStkOutList')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    onResetSetting() {
      localStorage.removeItem('waitStkOutList')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem('waitStkOutList')
      return this.$post(CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    hasRes(code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    cancelAllChecked() {
      this.$refs.flexGrid.cancelAllChecked()
    },
    doStkOut(rows) {
      var lastCustomerName = rows[0].CustomerName
      var isDiff = false
      _.each(rows, r => {
        if (lastCustomerName !== r.CustomerName) {
          isDiff = true
        }
      })
      if (isDiff) {
        return this.$message({ message: '请选择相同客户名称的销售单', type: 'error' })
      }
      var guids = _.map(rows, item => { return item.SOLineGuid })
      return this.$post(CONFIG.GetStkOutBySOLineGuids, { SOLineGuids: guids }, (data) => {
        if (_.isNumber(data)) {
          switch (data) {
            case 1:
              return this.$message({ message: '请选择相同客户名称的销售单!', type: 'error' })
            case 2:
              return this.$message({ message: '选中的单据中，存在未审核单据, 不能出库!', type: 'error' })
            case 3:
              return this.$message({ message: '选中的单据中，存在已撤消单据, 不能出库!', type: 'error' })
            case 4:
              return this.$message({ message: '选中的单据中，存在未审核或未出库的相关单据, 不能再发货!', type: 'error' })
            default:
              return this.$message({ message: '未知原因：' + data, type: 'error' })
          }
        } else {
          let order = { SOModels: data }
          this.$store.commit('setParamOrder', order)
          this.$store.state.visibled = false
          // this.editType = 'e'
          this.$nextTick(function () {
            this.stkOutEditVisible = true // 显示编辑页面
          })
        }
      })
    },
    onStkOut() {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 0 || rows[0] === undefined) {
        return this.$message({ message: '请选择一条数据进行操作', type: 'warning' })
      }
      this.doStkOut(rows)
    },
    dateFormat(value) {
      if (value === '') {
        return value
      }
      try {
        let date = new Date(Date.parse(value))
        if (date) {
          let month = (date.getMonth() + 1).toString()
          let day = date.getDate().toString()
          if (month.length === 1) { month = '0' + month }
          if (day.length === 1) { day = '0' + day }
          return date.getFullYear().toString() + '-' + month + '-' + day
        }
      } catch (error) {
        return value
      }
    },
    onChangeOverdueDay(val) {
      this.OverdueDay = val.key
      this.$refs.flexGrid.search()
    }
  }
}
</script>

<style lang="scss">
</style>
