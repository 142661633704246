<template>
  <znl-layout-spa :page-loading="pageLoading">
    <div class="second-tab-div">
      <tencent-qdw-inquiry :init-data="false" @page-loading="setClientSelectLoading"></tencent-qdw-inquiry>
    </div>
  </znl-layout-spa>
</template>
<script>
import TencentQdwInquiry from "@c_modules/QDW/TencentQDW_Inquiry";

export default {
  name: "VTencentQDW_Inquiry",
  components: {
    TencentQdwInquiry
  },
  data() {
    return {
      pageData: [],
      pageLoading: false
    };
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    }
  },
  methods: {
    pageSearch() {},
    setClientSelectLoading(val) {
      this.pageLoading = val;
    }
  },
  async created() {}
};
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_variables.scss";
</style>
