
<template>
  <!-- 采购单 -->
  <!-- :totalField="['Amount']" -->
  <div class="module-wrapper m-purchase-order">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-purchase-order"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      :totalField="['Amount']"
      checkboxBindingKey="POGuid"
      @on-refresh-click="onSearch"
      @current-row-change="onCurrentRowChangeHandle"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('OrdPurchaseOrder')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @cell-click="cellClick"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :border="true"
          v-model="ordpurchaseOrder.pOLineModel"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="销售单号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :border="true"
          :clearable="true"
          v-model="ordpurchaseOrder.soBillNo"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="跟踪码"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :border="true"
          v-model="ordpurchaseOrder.trackCode"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="PO号码"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :border="true"
          v-model="ordpurchaseOrder.pONumber"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            class="box_input_checkbox"
            placeholder="供应商名称"
            size="mini"
            layout="left"
            width="160px"
            inp-perc="100%"
            v-model="ordpurchaseOrder.SupplierName"
            :clearable="false"
            :border="true"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox
            v-model="isLikeSearch"
            class="accurate-checbox"
            size="small"
            >精确</el-checkbox
          >
        </div>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="采购日期从"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="fieldsFilter.startPODate"
        ></znl-input>
        <span>到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="采购日期止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="fieldsFilter.endPODate"
        ></znl-input>
        <znl-button
          type="multiple"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button
          style-type="main"
          @click="onPurchaseOrderAdd"
          v-if="isShowAdd"
        >
          <span> <i class="iconfont icon-add_btn_ic"></i>添加 </span>
        </znl-button>
        <znl-button style-type="main" @click="onImportDeclaration">
          <span> <i class="iconfont icon-login-full"></i>进口报关 </span>
        </znl-button>
        <znl-button style-type="mac" v-if="isShowPrint" type="multiple">
          <znl-button-menu @click="onPrint">
            <span> <i class="iconfont icon-print_btn_ic"></i>打印 </span>
          </znl-button-menu>
          <znl-button-menu
            :width="16"
            tip="打印设置"
            @click="znlPrintVisible = true"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-print
          :visible.sync="znlPrintVisible"
          :printInfo="printInfo"
          :loading="printLoading"
          :is-get-default-param="printInfo.isGetDefaultParam"
          print-type="ORD_PurchaseOrder"
          title="采购单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
        <znl-button
          style-type="mac"
          v-if="isShowPrint"
          :btns="exportBtns"
          :height="22"
          @click="onExport"
          tip="按搜索条件"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
        <GlobalCargo style="position: relative; top: 7px"></GlobalCargo>
      </div>
    </znl-table>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="
        () => {
          setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          setExportColumnVisible = false;
        }
      "
    ></export-column>

    <operate-log
      v-if="operateLogVisible"
      ref="OperateLog"
      @close="
        () => {
          operateLogVisible = false;
        }
      "
      :visible="operateLogVisible"
      :orderGuid="orderGuid"
      :refreshLog="refreshLog"
      height="50%"
    ></operate-log>

    <ord-purchase-order-edit
      :editvisible="ordEditVisible"
      v-if="ordEditVisible"
      :edit-type="editType"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @confirm="
        () => {
          (ordEditVisible = false), onBindData(pageIndex);
        }
      "
      @close="ordEditVisible = false"
    ></ord-purchase-order-edit>

    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      @confirm="stkInEditVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @close="stkInEditVisible = false"
    ></stk-in-edit>

    <ord-purchase-return-edit
      :editvisible="ordReturnEditVisible"
      v-if="ordReturnEditVisible"
      :edit-type="editType"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @confirm="ordReturnEditVisible = false"
      @close="ordReturnEditVisible = false"
    ></ord-purchase-return-edit>

    <!-- 单据附件 -->
    <OrderAttachment
      v-if="OrderAttachmentVisible"
      ref="OrderAttachment"
      :Title="'采购单附件'"
      :OrderType="2"
      :OrderId="OrderId"
      :Visible="OrderAttachmentVisible"
      @close="orderAttachmentClose"
    ></OrderAttachment>
  </div>
</template>
<style>
.redLink {
  cursor: pointer;
  color: red;
  text-decoration: underline;
}
.blueLink {
  cursor: pointer;
  color: #0092dd;
  text-decoration: underline;
}
</style>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import OperateLog from "@c_modules/Log/OperateLog";
import OrdPurchaseOrderEdit from "@c_modules/Purchase/OrderEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import OrdPurchaseReturnEdit from "@c_modules/Purchase/OrderReturnEdit";
import { purchaseToSCM, openSCM } from "~/assets/scripts/methods/clientTabPage";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import GlobalCargo from "~/components/common/global-cargo/index";
import OrderAttachment from "~/components/common/orderAttachment/OrderAttachment";

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  configURL: "OrdPurchaseOrder/GetConfig",
  saveConfigURL: "OrdPurchaseOrder/SaveConfig",
  resetParamConfigURL: "OrdPurchaseOrder/ResetParamConfig",
  defaultValURL: "OrdPurchaseOrder/Default",
  searchURL: "OrdPurchaseOrder/Search",
  deleteURL: "OrdPurchaseOrder/DeleteOrder",
  onExamineURL: "OrdPurchaseOrder/Examine",
  onAntiTrialURL: "OrdPurchaseOrder/AntiTrial",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  PurchaseGotoStkInGUID: "OrdPurchaseOrder/PurchaseGotoStkInGUID",
  WebPrintURL: "WebPrint/PurchaseOrderPrint",
  DownloadPrintFile: "WebPrint/DownloadPrintFile",
  AddInfoCollectLogUrl: "InfoCollection/AddLog", //添加点击记录日志
};

export default {
  name: "PurchaseOrder",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler, printInfoMixin],
  config: CONFIG,
  components: {
    ExportColumn,
    OperateLog,
    OrdPurchaseOrderEdit,
    StkInEdit,
    ZnlPrint,
    OrdPurchaseReturnEdit,
    GlobalCargo,
    OrderAttachment,
  },
  data() {
    return {
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          },
        },
      ],
      setSaveConfigURL: "",
      defaultConfig: {},
      addColumnsList: [],
      setExportColumnVisible: false,
      isShowFiedsSearch: false,
      editType: "edit",
      stkInEditVisible: false,
      ordReturnEditVisible: false,
      POGuid: "",
      ordEditVisible: false,
      IsContinuousRecord: false,
      ordpurchaseOrder: {
        pOLineModel: "",
        soBillNo: "",
        SupplierName: "",
        IsAuditedName: "",
        MatchOperation: "",
        startPODate: "",
        endPODate: "",
        trackCode: "",
        pONumber: "",
      },
      columns: [],
      itemSource: [],
      isLikeSearch: false,
      fieldsFilter: {
        pOLineModel: "",
        IsAuditedName: "",
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      showInAdd: false, // 是否显示入库单
      operateLogVisible: false,
      orderGuid: "",
      refreshLog: true,
      parameterConfig: [],
      ParamJosn: [],
      IsAutoPrint: false, // 是否自动打印模板
      Isdisabled: !this.$store.state.accountInfo.IsMainAccount,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true },
      ],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "ORD_PurchaseOrder", // 打印模板类型
      },
      isShowPrint: false,
      isShowAdd: false,
      loading: false,
      currencyList: [],
      userList: [],
      taxRateList: [],
      OrderAttachmentVisible: false, //单据附件
      OrderId: "", //主单Id
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );
    this.userList = _.map(await this.selectOptionsAllUserURL, function (obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config || config === undefined) {
        config = await this.isSaveStorage(
          "ordPurchaseOrder",
          this.$options.config.configURL
        );
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "BuyerName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList,
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.taxRateList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: this.auditedOptions,
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            if (rowData.IsAudited != undefined) {
              var status = _.find(this.options, function (item) {
                return item.value === rowData.IsAudited;
              });
              if (rowData.IsAudited == true) {
                //已审
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }

      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "StkInStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            { key: "未入", value: 1 },
            { key: "部分入", value: 2 },
            { key: "已入", value: 3 },
          ],
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            if (rowData.StkInStatus != undefined) {
              var status = _.find(this.options, function (item) {
                return item.value === rowData.StkInStatus;
              });
              if (rowData.StkInStatus != 3) {
                //非已入
                return '<div class="redLink">' + status.key + "</div>";
              } else {
                return '<div class="blueLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }
      this.defaultConfig = config;
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "ReturnStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            { key: "未退", value: 1 },
            { key: "部分退", value: 2 },
            { key: "已退", value: 3 },
          ],
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            if (rowData.ReturnStatus != undefined) {
              var status = _.find(this.options, function (item) {
                return item.value === rowData.ReturnStatus;
              });
              if (rowData.ReturnStatus == 1) {
                //未退
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }

      // 附件列 start
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "OrderAttachment"
      );
      if (colIndex > -1) {
        let _this = this;
        _.extend(columns[colIndex], {
          type: "icon",
          width: 34,
          isFrozen: false,
          canSearch: false,
          classNames: [
            "show-hand icon iconfont icon-pic_btn_ic color-ccc",
            "show-hand icon iconfont icon-pic_btn_ic color-green",
          ],
          status(row) {
            return 1;
          },
          click(row) {
            _this.OrderId = row.POGuid; //单据Guid
            _this.OrderAttachmentVisible = true; //显示单据附件
          },
        });
      }
      // 附件列 end

      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration();

      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "编辑",
          name: "Edit",
          click: (row) => {
            this.onPurchaseOrderEdit(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: (row) => {
            this.onPurchaseOrderDel(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: (row) => {
            this.onPostExamine(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: (row) => {
            this.onPostAntiTrial(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("StorageIn")) {
        mouseBtn.push({
          title: "入库",
          name: "StorageIn",
          click: (row) => {
            this.onStorage(row, false);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Return")) {
        mouseBtn.push({
          title: "退货",
          name: "Return",
          click: (row) => {
            this.onStorage(row, true);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        mouseBtn.push({
          title: "日志",
          name: "OperateLog",
          click: (row) => {
            this.operateLogVisible = true;
            this.$nextTick(() => {
              this.orderGuid = row.POGuid;
              this.refreshLog = !this.refreshLog;
            });
          },
        });
      }
      if (this.isMainAccount || this.hasRes("AddCustomsOrder")) {
        mouseBtn.push({
          title: "报关",
          name: "AddCustomsOrder",
          click: (row) => {
            this.$nextTick(() => {
              purchaseToSCM(row.POGuid);
            });
          },
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
        },
      ];
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      columns = tempConfig.concat(columns);

      this.isShowPrint = this.isMainAccount || this.hasRes("Print");
      this.isShowAdd = this.isMainAccount || this.hasRes("Add");
      this.columns = columns;
      if (
        this.$route.params != undefined &&
        this.$route.params.poNumber != undefined
      ) {
        this.ordpurchaseOrder.pONumber = this.$route.params.poNumber;
      }
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.$nextTick(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },
    //关闭单据附件
    orderAttachmentClose() {
      this.OrderAttachmentVisible = false;
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    setPoNumber(poNumber) {
      this.ordpurchaseOrder.pOLineModel = "";
      this.ordpurchaseOrder.soBillNo = "";
      this.ordpurchaseOrder.MatchOperation = "";
      this.ordpurchaseOrder.SupplierName = "";
      this.fieldsFilter.startPODate = "";
      this.fieldsFilter.endPODate = "";
      this.ordpurchaseOrder.pONumber = poNumber;
      this.ordpurchaseOrder.trackCode = "";

      this.onBindData(1);
    },
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "OrdPurchaseOrder",
        this.getSearchWhere(1),
        this.columns,
        "采购单"
      );
    },
    getSearchWhere(pageIndex) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          pOLineModel: this.ordpurchaseOrder.pOLineModel,
          soBillNo: this.ordpurchaseOrder.soBillNo,
          MatchOperation: this.ordpurchaseOrder.MatchOperation,
          SupplierName: this.ordpurchaseOrder.SupplierName,
          startPODate: this.fieldsFilter.startPODate,
          endPODate: this.fieldsFilter.endPODate,
          pONumber: this.ordpurchaseOrder.pONumber,
          trackCode: this.ordpurchaseOrder.trackCode,
          AllowViewFollowBill:
            this.getCompanySetupValue("AllowViewFollowBill") === "1",
        }
      );
      return data;
    },
    cellClick(cellData) {
      if (cellData.field == "StkInStatus") {
        if (cellData.row["StkInStatus"] == 1) {
          //未入
          if (this.isMainAccount || this.hasRes("StorageIn")) {
            this.onStorage(cellData.row, false);
          }
        } else {
          this.$emit("tab-change", {
            tabName: "stkInManage",
            billNo: cellData.row["BillNo"],
          });
        } //部分发或已发
      } else if (cellData.field == "IsAudited") {
        if (!cellData.row["IsAudited"]) {
          //未审
          if (this.isMainAccount || this.hasRes("Audit")) {
            this.onPostExamine(cellData.row);
          }
        }
      } else if ((cellData.field = "PONumber" && cellData.row.PONumber != "")) {
        this.switchTab("Sales");
        this.$router.push({
          path: "OrderManage",
          name: "Sales/OrderManage",
          params: {
            poNumber: cellData.row.PONumber,
          },
        });
      }
    },
    // 获取参数配置
    paramConfiguration() {
      // 单据打印后自动保存 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "1") {
        this.IsAutoPrint = true;
      }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinuousRecord = true;
      }
      // 默认币种
      let paramCurrencyCode = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "CurrencyCode") {
          return paramjosn.ParamValue;
        }
      });
      if (paramCurrencyCode) {
        this.CurrencyCode = paramCurrencyCode.ParamValue;
      }

      // 默认税率
      let paramTaxRate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "TaxRate") {
          return paramjosn.ParamValue;
        }
      });
      if (paramTaxRate) {
        this.TaxRate = paramTaxRate.ParamValue;
      }

      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }

      // 设置打印
      this.printInfo.template = this.getPrintTemplate(this.ParamJosn);
      this.printInfo.title = this.getPrintTitle(this.ParamJosn);
    },

    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "OrdPurchaseOrder", ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("ordPurchaseOrder");
          }
        );
      }
    },

    onPrint() {
      //网页版打印
      if (!isClient()) {
        this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.POGuid)) {
        return this.$message({
          message: "请选中要打印的采购单",
          type: "error",
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）

      if (!this.hasValue(tableName)) {
        this.$message({ message: "请先选择打印模板", type: "error" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: row.POGuid,
          SubCompanyId: titleId,
        };
        this.printPurchaseOrder(printParm, () => {});
      }
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      // this.itemSource = [];
      let matchOperation = this.isLikeSearch === true ? "Equal" : "Like";
      this.ordpurchaseOrder.MatchOperation = matchOperation;
      let data = this.getSearchWhere(pageIndex);
      this.loading = true;
      return await this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if (this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 审核请求
    onPostExamine(row) {
      if (!this.hasValue(row) || !this.hasValue(row.POGuid)) {
        return;
      }
      this.$post(
        CONFIG.onExamineURL,
        { ListPurchaseGuid: [row.POGuid] },
        (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "审核成功", type: "success" });
            this.onBindData(this.pageIndex);
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
          return data;
        }
      );
    },
    // 反审核请求
    onPostAntiTrial(row) {
      if (!this.hasValue(row) || !this.hasValue(row.POGuid)) {
        return;
      }
      this.$post(
        CONFIG.onAntiTrialURL,
        { ListPurchaseGuid: [row.POGuid] },
        (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "反审成功", type: "success" });
            this.onBindData(this.pageIndex);
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
          return data;
        }
      );
    },

    // 添加
    onPurchaseOrderAdd() {
      this.$store.state.visibled = false;
      this.$nextTick(function () {
        this.editType = "addNew";
        this.ordEditVisible = true;
      });
    },
    //进口报关
    onImportDeclaration() {
      //打开供应链
      openSCM({ key1: 1, key2: "test" });
      try {
        var param = {
          collectionType: "供应链入口",
          eventName: "采购单管理-进口报关",
        };

        //记录日志
        this.$post(CONFIG.AddInfoCollectLogUrl, param, (data, logic) => {});
      } catch (error) {
        console.log("进口报关记录失败:", error);
      }
    },
    // 编辑
    onPurchaseOrderEdit(row) {
      if (!this.hasValue(row)) {
        return;
      }
      let paramOrder = { POGuid: row.POGuid };
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function () {
        this.editType = "edit";
        this.ordEditVisible = true;
      });
    },
    // 删除
    onPurchaseOrderDel(row) {
      this.$confirm("确认要删除该采购单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return this.$post(
          CONFIG.deleteURL,
          { DelPOGuids: [row.POGuid] },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }
        );
      });
    },
    // 入库
    onStorage(row, IsReturn) {
      if (!this.hasValue(row) || !this.hasValue(row.POGuid)) {
        return this.$message({
          message: "请选择要入库的采购单",
          type: "warning",
        });
      }
      if (!IsReturn) {
        if (row.IsAudited === false) {
          return this.$message({
            message: "单号:【" + row.BillNo + "】先审核再入库",
            type: "warning",
          });
        }
      } else {
        // 退货
        if (
          row.StkInStr !== "全部入库" &&
          row.StkInStr !== "部分入库" &&
          row.StkInStr !== "部分退货"
        ) {
          return this.$message({
            message: "单号:【" + row.BillNo + "】尚未入库，不可退货",
            type: "warning",
          });
        }
      }
      this.editType = "edit";
      let msg = "";
      if (IsReturn) {
        msg = "存在对应未出库的退货单,是否立即查看?";
      } else {
        msg = "存在对应未入库的入库单,是否立即查看?";
      }
      this.$post(
        CONFIG.PurchaseGotoStkInGUID,
        { POGuid: row.POGuid, IsReturn: IsReturn },
        (data) => {
          if (
            data != null &&
            data.StkInGUID !== null &&
            data.StkInGUID !== undefined
          ) {
            this.$confirm("采购单【" + row.BillNo + "】" + msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              let datas = {};
              IsReturn
                ? (datas = { stkInGUID: data.StkInGUID, IsReturn: false })
                : (datas = { stkInGUID: data.StkInGUID });
              this.$store.commit("setParamOrder", datas);
              IsReturn
                ? (this.ordReturnEditVisible = true)
                : (this.stkInEditVisible = true);
            });
          } else {
            let datas = {
              POModels: { POGuid: row.POGuid, IsReturn: IsReturn },
            };
            this.$store.commit("setParamOrder", datas);
            IsReturn
              ? (this.ordReturnEditVisible = true)
              : (this.stkInEditVisible = true);
          }
        }
      );
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    onRefresh() {
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },
    onChange(val) {
      if (val) {
        this.isLikeSearch = false;
      } else {
        this.isLikeSearch = true;
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      }
      if (row) {
        this.$emit("selection-changed", row);
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("OrdPurchaseOrder", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("OrdPurchaseOrder", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("OrdPurchaseOrder", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("OrdPurchaseOrder");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.POGuid)) {
        return this.$message({
          message: "请选中要打印的采购单",
          type: "error",
        });
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "warning" });
        this.znlPrintVisible = true;
        return;
      }

      if (!this.hasValue(titleId) || titleId <= 0) {
        this.$message({ message: "请选择抬头", type: "warning" });
        this.znlPrintVisible = true;
        return;
      }

      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.POGuid,
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if (data) {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl); // 下载文件
          }
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>

