<template>
    <znl-layout-spa :page-loading="pageLoading" znl-loading-text="页面加载中,请稍后...">
      <znl-inquire-search
        v-show="!pageLoading"
        slot="znlLayoutSpa"
        class="znlLayoutSpa-v-inquire-search"

        @page-loading="
          loading => {
            pageLoading = loading;
          }
        "
      />
    </znl-layout-spa>
</template>

<script>
import ZnlInquireSearch from "@c_modules/Purchase/InquireSearch";

export default {
  components: {
    ZnlInquireSearch
  },
  data() {
    return {
      pageData: [],
      pageLoading: true,
    }
  },
  methods: {
    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VPurchaseManage" });
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.Code === code);
    }
  },
  async created() {
  this.getPageData()
    .then(data => {
      this.pageData = data;
      this.$nextTick(() => {

      });
    })
    .catch(e => {
      // console.log(e)
    });
  }
}
</script>

<style lang="scss" scoped>
.znlLayoutSpa-v-inquire-search{
  height: 100%;
  margin-left: 4px;
  background-color: white;
}
</style>
