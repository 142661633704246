<template>
  <div>
    <znl-dialog
      v-loading="loading"
      :close-on-click-modal="false"
      :element-loading-text="loadingText"
      :is-footer-show="false"
      :visible="editvisible"
      class="dialog-sales-order-reserve-add-box"
      height="90vh"
      title="销售备货"
      width="90vw"
      @close="$emit('close')"
    >
      <znl-layout-multiple class="m-multiple-box">
        <!-- 销售明细 -->
        <!-- <multiple>
          <znl-sales-order-line-reserve
            class="sales-order-line"
            header="待备货物料"
            ref="salesOrderLine"
            :searchModel="salesOrderLineSearchModel"
            @selection-changed="salesOrderLineChange"
            @on-submit-reserve="
              (val, callback) => {
                this.onSubmitReserve(val, callback);
              }
            "
            @on-automatch="
              (val) => {
                this.onAutomatch(val);
              }
            "
            @page-loading="
              (d) => {
                this.loading = d;
              }
            "
          ></znl-sales-order-line-reserve>
        </multiple> -->

        <!-- 我的库存 -->
        <Split
          class="split-content"
          direction="vertical"
          @onDragEnd="onDragEndBySales"
        >
          <SplitArea :size="33" class="sales-order-line">
            <znl-sales-order-line-reserve
              ref="salesOrderLine"
              :searchModel="salesOrderLineSearchModel"
              header="待备货物料"
              @selection-changed="salesOrderLineChange"
              @on-submit-reserve="
                (val, callback) => {
                  this.onSubmitReserve(val, callback);
                }
              "
              @on-automatch="
                (val) => {
                  this.onAutomatch(val);
                }
              "
              @page-loading="
                (d) => {
                  this.loading = d;
                }
              "
            ></znl-sales-order-line-reserve>
          </SplitArea>
          <SplitArea :size="33" class="znl-multiple-stock-select">
            <stock-select
              ref="stockSelect"
              :height="220"
              :init-data="false"
              :is-init="true"
              :searchModel.sync="stockSearchModel"
              header="批次库存"
              page-position="top"
              @stock-check-row="
                (val) => {
                  this.StockCheckRow(val);
                }
              "
            ></stock-select>
          </SplitArea>
          <SplitArea :size="33" class="sale-order-reserve-stock">
            <znl-sale-order-reserve-stock
              ref="saleOrderReserveStock"
              :init-data="false"
              :is-init="true"
              :searchModel="stockReserveSearchModel"
              :SOGuid="SOGuid"
              :initData="true"
              page-position="top"
              @close="
                (val) => {
                  this.onClose(val);
                }
              "
              @loading="
                (val) => {
                  this.saleOrderReserveStockResetTableH();
                  this.loading = val;
                }
              "
              @cell-click="onReserveStockCellClick"
              @selected-stock-Line="
                (val) => {
                  this.TotalSelectStockQty(val);
                }
              "
              @auto-change-Qty="
                (val) => {
                  this.autoChangeQty(val);
                }
              "
              @on-changed-qty="
                (solineGUID, qty) => {
                  this.changeQty(solineGUID, qty);
                }
              "
            ></znl-sale-order-reserve-stock>
          </SplitArea>
          <!-- <SplitArea :size="35" :minSize="35"> </SplitArea> -->
        </Split>

        <!-- 备货库存 -->
        <!-- <multiple>

        </multiple> -->
      </znl-layout-multiple>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { mixin as common } from "~assets/scripts/methods/common";
import StockSelect from "@c_modules/Stk/StockSelect";
import ZnlSaleOrderReserveStock from "@c_modules/Sale/SaleOrderReserveStock";
import ZnlSalesOrderLineReserve from "@c_modules/Sale/SalesOrderLineReserve";

const CONFIG = {
  PK: "StkOutLineID",
};
export default {
  name: "SalesOrderReserveAdd",
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin, common],
  components: {
    StockSelect,
    ZnlSaleOrderReserveStock,
    ZnlSalesOrderLineReserve,
  },
  data() {
    return {
      loading: false,
      isCheck: false,
      loadingText: "数据加载中,请稍后...",
      // 我的库存
      stockSearchModel: {
        Model: "",
      },
      // 备货库存
      stockReserveSearchModel: {
        Model: "",
        SOLineGUID: "",
      },
      // 销售明细
      salesOrderLineSearchModel: {
        Model: "",
      },
    };
  },
  props: {
    editType: String,
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    autoAddNewRow: {
      // 是否在明细自动增加一行
      type: Boolean,
      default: () => {
        return true;
      },
    },
    SOGuid: null,
  },
  watch: {
    SOGuid: {
      handler(value) {
        // 根据传入参数加载页面
        if (!_.isEmpty(value)) {
          this.onInit(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 初始化
    onInit(val) {
      this.loadingText = "数据加载中,请稍后...";
      this.loading = true;
      if (val !== undefined && val !== null) {
        this.LoadSaleData(val);
      }
      //console.log('$nextTick 111 ');
      this.$nextTick(function () {
        //console.log('$nextTick 222 ');
        this.pageDomHeightSet();
        // this.saleOrderReserveStockResetTableH();
        // this.salesOrderLineResetTableH();
      });
    },

    // 拖拽调整高度
    onDragEndBySales(e) {
      //console.log("拖拽调整高度");
      // this.pageDomHeightSet();
      this.$refs.salesOrderLine.resetTableH();

      this.$refs.stockSelect.resetTableH();

      this.$refs.saleOrderReserveStock.resetTableH();
    },

    //获取弹窗内容的高度
    getDialogContentHeight() {
      let parentDom = document.querySelector(
        ".dialog-sales-order-reserve-add-box .znl-dialog"
      );

      let handerDom = document.querySelector(
        ".dialog-sales-order-reserve-add-box .znl-dialog__header"
      );

      let parentDomHeight = parseFloat(
        window.getComputedStyle(parentDom).height
      );

      let handerDomHeight = parseFloat(
        window.getComputedStyle(handerDom).height
      );

      let contentHeight = parentDomHeight - handerDomHeight - 4;

      return contentHeight;
    },

    // 页面元素高度设置
    pageDomHeightSet() {
      //console.log("页面元素高度设置 222 ");
      let contentHeight = this.getDialogContentHeight();

      let itemHeight = contentHeight * 0.32;

      //console.log(" 111 itemHeight is ", itemHeight);

      let splitContent = document.querySelector(
        ".dialog-sales-order-reserve-add-box .split-content"
      );

      //console.log('splitContent is ',splitContent,'contentHeight is ',contentHeight);

      splitContent.style.height = contentHeight + "px";

      let setDom = [
        ".dialog-sales-order-reserve-add-box .sales-order-line",
        ".dialog-sales-order-reserve-add-box .znl-multiple-stock-select",
        ".dialog-sales-order-reserve-add-box .sale-order-reserve-stock",
      ];

      let setContentDom = [
        ".dialog-sales-order-reserve-add-box .sales-order-line .znl-grid-component",
        ".dialog-sales-order-reserve-add-box .znl-multiple-stock-select .znl-grid-component",
        ".dialog-sales-order-reserve-add-box .sale-order-reserve-stock .znl-grid-component",
        ".dialog-sales-order-reserve-add-box .sale-order-reserve-stock .table-container",
      ];

      // itemHeight = itemHeight - 10 ;

      setDom.forEach((item) => {
        let itemDom = document.querySelector(item);
        if (itemDom) itemDom.style.height = itemHeight + "px";
      });

      setContentDom.forEach((item) => {
        let itemDom = document.querySelector(item);
        if (itemDom) itemDom.style.height = itemHeight - 10 + "px";
      });
    },

    salesOrderLineChange(item) {
      if (!_.isEqual(this.stockReserveSearchModel.Model, item.Model)) {
        this.stockReserveSearchModel.SOLineGUID = item.SOLineGuid;
        this.stockReserveSearchModel.SOGUID = item.SOGuid;
        //  UnReserveQty 待备货量
        this.stockReserveSearchModel.Qty = item.UnReserveQty;
      }
      if (!_.isEqual(this.stockSearchModel.Model, item.Model)) {
        this.stockSearchModel.Model = item.Model;
      }
    },
    onReserveStockCellClick(item) {
      if (!_.isEqual(this.stockSearchModel.Model, item.Model)) {
        this.stockSearchModel.Model = item.Model;
      }
    },
    // 点击备货
    LoadSaleData(val) {
      this.$nextTick(function () {
        this.$refs.salesOrderLine.LoadSaleData(val);
      });
    },
    StockCheckRow(item) {
      this.$refs.saleOrderReserveStock.StockCheckRow(item);
    },

    onSubmitReserve(val, callback = null) {
      // this.loadingText = "正在保存数据,请稍后...";
      // this.loading = true;
      this.$refs.saleOrderReserveStock.onSubmitReserve(val, callback);
    },
    onAutomatch(val) {
      this.loading = true;
      this.$refs.saleOrderReserveStock.onAutomatch(val);
    },
    onClose(val) {
      this.$emit("close");
    },
    TotalSelectStockQty(val) {
      this.$refs.salesOrderLine.selectedStockLine(val);
    },
    changeQty(soLineGUID, qty) {
      this.$refs.salesOrderLine.changeQty(soLineGUID, qty);
    },
    autoChangeQty(val) {
      this.$refs.salesOrderLine.autoChangeQty(val);
    },
  },
  mounted() {},
  created() {
    this.specialResource = this.selectOptionsSpecialResource;
  },
};
</script>
<style lang="scss">
.dialog-sales-order-reserve-add-box {
  .table-container {
    height: 100% !important;
  }

  // .sales-order-line{
  //   box-sizing: border-box;
  // }

  // .el-table {
  //   td {
  //     padding: 0 3px !important;
  //     max-height: 26px !important;
  //     box-sizing: border-box;
  //   }
  //   th {
  //     padding: 0 !important;
  //     max-height: 24px !important;
  //     box-sizing: border-box;
  //   }
  // }

  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 15px 30px;
  }

  .flex-between {
    padding-top: 6px;
  }

  .znl-layout-topbottom > .znlTop {
    border-bottom: 0;
  }

  .grid-data-order-reserve-add-box .has-gutter tr th {
    background: #ebeef5;
  }

  .znl-dialog.el-dialog .el-dialog__header {
    height: 100%;
  }

  .znl-content .base-flex-grid {
    padding: 0;
    background: #fff;
  }

  .el-pagination button,
  .footer .el-pager li {
    background: transparent;
  }
}
</style>

