<template>
  <!-- 采购退货单 -->
  <div class="module-wrapper m-purchase-return">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-purchase-return"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :showCheck="true"
      checkboxBindingKey="StkInGUID"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      :totalField="['PayablesAmount']"
      @on-refresh-click="onSearch"
      @current-row-change="onCurrentRowChangeHandle"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('OrdPurchaseReturn')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="fieldsFilter.inputModel"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>

        <znl-input
          from-type="input"
          :disable="false"
          placeholder="采购单号"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="fieldsFilter.poBillNO"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            class="box_input_checkbox"
            placeholder="供应商名称"
            size="mini"
            layout="left"
            width="160px"
            inp-perc="100%"
            v-model="fieldsFilter.SupplierName"
            :clearable="false"
            :border="true"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <el-checkbox v-model="isLikeSearch" class="accurate-checbox" size="small">精确</el-checkbox>
        </div>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="退货时间从"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width="100px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="fieldsFilter.startReceiveTime"
        ></znl-input>
        <span>到</span>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="退货时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="fieldsFilter.endReceiveTime"
        ></znl-input>

        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac" v-if="isShowPrint" type="multiple">
          <znl-button-menu @click="onPrint">
            <span>
              <i class="iconfont icon-print_btn_ic"></i>打印
            </span>
          </znl-button-menu>
          <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" @click="onBatchPay" v-if="isShowPay">
          <i class="iconfont icon-table_money_ic"></i>
          <span>批量付款</span>
        </znl-button>
      </div>
    </znl-table>

    <znl-print
      :visible.sync="znlPrintVisible"
      :printInfo="printInfo"
      :loading="printLoading"
      :is-get-default-param="printInfo.isGetDefaultParam"
      print-type="STK_In"
      title="退货单打印设置"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>

    <operate-log
      ref="OperateLog"
      :visible="operateLogVisible"
      v-if="operateLogVisible"
      :orderGuid="orderGuid"
      @close="operateLogVisible=false"
      :refreshLog="refreshLog"
    ></operate-log>

    <!--采购退货-->
    <ord-purchase-return-edit
      :editvisible="OrdPurchaseRetrunVisible"
      v-if="OrdPurchaseRetrunVisible"
      :edit-type="editType"
      @confirm="OrdPurchaseRetrunVisible=false"
      @close="OrdPurchaseRetrunVisible=false"
      @save-success="()=>{this.onRefresh()}"
    ></ord-purchase-return-edit>

    <stk-batch-pay
      ref="StkBatchPay"
      :visible="payVisible"
      v-if="payVisible"
      @close="()=>{payVisible=false,this.stkInGUIDS = null}"
      :isFinRecPay="isFinRecPay"
      :stkInGUIDS="stkInGUIDS"
      @confirm="(val)=>{val&&(payVisible=false),this.onRefresh(),this.stkInGUIDS = null}"
    ></stk-batch-pay>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import OperateLog from "@c_modules/Log/OperateLog";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import OrdPurchaseReturnEdit from "@c_modules/Purchase/OrderReturnEdit";
import { erpTableSetHandler } from "@scripts/methods/common";
import StkBatchPay from "@c_modules/Fin/StkBatchPay";
import { isClient } from "~/lib/runtime";
import * as urls from '~/lib/urls'

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "StkInGUID",
  configURL: "OrdPurchaseReturn/GetConfig",
  resetParamConfigURL: "OrdPurchaseReturn/ResetParamConfig",
  searchURL: "OrdPurchaseReturn/Search",
  onExamineURL: "OrdPurchaseReturn/Examine",
  onAntiTrialURL: "OrdPurchaseReturn/AntiTrial",
  onAbortURL: "OrdPurchaseReturn/Abort",
  onCancelAnOrderURL: "OrdPurchaseReturn/CancelAnOrder",
  onStorageURL: "OrdPurchaseReturn/Storage",
  onDeleteURL: "OrdPurchaseReturn/DeleteList",
  searchSumAmountURL: "StkBatchPay/SumAmount",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  WebPrintURL:'WebPrint/PurchaseReturnPrint',
  DownloadPrintFile:'WebPrint/DownloadPrintFile',
};

export default {
  name: "OrdPurchaseReturn",
  config: CONFIG,
  mixins: [getCommonDataMixin, erpTableSetHandler, printInfoMixin],
  components: {
    OperateLog,
    StkInEdit,
    ZnlPrint,
    OrdPurchaseReturnEdit,
    StkBatchPay
  },
  data() {
    return {
      isShowFiedsSearch: false,
      editType: "addNew",
      dialogCompanySelectVisible: false,
      inputModel: "",
      OrdPurchaseRetrunVisible: false,
      isLikeSearch: false,
      stkInGUID: "", // 需要传递给编辑页面的GUID
      stkIn: {
        startReceiveTime: "",
        endReceiveTime: "",
        StartPaidTime: "",
        EndPaidTime: "",
        searchModel: {
          data: {},
          extra: {}
        }
      },
      columns: [],
      itemSource: [],
      fieldsFilter: {
        startReceiveTime: "",
        endReceiveTime: "",
        StartPaidTime: "",
        EndPaidTime: "",
        inputModel: "",
        poBillNO: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      totalCountLog: 1,
      resourceList: [],
      operateLogVisible: false,
      payVisible: false,
      orderGuid: "",
      stkInGUIDS: [],
      refreshLog: true,
      isFinRecPay: false,
      parameterConfig: [],
      ParamJosn: [],
      isShowPrint: false,
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "STK_In" // 打印模板类型
      },
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      currencyList: [],
      userList: [],
      taxRateList: [],
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true }
      ],
      isShowPay: false
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(await this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage(
          "ordPurchaseReturn",
          CONFIG.configURL
        );
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      // 设置打印
      this.printInfo.template = this.getPrintTemplate(config.ParamJosn);
      this.printInfo.title = this.getPrintTitle(config.ParamJosn);
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Receiver");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.taxRateList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.auditedOptions
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "未出", value: 1 },
            { key: "已出", value: 3 },
            { key: "已撤", value: 9 }
          ]
        });
      }
      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "编辑",
          name: "Edit",
          click: row => {
            this.onOrdPurchaseReturnEdit(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: row => {
            this.onPostDelete(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("In")) {
        mouseBtn.push({
          title: "出库",
          name: "In",
          click: row => {
            this.onPostStorage(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Cancel")) {
        mouseBtn.push({
          title: "撤单",
          name: "Cancel",
          click: row => {
            this.onPostCancelAnorder(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: row => {
            this.onPostExamine(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: row => {
            this.onpostAntiTrial(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        mouseBtn.push({
          title: "日志",
          name: "OperateLog",
          click: row => {
            this.operateLogVisible = true;
            this.$nextTick(() => {
              this.orderGuid = row.StkInGUID;
              this.refreshLog = !this.refreshLog;
            });
          }
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true
        }
      ];
      this.isShowPrint = this.hasRes("Print");
      this.isShowPay = this.hasRes("In");
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      columns = tempConfig.concat(columns);

      this.columns = columns;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },

    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
      let datas = _.find(this.ParamJosn, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "OrdPurchaseReturn", ParamJson: [datas] },
          data => {
            localStorage.removeItem("ordPurchaseReturn");
          }
        );
      }
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onPrint() {
      //网页版打印
      if(!isClient())
      {
        this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选中要打印的退货单",
          type: "error"
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "warning" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        this.printStkIn({
          TableName: tableName,
          Guid: row.StkInGUID,
          SubCompanyId: titleId
        });
      }
    },

    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          startReceiveTime: this.fieldsFilter.startReceiveTime,
          endReceiveTime: this.fieldsFilter.endReceiveTime,
          startPaidTime: this.fieldsFilter.StartPaidTime,
          endPaidTime: this.fieldsFilter.EndPaidTime,
          inputModel: this.fieldsFilter.inputModel,
          poBillNO: this.fieldsFilter.poBillNO
        }
      );
      this.loading = true;
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if(this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },

    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(this.pageIndex);
    },

    // 审核请求
    onPostExamine(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      if (this.checkData(row, 3)) {
        this.$post(
          CONFIG.onExamineURL,
          { ListStkinGuid: [row.StkInGUID] },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 反审核请求
    onpostAntiTrial(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      if (this.checkData(row, 4)) {
        this.$post(
          CONFIG.onAntiTrialURL,
          { ListStkinGuid: [row.StkInGUID] },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    checkData(row, state) {
      // 1.删除 2.撤单 3.审核 4.反审 5.出库
      if (state === 1) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审，不能删除",
            type: "warning"
          });
          return false;
        }
        if (row.Status === 3 || row.Status === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已入库或部分入库，不能删除",
            type: "warning"
          });
          return false;
        }
      } else if (state === 2) {
        if (row.Status === 9) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已撤销，不能重复撤单",
            type: "warning"
          });
          return false;
        }
        if (row.Status === 1) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未出库，不能撤单",
            type: "warning"
          });
          return false;
        }
        if (row.ReturnStatus === 3 || row.ReturnStatus === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已退货，不能撤单",
            type: "warning"
          });
          return false;
        }
      } else if (state === 3) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审核，不能重复审核",
            type: "warning"
          });
          return false;
        }
      } else if (state === 4) {
        if (!row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未审核，不能反审",
            type: "warning"
          });
          return false;
        }
      } else if (state === 5) {
        if (row.Status === 3) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部入库，不能重复入库",
            type: "warning"
          });
          return false;
        }
      }
      return true;
    },

    // 入库请求
    onPostStorage(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      if (this.checkData(row, 5)) {
        this.$post(
          CONFIG.onStorageURL,
          { StkInGUID: row.StkInGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 撤单请求数据
    onPostCancelAnorder(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      if (this.checkData(row, 2)) {
        this.$post(
          CONFIG.onCancelAnOrderURL,
          { StkInGUID: row.StkInGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 删除请求
    onPostDelete(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      this.$confirm("确认要删除该采购退货单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if (this.checkData(row, 1)) {
          this.$post(
            CONFIG.onDeleteURL,
            { ListStkinGuid: [row.StkInGUID] },
            (data, logic) => {
              if (logic.code === 200) {
                this.$message({ message: "操作成功", type: "success" });
                this.onRefresh();
              } else {
                this.$message({ message: logic.msg, type: "error" });
              }
              return data;
            }
          );
        }
      });
    },
    // 编辑
    onOrdPurchaseReturnEdit(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return;
      }
      let paramOrder = { stkInGUID: row.StkInGUID };
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function() {
        this.editType = "edit";
        this.OrdPurchaseRetrunVisible = true;
      });
    },
    onRefresh() {
      this.$refs.table.cleanAllCheck();
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },

    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.StkInGUID)) {
        this.$emit("selection-changed", row);
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("OrdPurchaseReturn", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("OrdPurchaseReturn", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("OrdPurchaseReturn", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("OrdPurchaseReturn");
    },
    onBatchPay() {
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length === 0) {
        this.$message({ message: "请勾选上要操作的退货单", type: "warning" });
        return false;
      }
      let guid = _.map(rows, item => {
        return item.StkInGUID;
      });
      var lastCustomerName = rows[0].SupplierName;
      var isDiff = false;
      var isAllOut = false;
      var isPay = false;
      var isCancel = false;
      _.each(rows, r => {
        if (lastCustomerName !== r.SupplierName) {
          isDiff = true;
        }
        if (r.StatusName === "未入库") {
          isAllOut = true;
        }
        if (r.StatusName === "已撤消") {
          isCancel = true;
        }
        if (r.PayablesAmount * -1 <= (r.PaidAmount + r.DiscountAmount) * -1) {
          isPay = true;
        }
      });
      if (isCancel) {
        return this.$message({
          message: "您选择的单据中存在已撤消的订单",
          type: "error"
        });
      }
      if (isPay) {
        return this.$message({
          message: "你选择的单据中存在付款完成的订单",
          type: "error"
        });
      }
      if (isDiff) {
        this.$confirm("你选择的入库单包含多个供应商, 是否继续付款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if (isAllOut) {
            this.$confirm("你选择了还未出库的退货单, 是否继续付款?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              if (guid.length > 0) {
                return this.onBatchPayment(guid);
              }
            });
          } else {
            if (guid.length > 0) {
              return this.onBatchPayment(guid);
            }
          }
        });
      } else if (isAllOut) {
        this.$confirm("你选择了还未出库的退货单, 是否继续付款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if (guid.length > 0) {
            return this.onBatchPaymentFinRec(guid, lastCustomerName);
          }
        });
      } else {
        if (guid.length > 0) {
          return this.onBatchPaymentFinRec(guid, lastCustomerName);
        }
      }
    },
    onBatchPayment(guid) {
      // 付款
      this.payVisible = true;
      this.$nextTick(() => {
        this.isFinRecPay = false;
        this.stkInGUIDS = guid;
        this.$refs.table.cleanAllCheck();
      });
    },
    onBatchPaymentFinRec(guid, val) {
      // 付款
      this.payVisible = true;
      this.$nextTick(() => {
        this.isFinRecPay = false;
        this.stkInGUIDS = guid;
        this.$refs.table.cleanAllCheck();
      });
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选中要打印的发货单",
          type: "error",
        });
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "warning" });
        this.znlPrintVisible = true;
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.StkInGUID
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
          window.open(downloadUrl) // 下载文件
        }
        else{
          this.$message({ message: logic.msg, type: "error" });
        }
      })
    },

  }
};
</script>

<style lang="scss">
</style>
