<template>
  <znl-layout-spa class="p-order-mange" :page-loading="pageLoading" znl-loading-text="页面加载中,请稍后...">
    <el-tabs
      v-show="!pageLoading"
      v-model="activeName"
      class="nav-common-ui tab-btnstyle-box tab-manage-tab"
      value="VPurchaseManage"
      @tab-click="pageSearch"
    >

      <el-tab-pane v-if="hasRight('BomWaitToInquire')" label="待查价清单" name="waitToInquire">
        <Split
          v-if="waitToInquireInitData"
          direction="vertical"
          @onDragEnd="onDragEndByWaitInquire"
        >
          <SplitArea :minSize="65" :size="65">
            <wait-inquire
              ref="waitToInquire"
              :init-data="purchaseOrderInitData"
              @page-loading="(d) => { pageLoading = d; }"
              @selection-changed="waitInquireSelectionChange"
              @tab-change="tabJumpByTemplate"
            ></wait-inquire>
          </SplitArea>
          <SplitArea :minSize="35" :size="35">
            <inquire-list
              ref="inquireList"
              :has-operation-column="false"
              :is-adopt="true"
              :is-show-operate="false"
              @save-success="saveInquireSuccess"
            ></inquire-list>
          </SplitArea>
        </Split>
      </el-tab-pane>

      <!-- 待查价清单界面 end-->
      <!-- 待采购清单界面  start -->
      <el-tab-pane v-if="hasRight('WaitToPurchaseOrder')" label="待采购清单" name="waitToPurchaseOrder">
<!--        <znl-layout-topbottom top-height="62%">-->

<!--            <wait-to-purchase-list-->
<!--              slot="znlTop" class="znlTop"-->
<!--              style="flex: 1"-->
<!--              ref="WaitToPurchase"-->
<!--              :init-data="waitToPurchaseInitData"-->
<!--              :searchModel="purchaseSearcFields.searchModel"-->
<!--              height="100%"-->
<!--              page-position="top"-->
<!--              @page-loading="-->
<!--              d => {-->
<!--                pageLoading = d;-->
<!--              }-->
<!--            "-->
<!--              @cell-click="onWaitPurchaseCellClick"-->
<!--            ></wait-to-purchase-list>-->


<!--            <el-tabs-->
<!--              slot="znlBottom" class="znlBottom tab-btnstyle-box tab-details"-->
<!--              style="height: 100%"-->
<!--              v-model="panePurchaseName"-->
<!--              @tab-click="waitToPurchaseTabClick">-->
<!--              <el-tab-pane-->
<!--                v-if="hasRight('StkInquireRecord')"-->
<!--                height="100%"-->
<!--                label="询价记录 (按型号)"-->
<!--                name="StkInquireRecord">-->
<!--                <znl-stk-inquire-record-->
<!--                  ref="StkInquireRecord"-->
<!--                  :has-operation-column="false"-->
<!--                  :init-data="false"-->
<!--                  :is-activity="true"-->
<!--                  :is-adopt="true"-->
<!--                  :is-fields-search="false"-->
<!--                  :is-init="false"-->
<!--                  :is-mini="true"-->
<!--                  :is-multi-rows-check="false"-->
<!--                  :is-show-header="true"-->
<!--                  :page-loading="false"-->
<!--                  :search-model="waitToPurchase_inquireSearchModel"-->
<!--                  :source-type="3"-->
<!--                  class="bomWaitToInquire"-->
<!--                  height="100%"-->
<!--                  page-position="top"-->
<!--                  @save-success="-->
<!--                  d => {-->
<!--                    saveInquireSuccessByGotoBuy(d);-->
<!--                  }-->
<!--                "-->
<!--                ></znl-stk-inquire-record>-->
<!--              </el-tab-pane>-->
<!--              <el-tab-pane-->
<!--                v-if="hasRight('StockQuery')"-->
<!--                height="100%"-->
<!--                label="我的库存 (按型号)"-->
<!--                name="StockQuery"-->
<!--              >-->
<!--                <znl-stock-->
<!--                  ref="StkStockMini"-->
<!--                  :init-data="false"-->
<!--                  :is-adopt="false"-->
<!--                  :is-fields-search="false"-->
<!--                  :is-init="false"-->
<!--                  :page-loading="false"-->
<!--                  :searchModel="waitToPurchase_stockSearchModel"-->
<!--                  header="StockQuery"-->
<!--                  height="100%"-->
<!--                  page-position="top"-->
<!--                ></znl-stock>-->
<!--              </el-tab-pane>-->
<!--              <el-tab-pane-->
<!--                v-if="hasRight('PastStkIn')"-->
<!--                height="100%"-->
<!--                label="入库记录 (按型号)"-->
<!--                name="PastStkIn"-->
<!--              >-->
<!--                <znl-stk-in-record-->
<!--                  ref="PastStkIn"-->
<!--                  :grid-type="true"-->
<!--                  :has-operation-column="false"-->
<!--                  :init-data="false"-->
<!--                  :is-activity="true"-->
<!--                  :is-fields-search="false"-->
<!--                  :is-init="false"-->
<!--                  :is-mini="true"-->
<!--                  :is-multi-rows-check="false"-->
<!--                  :is-show-header="true"-->
<!--                  :isShowBtn="true"-->
<!--                  :isVisibleAdopt="true"-->
<!--                  :page-loading="false"-->
<!--                  :searchModel="waitToPurchase_purchaseSearchModel"-->
<!--                  height="100%"-->
<!--                  page-position="top"-->
<!--                ></znl-stk-in-record>-->
<!--              </el-tab-pane>-->
<!--            </el-tabs>-->



<!--        </znl-layout-topbottom>-->

                <Split  direction="vertical" @onDragEnd="onDragEndCheckThePrice">
                  <SplitArea :size="65" :minSize="65" v-if="true">
                    <wait-to-purchase-list
                      style="height: 100%"
                      class="znlTop"
                      ref="WaitToPurchase"
                      height="100%"
                      page-position="top"
                      @page-loading=" d => {pageLoading = d;}"
                      @cell-click="onWaitPurchaseCellClick"
                      :init-data="waitToPurchaseInitData"
                      :searchModel="purchaseSearcFields.searchModel"
                    ></wait-to-purchase-list>
                  </SplitArea>
                  <SplitArea :size="35" :minSize="35">
                    <el-tabs
                      class="znlBottom tab-btnstyle-box tab-details"
                      v-model="panePurchaseName"
                      @tab-click="waitToPurchaseTabClick"
                    >
                      <el-tab-pane
                        label="询价记录 (按型号)"
                        name="StkInquireRecord"
                        height="100%"
                        v-if="hasRight('StkInquireRecord')"
                      >
                        <znl-stk-inquire-record
                          ref="StkInquireRecord"
                          :init-data="false"
                          class="bomWaitToInquire"
                          height="100%"
                          :is-fields-search="false"
                          :is-mini="true"
                          :is-activity="true"
                          page-position="top"
                          :source-type="3"
                          :is-show-header="true"
                          :has-operation-column="false"
                          :is-multi-rows-check="false"
                          :is-init="false"
                          :is-adopt="true"
                          :page-loading="false"
                          @save-success="
                          d => {
                            saveInquireSuccessByGotoBuy(d);
                          }
                        "
                          :search-model="waitToPurchase_inquireSearchModel"
                        ></znl-stk-inquire-record>
                      </el-tab-pane>
                      <el-tab-pane
                        label="我的库存 (按型号)"
                        name="StockQuery"
                        height="100%"
                        v-if="hasRight('StockQuery')"
                      >
                        <znl-stock
                          ref="StkStockMini"
                          header="StockQuery"
                          height="100%"
                          :is-fields-search="false"
                          page-position="top"
                          :is-init="false"
                          :init-data="false"
                          :is-adopt="false"
                          :page-loading="false"
                          :searchModel="waitToPurchase_stockSearchModel"
                        ></znl-stock>
                      </el-tab-pane>
                      <el-tab-pane
                        label="入库记录 (按型号)"
                        name="PastStkIn"
                        height="100%"
                        v-if="hasRight('PastStkIn')"
                      >
                        <znl-stk-in-record
                          ref="PastStkIn"
                          height="100%"
                          :init-data="false"
                          :is-fields-search="false"
                          :isVisibleAdopt="true"
                          :isShowBtn="true"
                          :is-show-header="true"
                          :is-mini="true"
                          :grid-type="true"
                          :is-activity="true"
                          page-position="top"
                          :has-operation-column="false"
                          :is-multi-rows-check="false"
                          :is-init="false"
                          :page-loading="false"
                          :searchModel="waitToPurchase_purchaseSearchModel"
                        ></znl-stk-in-record>
                      </el-tab-pane>
                    </el-tabs>
                  </SplitArea>
                </Split>
      </el-tab-pane>

      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->
      <el-tab-pane v-if="hasRight('OrdPurchaseOrder')" label="采购单管理" name="purchaseManage">
        <Split v-if="purchaseOrderInitData" direction="vertical" @onDragEnd="onDragEndByPOOrder">
          <SplitArea :minSize="65" :size="65">
            <purchase-order
              ref="poOrder"
              :init-data="purchaseOrderInitData"
              @page-loading="(d) => { pageLoading = d; }"
              @selection-changed="purchaseOrderSelectionChange"
              @tab-change="tabJumpByTemplate"
            ></purchase-order>
          </SplitArea>
          <SplitArea :minSize="35" :size="35">
            <purchase-order-line ref="poOrderLine"></purchase-order-line>
          </SplitArea>
        </Split>
      </el-tab-pane>
      <el-tab-pane v-if="hasRight('StkIn')" label="入库单管理" name="stkInManage">
        <Split v-if="stkInOrderInitData" direction="vertical" @onDragEnd="onDragEndByStkIn">
          <SplitArea :minSize="65" :size="65">
            <stk-in-order
              ref="stkIn"
              :init-data="stkInOrderInitData"
              :initStkInBillNo="initStkInBillNo"
              @page-loading="(d) => {pageLoading = d;}"
              @selection-changed="stkInSelectionChange"
            ></stk-in-order>
          </SplitArea>
          <SplitArea :minSize="35" :size="35">
            <stk-in-order-line ref="stkInLine"></stk-in-order-line>
          </SplitArea>
        </Split>
      </el-tab-pane>
      <el-tab-pane v-if="hasRight('OrdPurchaseReturn')" label="采购退货管理" name="purchaseReturnManage">
        <Split v-if="purchaseReturnInitData" direction="vertical" @onDragEnd="onDragEndByPOReturn">
          <SplitArea :minSize="65" :size="65">
            <purchase-return
              ref="returnOrder"
              :init-data="purchaseReturnInitData"
              @page-loading="(d) => {pageLoading = d;}"
              @selection-changed="returnSelectionChange"
            ></purchase-return>
          </SplitArea>
          <SplitArea :minSize="35" :size="35">
            <purchase-return-line ref="returnOrderLine"></purchase-return-line>
          </SplitArea>
        </Split>
      </el-tab-pane>
      <el-tab-pane v-if="hasRight('PurchaseStatement')" label="采购对账单" name="purchaseStatements">
        <statement-order
          v-if="purStaInitData"
          :init-data="purStaInitData"
          @page-loading="(d) => { pageLoading = d;} "
        ></statement-order>
      </el-tab-pane>
    </el-tabs>
    <shopping-cart
      v-if="
        activeName === 'waitToPurchaseOrder' &&
          this.$route.path === '/Purchase/OrderManage'
      "
      ref="ShoppingTrolley"
      :visible.sync="ShoppingCartVisible"
      @close="ShoppingCartVisible = false"
      @confirm="ShoppingCartVisible = false"
      @clear-cart-after="onClearCartAfter"
      @remove-cart-item="removeCartItem"
    ></shopping-cart>
  </znl-layout-spa>
</template>
<script>
import ZnlBomWaitToInquire from "@c_modules/BOM/BomWaitToInquire";
import ZnlStkInquireRecord from "@c_modules/Stk/StkInquireRecord";
import ZnlStock from "@c_modules/Stk/StkStock_Mini";
import ZnlStkInRecord from "@c_modules/Stk/StkInRecord";
import WaitToPurchaseList from "@c_modules/Ord/WaitToPurchaseList";
import ShoppingCart from "@c_common/shoppingCart/ShoppingCart";

// 窗口大小监控mixin
import Resize from '~assets/scripts/mixins/resize';
import {isClient} from "~/lib/runtime";

// v-table
import PurchaseOrder from "@c_modules/PPM/PurchaseOrder";
import PurchaseOrderLine from "@c_modules/PPM/PurchaseOrderLine";
import StkInOrder from "@c_modules/Stk/StkInOrder";
import StkInOrderLine from "@c_modules/Stk/StkInOrderLine";
import PurchaseReturn from "@c_modules/PPM/PurchaseReturn";
import PurchaseReturnLine from "@c_modules/PPM/PurchaseReturnLine";
import StatementOrder from "@c_modules/PPM/StatementOrder";
import WaitInquire from "@c_modules/BOM/WaitToInquire";
import InquireList from "@c_modules/Stk/StkInquireRecord_v1";

export default {
  name: "VPurchaseManage",
  mixins: [Resize],
  components: {
    ZnlBomWaitToInquire,
    ZnlStkInquireRecord,
    ZnlStock,
    ZnlStkInRecord,
    WaitToPurchaseList,
    ShoppingCart,

    //v-table
    PurchaseOrder,
    PurchaseOrderLine,
    StkInOrder,
    StkInOrderLine,
    PurchaseReturn,
    PurchaseReturnLine,
    StatementOrder,
    WaitInquire,
    InquireList
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    },
    $route: "routeChangeFn"
  },

  data() {
    return {
      bomMasterLine: {
        searchModel: {}
      },
      BomWaitToInquireModel: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      purchaseSearcFields: {
        searchModel: {}
      },
      inquireSearchModel: {
        // 供应商报价记录
        Model: ""
      },
      stockSearchModel: {
        // 我的库存
        Model: ""
      },
      purchaseSearchModel: {
        // 采购记录
        Model: ""
      },
      waitToPurchaseModel: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      waitToPurchase_inquireSearchModel: {
        Model: ""
      },
      waitToPurchase_purchaseSearchModel: {
        Model: ""
      },
      waitToPurchase_stockSearchModel: {
        // 我的库存
        Model: ""
      },
      ShoppingCartVisible: false,

      pageData: [],
      // 默认选中选项卡
      activeName: "waitToInquire",
      // 待采购清单是否起始搜索
      waitToPurchaseInitData: false,
      // 待查价清单是否起始搜索
      waitToInquireInitData: false,
      // 采购单管理是否起始搜索
      purchaseOrderInitData: false,
      // 入库单管理是否起始搜索
      stkInOrderInitData: false,
      // 采购退货单管理是否起始搜索
      purchaseReturnInitData: false,
      // 采购对账单是否起始搜索
      purStaInitData: false,

      paneName: "StkInquireRecord",
      panePurchaseName: "StkInquireRecord",
      bomQuoteType: "", // 报价模式
      purGrossRate: 0, // 采购固定毛利率
      pageLoading: true,
      initStkInBillNo: "" //单击列表中已入的时候，如果发入库Tab没有初始化，该参数会带过去
    };
  },
  methods: {
    onresize() {
      //从store取得domRect，在计算
      const height = isClient()
        ? this.$store.state.domRect.clientHeight
        : this.$store.state.domRect.clientHeight - 50;

      this.onDragEndByWaitInquire()

      // if(this.$refs.batchesStock){
      //   this.$refs.batchesStock.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.summaryStock){
      //   this.$refs.summaryStock.$refs.flexGrid.gridHeightValue = height - 180
      // }

    },
    routeChangeFn(to, from) {
      if (to.name == "Purchase/OrderMange" && to.params.poNumber != undefined) {
        if (this.hasRight("OrdPurchaseOrder")) {
          this.activeName = "purchaseManage";
          if (!this.purchaseOrderInitData) {
            this.purchaseOrderInitData = true;
          } else {
            this.$refs.poOrder.setPoNumber(to.params.poNumber);
          }
        }
      }
    },
    tabJumpByTemplate(data) {
      this.activeName = data.tabName;
      if (data.tabName == "stkInManage") {
        if (this.hasRight("StkIn")) {
          this.initStkInBillNo = data.billNo;

          if (!this.stkInOrderInitData) {
            this.stkInOrderInitData = true;
          } else {
            this.$refs.stkIn.setBillNo(data.billNo);
          }
        }
      }
    },
    hasRight(code) {
      return _.includes(this.pageData, code);
    },

    // 顶部页签切换
    async pageSearch() {
      // RowGUID 辅助作用(没实际作用),用于改变条件,触发起始搜索
      if (this.activeName === "waitToInquire" && !this.waitToInquireInitData) {
        this.$set(this.bomMasterLine.searchModel, "RowGUID", "");
      } else if (
        this.activeName === "waitToPurchaseOrder" &&
        !this.waitToPurchaseInitData
      ) {
        this.$set(this.purchaseSearcFields.searchModel, "RowGUID", "");
      } else if (
        this.activeName === "purchaseManage" &&
        !this.purchaseOrderInitData
      ) {
        this.purchaseOrderInitData = true;
      } else if (
        this.activeName === "stkInManage" &&
        !this.stkInOrderInitData
      ) {
        this.stkInOrderInitData = true;
      } else if (
        this.activeName === "purchaseReturnManage" &&
        !this.purchaseReturnInitData
      ) {
        this.purchaseReturnInitData = true;
      } else if (
        this.activeName === "purchaseStatements" &&
        !this.purStaInitData
      ) {
        this.purStaInitData = true;
      }
    },

    // 待查价清单 点击单元格事件
    onBomWaitToInquireCellClick(item) {
      this.BomWaitToInquireModel.searchModel = {
        Model: item.BomModel || "",
        ASGUID: item.ASGUID || null, // 需求明细辅助GUID
        BDLineGUID: item.BDLineGUID || null, // 需求明细GUID
        IQGUID: item.IQGUID || null, // 询价GUID
        // NeedAdoptQty: (item.AsQty || 0) - (item.QuoteQty || 0)  // 待查价清单的通知数量-已经通知数量
        NeedAdoptQty: item.AsQty || 0
      };
      // 关联查询条件为空,子模块不做搜索
      if (
        this.BomWaitToInquireModel.searchModel.Model === "" ||
        this.BomWaitToInquireModel.searchModel.ASGUID === null
      ) {
        return;
      }
      this.waitToInquireTabClick();
    },

    // 待查价清单里面的tab切换事件
    // waitToInquireTabClick() {
    //   // 关联查询条件为空,子模块不做搜索
    //   if (
    //     this.BomWaitToInquireModel.searchModel.Model === "" ||
    //     this.BomWaitToInquireModel.searchModel.ASGUID === null
    //   ) {
    //     return;
    //   }
    //   // 询价记录模块
    //   if (this.paneName === "StkInquireRecord") {
    //     this.queryInquireSearchModel();
    //   }
    //   // 我的库存模块
    //   if (this.paneName === "StockQuery") {
    //     this.queryStockSearchModel();
    //   }
    //   // 采购记录模块
    //   if (this.paneName === "PastStkIn") {
    //     this.queryPurchaseSearchModel();
    //   }
    // },

    // 待采购清单 行改变事件
    onWaitPurchaseCellClick(item) {
      this.waitToPurchaseModel.searchModel = {
        Model: item.Model || "",
        ASGUID: item.ASGUID || null,
        BDLineGUID: item.BDLineGUID || null,
        PNGUID: item.NoticeGUID || null,
        NeedAdoptQty: (item.NoticeQty || 0) - (item.PurchaseQty || 0) // 待采购清单的需采购量-已采购量
      };
      if (item.Model === "" || item.Model === null) {
        return;
      }
      this.waitToPurchaseTabClick();
    },

    // 待采购清单里面的tab切换事件
    waitToPurchaseTabClick() {
      if (this.panePurchaseName === "StkInquireRecord") {
        this.queryWaitToPurchase_inquireSearchModel();
      }
      if (this.panePurchaseName === "PastStkIn") {
        this.queryWaitToPurchase_purchaseSearchModel();
      }
      if (this.panePurchaseName === "StockQuery") {
        this.querywaitToPurchase_stockSearchModel();
      }
    },
    // queryInquireSearchModel() {
    //   // 待查价清单-询价记录模块
    //   if (
    //     !_.isEqual(
    //       this.inquireSearchModel.Model,
    //       this.BomWaitToInquireModel.searchModel.Model
    //     ) ||
    //     !_.isEqual(
    //       this.inquireSearchModel.ASGUID,
    //       this.BomWaitToInquireModel.searchModel.ASGUID
    //     )
    //   ) {
    //     this.hasRight("StkInquireRecord") &&
    //       (this.inquireSearchModel = this.BomWaitToInquireModel.searchModel);
    //   }
    // },
    // queryStockSearchModel() {
    //   // 待查价清单-我的库存模块
    //   if (
    //     !_.isEqual(
    //       this.stockSearchModel.Model,
    //       this.BomWaitToInquireModel.searchModel.Model
    //     ) ||
    //     !_.isEqual(
    //       this.inquireSearchModel.ASGUID,
    //       this.BomWaitToInquireModel.searchModel.ASGUID
    //     )
    //   ) {
    //     // 不需要传入 BDLineGUID
    //     this.BomWaitToInquireModel.searchModel.BDLineGUID = null;
    //     this.hasRight("StockQuery") &&
    //       (this.stockSearchModel = this.BomWaitToInquireModel.searchModel); // 我的库存
    //   }
    // },
    // queryPurchaseSearchModel() {
    //   // 待查价清单-采购入库记录模块
    //   if (
    //     !_.isEqual(
    //       this.purchaseSearchModel.Model,
    //       this.BomWaitToInquireModel.searchModel.Model
    //     )
    //   ) {
    //     this.hasRight("PastStkIn") &&
    //       (this.purchaseSearchModel.Model = this.BomWaitToInquireModel.searchModel.Model);
    //   }
    // },

    queryWaitToPurchase_inquireSearchModel() {
      // 待采购清单-- 询价记录模块
      if (
        !_.isEqual(
          this.waitToPurchase_inquireSearchModel.Model,
          this.waitToPurchaseModel.searchModel.Model
        ) ||
        !_.isEqual(
          this.waitToPurchase_inquireSearchModel.PNGUID,
          this.waitToPurchaseModel.searchModel.PNGUID
        )
      ) {
        this.hasRight("StkInquireRecord") &&
        (this.waitToPurchase_inquireSearchModel = this.waitToPurchaseModel.searchModel);
      }
    },
    queryWaitToPurchase_purchaseSearchModel() {
      // 待采购清单-- 采购入库记录模块
      if (
        !_.isEqual(
          this.waitToPurchase_purchaseSearchModel.Model,
          this.waitToPurchaseModel.searchModel.Model
        )
      ) {
        this.hasRight("PastStkIn") &&
        (this.waitToPurchase_purchaseSearchModel.Model = this.waitToPurchaseModel.searchModel.Model);
      }
    },
    querywaitToPurchase_stockSearchModel() {
      // 待采购清单--我的库存模块
      if (
        !_.isEqual(
          this.waitToPurchase_stockSearchModel.Model,
          this.waitToPurchaseModel.searchModel.Model
        )
      ) {
        this.hasRight("StockQuery") &&
        (this.waitToPurchase_stockSearchModel = this.waitToPurchaseModel.searchModel); // 我的库存
      }
    },

    purchaseOrderSelectionChange(item) {
      this.$refs.poOrderLine.onHeadSearch(item.POGuid, item.SupplierName);
    },
    stkInSelectionChange(item) {
      this.$refs.stkInLine.onHeadSearch(item.StkInGUID);
    },
    returnSelectionChange(item) {
      this.$refs.returnOrderLine.onHeadSearch(item.StkInGUID);
    },
    waitInquireSelectionChange(item) {
      this.$refs.inquireList.onHeadSearch(item.BomModel, item.ASGUID, item.IsSubmit);
    },
    // 待查价界面的询价记录模块 保存/采纳后的事件
    saveInquireSuccess(val) {
      this.$refs.waitToInquire.adoptInquireSuccess(val);
    },
    saveInquireSuccessByGotoBuy(val) {
      this.$refs.WaitToPurchase.adoptInquireSuccess(val);
    },

    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, {PageCode: "VPurchaseManage"});
    },

    setQuoteParam(t, p) {
      this.$nextTick(() => {
        this.bomQuoteType = t;
        this.purGrossRate = Number(p);
      });
    },
    removeCartItem(row) {
      this.$refs.WaitToPurchase.removeShoppingCart(row);
    },
    // 设置默认选中选项卡
    defaultSelectedTab() {
      this.activeName = "waitToInquire";
      if (
        this.$route.params.poNumber != undefined &&
        this.hasRight("OrdPurchaseOrder")
      ) {
        this.activeName = "purchaseManage";
        this.purchaseOrderInitData = true;
      } else if (this.hasRight("BomWaitToInquire")) {
        this.activeName = "waitToInquire";
        this.waitToInquireInitData = true;
      } else if (this.hasRight("WaitToPurchaseOrder")) {
        this.activeName = "waitToPurchaseOrder";
        this.waitToPurchaseInitData = true;
      } else if (this.hasRight("OrdPurchaseOrder")) {
        this.activeName = "purchaseManage";
        this.purchaseOrderInitData = true;
      } else if (this.hasRight("StkIn")) {
        this.activeName = "stkInManage";
        this.stkInOrderInitData = true;
      } else if (this.hasRight("OrdPurchaseReturn")) {
        this.activeName = "purchaseReturnManage";
        this.purchaseReturnInitData = true;
      } else if (this.hasRight("PurchaseStatement")) {
        this.activeName = "purchaseStatements";
        this.purStaInitData = true;
      }
    },
    // 清除购物车之后刷新待采购清单
    onClearCartAfter() {
      // this.getNewGuid() 生成GUID
      // RowGUID 用于改变条件,(触发搜索事件,没实际查询条件)
      this.$set(
        this.purchaseSearcFields.searchModel,
        "RowGUID",
        this.getNewGuid()
      );
    },
    onDragEndCheckThePrice(){
      this.$refs.WaitToPurchase.$refs.flexGrid.init();
    },
    onDragEndByPOOrder() {
      this.$refs.poOrder.$refs.table.init();
      this.$refs.poOrderLine.$refs.table.init();
    },
    onDragEndByStkIn() {
      this.$refs.stkIn.$refs.table.init();
      this.$refs.stkInLine.$refs.table.init();
    },
    onDragEndByPOReturn() {
      this.$refs.returnOrder.$refs.table.init();
      this.$refs.returnOrderLine.$refs.table.init();
    },
    onDragEndByWaitInquire() {
      this.$refs.waitToInquire.$refs.table.init();
      this.$refs.inquireList.$refs.table.init();
    }
  },
  async created() {
    this.getPageData()
      .then(data => {
        this.$nextTick(() => {
          if (data && data.length > 0) {
            this.pageData = data.map(item => item.Code);
          }
          this.defaultSelectedTab();
        });
      })
      .catch(e => {
        console.log("e:", e);
      });
  }
};
</script>
<style lang="scss">
.p-order-mange{
   .znl-table-body-wrapper {
    overflow: hidden;
    height: calc(100% - 32px)!important;
    box-sizing: border-box;
  }

  .table-container {
    height: calc(100% - 32px) !important;
  }

  .base-flex-grid {
    height: calc(100% - 32px) !important;
  }

  .znlBottom{
     .el-tab-pane{
      min-height:200px;
     }
  }


}
</style>
