<template>
  <!-- Open销售单明细 -->
  <!-- :totalField="['Amount','Qty','GrossProfit']" -->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('DevOrderLine')"
      @on-show-trigger="onHideShowColTrigger"
      box-class="fdsjijos"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 销售明细</span>
      </div>
    </znl-table>
  </div>
</template>
<script>

import { erpTableSetHandler } from '@scripts/methods/common'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  configURL: 'DevOrderLine/GetConfig',
  searchURL: 'DevOrderLine/Search'
}

export default {
  name: 'DevOrderLine',
  config: CONFIG,
  mixins: [getCommonDataMixin, erpTableSetHandler],
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      parameterConfig: [],
      orderNo: null,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    }
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage('devOrderLine', CONFIG.configURL)
      }
      let columns = config.ColumnConfigs
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      //处理特殊权限
      // if (!this.isMainAccount) {
      //   let noSeeProfit = this.getSpecialResourceByCode('NoSeeProfit')
      //   if (noSeeProfit) {
      //      _.remove(columns,item =>{
      //         if(item.BindField == "GrossProfit") // 利润
      //           return true;
      //         else if(item.BindField == "GrossProfitRate") // 利润率
      //           return true;
      //         else if(item.BindField == "CostPrice")// 采购价
      //           return true;
      //     })
      //   }
      // }

      this.columns = columns
      this.$refs.table.init()
      this.$emit('page-loading', false)
    },
    async onBindData(pageIndex = 1) {
      if (!this.hasValue(this.orderNo)) {
        return
      }
      //this.$refs.table.cleanSort()
      let data = _.extend({},
        {
          orderNo: this.orderNo
        }
      )
      return await this.$post(CONFIG.searchURL, data, data => {
        this.itemSource = data === undefined ? [] : data
      })
    },
    async onHeadSearch(orderNo) {
      if (orderNo) {
        this.orderNo = orderNo
        await this.onBindData()
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("DevOrderLine", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("DevOrderLine", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("DevOrderLine", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("DevOrderLine")
    }
  }
}
</script>

<style lang="scss">
</style>
