<template>
  <div class="global-cargo-dialog">
    <znl-dialog
      ref="gg"
      title="AI全球找料"
      :visible="visible"
      height="640px"
      width="1000px"
      class="znl-editInvoices2"
      :close-on-click-modal="false"
      :loading.sync="loading"
      :loading-text="elementLoadingText"
      @close="close"
    >
      <iframe
        src="http://test.bom.ai:8080/#/GlobalCargo"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </znl-dialog>
  </div>
</template>

<script>
export default {
  name: "global-cargo-dialog",
  data() {
    return {
      loading: false,
      elementLoadingText: "加载中",
    };
  },
  props: {
    visible: Boolean,
  },
  methods: {
    close() {
      this.$parent.dialogVisible = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.global-cargo-dialog .znl-dialog-button.main {
  display: none;
}
</style>
