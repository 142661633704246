<template>
  <!-- 销售对账单列表 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('SalesStatement')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="请输入客户名称"
            size="mini"
            layout="left"
            width="180px"
            inp-perc="100%"
            class="box_input_checkbox"
            :border="true"
            :clearable="true"
            v-model="filterFields.CustomerName"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text"
          ></znl-input>
          <el-checkbox class="accurate-checbox" size="small" v-model="isAccurateSearch">精确</el-checkbox>
        </div>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="对账单时间起"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          v-model="filterFields.startOutOn"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="对账单时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="filterFields.endOutOn"
        ></znl-input>
        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac" v-if="true" type="multiple">
          <znl-button-menu @click="onPrint">
            <i class="iconfont icon-print_btn_ic"></i>
            <span>打印</span>
          </znl-button-menu>
          <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-table>
    <znl-print
      :visible.sync="znlPrintVisible"
      :print-info="printInfo"
      :loading="printLoading"
      :is-get-default-param="printInfo.isGetDefaultParam"
      print-type="Statement"
      title="对账单打印设置"
      page-code="StsSalesOut"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>
    <!-- 对账单新增_编辑_查看 -->
    <statement-order-edit
      :isDisabled="isDisabledBtn"
      v-if="statementVisible"
      :accountVisible="statementVisible"
      :title="saleAccountTitle"
      @close="statementVisible=false"
      @save-success="onRefresh"
    ></statement-order-edit>
    <!-- 销售发票新增_编辑 -->
    <fin-so-invoice-edit
      :isReturninv="isReturninvoice"
      v-if="invoiceVisible"
      :accountVisible="invoiceVisible"
      :title="invoiceTitle"
      @close="invoiceVisible=false"
      @save-success="onRefresh"
    ></fin-so-invoice-edit>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import StatementOrderEdit from "@c_modules/Sale/StatementOrderEdit";
import FinSoInvoiceEdit from "@c_modules/Fin/SalesInvoice/InvoiceEdit";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import { erpTableSetHandler } from "@scripts/methods/common";
import { isClient } from "~/lib/runtime";
import * as urls from '~/lib/urls'

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "SSGUID",
  configURL: "SalesStatement/GetConfig",
  searchURL: "SalesStatement/Search",
  onDeleteURL: "SalesStatement/Delete",
  onAuditURL: "SalesStatement/Audit",
  onUnAuditURL: "SalesStatement/UnAudit",
  searchBySSGUIDURL: "SalesStatementLine/SearchBySSGUID",
  WebPrintURL:'WebPrint/SalesStatementPrint',
  DownloadPrintFile:'WebPrint/DownloadPrintFile',
};
export default {
  name: "SalesStatement",
  mixins: [znlMethodsMixin, getCommonDataMixin, erpTableSetHandler, printInfoMixin],
  config: CONFIG,
  // 组件
  components: {
    StatementOrderEdit,
    ZnlPrint,
    FinSoInvoiceEdit
  },
  data() {
    return {
      isAccurateSearch: false,
      isShowFiedsSearch: false,
      Statementvisible: false, // 对账单
      invoiceVisible: false, // 销售发票
      isReturninvoice: false, // 是否冲红发票
      isDisabledBtn: false,
      invoiceTitle: "",
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [],
      filterFields: {
        startOutOn: "",
        endOutOn: "",
        CustomerName: "", // 客户名称
        MatchOperation: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [], // 权限列表，,
      gridShow: false,
      parameterConfig: [],
      loading: false,
      statementVisible: false,
      saleAccountTitle: "",
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "Statement" // 打印模板类型
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      currencyList: [],
      userList: [],
      auditedOptions: [
        { key: "未审核", value: "未审核" },
        { key: "已审核", value: "已审核" }
      ]
    };
  },
  // 父组件传过来的参数
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
  },
  async mounted() {
    await this.onInit();
  },
  // 方法
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("salesStatement", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      // 设置打印
      this.printInfo.template = this.getPrintTemplate(config.ParamJosn);
      this.printInfo.title = this.getPrintTitle(config.ParamJosn);
      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "查看",
          name: "Edit",
          click: row => {
            this.onStatementOrder(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: row => {
            this.onDelete(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: row => {
            this.onAudit(row);
          }
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: row => {
            this.onUnAudit(row);
          }
        });
      }
      let tempConfig = [
        {
          width: 35,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
          canSearch: false
        }
      ];

      let columns = tempConfig.concat(config.ColumnConfigs);
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "AuditedName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.auditedOptions
        });
      }
      this.columns = columns;
      this.$refs.table.init();
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },

    // 获取按钮权限
    hasRes(code) {
      if (code.length === 0) {
        return false;
      }
      return _.includes(this.resourceList, code);
    },

    // 添加 打开生成销售(从销售出库)对话框，选择客户、币种、起、止时间，判断条件是否满足生成条件
    onSatementAdd() {
      return;
    },

    // 1.查看详情 打开对帐单详情页 2.修改 未审核，否则提示“只有未审核的对帐单才有修改”，操作：对帐单编辑界面
    onStatementOrder(row) {
      this.isDisabledBtn = row.IsAudited;
      this.$store.commit("setParamOrder", { SSGUID: row.SSGUID });
      this.$nextTick(function() {
        this.saleAccountTitle = "查看对账单";
        this.statementVisible = !this.statementVisible;
      });
    },

    // 开票- 前置条件：未开票或部分开票，否则提示“全部开票的对帐单不能再开票”
    // 操作：打开 发票编辑界面，并把对帐单号带入
    onInvoice(row) {
      if (this.checkData(row, 1)) {
        let data = { SSGUID: row.SSGUID, StatementBillNO: row.BillNo };
        this.$store.commit("setParamOrder", { SSInfo: data });
        this.$nextTick(function() {
          this.invoiceTitle = "新增销售发票";
          this.invoiceVisible = !this.invoiceVisible;
          this.isReturninvoice = false; // 生成发票，不是冲红
        });
      }
    },

    // 收款-前置条件： 未收款或部分收款，否则提示“全部收款的帐单不能再收款”，操作：打开收款界面，并把对帐单号带入
    onReceipt(row) {
      if (this.checkData(row, 2)) {
        return;
      }
    },

    // 审核-前置条件：未审核，否则提示“已审核的对帐单不能再审核” 操作：设置 已审核 状态
    onAudit(row) {
      if (this.checkData(row, 3)) {
        return this.$post(
          CONFIG.onAuditURL,
          { SSGUID: row.SSGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "审核成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },

    // 撤消审核-前置条件：已审核 ，否则提示“只有已审核的对帐单才能撤消” 操作：设置 未审核 状态
    onUnAudit(row) {
      if (this.checkData(row, 4)) {
        return this.$post(
          CONFIG.onUnAuditURL,
          { SSGUID: row.SSGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "撤销审核成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },

    // 删除-前置条件：未审核、未开票、未收款，只要一个条件不满足提示“只有未审核、未收款且未开票的对帐单才能删除”
    onDelete(row) {
      if (this.checkData(row, 5)) {
        this.$confirm("删除确认, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return this.onPostDelete(row.SSGUID);
        });
      }
    },
    onPostDelete(guid) {
      this.$post(CONFIG.onDeleteURL, { SSGUID: guid }, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "操作成功", type: "success" });
          this.onBindData(this.pageIndex);
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
        return data;
      });
    },
    // 操作说明
    checkData(row, state) {
      // 1.开票  2.收款 3.审核 4.撤消审核 5.删除 6.修改
      if (state === 1) {
        if (row.CurrencyCode !== "RMB") {
          this.$message({
            message:
              "单号:【" + row.BillNo + "】币种不是人民币类型，不能开发票",
            type: "warning"
          });
          return false;
        }
        if (row.InvoicedStatus === 3) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部开票，不能继续开票",
            type: "warning"
          });
          return false;
        }
        if (row.TaxRate === 0) {
          this.$message({
            message: "单号:【" + row.BillNo + "】税率为0，不能开发票",
            type: "warning"
          });
          return false;
        }
      } else if (state === 2) {
        if (row.PaymentSatus === 3) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部收款，不能再收款",
            type: "warning"
          });
          return false;
        }
      } else if (state === 3) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审核，不能重复审核",
            type: "warning"
          });
          return false;
        }
      } else if (state === 4) {
        if (!row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未审核，不能反审",
            type: "warning"
          });
          return false;
        }
      } else if (state === 5) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审核，不能删除",
            type: "warning"
          });
          return false;
        }
        if (row.PaymentSatus === 3 || row.PaymentSatus === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已收款或部分收款，不能删除",
            type: "warning"
          });
          return false;
        } else if (row.InvoicedStatus === 3 || row.InvoicedStatus === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已开票或部分开票，不能删除",
            type: "warning"
          });
          return false;
        }
      } else if (state === 6) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审核，不能修改",
            type: "warning"
          });
          return false;
        }
      }
      return true;
    },

    onSearch(params = null) {
      if (this.hasValue(params)) {
        this.filterFields = _.extend({}, this.filterFields, params);
      }
      return this.onBindData(1);
    },
    // 刷新
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    // 高级搜索 搜索栏的显示和隐藏
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    // 打印对帐单 列出此对帐单所有明细，并统计此客户所有预收款合计
    // 对帐单明细所有未收金额汇总-预收款合计，为本期应收
    onPrint() {
      //网页版打印
      if(!isClient())
      {
        this.onWebPrint();
        return;
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.SSGUID)) {
        return this.$message({
          message: "请选中要打印的对账单",
          type: "error"
        });
      }
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        return this.$post(
          CONFIG.searchBySSGUIDURL,
          { SSGUID: row.SSGUID },
          (data, logic) => {
            this.printSOStatement({
              TableName: tableName,
              Guid: row.SSGUID,
              SubCompanyId: titleId,
              MainJson: JSON.stringify(data),
              LineJson: JSON.stringify(data.LineList)
            });
          }
        );
      }
    },

    // 访问接口,绑定数据
    onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let binddata = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          StartOutOn: this.filterFields.startOutOn,
          EndOutOn: this.filterFields.endOutOn
        },
        {
          // 外部搜索条件
          StatementDateStart: this.filterFields.startOutOn,
          StatementDateEnd: this.filterFields.endOutOn,
          CustomerExact: this.isAccurateSearch,
          CustomerName: this.filterFields.CustomerName
        }
      );
      this.$post(CONFIG.searchURL, binddata, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if(this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
    },
    // 精确按钮改变事件
    onChange(val) {
      if (val) {
        this.isAccurateSearch = false;
      } else {
        this.isAccurateSearch = true;
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("SalesStatement", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("SalesStatement", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("SalesStatement", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("SalesStatement");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.SSGUID)) {
        return this.$message({
          message: "请选中要打印的对账单",
          type: "error",
        });
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.SSGUID
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
          window.open(downloadUrl) // 下载文件
        }
        else{
          this.$message({ message: logic.msg, type: "error" });
        }
      })
    },

  }
};
</script>

<style lang="scss">
</style>

