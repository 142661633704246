<template>
  <el-dialog
    :visible="visible"
    width="840px"
    height="600px"
    title="预览页面"
    class="dialog-log-grid-box dialog-grid-footer-box m-operate-log"
    :loading="true"
    :fullscreen="true"
    :close-on-click-modal="false"
    :footer-height="0"
    :is-footer-show="false"
    :append-to-body="true"
    @close="()=>{$emit('close')}"
  >
    <form-printer
      ref="formPrinter"
      :page="pageConfig"
      :row="rowConfig"
      :table="tableConfig"
      :silent="false"
      :data-sources="dataLine"
      :info="dataInfo"
      @output-data="getPdfData"
      id="print-preview"
    ></form-printer>
  </el-dialog>
</template>
<script>
import FormPrinter from "@c_common/print/FormPrinter";
import PrintConfig from "@c_common/print/config";

const CONFIG = {
  GetConfigsUrl: "PrintTemplate/Get"
}

export default {
  name: "printerDialog",
  config: CONFIG,
  components: {
    FormPrinter
  },
  data() {
    return {
      rowConfig: [],
      pageConfig: {},
      tableConfig: {},
      isShowPrinter: false
      // dataInfo: Data.info,
      // dataLine: Data.dataSources
    };
  },
  props: {
    refreshLog: Boolean,
    visible: Boolean,
    dataInfo: {},
    dataLine: Array,
    tableName: String
    // title: "打印单据"
  },
  watch: {
    visible (val) {
      if (!val) {
        this.isShowPrinter = false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {})
    this.getConfigs()
  },
  created() {},
  methods: {
    async getConfigs () {
      await this.$post(CONFIG.GetConfigsUrl, { TableName: this.tableName }, (data, logic) => {
        if (data) {
          let cfg = JSON.parse(data.ConfigValue)
          if (cfg) {
            this.rowConfig = cfg.row
            this.pageConfig = cfg.page
            this.tableConfig = cfg.table
            // console.log(this.rowConfig, this.pageConfig, this.dataInfo, this.dataLine, 11234)
            this.isShowPrinter = true
          }
        } else {
          this.$message({message: logic.msg || '找不到模板', type: 'error'})
        }
      })
      let datas = await this.$refs.formPrinter.printPDF()
      this.getPdfData(datas)
    },

    getPdfData (datas) {
      if (datas && datas.length > 0) {
        let param = {
          FileData: datas
        }
        console.log('get pdf data successfully. now start to send the datas to winform.')
        this.printBase64Pdf(param, () => {})
        this.$emit('close')
      } else {
        console.log('no pdf datas')
        this.$message({message: '生成打印文件失败', type: 'error'})
      }
    }
  }
};
</script>

<style lang="scss">
.m-operate-log {
  .el-pagination--small {
    height: auto !important;
  }
}
</style>

