<template>
  <!-- 汇总库存 -->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="action"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      v-loading="loading"
      :element-loading-text="loadingText"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      checkboxBindingKey="SUMGuid"
      @select-change="selectChange"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="180px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="品牌"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="100px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Brand"
          type="text"
        ></znl-input>
        <el-checkbox v-model="searchFields.IsZero" @change="reSearch">包含零库存</el-checkbox>
        <el-checkbox v-model="searchFields.IsNegative" @change="reSearch">仅查负库存</el-checkbox>
        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="mac" @click="onMarketSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>市场查货</span>
        </znl-button>
        <!-- <znl-button style-type="mac" :height="22" @click="onUploadQDW()" :disabled="isDisabled">
          <i class="iconfont icon-table_set_btn_n"></i>
          <span>上传到抢单王库存</span>
        </znl-button> -->

        <!-- <znl-button style-type="mac" :height="22" :btns="qdw_btns" @click="onUploadQDW" tip="点击三角符号，根据搜索条件上传" :disabled="isDisabled">
          <span style="margin-right:4px;">
            <i class="iconfont icon-uploading-wh_btn_ic"></i> 上传到抢单王商品
          </span>
        </znl-button> -->
        &nbsp;&nbsp;&nbsp;
        <znl-button
          style-type="main"
          class="save-self-btn"
          :height="22"
          @click="onBatchSave()"
          :disabled="isDisabled"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存修改</span>
        </znl-button>

        <znl-button style-type="mac" @click="onExport()" v-if="false">
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>

        <a
          style="text-decoration:underline; margin-left:3px;margin-right:3px;"
          title="已选中条数"
        >已选择 ({{selectedRows.length}}) 条</a>
        <a
          @click="onCleaSelectCount()"
          style="text-decoration:underline; margin-left:5px;"
          title="清除选中条数"
        >清除</a>
      </div>
    </znl-table>

    <!-- 导出库存格式 -->
    <export-format
      :columns="setColumns"
      :saveConfigURL="setSaveConfigURL"
      :defaultConfigColumns="setDefaultConfigColumns"
      :visible="exportFormatVisible"
      actionServiceName="StockSummary"
      :searchCondition="searchCondition"
      @close="()=>{exportFormatVisible=false}"
      @confirm="(v)=>{exportFormatVisible=false}"
    ></export-format>

    <!--已选择库存列表-->
    <stk-check-info
      ref="stkCheckInfo"
      v-if="showStkCheckInfo"
      :visible="showStkCheckInfo"
      @checkInfo="(val)=>{this.onSeletedInfo(val)}"
      @close="()=>{showStkCheckInfo=false}"
    ></stk-check-info>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";
import ExportFormat from "@c_modules/StkSum/ExportFormat";
import { erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  configURL: "StockSummary/GetConfig",
  saveConfigURL: "StockSummary/SaveConfig",
  resetConfigURL: "StockSummary/ResetConfig",
  searchURL: "StockSummary/Search",

  saveRowsURL: "StockSummary/BatchSave",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  resetParamConfigURL: "StockSummary/ResetParamConfig",
  UploadQDW: "StockSummary/UploadBMStock"
};

export default {
  name: "StockSummary",
  mixins: [getCommonDataMixin, common, erpTableSetHandler],
  config: CONFIG,
  components: {
    ExportFormat
  },

  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        Model: "",
        Brand: "",
        IsZero: false,
        IsNegative: false
      },
      isShow: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      SumSearchList: [], // 统计集合,
      editVisible: false,
      loading: false,
      loadingText: '数据加载中,请稍后...',

      // 导入
      pubStockImportVisible: false,
      sumGuids: [],
      Status: true,
      stype: "", // 鼠标悬停时，弹出层选中的类型
      sumGuid: "", // 鼠标悬停时的所在行的sumGuid

      searchCondition: {}, // 查询条件
      options: {},

      gridtype: "action", // 表格类型。 action: 可编辑， base: 不可编辑
      setExportColumnVisible: false,
      addItemSourceList: [],
      exportFormatVisible: false,
      setColumns: [],
      setDefaultConfigColumns: {},
      setSaveConfigURL: "",

      selectedSType: 0, // 当只选中一行时，当前行的推广类型
      selectedSubtype: [], // 当只选中一行时，当前行的订阅类型
      selectedMarkNames: "", // 当只选中一行时，当前行的自定义标签
      isNegative: false, // 负库存
      // imageUploadVisible: false, // 上传图片
      imageBoxVisible: false, // 是否显示型号的图片
      imageList: [], // 待显示的图片
      imageTitle: "",
      parameterConfig: [],
      selectedRows: [], // 选中的数据
      showStkCheckInfo: false, // 是否显示已选择库存列表
      isDisabled: false,
      qdw_btns: []
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    matchOperation: {
      type: String,
      default: "Like"
    },
    ReceiveisZero: Boolean,
    ReceiveisNegative: Boolean
  },

  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("stockSummary", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      // this.hasExtendRight = this.hasRes("SetExtendStock");
      // this.hasSubscriptRight = this.hasRes("SetSubscription");

      // let viewBuyPrice = this.hasViewBuyPrice();
      // let viewSalesPrice = this.hasViweSalesPrice();

      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;

      // 判断是否有保存权限
      if (!this.hasRes("Save") && !this.hasRes("StkWarning")) {
        this.gridtype = "base";
      }

      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }

      this.loading = false;
      this.$emit("page-loading", false);

      this.qdw_btns = [{
          name: '根据搜索条件上传商品',
          click: this.onUploadQDW_byCondition
        }
      ]
    },

    async onBindData(pageIndex = 1) {
      this.loading = true;
      let where = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: this.toInt(pageIndex, 1),
          PageSize: this.pageSize
        },
        {
          IsZero: this.searchFields.IsZero,
          IsNegative: this.searchFields.IsNegative
        }
      );
      return await this.$post(
        this.$options.config.searchURL,
        where,
        (datas, logic) => {
          if (logic.code === 200) {
            this.pageIndex = pageIndex;
            this.itemSource = datas.ResultList;
            this.totalCount = datas.TotalCount;
          }
        }
      ).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    async onUploadQDW() {
      let rows = this.selectedRows;

      if (rows === null || rows === undefined || rows.length === 0) {
        this.$message({ message: "请先选择库存", type: "error" })
        return false
      }
      let sumGuids = [];
      _.each(rows, item => {
        sumGuids.push(item.SUMGuid);
      });
      if (sumGuids == null || sumGuids.length <= 0) {
        return;
      }
      this.isDisabled = true;
      this.$post(CONFIG.UploadQDW, {SUMGuids: sumGuids}, (data, logic) => {
        this.isDisabled = false;
        this.onCleaSelectCount();
        if (logic.code === 200) {
          this.$message({
            message: "已上传成功",
            type: "success"
          });
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "上传失败", type: "error" });
        }
      }).finally(e => {
        this.isDisabled = false;
      });
    },

    // 根据查询条件上传到抢单王库存
    async onUploadQDW_byCondition() {
      this.loading = true;
      this.loadingText = '上传中，请稍后...'
      let searchParam = {
        FieldWhereString: JSON.stringify(this.searchFields),
        UploadQDW_byCondition: true,
        IsZero: this.searchFields.IsZero,
        IsNegative: this.searchFields.IsNegative
      }

      this.$post(CONFIG.UploadQDW, searchParam, (data, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: "已上传成功",
            type: "success"
          });
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "上传失败", type: "error" });
        }
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
          this.loadingText = '数据加载中,请稍后...'
        }, 500);
      })

    },

    async onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.$refs.table.cleanEdit();
          this.onSearchFields(params);
        });
      } else {
        this.onSearchFields(params);
      }
    },

    async onSearchFields(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      this.onBindData(1);
    },
    async onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      this.isShow = false;
      this.onBindData(1);
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        this.isDisabled = true;
        return this.$post(CONFIG.saveRowsURL, rows, (data, logic) => {
          this.isDisabled = false;
          if (logic.code === 200) {
            this.onCleaSelectCount();
            this.$refs.table.cleanEdit();
            this.$message({ message: "保存成功", type: "success" });
            this.onSearch();
          } else if (logic.msg !== "") {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "保存失败", type: "error" });
          }
        });
      }
    },
    // 判断汇总库存权限
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    // hasViewBuyPrice() {
    //   // 特殊权限，查看采购价
    //   let isnotSee = _.some(
    //     this.specialResource,
    //     item => item.Code === "ViewBuyPrice"
    //   );
    //   return isnotSee;
    // },
    // hasViweSalesPrice() {
    //   // 特殊权限，查看销售价
    //   let isnotSee = _.some(
    //     this.specialResource,
    //     item => item.Code === "ViewSalesPrice"
    //   );
    //   return isnotSee;
    // },

    reSearch() {
      this.pageIndex = 1;
      this.onSearch();
    },

    // 导出
    onExport() {
      let searchCondition = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: this.toInt(this.pageIndex, 1),
          PageSize: this.pageSize
        },
        {
          IsZero: this.searchFields.IsZero
        }
      );
      this.exportFormatVisible = true;
      let notExportColumns = "StockImage"; // 不进行导出的列
      this.setColumns = _.filter(this.columns, col => {
        return notExportColumns.indexOf(col.binding) === -1;
      });
      this.setDefaultConfigColumns = this.defaultConfig;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.searchCondition = searchCondition;
    },

    async onMarketSearch() {
      let rows = this.selectedRows;
      let marketRows = [];
      if (
        (this.searchFields.Model === null || this.searchFields.Model === "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        return this.$message({
          message: "请输入搜索条件或勾选需要市场查货的汇总库存",
          type: "warning"
        });
      } else if (
        (this.searchFields.Model !== null || this.searchFields.Model !== "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        const regex = /,|，/;
        let modeList = (this.searchFields.Model === null
          ? ""
          : this.searchFields.Model
        ).split(regex);
        _.each(modeList, item => {
          marketRows.push({
            Model: item
          });
        });
      } else {
        marketRows = rows;
      }
      let request = [];
      _.each(rows, item => {
        request.push({
          Model: item.Model, // 型号，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.InvQty, // 数量，
          Remark: item.Remark // 需求说明
        });
      });
      this.$nextTick(function() {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    // 勾选选择框事件
    selectChange(rows) {
      //this.selectedRows = rows
      _.each(rows, a => {
        a.pageIndex = this.pageIndex;
      });
      if (this.selectedRows.length <= 0) this.selectedRows = rows;
      else {
        //过滤当前页数据
        this.selectedRows = _.filter(this.selectedRows, a => {
          return a.pageIndex != this.pageIndex;
        });
        //添加当前页数据
        this.selectedRows = this.selectedRows.concat(rows);
        //从小到大 排序
        this.selectedRows.sort(function(a, b) {
          return a.pageIndex - b.pageIndex;
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: "至少选择一条数据", type: "warning" });
      } else {
        _.each(this.selectedRows, item => {
          item.CheckGUID = item.SUMGuid;
        });
        let order = { checkInfo: this.selectedRows };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function() {
          this.showStkCheckInfo = true;
        });
      }
    },
    onSeletedInfo(val) {
      this.selectedRows = val;
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("stockSummary", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("stockSummary", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("stockSummary", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("stockSummary");
    }
  }
};
</script>

<style lang="scss">
</style>

