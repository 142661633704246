<template>
  <div>
    <znl-gridmodule
      ref="flexGrid"
      header="BOM管理"
      height="200px"
      loading-type="animation"
      pagination-position="top"
      :table-plug-width="230"
      :is-fields-search="isShowFiedsSearch"
      :columns="columns"
      class="BomManageModule"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :item-source="itemSource"
      :is-multi-rows-check="false"
      :on-init="onInit"
      :on-page-changed="onBindData"
      :btn-mouse-enter-menus="btnMouseEnterMenus"
      :has-znl-btns="true"
      :role="role"
      table-tip="可拉动当前行到左边BOM文件夹"
      :cell-draggable="true"
      :loading="loading"
      :on-refresh="onRefresh"
      :on-save-setting="onSaveSetting"
      :on-save-config="onSaveConfig"
      :on-reset-setting="onResetSetting"
      @switch-to-hide="
        val => {
          $emit('switch-to-hide', val);
        }
      "
      :on-search="onSearch"
      :is-init="true"
      @cell-click="onCellClick"
    >
      <div slot="heading-slot" class="BomManageHeaderSlot">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="输入BOM名称 、客户名称 、单号"
          size="mini"
          layout="left"
          width="200px"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="fieldsFilter.Keyword"
          @keyup.enter.native="$refs.flexGrid.search()"
          type="text"
        ></znl-input>
        <znl-button
          type="multiple"
          :height="22"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button
          @click="add()"
          style-type="main"
          tip="可选中左边文件夹再添加,对BOM进行分类管理"
          :height="22"
          v-show="hasRes('AddBom')"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加新需求</span>
        </znl-button>
      </div>
      <znl-folder-tree
        slot="tablePlug"
        @folder-change="folderChange"
        @trigger-search="$refs.flexGrid.search()"
      />
    </znl-gridmodule>
    <znl-bom-master-edit
      title="新增客户需求"
      :editvisible="bomMasterDetailEditVisibleTwo"
      v-if="bomMasterDetailEditVisibleTwo"
      :folderId="folderId"
      :edit-type="setBomEdit"
      @confirm="bomMasterDetailEditVisibleTwo = false"
      @close="bomMasterDetailEditVisibleTwo = false"
      @save-success="
        () => {
          onRefresh();
        }
      "
      @select-bom="selectbom"
    />
  </div>
</template>
<script>
import { switchToLegacyPage } from "~/lib/native";
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import ZnlBomMaster from "@c_modules/BOM/BomMaster";
import ZnlFolderTree from "@c_modules/BOM/BomFolderTree";
import ZnlBomMasterEdit from "@c_modules/BOM/BomMasterEdit";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { openLinkAll } from "@scripts/methods/common";

const CONFIG = {
  default: null,
  bomConfigURL: "BomMaster/GetConfig",
  configURL: "BomMaster/GetConfig",
  saveConfigURL: "BomMaster/SaveConfig",
  resetConfigURL: "BomMaster/ResetConfig",
  searchURL: "BomMaster/Search",
  deleteUrl: "BomMaster/DeleteList",
  completeUrl: "BomMaster/Complete",
  configURL_detailLine: "BomDetailLine/GetConfig"
};

export default {
  name: "BomManageModule",

  config: CONFIG,

  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll],

  props: {
    height: String,
    isActivity: Boolean
  },

  computed: {
    switchToLegacyPage,
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      }
    }
  },

  data() {
    return {
      loading: false,
      bomMaster: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      fieldsFilter: {
        Keyword: "" // Bom名称或客户名称
      },
      folderId: 0, // bom文件夹ID
      // bomMasterDetailEditVisibleTwo: false,
      bomResourceList: [],
      bomConfig: {},

      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      currentItem: [],
      Size: 10,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loadingInstance: {},
      command: "",
      gridShow: false,
      bomMasterDetailEditVisibleTwo: false,
      btnMouseEnterMenus: {
        List: []
      },
      isShowFiedsSearch: false,
      Status: true,
      BomGuid: "",
      bdLinedGuidsStr: "",
      setBomEdit: "addNew",
      waitNoticedBomVisible: false,
      detailLineResourceList: [] // 需求物料模块权限
    };
  },

  watch: {
    folderId(val) {
      this.onSearch();
    },
    selsectOptionsDemandSources(val) {
      _.each(this.columns, column => {
        if (column.binding === "RequestSource") {
          this.$set(column, "dataMap", val);
        }
      });
    }
  },

  components: {
    ZnlFolderTree,
    ZnlBomMaster,
    ZnlBomMasterEdit
  },

  methods: {
    async onInit(config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage(
          "BomMaster",
          this.$options.config.configURL
        );
      }
      // 获取模块打开或者关闭的状态
      // this.GetStatus(config.Status)
      this.resourceList = config.ResourceList.map(item => item.Code);
      this.bomResourceList = config.ResourceList;
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      // 列的初始化
      this.settingColumns(columns);
      // 操作按钮的初始化
      this.settingActionBtn(columns);
      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      // this.PageIndex = config.PageIndex
      this.onBindData();
    },

    resetQuery(opts){
      this.fieldsFilter.Keyword=opts.BillNo;
      this.$refs.flexGrid.search()
    },

    // 列的初始化
    settingColumns(columns) {
      /** **********下拉列表start*************/
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "SalesStatus";
        }),
        {
          dataMap: [
            { key: false, value: "未下单" },
            { key: true, value: "已下单" }
          ],
          showDropDown: true
        }
      );
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "QuoteStatus";
        }),
        {
          dataMap: [
            { key: "待报价", value: "待报价" },
            { key: "已报价", value: "已报价" }
          ],
          showDropDown: true
        }
      );
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });

      // 录入人
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "CreatedName";
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      );
      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });
      this.CurrencyNames = CurrencyNames;
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "CurrencyName";
        }),
        { dataMap: this.CurrencyNames, showDropDown: true }
      );
      // 税点
      let TaxRate = this.selectOptionsTaxRate;
      this.TaxRate = TaxRate;
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "TaxRate";
        }),
        {
          dataMap: TaxRate,
          showDropDown: true
        }
      );
      // 需求来源
      let sources = this.selsectOptionsDemandSources;
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "RequestSource";
        }),
        {
          dataMap: sources,
          showDropDown: true
        }
      );

      /** **********下拉列表end*************/

      _.extend(
        _.find(columns, item => {
          return item.binding === "BFileName";
        }),
        {
          buttons: [
            {
              content: row => {
                return row["BFileName"]
                  ? '<i class="iconfont icon-excel_ic"></i><span>' +
                      row["BFileName"] +
                      "</span>"
                  : row["BFileName"];
              },
              className: "znl-file-name",
              click: row => {
                this.ondownload(row);
              }
            }
          ]
        }
      );
      _.each(columns, item => {
        //   if (item.binding === 'CopyNumber') {
        //     item.element = (item) => {
        //       if (!_.isUndefined(item) && !_.isNull(item)) {
        //         return '<span style="color:#0B62EF">' + item + '</span>'
        //       }
        //     }
        //   }
        if (
          item.binding === "QuotePriceText" ||
          item.binding === "BuyerPriceText"
        ) {
          item.element = item => {
            if (!_.isUndefined(item) && !_.isNull(item)) {
              return this.toFloat(item, 2);
            }
          };
        }

        if (
          item.binding === "Model" ||
          item.binding === "SupplierName" ||
          item.binding === "CustomerName" ||
          item.binding === "BillNo"
        ) {
          _.extend(item, {
            className: "znl-linkAll-column",
            renderCell: this.openLinkAll(item)
          });
        }
      });
      _.extend(
        _.find(columns, column => {
          return column.binding === "IsUrgent";
        }),
        {
          isCheckedColumn: true,
          isReadOnly: true
        }
      );
      _.each(columns, item => {
        // 处理特殊权限 不看客户资料。 隐藏客户资料 （客户名称、联系人、电话、QQ、Email、地址）
        let hideCols = "CustomerName,Contact,Telephone,Email,Address,QQ";
        if (
          this.getSpecialResourceByCode("NoSeeCustomer") &&
          hideCols.indexOf(item.binding) > -1
        ) {
          item.hasRole = false;
        }
        // 不看采购价
        if (
          !this.getSpecialResourceByCode("ViewBuyPrice") &&
          item.binding === "BuyerPriceText"
        ) {
          item.hasRole = false;
        }
        // 不看利率
        if (
          this.getSpecialResourceByCode("NoSeeProfit") &&
          (item.binding === "GrossInterestRate" ||
            item.binding === "BuyerPriceText")
        ) {
          item.hasRole = false;
        }
      });
    },
    settingActionBtn(columns) {
      this.btnMouseEnterMenus = {
        List: [
          {
            name: "修改",
            isShow: this.hasRes("AddBom"),
            iconName: "icon-bomlist_edit_btn",
            click: rows => {
              this.onUpdate(rows);
            }
          },
          {
            name: "删除",
            isShow: this.hasRes("deleteBom"),
            iconName: "icon-delete_btn_ic",
            click: rows => {
              this.onDelete(rows);
            }
          },
          // {
          //   name: '通知查价',
          //   isShow: this.hasRes('PurchaseNotic'),
          //   iconName: 'icon-tag_btn_ic',
          //   click: (rows) => {
          //     this.onNoticedBom(rows)
          //   }
          // },
          {
            name: "标记完成",
            iconName: "icon-tag_btn_ic",
            isShow: this.hasRes("SetCompleted"),
            click: rows => {
              this.onComplete(rows);
            }
          },
          {
            name: "下载文件",
            iconName: "icon-uploading-wh_btn_ic",
            isShow: this.hasRes("DownloadFile"),
            click: rows => {
              this.ondownload(rows);
            }
          }
        ],
        Binding: "operate"
      };
      columns.unshift({
        name: "operate",
        dataType: 5,
        binding: "operate",
        width: 36,
        header: "操作",
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: "left",
        className: "znlerp-operate znl-text-center",
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: "znl-table-btn",
            click: () => {}
          }
        ]
      });
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;
      this.$emit("model-loading", true);
      let filterFields = {
        // 外部搜索条件
        Keyword: this.fieldsFilter.Keyword,
        FolderId: this.folderId
      };
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[
            key.substring(0, 1).toUpperCase() + key.substring(1)
          ] = value;
        });
      }
      let response = await this.$post(
        this.$options.config.searchURL,
        _.extend(
          {
            FieldWhereString: JSON.stringify(this.searchFields),
            PageIndex: pageIndex,
            PageSize: this.pageSize
          },
          filterFields
        )
      );
      this.pageIndex = pageIndex;
      this.itemSource = response.ResultList;
      this.totalCount = response.TotalCount;
      this.checkedRows = [];
      this.checkedRowsCount = 0;
      this.gridShow = true;
      _.delay(() => {
        this.$emit("model-loading", false);
        this.loading = false;
        this.$emit("page-loading", false);
      }, 200);
    },
    ondownload(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: "请选择要操作的行", type: "warning" });
      }
      if (
        rows.BomUrl !== null &&
        rows.BomUrl !== undefined &&
        rows.BomUrl !== ""
      ) {
        this.downFile(
          { FileName: rows.BFileName, FileUrl: "http:" + rows.BomUrl },
          function(res) {}
        );
      } else {
        this.$message({ message: "下载的文件Url为不能空", type: "error" });
      }
    },
    folderChange(node) {
      this.folderId = node.FolderId;
    },
    onInquire: async function(row) {
      this.$nextTick(function() {
        if (!_.isUndefined(row)) {
          // this.onUpdateRow(row)
          this.onBindData(this.pageIndex);
        }
      });
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    async onSaveSetting(columns, pageSize) {
      // console.log(columns)
      localStorage.removeItem("BomMaster");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      );
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("BomMaster");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },
    onResetSetting() {
      localStorage.removeItem("BomMaster");
      return this.$post(CONFIG.resetConfigURL);
    },
    async onSearch(params = {}) {
      this.searchFields = {};
      _.extend(this.searchFields, params);
      await this.onBindData(1);
      this.checkedRows = [];
      this.checkedRowsCount = 0;
    },
    // 删除
    onDelete(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: "请选择要操作的行", type: "warning" });
      }
      this.$confirm("确定要删除【" + rows.BillNo + "】的需求单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let guids = [];
        guids.push(rows.BOMGUID);
        this.$post(
          CONFIG.deleteUrl,
          { ListBomGuid: guids, SalesStatus: rows.SalesStatus },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }
        );
      });
    },
    // 修改
    onUpdate(rows) {
      let order = { BOMGUID: rows.BOMGUID };
      this.setBomEdit = "edit";
      this.$store.commit("setParamOrder", order);
      this.bomMasterDetailEditVisibleTwo = true;
    },
    // 通知
    onNoticedBom(rows) {
      if (rows === undefined || rows.length <= 0) {
        return this.$message({
          message: "请勾选要通知查价的需求订单",
          type: "warning"
        });
      }
      this.BomGuid = rows.BOMGUID;
      this.waitNoticedBomVisible = true;
    },
    // 标记完成
    onComplete(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: "请选择要操作的行", type: "warning" });
      }
      this.$confirm("确定要将【" + rows.BillNo + "】需求单标记完成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let guids = [];
        guids.push(rows.BOMGUID);
        this.$post(
          CONFIG.completeUrl,
          { ListBomGuid: guids },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }
        );
      });
    },
    add() {
      this.$nextTick(function() {
        this.bomMasterDetailEditVisibleTwo = true;
        this.setBomEdit = "addNew";
      });
    },
    QuotePriceText(row) {
      this.$refs.znlBomMaster.QuotePriceText(row);
    },
    hasRes(code) {
      return _.some(this.bomResourceList, page => page.Code === code);
    },
    // 获取模块状态
    GetStatus(val) {
      this.Status = val !== 0;
      this.Status
        ? this.$refs.flexGrid.toggleTableShow(true)
        : this.$refs.flexGrid.toggleTableShow(false);
    },
    // 保存模块状态
    saveStatus(val) {
      let Status = val ? 1 : 0;
      let defaultConfig = this.defaultConfig;
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status;
        let statusConfig = this.defaultConfig;
        statusConfig.ColumnConfigs = [];
        this.$post(this.$options.config.saveConfigURL, statusConfig);
      }
    },
    selectbom(d) {
      let BOMGUID = { BOMGUID: d };
      this.$emit("selection-changed", BOMGUID);
    },
    // 切换到旧版
    async oldEdition() {
      await switchToLegacyPage("Bom");
    },
    updateBomAmount(val) {
      _.each(this.itemSource, item => {
        if (item.BOMGUID === val.BOMGUID) {
          this.$set(
            item,
            "BuyerPriceText",
            this.toFloat(val.BuyerPriceText, 6)
          );
          this.$set(
            item,
            "QuotePriceText",
            this.toFloat(val.QuotePriceText, 6)
          );
          this.$set(item, "GrossInterestRate", val.GrossInterestRate);
          this.$set(item, "QuoteStatus", val.QuoteStatus);
        }
      });
    },
    onCellClick(row, column, cell, event) {
      if (
        column.columnKey === "BomName" ||
        column.columnKey === "CustomerName" ||
        column.columnKey === "BillNo" ||
        column.columnKey === "CreatedTime" ||
        column.columnKey === "CreatedName"
      ) {
        this.$emit("cell-click", row);
      }
    }
  },

  mounted() {
    // this.$nextTick(() => {
    //   _.delay(() => {
    //     this.$refs.flexGrid.reFMHeight()
    //   }, 3000)
    // })
  }
};
</script>
<style lang="scss">
.BomManageModule {
  width: 100%;
  // display: flex;
  .BomManageModuleSlot {
    width: 100%;
    display: block;
    box-sizing: border-box;
    .znl-folder-tree {
      min-width: 230px;
      margin-right: 5px;
      float: left;
      max-width: 230px;
      width: 230px;
      //overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      // height: 170px;
      .znl-tree {
        width: 230px;
        & > div > div > .tree-row > .folder-name {
          font-weight: 700;
          color: #666;
        }
      }
    }
    .BomManageModuleRight {
      // width: 80%;
      // height: 200px;
      flex: 1;
      justify-content: space-around;
      display: block;
      margin-left: 235px;
      flex-direction: column;
      .bommaster-lists {
        flex: 1;
      }
      .znl-lists .lists-empty-data {
        border-bottom: none;
      }
    }


  }
  .znl-grid-component {
    width: 100%;
    .base-flex-grid {
      flex: none;
    }
  }
  .el-table__empty-block {
    min-height: 0;
    height: 0;
  }
  .BomManageHeaderSlot {
    // position: absolute;
    margin-left: 123px;
    // top:2px;
    width: 80%;
    display: flex;
    line-height: 20px !important;
    align-items: center;
    & > div,
    & > .el-button {
      margin-right: 5px;
      & > .znlInputInput {
        display: flex;
        align-items: center;
      }
    }
  }
  .table-container, //修复配单列表滚动条问题 by zilong
  .znl-table-plug,
  .base-flex-grid {
    background-color: #fff;
    height: 100%;
  }
  .base-flex-grid {
    border-left: 1px solid #c0c0c0;
  }
  .znl-table_base-case {
    display: block !important;
  }
  .old-edition {
    right: 220px;
  }
}
</style>
