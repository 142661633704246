<template>
  <div id="p-wait-to-purchase-list">
    <!-- 待采购清单 -->
    <znl-gridmodule
      ref="flexGrid"
      :columns="columns"
      :has-znl-actions="true"
      :height="height"
      :is-fields-search="isShowFiedsSearch"
      :is-init="true"
      :is-multi-rows-check="true"
      :is-multi-rows-check-fixed="false"
      :is-show-rightbtns="false"
      :item-source="itemSource"
      :loading="loading"
      :on-init="onInit"
      :on-page-changed="onBindData"
      :on-refresh="onRefresh"
      :on-reset-params="onResetParams"
      :on-reset-setting="onResetSetting"
      :on-save-config="onSaveConfig"
      :on-save-setting="onSaveSetting"
      :on-search="onSearch"
      :page-index="pageIndex"
      :page-size="pageSize"
      :role="role"
      :search-fields="searchModel"
      :total-count="totalCount"
      element-loading-text="数据加载中,请稍后..."
      @switch-to-hide="
        (val) => {
          $emit('switch-to-hide', val);
        }
      "
      @cell-click="onCellClick"
      @select-change="selectChange"
    >
      <div slot="heading-slot" class="v-table-toolbar">
        <!-- 市场查货 -->
        <znl-button style-type="mac" @click="onMarketSearch()">
          <span> <i class="iconfont icon-search_ic"></i> 市场查货 </span>
        </znl-button>
        <!-- 记录询价 -->
        <znl-button style-type="mac" @click="onRecordInquiry()">
          <span> <i class="iconfont icon-add_btn_ic"></i> 记录询价 </span>
        </znl-button>
        <znl-button style-type="mac" @click="onAddToShoppingCart()">
          <span> <i class="iconfont icon-add_btn_ic"></i> 加入购物车 </span>
        </znl-button>
        <znl-input
          v-model="searchCondition.NoticeTimeByStart"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="datepicker"
          inp-perc="100%"
          layout="left"
          placeholder="通知时间"
          size="mini"
          value-format="yyyy-MM-dd"
          width="90px"
        ></znl-input>
        <span style="font-size: small">到</span>
        <znl-input
          v-model="searchCondition.NoticeTimeByEnd"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="datepicker"
          inp-perc="100%"
          layout="left"
          placeholder="通知时间"
          size="mini"
          value-format="yyyy-MM-dd"
          width="90px"
        ></znl-input>
        <znl-input
          v-model="searchCondition.Model"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="input"
          inp-perc="100%"
          layout="left"
          placeholder="型号"
          size="mini"
          type="text"
          width="100px"
          @keyup.enter.native="$refs.flexGrid.search()"
        ></znl-input>
        <znl-input
          v-model="searchCondition.BillNo"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="input"
          inp-perc="100%"
          layout="left"
          placeholder="销售单号"
          size="mini"
          type="text"
          width="100px"
          @keyup.enter.native="$refs.flexGrid.search()"
        ></znl-input>
        <znl-input
          v-model="searchCondition.PurchaseStatusStr"
          :border="true"
          :clearable="true"
          :disabled="false"
          :select-options="ordStatus"
          form-type="select"
          inp-perc="100%"
          layout="left"
          placeholder="选择状态"
          popper-class="popperClass"
          width="78px"
          @change="onChangeStatus"
        ></znl-input>
        <znl-input
          v-model="BuyEmpID"
          :border="true"
          :clearable="true"
          :disabled="false"
          :multiple="true"
          :select-options="selectOptionsAllUserURL"
          form-type="select"
          layout="left"
          placeholder="选择采购员"
          popper-class="popperClass"
          width="90px"
          @change="onChangeUser"
        ></znl-input>
        <znl-button
          class="box-left-btn search-btn"
          style-type="mac"
          type="multiple"
        >
          <znl-button-menu @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button v-if="false" @click="updateBuyEmp">
          <span>
            <i class="iconfont icon-table_set_btn_n"></i>转给其它采购
          </span>
        </znl-button>
        <!-- <znl-button @click="addToCar">
          <span>
            <i class="iconfont el-icon-circle-plus"></i>加入购物车</span>
        </znl-button>-->
      </div>
      <div slot="parameterConfig">
        <el-table :data="parameterConfig" :height="338">
          <el-table-column
            binding="ParamText"
            class-name="znl-parameterConfig"
            header-align="center"
            label="参数名称"
            name="ParamText"
            prop="ParamText"
          ></el-table-column>
          <el-table-column
            class-name="znl-parameterConfig"
            header-align="center"
            label="参数值"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.ParamCode === 'DateSearchDefault'">
                <el-select
                  v-model="scope.row.ParamValue"
                  :disabled="false"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in [
                      { key: 'ToDay', value: '当天日期' },
                      { key: 'ThreeDays', value: '最近三天' },
                      { key: 'LatestWeek', value: '最近一周' },
                    ]"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </template>
              <template
                v-if="scope.row.ParamCode === 'IsQueryBarStatus'"
                v-show="false"
              >
                <el-select v-model="scope.row.ParamValue" placeholder="请选择">
                  <el-option
                    v-for="item in [
                      { key: '0', value: '关闭' },
                      { key: '1', value: '打开' },
                    ]"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </template>
              <template v-if="scope.row.ParamCode === 'ParamType'">
                <input v-model="scope.row.ParamType" type="hidden" />
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </znl-gridmodule>
    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>
  </div>
</template>

<script>
import { mixin as znlMethodsMinix } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import EnquiryView from "@c_modules/Enquiry/index";
import { isClient } from "~/lib/runtime";

const CONFIG = {
  configURL: "WaitToPurchase/GetConfig",
  saveConfigURL: "WaitToPurchase/SaveConfig",
  resetConfigURL: "WaitToPurchase/ResetConfig",
  resetParamConfigURL: "WaitToPurchase/ResetParamConfig",
  searchURL: "WaitToPurchase/Search",
  updateBuyEmp: "WaitToPurchase/UpdateBuyEmp",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  searchAdaptURL: "WaitToPurchase/GetAdoptInfoGotoCart",
};
export default {
  name: "MWaitToPurchse",
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: { EnquiryView },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      defaule: "100%",
    },
    initData: {
      type: Boolean,
      defaule: true,
    },
  },
  watch: {
    // itemSource () {
    //   this.$emit('page-loading', false)
    // }
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      currentItem: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      ParamJosn: [],
      role: "1",
      isShowFiedsSearch: false,
      fieldsFilter: {},
      ordStatus: [
        {
          value: "全部采购",
          key: 0,
        },
        {
          value: "部分采购",
          key: 1,
        },
        {
          value: "待采购",
          key: 2,
        },
      ],

      searchCondition: {
        Model: "",
        BillNo: "",
        PurchaseStatusStr: 2, // 默认未采购
        BuyEmpID: [],
        NoticeTimeByStart: "",
        NoticeTimeByEnd: "",
      },
      StartCreateTime: "",
      EndCreateTime: "",
      BuyEmpID: [],
      BuyEmpList: [],
      BuyEmpName: "",
      UserID: this.$store.state.accountInfo.UserID, // 当前用户ID
      CurrencyNames: [],
      parameterConfig: [],
      loading: false,
      SelectedRows: [], //选中行
    };
  },
  methods: {
    async onInit(config) {
      if (!config) {
        // config = await this.$post(CONFIG.configURL)
        config = await this.isSaveStorage("WaitToPurchase", CONFIG.configURL);
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.resourceList = config.ResourceList;
      this.paramConfiguration(config.ParamJosn);
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.defaultConfig = config;
      this.columns = this.flexGridColumnsHandler(
        this.defaultConfig.ColumnConfigs
      );
      // _.extend(
      //   _.find(this.columns, col => {
      //     return col.binding === 'BuyEmpName'
      //   }),
      //   {
      //     multiple: true,
      //     mapping: 'BuyerNameList',
      //     separator: ',',
      //     showDropDown: true
      //   }
      // )
      // // 币种
      // let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (
      //   obj,
      //   index
      // ) {
      //   return { key: obj.key, value: obj.value }
      // })
      // this.CurrencyNames = CurrencyNames
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'Currency'
      //   }),
      //   { dataMap: CurrencyNames, showDropDown: true }
      // )
      // // 状态
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'PurchaseStatusStr'
      //   }),
      //   { dataMap: this.ordStatus, showDropDown: true }
      // )
      // 单位
      // let QtyUnitDropDown = this.selectOptionsQtyUnit
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'Unit'
      //   }),
      //   { dataMap: QtyUnitDropDown, showDropDown: true }
      // )
      // // 业务员
      // let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
      //   obj,
      //   index
      // ) {
      //   return { key: obj.value, value: obj.value }
      // })
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'SalerName'
      //   }),
      //   { dataMap: UserAllowUsers, showDropDown: true }
      // )
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'BuyEmpName'
      //   }),
      //   { dataMap: UserAllowUsers, showDropDown: true }
      // )

      // // 税点
      // let TaxRate = this.selectOptionsTaxRate
      // this.TaxRate = TaxRate
      // _.extend(
      //   _.find(columns, function (column) {
      //     return column.binding === 'TaxRate'
      //   }),
      //   { dataMap: TaxRate, showDropDown: true }
      // )
      columns.unshift({
        name: "operate",
        dataType: 5,
        binding: "operate",
        width: 48,
        header: "购物车",
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: "left",
        className: "znlerp-operate znl-text-center",
        buttons: [
          {
            content: (d) => {
              if (d.IsShopingCar === undefined) {
                d.IsShopingCar = false;
              }
              return d.IsShopingCar
                ? '<a style="color:gray">已加入</a>'
                : "<a>加入</a>";
            },
            className: "znl-table-btn",
            click: (row) => {
              let existCartItem = _.find(
                this.$store.state.shoppingData,
                function (item) {
                  return item.NoticeGUID === row.NoticeGUID;
                }
              );
              // 重复项不添加
              if (!existCartItem) {
                if (!row.IsShopingCar) {
                  this.AddToShoppingCart(row);
                  this.$message({
                    message: "已加入到我的购物车中",
                    type: "success",
                  });
                } else {
                  this.setShoppingCart([row]);
                }
              }
            },
          },
        ],
      });
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.parameterConfig = config.ParamJosn;

      if (this.initData) {
        await this.onBindData();
      }
      this.$emit("page-loading", false);
    },
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    onSearch(params) {
      this.fieldsFilter = _.extend({}, this.searchCondition, params);
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;
      this.CurrentItem = [];
      let searchParams = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          BuyEmpIDs: this.BuyEmpID,
        }
      );
      return await this.$post(CONFIG.searchURL, searchParams, (data) => {
        this.itemSource = data === null ? [] : data.ResultList;
        this.pageIndex = pageIndex;
        this.totalCount = data.TotalCount;
        this.addShopingCarStatus();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.gridShow = true;
        this.$emit("page-loading", false);
      });
    },
    hasRes(code) {
      return _.some(this.resourceList, (page) => page.Code === code);
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("WaitToPurchase");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    onResetSetting() {
      localStorage.removeItem("WaitToPurchase");
      return this.$post(CONFIG.resetConfigURL);
    },

    onResetParams(val) {
      localStorage.removeItem("WaitToPurchase");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("WaitToPurchase");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "WaitToPurchase", ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("WaitToPurchase");
          }
        );
      }
    },
    onChangeStatus(val) {
      this.pageIndex = 1;
      if (!_.isUndefined(val)) {
        this.searchCondition.PurchaseStatusStr = val.key;
      }
      this.onSearch();
    },
    onChangeUser(val) {
      this.BuyEmpID = val;
    },
    updateBuyEmp() {
      if (this.BuyEmpID === null || this.BuyEmpID.length === 0) {
        this.$message({ message: "请选择采购员", type: "error" });
      } else {
        let selected = "[" + this.BuyEmpID.join(",") + "]";
        let selectedRows = this.$refs.flexGrid.getSelectedRows();
        if (selectedRows && selectedRows.length > 0) {
          _.each(selectedRows, (item) => {
            this.$set(item, "BuyEmpName", selected);
          });
          this.onSavePurchaseNotices(selectedRows);
        }
      }
    },
    async onMarketSearch() {
      let rows = this.$refs.flexGrid.getSelectedRows();

      if (rows === null || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选一行或者多行待采购清单，再点击市场查货操作",
          type: "warning",
        });
      }
      let request = [];
      _.each(rows, (item) => {
        request.push({
          Model: item.Model, // 型号，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.NoticeQty, // 数量，
          Remark: item.Remark, // 需求说明
          SourceType: 3, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
          SourceGuid: item.NoticeGUID, // 通知GUID
          SourceId: item.NoticeID, // 通知ID，
        });
      });

      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },

    onRecordInquiry: async function () {
      // 记录询价
      let rows = this.$refs.flexGrid.getSelectedRows();
      let inquireList = [];

      this.$nextTick(function () {
        if (rows.length > 0) {
          _.each(rows, (row) => {
            inquireList.push({
              SupplierName: null,
              Model: row.Model,
              Brand: row.Brand,
              Packaging: row.Packaging,
              MakeYear: row.MakeYear,
              Quality: row.Quality,
              InvQty: row.SOLineQty,
              MPQ: row.MPQ,
              Qty: row.SOLineQty,
              Price: null,
              SourceType: 3, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
              SourceGuid: row.NoticeGUID, // 通知GUID
              SourceId: row.NoticeID, // 通知ID，
            });
          });
        } else {
          inquireList.push({
            SupplierName: null,
            Model: null,
            Brand: null,
            Packaging: null,
            MakeYear: null,
            Quality: null,
            InvQty: null,
            MPQ: null,
            Qty: null,
            Price: null,
            SourceType: 3, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
            SourceGuid: null, // 需求明细GUID
            SourceId: null, // 需求明细ID，
          });
        }
        if (isClient()) {
          this.addInquireList(inquireList, function (msg) {
            if (msg === "不能向自己询价") {
              this.$message({ message: msg, type: "warning" });
            }
          });
        } else {
          let editItem = {};
          if (inquireList.length > 0) {
            const data = inquireList[0];
            data.SourceGuid = row.NoticeGUID; // 通知GUID
            data.SourceId = row.NoticeID; // 通知ID，
            Object.keys(data).forEach((key) => (editItem[key] = data[key]));
          }
          editItem.SourceType = 3;
          this.$refs.enquiryView.onOpen(editItem);
        }
      });
    },
    onConfirm() {},
    onSavePurchaseNotices(selectedRows) {
      if (selectedRows !== null && selectedRows.length > 0) {
        let request = [];
        _.each(selectedRows, (item) => {
          let m = {};
          if (item.BuyEmpName !== "" && !_.isUndefined(item.BuyEmpName)) {
            m.NoticedEmpUserIds = JSON.parse(item.BuyEmpName);
            m.NoticedEmpList = this.getEmpList(
              item.BuyerNameList,
              m.NoticedEmpUserIds
            );
          }
          m.SOLineGUID = item.SOLineGUID;
          m.Model = item.Model;
          m.ProductCategory = item.ProductCategory;
          m.Brand = item.Brand;
          m.Packaging = item.Packaging;
          m.SOLineQty = item.SOLineQty;
          m.StockQty = item.StockQty;
          m.AlreadyQty = item.AlreadyQty;
          m.NoticeQty = item.NoticeQty;
          m.Remark = item.Remark;
          m.DeliveryDate = item.DeliveryDate;
          request.push(m);
        });

        return this.$post(
          CONFIG.updateBuyEmp,
          { SOLineList: request },
          (data, logic) => {
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "通知成功", type: "success" });
              this.$emit("close", true);
              // this.visible = false
            }
          },
          () => {
            // this.visible = false
          }
        );
      }
    },
    getEmpList(itemSource, userIds) {
      let list = [];
      if (itemSource.length > 0 && userIds && userIds.length > 0) {
        _.each(userIds, (uid) => {
          let item = _.find(itemSource, (m) => m.key === uid);
          list.push(item);
        });
      }
      return list;
    },
    async AddToShoppingCart(row) {
      let adaptRow = [];
      let data = {
        NoticeGUID: row.NoticeGUID,
        BDLineGUID: row.BDLineGUID,
        SOLineGUID: row.SOLineGUID,
        NoticeGUID: row.NoticeGUID,
      };
      await this.$post(CONFIG.searchAdaptURL, data, (datas) => {
        _.each(datas, (item) => {
          let info = {
            JGUID: this.getNewGuid(),
            IAGUID: item.IAGUID || null,
            NoticeGUID: item.NoticeGUID,
            CompanyName: item.SupplierName || "",
            CompanyGuid: item.SupplierID || "",
            Model: item.Model,
            Brand: item.Brand,
            InvQty: item.Qty,
            Packaging: item.Packaging,
            Price: item.Price,
            Small: item.Qty * item.Price,
            MakeYear: item.MakeYear,
            Quality: item.Quality,
            SOLineGUID: item.SOLineGUID,
            SOGuid: item.SOGuid,
            SOBillNo: item.BillNo,
            MPQ: item.MPQ,
            Unit: item.Unit,
            BuyEmpID: item.BuyEmpID,
            BuyEmpName: item.BuyEmpName,
            DeliveryDate: item.DeliveryDate,
            CustomerPartNo: item.CustomerPartNo,
            ProductCategory: item.ProductCategory,
            CONumber: item.CONumber,
          };
          adaptRow.push(info);
        });
      });
      row.IsShopingCar = true;
      this.setShoppingCart(adaptRow);
      this.addShopingCarStatus();
      this.$emit(
        "shopping-cart-count",
        this.getShoppingCart() !== null ? this.getShoppingCart().length : 0
      );
      // })
    },
    removeShoppingCart(caritem) {
      // debugger
      if (caritem) {
        _.each(this.itemSource, (item, index) => {
          if (caritem.NoticeGUID === item.NoticeGUID) {
            item.IsShopingCar = false;
          }
        });
      }
    },
    paramConfiguration(val) {
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(val, function (paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }

      var dateSearchInfo = _.find(val, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "DateSearchDefault") {
          return paramjosn;
        }
      });
      if (dateSearchInfo) {
        this.searchCondition.NoticeTimeByEnd = this.getDate(0);
        if (dateSearchInfo.ParamValue === "ToDay") {
          this.searchCondition.NoticeTimeByStart = this.getDate(0);
        } else if (dateSearchInfo.ParamValue === "LatestWeek") {
          this.searchCondition.NoticeTimeByStart = this.getDate(6);
        } else {
          this.searchCondition.NoticeTimeByStart = this.getDate(2);
        }
      }
    },
    addShopingCarStatus() {
      let cartList = this.getShoppingCart();
      if (cartList !== null && cartList !== undefined) {
        _.each(this.itemSource, (item) => {
          let info = _.find(cartList, (x) => x.NoticeGUID === item.NoticeGUID);
          if (info !== undefined && info !== null) {
            item.IsShopingCar = true;
          }
        });
      }
    },
    // 采纳询价后回调事件 回写待查价界面的数据
    adoptInquireSuccess(val) {
      _.each(this.itemSource, (item) => {
        if (item.NoticeGUID === val.PNGUID) {
          this.$set(item, "AdoptQty", val.SumQty);
        }
      });
    },
    onCellClick(row, column, cell, event) {
      if (
        column.columnKey === "Model" ||
        column.columnKey === "Brand" ||
        column.columnKey === "AsQty"
      ) {
        this.$emit("cell-click", row);
      }
    },
    //选中行加入购物车
    async onAddToShoppingCart() {
      _.each(this.SelectedRows, (item) => {
        let existCartItem = _.find(
          this.$store.state.shoppingData,
          function (val) {
            return val.NoticeGUID === item.NoticeGUID;
          }
        );
        // 重复项不添加
        if (!existCartItem) {
          if (!item.IsShopingCar) {
            this.AddToShoppingCart(item);
          } else {
            this.setShoppingCart([item]);
          }
        }
      });
      this.$message({ message: "已加入到我的购物车中", type: "success" });
    },
    selectChange(rows) {
      this.SelectedRows = rows;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
#p-wait-to-purchase-list {
  .el-table__body-wrapper {
    // flex: none;
  }
}
</style>
