<template>
  <znl-gridmodule
    ref="flexGrid"
    :header="header"
    :table-handle="true"
    :height="height"
    :columns="columns"
    :item-source="itemSource"
    :is-multi-rows-check="multiRowsCheck"
    :is-fields-search="isShowFiedsSearch"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :is-show-rightbtns="false"
    :role="role"
    :users="$store.state.users"
    :on-init="onInit"
    :is-init="isInit"
    :loading="loading"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :is-activity="false"
    @switch-to-hide="
      (val) => {
        $emit('switch-to-hide', val);
      }
    "
    @ondblclick="
      (val) => {
        this.stockCheckRow(val);
      }
    "
    :on-page-changed="onBindData"
    class="m-znl-stock-select"
  >
    <div slot="heading-slot">
      <znl-input
        from-type="input"
        :disable="false"
        placeholder="请输入型号"
        size="mini"
        layout="left"
        width="150px"
        inp-perc="100%"
        :is-upper-case="true"
        :clearable="true"
        :border="true"
        v-model="searchFields.Model"
        @keyup.enter.native="onSearch()"
        type="text"
      ></znl-input>
      <znl-button
        type="multiple"
        style-type="mac"
        class="box-left-btn search-btn"
      >
        <znl-button-menu @click="onSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu
          :width="20"
          tip="高级搜索"
          @click="onShowFiedsSearch()"
        >
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
      <el-checkbox v-model="isZero" size="small">包含零库存</el-checkbox>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";
const CONFIG = {
  searchURL: "StkStockMini/Search",
  configURL: "StkStock/GetConfig",
  getStockLockQty: "StkStockReserve/GetStockLockQty",
  GetPageParamURL: "PageParam/Get", // 获取页面参数配置
};

export default {
  name: "SaleOrderStock",
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  config: CONFIG,
  components: {},
  data() {
    return {
      isCheck: false,
      isZero: false,
      columns: [],
      itemSource: [],
      Size: 10,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loading: this.isShowLoading,
      isShowFiedsSearch: false,
      inputModel: "",
      searchFields: {
        Model: "",
      },
      IsOutQty: true,
      ParamJson: [],
      TempParamJson: [],
      GCode: "StkStock",
    };
  },
  watch: {},
  computed: {
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      },
    },
  },
  props: {
    isInit: Boolean,
    initData: {
      type: Boolean,
      default: true,
    },
    pagePosition: {
      type: String,
      defaule: "bottom",
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: Number,
    },
    header: {
      type: String,
      defaule: "我的库存",
    },
    multiRowsCheck: {
      type: Boolean,
      default: false,
    },
    isShowLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onInit() {
      this.$emit("page-loading", true);
      let config = await this.isSaveStorage("stkStockCheck", CONFIG.configURL);
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      // 获取参数设置
      this.ParamJson = config.ParamJosn;
      await this.GetParamJson();

      this.resourceList = config.ResourceList;
      this.ParamJosn = config.ParamJosn;
      let IsOutQty = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsOutQty") {
          return paramjosn.ParamValue;
        }
      });
      if (IsOutQty && IsOutQty.ParamValue === "0") {
        this.IsOutQty = false;
      }

      // 隐藏图片列列
      _.each(columns, (item, index) => {
        if (item.binding === "StockImage" || item.binding === "AdoptQty") {
          item.hasRole = false;
          item.visibled = false;
        }
      });
      if (!this.$store.state.accountInfo.IsMainAccount) {
        // 看采购价权限控制采购价未税BuyPrice、采购价(含税)TaxBuyPrice、成本额TotalCostAmount、成本额（含税）CostAmountInTax
        let Jurisdiction = !this.getSpecialResourceByCode("ViewBuyPrice");

        // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
        let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

        // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
        let ViewSalesPriceJurisdiction =
          !this.getSpecialResourceByCode("ViewSalesPrice");
        _.each(columns, (item, index) => {
          if (
            (Jurisdiction && item.binding === "BuyPrice") ||
            (Jurisdiction && item.binding === "TaxBuyPrice") ||
            (Jurisdiction && item.binding === "TotalCostAmount") ||
            (Jurisdiction && item.binding === "CostAmountInTax")
          ) {
            item.hasRole = false;
          }
          if (noSeeSupplier && item.binding === "SupplierName") {
            item.hasRole = false;
          }
          if (
            (ViewSalesPriceJurisdiction && item.binding === "SalesPrice") ||
            (ViewSalesPriceJurisdiction && item.binding === "FollowPrice")
          ) {
            item.hasRole = false;
          }
          // 看备注1 没有这个权限隐藏列
          if (item.binding === "Remark") {
            item.hasRole = this.hasRes("ViewRemark1");
          }
          // 看备注2 没有这个权限隐藏列
          if (item.binding === "Remark1") {
            item.hasRole = this.hasRes("ViewRemark2");
          }
          // 看备注3  没有这个权限隐藏列
          if (item.binding === "Remark2") {
            item.hasRole = this.hasRes("ViewRemark3");
          }
          // 不看替代型号，有这个权限隐藏列
          if (item.binding === "ReplaceModel") {
            item.hasRole = !this.hasRes("NoSeeReplaceModel");
          }
        });
      }
      !this.multiRowsCheck &&
        columns.unshift({
          name: "log",
          dataType: 5,
          binding: "log",
          width: 48,
          header: "选择",
          visible: true,
          allowSorting: false,
          isReadOnly: false,
          editable: false,
          isFieldSearch: true,
          isSystem: true,
          buttons: [
            {
              className: "znl-table-btn",
              content: (d) => {
                return "<a>选择</a>";
              },
              click: (row) => {
                this.stockCheckRow(row);
              },
            },
          ],
        });
      this.columns = columns;

      this.role = config.Role;
      this.pageSize = config.PageSize;
      if (this.initData) {
        this.onBindData();
      } else {
        this.loading = false;
        this.$emit("page-loading", false);
      }
    },

    // 重置表格高度
    resetTableH() {
      let data = this.resetTableHeight({
        dom: ".znl-multiple-stock-select .znl-grid-component",
        parentDom: ".znl-multiple-stock-select",
        brother: [
          // ".m-znl-stock-select .isFieldsSearch",
          // ".m-znl-stock-select .footer",
        ],
        heightSet: true,
        surplus: 10,
      });

      // console.log('批次库存 data is ',data);

      this.resetTableHeight({
        dom: ".m-znl-stock-select .el-table",
        parentDom: ".znl-multiple-stock-select",
        brother: [
          ".m-znl-stock-select .isFieldsSearch",
          ".m-znl-stock-select .footer",
        ],
        heightSet: true,
        surplus: 0,
      });
    },

    async onBindData(pageIndex = 1) {
      let filterFields = {
        // 外部搜索条件
        isZero: this.isZero, // 零库存
      };
      let data = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize,
        },
        filterFields
      );
      if (this.isShowLoading) {
        this.loading = true;
      }
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        this.loading = false;
        this.$emit("page-loading", false);
        // 要放nextTick函数里面执行
        this.$nextTick(() => {
          // this.getStockLockQty()
        });
        // console.log('执行到这里')
        this.resetTableH();
      });
    },
    // 获取参数配置默认值
    async GetParamJson() {
      if (!this.hasValue(this.ParamJson)) {
        return;
      }
      if (this.ParamJson.length > 0) {
        // 零库存
        let paramIsZero = this.findVal(
          this.ParamJson,
          (a) => a.ParamCode === "IsZeroInventory"
        ).ParamValue;
        if (paramIsZero === "true") {
          this.isZero = true;
        }
      }
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    stockCheckRow(item) {
      this.$emit("stock-check-row", item);
    },
    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      return this.onBindData(1);
    },
    onGetSelectedRows() {
      return this.$refs.flexGrid.getSelectedRows();
    },
    hasRes(code) {
      return _.some(this.resourceList, m => m.Code === code)
    },
    // 获取库存锁定量
    async getStockLockQty() {
      let items = this.itemSource;
      if (items === null || items.length <= 0) {
        return;
      }
      let stkGuids = _.uniq(_.map(items, (item) => item.StkGUID));
      let result = await this.$post(CONFIG.getStockLockQty, {
        StkGUIDList: stkGuids,
      });
      _.each(this.itemSource, (item) => {
        let info = _.find(result, (i) => {
          return i.StkGUID === item.StkGUID;
        });
        if (info !== undefined && info != null) {
          this.$set(item, "BookedQty", info.ReservedNum);
        }
      });
      _.each(this.itemSource, (item) => {
        let qty = 0;
        let bookedQty = 0;
        if (this.hasValue(item.InvQty)) {
          qty = item.InvQty;
        }
        if (this.hasValue(item.BookedQty)) {
          bookedQty = item.BookedQty;
        }
        this.$set(item, "UsableQty", qty - bookedQty);
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
</style>
