<template>
  <div class="reserver-container">
    <znl-dialog
      title="备货资料"
      height="460px"
      width="540px"
      :loading.sync="loading"
      :loading-text="elementLoadingText"
      class="m-stk-reserveradd-box"
      :visible="visible"
      @close="()=>{$emit('close')}"
    >
      <div slot="znl-dialog">
        <el-row>
          <el-col>
            <znl-input
              :title-width="firstStyle.width"
              form-type="input"
              placeholder="输入客户简称或拼音字母,并按回车键查询"
              size="mini"
              layout="left"
              title="客户："
              width="100%"
              :is-must-fill="false"
              :border="true"
              :clearable="true"
              v-model="stkReserve.CustomerName"
              @keyup.enter.native="SearchCompany"
              type="text"
            >
              <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
            </znl-input>
          </el-col>
        </el-row>
        <el-row>
          <znl-input
            ref="refModel"
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="*型号："
            :border="true"
            :is-must-fill="true"
            width="250px"
            :clearable="true"
            v-model="stkReserve.Model"
            type="text"
          ></znl-input>

          <znl-input
            title-width="37px"
            ref="refPrice"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="单价："
            width="130px"
            class="same-line"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Price"
            type="text"
          ></znl-input>

          <znl-input
            title-width="37px"
            form-type="select"
            :select-options="taxRateList"
            :disabled="false"
            placeholder
            popper-class="popperClass"
            size="mini"
            layout="left"
            title="税率："
            width="100px"
            class="same-line float-right"
            :clearable="true"
            :border="true"
            v-model="stkReserve.TaxRate"
            type="text"
          ></znl-input>
        </el-row>
        <el-row>
          <znl-input
            ref="refQty"
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            layout="left"
            title="*数量："
            :is-must-fill="true"
            width="180px"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Qty"
            type="text"
          ></znl-input>&nbsp;
          <znl-input
            form-type="select"
            :disabled="false"
            placeholder="请选择"
            layout="left"
            :is-must-fill="true"
            width="62px"
            :clearable="true"
            :select-options="qtyUnitList"
            :border="true"
            v-model="stkReserve.Unit"
            type="text"
          ></znl-input>
          <el-checkbox
            size="small"
            v-model="stkReserve.IsLockStk"
            class="same-line red-checkbox"
          >锁定库存</el-checkbox>
          <!-- v-if="(IsShowLock || stkReserve.IsLockStk !== null && stkReserve.IsLockStk !== undefined)" -->
          <znl-input
            title-width="40px"
            form-type="datepicker"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="交期："
            width="160px"
            class="float-right"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Delivery"
            type="text"
          ></znl-input>
        </el-row>
        <el-row>
          <znl-input
            ref="refReservedNum"
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="已备货数："
            width="250px"
            :border="true"
            :clearable="true"
            v-model="stkReserve.ReservedNum"
            type="text"
          ></znl-input>
          <el-checkbox
            size="same-line"
            v-model="stkReserve.IsCompleted"
            @change="onComplete()"
            class="same-line red-checkbox"
          >完成备货(标记已备货完成)</el-checkbox>
        </el-row>
        <el-row>
          <znl-input
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="品牌："
            :border="true"
            width="250px"
            :clearable="true"
            v-model="stkReserve.Brand"
            type="text"
          ></znl-input>

          <znl-input
            title-width="40px"
            form-type="input"
            :disabled="false"
            placeholder
            class="ml10"
            size="mini"
            layout="left"
            title="封装："
            width="235px"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Packaging"
            type="text"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-input
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            :border="true"
            placeholder
            size="mini"
            layout="left"
            title="包装："
            width="178px"
            :clearable="true"
            v-model="stkReserve.MPQ"
            type="text"
          ></znl-input>

          <znl-input
            title-width="35px"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="批号："
            width="150px"
            class="same-line"
            style="margin-left:7px;"
            :clearable="true"
            :border="true"
            v-model="stkReserve.MakeYear"
            type="text"
          ></znl-input>

          <znl-input
            title-width="35px"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="品质："
            class="same-line float-right"
            width="150px"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Quality"
            type="text"
          ></znl-input>
        </el-row>
        <el-row>
          <znl-input
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="说明："
            width="100%"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Explain"
            type="text"
          ></znl-input>
        </el-row>
        <el-row>
          <znl-input
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="备货备注："
            width="100%"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Remark"
            type="text"
          ></znl-input>
        </el-row>
        <el-row>
          <znl-input
            :title-width="firstStyle.width"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="备货备注2："
            width="245px"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Remark2"
            type="text"
          ></znl-input>
          <znl-input
            title-width="62px"
            form-type="input"
            :disabled="false"
            placeholder
            size="mini"
            layout="left"
            title="备货备注3："
            width="245px"
            class="float-right"
            :clearable="true"
            :border="true"
            v-model="stkReserve.Remark3"
            type="text"
          ></znl-input>
        </el-row>
      </div>
      <el-row
        slot="footer"
        align="middle"
        type="flex"
        justify="end"
        class="dialog-footer StkStockReserveAddFooter"
        v-show="isShowFooter"
      >
        <el-col>
          <el-checkbox
            v-model="IscontinueSave"
            style="margin-right:5px;"
            class="red-checkbox"
            v-show="showContinueSave"
          >保存后继续操作</el-checkbox>
          <znl-button :height="33" :width="60" @click="onClear" style="margin-right:5px">清空</znl-button>
          <znl-button :height="33" :width="60" style-type="main" @click="Save">保存</znl-button>
        </el-col>
      </el-row>
      <d-company-contact-select
        v-if="dialogCompanySelectVisible"
        :visible="dialogCompanySelectVisible"
        :searchModel="SelectSearchModel"
        companyType="1,3"
        title="选择客户名称"
        @close="()=>{dialogCompanySelectVisible=false}"
        @update:visible="val=>{dialogCompanySelectVisible=val}"
        @confirm="companyChecked"
      ></d-company-contact-select>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as propSyncMixin } from '~assets/scripts/mixins/propSync'
import VCompanySelect from '@c_modules/Crm/DCompanyContactSelect'
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import dCompanyContactSelect from '../Crm/DCompanyContactSelect'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  stkReverseSave: 'StkStockReserve/Save',
  SearchByGUID: 'StkStock/GetStockListByGuids',
  SearchBySRGUID: 'StkStockReserve/SearchBySRGUID',
  DropDownURL: 'DictItem/GetItemValue',
  CurrencyCodeURL: 'SysCurrency/Search'
}
const defaultContent = {// 库存备货
  SRID: -1,
  SRGUID: '',
  CustomerGUID: '', // 客户GUID
  CustomerName: '', // 客户名称',
  CurrencyCode: 'RMB', // 币种
  StkGUID: '',
  Model: '', // 型号
  Price: '', // 单价
  Qty: '', // 库存量
  AvailableQty: '', // 可用量
  Unit: '', // 单位(PCS)
  IsLockStk: null, // 是否锁定库存
  Delivery: new Date().toLocaleDateString(), // 交期
  ReservedNum: '', // 已备货数量
  IsCompleted: '', // 是否完成
  Brand: '', // 品牌
  Packaging: '', // 封装
  MPQ: '', // 包装
  MakeYear: '', // 年份
  Quality: '', // 品质
  Explain: '', // 说明(内部使用、参考)
  Remark: ''// 备注
}
export default {
  name: 'StkWarningAdd',
  mixins: [znlMethodsMixin, propSyncMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    VCompanySelect,
    dCompanyContactSelect
  },
  data() {
    return {
      dialogCompanySelectVisible: false, // 是否显示客户选择框
      InTypes: '', // 类型
      IscontinueSave: false, // 是否继续保存备货
      showContinueSave: false, // 是否显示 继续保存备货
      isEditMode: false, // 是否为编辑模式
      stkReserve: {// 库存备货
        SRID: -1,
        SRGUID: '',
        CustomerGUID: '', // 客户GUID
        CustomerName: '', // 客户名称',
        StkGUID: '',
        Model: '', // 型号
        Price: '', // 单价
        TaxRate: null, // 税率
        Qty: '', // 库存量
        AvailableQty: '', // 可用量
        Unit: '', // 单位(PCS)
        CurrencyCode: 'RMB', // 币种
        IsLockStk: null, // 是否锁定库存
        Delivery: new Date().toLocaleDateString(), // 交期
        ReservedNum: '', // 已备货数量
        IsCompleted: '', // 是否完成
        Brand: '', // 品牌
        Packaging: '', // 封装
        MPQ: '', // 包装
        MakeYear: '', // 年份
        Quality: '', // 品质
        Explain: '', // 说明(内部使用、参考)
        Remark: '', // 备注
        Remark2: '', // 备注
        Remark3: ''// 备注
      },
      loading: false,
      elementLoadingText: '正在保存数据,请稍后...',
      config: {
        DropDownURL: 'DictItem/GetItemValue'
      },
      optionsIsLockStk: [
        {
          key: true,
          value: '锁定'
        },
        {
          key: false,
          value: '取消锁定'
        }
      ],
      optionsIsCompleted: [
        {
          key: true,
          value: '完成'
        },
        {
          key: false,
          value: '未完成'
        }
      ],
      SelectSearchModel: {
        CompanyName: ''
      },
      firstStyle: {
        width: '64px'
      },
      isShowFooter: true,
      qtyUnitList: [],
      taxRateList: []
    }
  },
  props: {
    Srguid: '', // 备货GUID
    isRefresh: Boolean, // 是否刷新
    stkInfo: String, // 库存信息传递
    isLoadByStk: Boolean, // 是否根据库存信息加载
    stkVMIInfo: Object, // 供应商库存对象
    isRefreshVMI: Boolean, // 根据供应商库存刷新
    availableQty: Number,
    visible: {
      type: Boolean,
      default: false,
      propSync: true
    },
    IsShowLock: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    stkGuid: null

  },
  watch: {
    isRefresh: {
      handler(value) {
        this.onResetPage()
        if (!_.isEmpty(this.Srguid)) {
          this.onBound(this.Srguid)
          this.showContinueSave = false
          // this.isEditMode = true
        } else {
          this.showContinueSave = true
          // this.isShowFooter = true
        }
      },
      immediate: true
    },
    isLoadByStk: {
      handler(value) {
        if (!_.isEmpty(this.stkInfo)) {
          this.LoadModelByStock(this.stkInfo)
        }
      },
      immediate: true
    },
    isRefreshVMI: {
      handler(value) {
        if (!_.isEmpty(this.stkVMIInfo)) {
          this.LoadModelByStkVMI(this.stkVMIInfo)
        }
      },
      immediate: true
    }
  },
  methods: {
    async onInit() {
      this.qtyUnitList = await this.selectOptionsQtyUnit
      this.taxRateList = await this.selectOptionsTaxRate
      if (this.hasValue(this.stkGuid)) {
        await this.$post(CONFIG.SearchByGUID, { StkGUID: this.stkGuid }, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: 'error' })
          } else {
            if (this.hasValue(data) && data.length > 0) {
              data[0].InvQty = this.availableQty
              this.onLoad(data[0])
            }
          }
        })
      }
    },

    onResetPage() { // 重置页面
      this.stkReserve.SRID = -1
      this.stkReserve.SRGUID = ''
      this.stkReserve.StkGUID = ''
      this.stkReserve.CustomerGUID = '' // 客户GUID
      this.stkReserve.CustomerName = '' // 客户名称'
      this.stkReserve.Model = '' // 型号
      this.stkReserve.Price = '' // 单价
      this.stkReserve.TaxRate = null //
      this.stkReserve.Qty = '' // 库存量
      this.stkReserve.AvailableQty = ''// 可用量
      this.stkReserve.Unit = '' // 单位(PCS)
      this.stkReserve.IsLockStk = null// 是否锁定库存
      this.stkReserve.Delivery = new Date().toLocaleDateString()// 交期
      this.stkReserve.ReservedNum = '' // 已备货数量
      this.stkReserve.IsCompleted = '' // 是否完成
      this.stkReserve.Brand = '' // 品牌
      this.stkReserve.Packaging = '' // 封装
      this.stkReserve.MPQ = '' // 包装
      this.stkReserve.MakeYear = '' // 年份
      this.stkReserve.Quality = '' // 品质
      this.stkReserve.Explain = '' // 说明(内部使用、参考)
      this.stkReserve.Remark = ''// 备注
      this.stkReserve.Remark2 = ''// 备注
      this.stkReserve.Remark3 = ''// 备注
    },
    LoadModelByStock: async function (stk) { // 根据库存的信息进行复制新增
      this.onResetPage()
      let stkModel = await this.$post(CONFIG.SearchByGUID, { StkGUIDS: stk.StkGUID })
      this.$nextTick(function () {
        this.onLoad(stkModel)
      })
    },
    onLoad(stkModel) { // 根据库存对象加载页面
      this.stkReserve.SRID = -1
      this.stkReserve.StkGUID = stkModel.StkGUID
      this.stkReserve.Model = stkModel.Model // 型号
      this.stkReserve.Qty = stkModel.InvQty < 0 ? -stkModel.InvQty : stkModel.InvQty // 库存量
      this.stkReserve.AvailableQty = stkModel.InvQty < 0 ? -stkModel.InvQty : stkModel.InvQty
      this.stkReserve.Unit = stkModel.Unit === '' || _.isUndefined(stkModel.Unit) ? 'PCS' : stkModel.Unit // 单位(PCS)
      this.stkReserve.Delivery = new Date().toLocaleDateString() // 交期
      this.stkReserve.Brand = stkModel.Brand // 品牌
      this.stkReserve.Packaging = stkModel.Packaging // 封装
      this.stkReserve.MPQ = stkModel.MPQ // 包装
      this.stkReserve.MakeYear = stkModel.MakeYear // 年份
      this.stkReserve.Quality = stkModel.Quality // 品质
    },
    LoadModelByStkVMI(stk) { // 根据供应商库存加载实体
      this.onLoad(stk)
    },
    CheckData() {
      // if (this.stkReserve.CustomerName === '') {
      //   this.$message({ message: '客户名称不能为空', type: 'error' })
      //   return false
      // }
      if (this.stkReserve.Model === '') {
        this.$message({ message: '型号为必填项', type: 'error' })
        this.$refs.refModel.handleFocus()
        return false
      }
      if (this.stkReserve.Qty !== '' && !_.isFinite(Number(this.stkReserve.Qty))) {
        this.$refs.refQty.handleFocus()
        this.$message({ message: '备货数量输入出错', type: 'error' })
        return false
      }
      if (this.stkReserve.Qty === '' || this.stkReserve.Qty <= 0) {
        this.$refs.refQty.handleFocus()
        this.$message({ message: '备货数量必须为大于零的整数', type: 'error' })
        return false
      }
      // if (this.stkReserve.IsLockStk === true && this.stkReserve.Qty > this.stkReserve.AvailableQty) {
      //   this.$refs.refQty.handleFocus()
      //   this.$message({ message: '要锁定的备货数量不可以大于可用库存', type: 'error' })
      //   return false
      // }
      // if (this.stkReserve.Price === '') {
      //   this.$message({ message: '请输入单价', type: 'error' })
      //   return false
      //   // } else if (!isFinite(this.StkOut.DiscountAmount)) {
      // }
      if (this.stkReserve.Price !== '' && !_.isFinite(Number(this.stkReserve.Price))) {
        this.$message({ message: '单价必须为数字或小数', type: 'error' })
        this.$refs.refPrice.handleFocus()
        return false
      }
      if (this.stkReserve.ReservedNum !== '' && !_.isFinite(Number(this.stkReserve.ReservedNum))) {
        this.$refs.refReservedNum.handleFocus()
        this.$message({ message: '已备货数必须为数字类型', type: 'error' })
        return false
      }
      return true
    },
    Save() { // 保存
      if (this.CheckData()) {
        this.loading = true
        this.$post(CONFIG.stkReverseSave, this.stkReserve, (datas) => {
          this.loading = false
          if (datas > 0) {
            this.$message({ message: '保存成功', type: 'success' })
            this.onResetPage()
            if (!this.IscontinueSave) {
              this.$emit('confirm', true)
            }
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
      }
    },
    SearchCompany() { // 显示客户选择框
      this.InTypes = '1,3'
      this.dialogCompanySelectVisible = true
      this.SelectSearchModel.CompanyName = this.stkReserve.CustomerName
    },
    companyChecked(item) { // 选择后的值
      this.stkReserve.CustomerName = item.CompanyName
      this.stkReserve.CustomerGUID = item.CCompanyID
    },
    onComplete() { // 完成
      if (this.stkReserve.IsCompleted && this.stkReserve.ReservedNum === '') {
        this.stkReserve.ReservedNum = this.stkReserve.Qty
      }
    },
    onBound: async function (srguid) { // 绑定页面
      let RModel = await this.$post(CONFIG.SearchBySRGUID, { SRGUID: srguid })
      this.$nextTick(function () {
        this.stkReserve = _.extend({}, defaultContent, RModel)
        this.stkReserve.Unit = this.stkReserve.Unit === '' ? 'PCS' : this.stkReserve.Unit // 单位(PCS)
        if (this.hasValue(RModel.SOLineGUID))
          this.isShowFooter = false
        else
          this.isShowFooter = true
      })
    },
    onClear() {
      this.stkReserve.SRID = -1
      this.stkReserve.SRGUID = ''
      this.stkReserve.StkGUID = ''
      this.stkReserve.CustomerGUID = '' // 客户GUID
      this.stkReserve.CustomerName = '' // 客户名称'
      this.stkReserve.AvailableQty = ''// 可用量
      this.stkReserve.IsLockStk = null// 是否锁定库存
      // this.stkReserve.IsCompleted = '' // 是否完成
      this.stkReserve.Explain = '' // 说明(内部使用、参考)
      this.stkReserve.Remark = ''// 备注
      this.stkReserve.Remark2 = ''// 备注
      this.stkReserve.Remark3 = ''// 备注
    },
    inputFocus() {

    },
    inputBlur() {

    }
  },
  created() {
    this.$nextTick(() => {
      this.onInit()
    })
  }
}
</script>

<style lang="scss">
.reserver-container {
  // .znl-dialog .znl-input-title {
  //   text-align: left;
  // }
  .same-line {
    margin-left: 3px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .finish-Stocking {
    display: inline;
  }
  .float-right {
    float: right;
  }
  .red-checkbox {
    .el-checkbox__label {
      color: red;
    }
  }
}
.m-stk-reserveradd-box .znl-component-input {
  padding-top: 13px;
}
</style>
