<template>
  <!-- 发货明细 -->
  <div class="module-wrapper m-stkout-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :showCheck="true"
      checkboxBindingKey="StkOutLineGUID"
      :columns="columns"
      box-class="m-stkout-order-line"
      :item-source="itemSource"
      :totalField="['Amount','Qty','GrossProfit']"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StkOutLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 发货明细</span>
        <znl-button style-type="mac" :height="22" v-if="hasRes('Return')" @click="onStkoutReturn()">
          <i class="iconfont icon-backout_btn_ic"></i>
          <span>销售退货</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" v-if="hasRes('Out')" @click="onStkOut()">
          <i class="iconfont icon-sell-wh_btn_ic"></i>
          <span>销售出库</span>
        </znl-button>
        <!-- <znl-button style-type="mac" :height="22" v-if="hasRes('In')" @click="onStkIn()">
          <i class="iconfont"></i>
          <span>采购入库</span>
        </znl-button>-->
      </div>
    </znl-table>
    <!-- 销售出库 -->
    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      edit-type="add"
      title="新增发货单"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>
    <!--销售退货-->
    <sales-order-return-edit
      :editvisible="SalesOrderRetrunVisible"
      v-if="SalesOrderRetrunVisible"
      edit-type="edit"
      @confirm="SalesOrderRetrunVisible=false"
      @close="SalesOrderRetrunVisible=false"
    ></sales-order-return-edit>
    <!-- 采购入库 -->
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      edit-type="add"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false"
    ></stk-in-edit>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import SalesOrderReturnEdit from "@c_modules/Sale/SalesOrderReturnEdit";
import { erpTableSetHandler } from "@scripts/methods/common";

const Config = {
  configURL: "StkOutLine/GetConfig",
  resetParamConfigURL: "StkOutLine/ResetParamConfig",
  searchURL: "StkOutLine/Search"
};
export default {
  name: "ZNLStkOutLine",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: Config,
  components: {
    StkOutEdit,
    StkInEdit,
    SalesOrderReturnEdit
  },
  data() {
    return {
      stkOutEditVisible: false,
      stkInEditVisible: false,
      SalesOrderRetrunVisible: false,
      columns: [],
      itemSource: [],
      resourceList: [],
      ParamJosn: [],
      parameterConfig: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      stkOutGUID: null
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("stkOutLine", Config.configURL);
      }
      this.ParamJosn = config.ParamJosn;

      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      //处理特殊权限
      let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");
      _.remove(columns, item => {
        if (noSeeProfit || !viewBuyPrice) {
          switch (item.BindField) {
            case "GrossProfit": // 利润
            case "ProfitMargins": // 利润率
            case "BuyPrice": // 采购价
            case "PurchaseAmount": // 采购额
              return true;
            default:
              break;
          }
        }

        if (noSeeSupplier && item.BindField == "SupplierInfo")
          // 供应商
          return true;
      });

      this.columns = columns;
      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },
    async onBindData(pageIndex = 1) {
      if (!this.hasValue(this.stkOutGUID)) {
        return;
      }
      let data = _.extend({ StkOutGUID: this.stkOutGUID });
      return await this.$post(Config.searchURL, data, data => {
        this.itemSource = data === undefined ? [] : data;
      });
    },
    async onHeadSearch(stkOutGuid) {
      if (stkOutGuid) {
        this.stkOutGUID = stkOutGuid;
        await this.onBindData();
      }
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = [];
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(rows[0])) {
        return this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error"
        });
      } else {
        let isStatus = false;
        _.some(rows, item => {
          if (item.Status !== 3) {
            isStatus = true;
            return true;
          }
        });
        if (isStatus) {
          return this.$message({
            message: "只有已出库的发货单才能退货",
            type: "error"
          });
        } else {
          guid = _.map(rows, item => {
            return item.StkOutLineGUID;
          });
        }
      }
      return guid;
    },
    onSelectNoStatusRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = [];
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length <= 0) {
        return this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error"
        });
      } else {
        guid = _.map(rows, item => {
          return item.StkOutLineGUID;
        });
      }
      return guid;
    },

    // 销售退货
    onStkoutReturn() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === null || rows === undefined || rows.length === 0) {
        this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error"
        });
        return false;
      }
      let msg = "";
      rows.some(row => {
        if (row.ReturnQty >= row.Qty) {
          msg = `型号${row.Model}已经全部退货，不能重复退货`;
          return true;
        } else if (row.Status !== 3) {
          msg = `型号${row.Model}不是已出库状态，不能退货`;
          return true;
        }
      });
      if (msg !== "") {
        this.$message({ message: msg, type: "error" });
        return false;
      }

      let guids = rows.map(row => row.StkOutLineGUID);
      let paramOrder = { stkOutGUIDLine: guids, IsReturn: true };

      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function() {
        this.$refs.table.cleanAllCheck();
        this.SalesOrderRetrunVisible = true;
      });
    },

    // 销售出库
    onStkOut() {
      let guids = this.onSelectNoStatusRow();
      if (guids.length > 0) {
        let paramOrder = { stkOutGUIDLine: guids, IsReturn: false };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(function() {
          this.stkOutEditVisible = true;
        });
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onStkIn() {
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (!_.isUndefined(rows[0])) {
        this.$refs.flexGrid.clearSelection();

        this.onStkInadd(this.clone(rows));
      } else {
        this.$message({ message: "请至少勾选一条数据进行操作", type: "error" });
      }
    },
    onStkInadd(rows) {
      // 采购入库传值方法
      let order = { stkOutLines: this.clone(rows) };
      // this.$store.commit('removeTab', 'Stk/StkInAdd')
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function() {
        this.editType = "edit";
        this.stkInEditVisible = true;
        // this.openTab('Stk/StkInAdd', {}) // 显示编辑页面
      });
    },
    hasSpecialRes(code) {
      return _.some(
        this.selectOptionsSpecialResource,
        page => page.Code === code
      );
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkOutLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkOutLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkOutLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkOutLine");
    }
  }
};
</script>

<style lang="scss">
</style>
