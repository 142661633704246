<template>
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      checkboxBindingKey="TQDGuid"
      :showCheck="this.isOperate"
      :gridtype="gridtype"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @select-change="selectChange"
      @on-show-trigger="(e) => hideShowColTrigger('TencentQDW', e.col)"
      :drag-done="(col) => setTableColWidth('TencentQDW', col)"
      @drag-col-position="(cols)=>{columns = cols, saveErpTableConfig('TencentQDW', cols, true)}"
      @on-config-save="(cols)=>{resetTableConfig(cols,false)}"
      @on-reset-click="() => {resetTableConfig('TencentQDW')}"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="180px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="品牌"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="100px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Brand"
          type="text"
        ></znl-input>
        <znl-input
          title-width="70px"
          layout="left"
          ref="CurrencyCode"
          form-type="select"
          width="84px"
          placeholder="全部状态"
          popper-class="popperClass"
          :border="true"
          :clearable="true"
          :select-options="selectOptions"
          v-model="searchFields.ShowStatus"
        ></znl-input>

        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
        <znl-button
          style-type="mac"
          @click="onSetShowStatus(true)"
          :disabled="isDisabled || setup.qq === '' || setup.isExpired"
        >
          <i class="iconfont icon-show_btn_ic"></i>
          <span>批量上架</span>
        </znl-button>

        <znl-button style-type="mac" @click="onSetShowStatus(false)" :disabled="isDisabled">
          <i class="iconfont icon-show_btn_ic"></i>
          <span>批量下架</span>
        </znl-button>
        <znl-button style-type="mac" @click="onDeleteRow()" :disabled="isDisabled">
          <i class="iconfont icon-delete_btn_ic"></i>
          <span>批量删除</span>
        </znl-button>
        <znl-button
          style-type="mac"
          @click="onUploadClick()"
          :disabled="isDisabled || setup.qq === '' || setup.isExpired"
        >
          <i class="iconfont icon-uploading-wh_btn_ic"></i>
          <span>批量上传</span>
        </znl-button>
        <znl-button style-type="mac" @click="onClearStock()" :disabled="isDisabled">
          <i class="iconfont icon-delete_btn_ic"></i>
          <span>清空库存</span>
        </znl-button>
        <znl-button
          style-type="main"
          class="save-self-btn"
          @click="onBatchSave()"
          :disabled="isDisabled || setup.qq === '' || setup.isExpired"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存修改</span>
        </znl-button>

        <znl-button style-type="mac" @click="onSaveQQSetup()">
          <i class="iconfont icon-table_set_btn_n"></i>
          <span>设置</span>
        </znl-button>

        <a
          style="text-decoration:underline; margin-left:3px;margin-right:3px;"
        >已选择 ({{selectedRows.length}}) 条</a>

        <a
          @click="onCleaSelectCount()"
          style="text-decoration:underline; margin-left:5px;"
          title="清除选中条数"
        >清除</a>
      </div>
    </znl-table>

    <!-- 设置对话框 -->
    <setup
      :visible="isShowSetup"
      v-if="isShowSetup"
      @close="() => {isShowSetup = false}"
      @confirm="(cfg) => {isShowSetup = false, onSearch()}"
    ></setup>

    <!-- 批量上传 -->
    <batch-upload :visible="isShowUpload"
      v-if="isShowUpload"
      @close="() => {isShowUpload = false}"
      @confirm="onUploadConfirm"
    ></batch-upload>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";
import Setup from "@c_modules/QDW/Setup";
import BatchUpload from "@c_modules/QDW/BatchUpload";

const CONFIG = {
  PK: "TQDGuid",
  configURL: "TencentQDW/GetConfig",
  searchURL: "TencentQDW/Search",
  addURL: "TencentQDW/Add",
  saveRowsURL: "TencentQDW/BatchSave",
  deleteURL: "TencentQDW/Del",
  setShowStatus: "TencentQDW/SetShowStatus",
  getSetupUrl: "TencentQDW/GetSetup",
  clearStock: "TencentQDW/BatchClear"
};

export default {
  name: "TencentQDWC",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: { Setup, BatchUpload },
  data() {
    return {
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      searchFields: {
        Model: "",
        Brand: "",
        ShowStatus: null
      },
      selectedRows: [], // 选中的数据
      showStkCheckInfo: false, // 是否显示已选择库存列表
      loading: true,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      selectOptions: [{ key: 1, value: "已上架" }, { key: 0, value: "已下架" }],
      isDisabled: false,
      isShowSetup: false, // 弹出设置对话框
      setup: {
        qq: "",
        isExpired: false
      },
      isShowUpload: false
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    gridtype: {
      type: String,
      default: "action"
    }, // 表格类型。 action: 可编辑， base: 不可编辑
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isOperate: {
      type: Boolean,
      default: true
    }
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    // 刷新
    async onRefresh(e) {
      await this.onBindData(this.pageIndex);
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          isShow: this.searchFields.ShowStatus
        }
      );
      return await this.$post(CONFIG.searchURL, data, datas => {
        this.pageIndex = pageIndex;
        this.itemSource = datas.ResultList;
        this.totalCount = datas.TotalCount;
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    async getSetup() {
      let cfg, logic;
      await this.$post(CONFIG.getSetupUrl, null, (data, l) => {
        cfg = data;
        logic = l;
      });
      if (logic && logic.code !== 200) {
        if (logic.code === 40002) {
          this.setup.isExpired = true;
        }

        this.$message({ message: logic.msg, type: "error" });
        this.setup.qq = "";
      } else {
        this.setup.qq = cfg.QQ || "";
      }
    },

    async onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.searchFields = params;
          return this.onBindData(1);
        });
      } else {
        this.searchFields = params;
        return this.onBindData(1);
      }
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("TencentQDW", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      this.initColumns(columns);
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;

      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.getSetup();
      this.$emit("page-loading", false);
    },

    initColumns(columns) {
      let colIndex = _.findIndex(columns, item => item.BindField === "IsShow");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          options: [{ key: "是", value: true }, { key: "否", value: false }]
        });
      }
    },

    onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.searchFields = params;
          return this.onBindData(1);
        });
      } else {
        this.searchFields = params;
        return this.onBindData(1);
      }
    },
    // 更改上下架的状态
    onSetShowStatus(isShow) {
      let rows = this.selectedRows;
      if (rows == null || rows.length <= 0) {
        return this.$message({ message: "请勾选上商品,再操作", type: "error" });
      }
      let guids = _.map(rows, item => {
        return item.TQDGuid;
      });
      if (guids == null || guids.length <= 0) {
        return;
      }
      let param = {
        isShow: isShow,
        Guids: guids
      };
      this.isDisabled = true;
      return this.$post(CONFIG.setShowStatus, param, data => {
        this.isDisabled = false;
        if (data > 0) {
          this.clearSelectedRows();
          this.onSearch();
          this.$message({ message: "操作成功", type: "success" });
        } else {
          this.$message({ message: "操作失败", type: "error" });
        }
      }).finally(e => {
        this.isDisabled = false;
      });
    },
    onDeleteRow() {
      let rows = this.selectedRows;
      if (rows == null || rows.length <= 0) {
        return this.$message({ message: "请勾选上商品,再操作", type: "error" });
      }
      let guids = _.map(rows, item => {
        return item.TQDGuid;
      });
      if (guids == null || guids.length <= 0) {
        return;
      }
      this.$confirm("确定删除选中的商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let param = { Guids: guids };
        this.isDisabled = true;
        this.$post(CONFIG.deleteURL, param, datas => {
          this.isDisabled = false;
          if (datas > 0) {
            this.$message({ message: "删除成功", type: "success" });
            this.clearSelectedRows();
            this.onSearch();
          } else {
            this.$message({ message: "删除失败", type: "error" });
          }
        }).finally(e => {
          this.isDisabled = false;
        });
      });
    },
    onClearStock() {
      this.$confirm("确定要清除全部的抢单王库存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.isDisabled = true;
        return this.$post(CONFIG.clearStock, {}, data => {
          this.isDisabled = false;
          if (data > 0) {
            this.clearSelectedRows();
            this.onSearch();
            this.$message({ message: "操作成功", type: "success" });
          } else {
            this.$message({ message: "操作失败", type: "error" });
          }
        }).finally(e => {
          this.isDisabled = false;
        });
      });
    },

    // 批量保存
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        this.isDisabled = true;
        return this.$post(CONFIG.saveRowsURL, rows, data => {
          this.isDisabled = false;
          if (data >= 0) {
            this.$refs.table.cleanEdit();
            this.$message({ message: "保存成功", type: "success" });
            this.onSearch();
          } else {
            this.$message({ message: "保存失败", type: "error" });
          }
        }).finally(e => {
          this.isDisabled = false;
        });
      }
    },
    clearSelectedRows() {
      this.onCleaSelectCount();
    },

    // 勾选选择框事件
    selectChange(rows) {
      _.each(rows, a => {
        a.pageIndex = this.pageIndex;
      });
      if (this.selectedRows.length <= 0) this.selectedRows = rows;
      else {
        //过滤当前页数据
        this.selectedRows = _.filter(this.selectedRows, a => {
          return a.pageIndex != this.pageIndex;
        });
        //添加当前页数据
        this.selectedRows = this.selectedRows.concat(rows);
        //从小到大 排序
        this.selectedRows.sort(function(a, b) {
          return a.pageIndex - b.pageIndex;
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: "至少选择一条数据", type: "warning" });
      } else {
        _.each(this.selectedRows, item => {
          item.CheckGUID = item.VMGUID;
        });
        let order = { checkInfo: this.selectedRows };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function() {
          this.showStkCheckInfo = true;
        });
      }
    },

    // 弹出抢单王设置对话框
    onSaveQQSetup() {
      this.isShowSetup = true;
    },

    // 弹出批量上传对话框
    onUploadClick () {
      this.isShowUpload = true
    },

    // 批量上传确认
    onUploadConfirm () {
      this.isShowUpload = false
      this.onCleaSelectCount()
      this.onSearch()
    }
  }
};
</script>

<style lang="scss">
</style>

