<template>
  <!-- 待查价清单 -->
  <znl-gridmodule
    ref="flexGrid"
    :height="height"
    :columns="columns"
    :item-source="itemSource"
    :btn-mouse-enter-menus="btnMouseEnterMenus"
    gridtype="action"
    :is-init="true"
    :is-fields-search="isShowFiedsSearch"
    :is-multi-rows-check="true"
    :show-save-row-right-menu="false"
    :show-delete-row-right-menu="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :on-init="onInit"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-save-row="onSaveRows"
    :on-save-rows="onSaveRows"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :on-reset-params="onResetParams"
    :on-page-changed="onBindData"
    :has-znl-actions="true"
    :toggle-table-show="false"
    :is-show-rightbtns="false"
    :loading="loading"
    :element-loading-text="loadingText"
    :table-row-class-name="rowClassName"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @cell-click="onCellClick"
    @select-change="selectChange"
    @edit:cell-ended="onCellEdited"
  >
    <div slot="heading-slot" class="v-table-toolbar">
      <!-- 市场查货 -->
      <znl-button style-type="mac" @click="onMarketSearch()">
        <span>
          <i class="iconfont icon-search_ic"></i> 市场查货
        </span>
      </znl-button>
      <!-- 记录询价 -->
      <znl-button style-type="mac" @click="onRecordInquiry()">
        <span>
          <i class="iconfont icon-add_btn_ic"></i> 记录询价
        </span>
      </znl-button>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        value-format="yyyy-MM-dd"
        placeholder="通知时间"
        size="mini"
        layout="left"
        width="90px"
        :clearable="true"
        inp-perc="100%"
        :border="true"
        v-model="searchFields.CreateTimeStart"
      ></znl-input>
      <span style="font-size:small">到</span>
      <znl-input
        form-type="datepicker"
        :disabled="false"
        value-format="yyyy-MM-dd"
        placeholder="通知时间"
        size="mini"
        layout="left"
        width="90px"
        :clearable="true"
        :border="true"
        inp-perc="100%"
        v-model="searchFields.CreateTimeEnd"
      ></znl-input>
      <znl-input
        from-type="input"
        :disable="false"
        placeholder="需求型号"
        size="mini"
        layout="left"
        width="100px"
        inp-perc="100%"
        :clearable="true"
        :border="true"
        v-model="searchFields.Model"
        @keyup.enter.native="$refs.flexGrid.search()"
        type="text"
      ></znl-input>
      <znl-input
        from-type="input"
        :disable="false"
        placeholder="需求单号"
        size="mini"
        layout="left"
        width="100px"
        inp-perc="100%"
        :clearable="true"
        :border="true"
        v-model="searchFields.BillNo"
        @keyup.enter.native="$refs.flexGrid.search()"
        type="text"
      ></znl-input>
      <!-- 状态 -->
      <znl-input
        layout="left"
        form-type="select"
        :disabled="false"
        :border="true"
        width="78px"
        placeholder="选择状态"
        popper-class="popperClass"
        :clearable="false"
        :multiple="true"
        :tags-clear="false"
        :select-options="selectOptions"
        @change="onChangeStatus"
        v-model="checkStatus"
      ></znl-input>
      <znl-button type="multiple" class="box-left-btn search-btn">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
      <znl-button style-type="main" class="save-self-btn" @click="onBatchSave()">
        <span>
          <i class="iconfont icon-save_btn_ic"></i>保存修改
        </span>
      </znl-button>
      <!-- 提交报价 -->
      <znl-button style-type="main" @click="onSummit()" v-if="true">
        <span>
          <i class="iconfont icon-select-wh_btn_ic"></i>提交报价
        </span>
      </znl-button>&nbsp;
      <el-checkbox v-model="searchFields.IsMy" @change="onCheckChange">分配给我的</el-checkbox>
      <!-- <el-checkbox v-model="isInquire"
          @change="onCheckChange">已查</el-checkbox>
        <el-checkbox v-model="isUnInquire"
          @change="onCheckChange">未查</el-checkbox>
        <el-checkbox v-model="isQuote"
          @change="onCheckChange">已报</el-checkbox>
        <el-checkbox v-model="isUnQuote"
          @change="onCheckChange">未报</el-checkbox>
        <el-checkbox v-model="isUrgent"
      @change="onCheckChange">加急</el-checkbox>-->
    </div>
    <div slot="parameterConfig">
      <el-table :data="parameterConfig" :height="338">
        <el-table-column
          label="参数名称"
          header-align="center"
          prop="ParamText"
          name="ParamText"
          class-name="znl-parameterConfig"
          binding="ParamText"
        ></el-table-column>
        <el-table-column label="参数值" header-align="center" class-name="znl-parameterConfig">
          <template slot-scope="scope">
            <template v-if="scope.row.ParamCode==='PurGrossRate'">
              <znl-input
                title-width="70px"
                :clearable="true"
                form-type="input"
                :disabled="Isdisabled"
                placeholder="采购固定毛利率"
                layout="left"
                :is-must-fill="false"
                :border="true"
                tabindex="5"
                width="200px"
                v-model="scope.row.ParamValue"
                type="text"
              ></znl-input>
            </template>
            <template v-if="scope.row.ParamCode==='BomQuoteType'">
              <el-select v-model="scope.row.ParamValue" placeholder="请选择" :disabled="Isdisabled">
                <el-option
                  v-for="item in [{key: 'SalesQuote', value: '销售报价'},
                 {key: 'BuyQuoteCost', value: '采购报价 (进价)'},
                 {key: 'BuyQuoteSalePrice', value: '采购报价 (售价)'}]"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
            <template v-if="scope.row.ParamCode==='DateSearchDefault'">
              <el-select v-model="scope.row.ParamValue" placeholder="请选择" :disabled="false">
                <el-option
                  v-for="item in [
                {key: 'ToDay', value: '当天日期'},
                {key: 'ThreeDays', value: '最近三天'},
                {key: 'LatestWeek', value: '最近一周'}]"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
            <template v-if="scope.row.ParamCode==='IsQueryBarStatus'">
              <el-select v-model="scope.row.ParamValue" placeholder="请选择">
                <el-option
                  v-for="item in [{key: '0', value: '关闭'}, {key: '1', value: '打开'}]"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <wait-noticed-bom
      :visible="waitNoticedBomVisible"
      v-if="waitNoticedBomVisible"
      :bomLineGuids="bdLinedGuidsStr"
      :bomGuid="BomGuid"
      title="待转发的需求物料"
      :is-transpond="true"
      @save-success="onRefresh"
      @close="()=>{waitNoticedBomVisible=false}"
      @confirm="(val)=>{val&&(waitNoticedBomVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"
    ></wait-noticed-bom>
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import WaitNoticedBom from "@c_modules/BOM/BomWaitNoticed";
import { openLinkAll, renderRequiredColumn } from "@scripts/methods/common";
import { on } from "~assets/scripts/utils/dom";

const CONFIG = {
  configURL: "BomWaitToInquire/GetConfig",
  saveConfigURL: "BomWaitToInquire/SaveConfig",
  resetConfigURL: "BomWaitToInquire/ResetConfig",
  resetParamConfigURL: "BomWaitToInquire/ResetParamConfig",
  configURLFeedback: "BomPurchaseFeedback/GetConfig",
  saveConfigURLFeedback: "BomPurchaseFeedback/SaveConfig",
  resetConfigURLFeedback: "BomPurchaseFeedback/ResetConfig",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  searchURL: "BomWaitToInquire/Search",
  saveURL: "BomWaitToInquire/Save",
  ToMyWaitListUrl: "BomWaitToInquire/ToMyWaitList",
  ToPublicUrl: "BomWaitToInquire/ToPublic",
  MarkOutStockUrl: "BomWaitToInquire/MarkOutStock",
  MarkNoStockUrl: "BomWaitToInquire/MarkNoStock"
};
export default {
  name: "BomWaitToInquire",
  mixins: [
    znlMethodsMixin,
    getCommonDataMixin,
    openLinkAll,
    renderRequiredColumn
  ],
  config: CONFIG,
  components: {
    WaitNoticedBom
  },

  data() {
    return {
      ParamJosn: [],
      waitNoticedBomVisible: false,
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {
        Model: "",
        BillNo: "",
        IsMy: true,
        CreateTimeStart: "",
        CreateTimeEnd: ""
      },
      btnMouseEnterMenus: {
        List: []
      },
      selectOptions: [
        { key: 1, value: "已查" },
        { key: 2, value: "未查" },
        { key: 3, value: "已报" },
        { key: 4, value: "未报" },
        { key: 5, value: "加急" }
      ],
      checkStatus: [4],
      parameterConfig: [],
      Isdisabled: !this.$store.state.accountInfo.IsMainAccount,
      Model: "",
      bomBillNo: "",
      currentItem: [],
      summaryColumns: [],
      Size: 20,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loading: false,
      loadingInstance: {},
      command: "",
      gridShow: false,
      bdLinedGuidsStr: "",
      BomGuid: "",
      IsPublic: "1", // 1.我的待查价清单  2.公海
      loadingText: "数据加载中,请稍后...",
      resourceList: [],
      isShowFiedsSearch: false,
      Status: true,
      bomQuoteType: "", // 报价模式
      purGrossRate: 0, // 采购固定毛利率
      selectedRows: [] // 选中的数据
    };
  },
  computed: {
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      }
    }
  },
  watch: {},
  props: {
    isActivity: Boolean,
    initData: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "auto"
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    async onInit(config, refresh) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage("bomWaitToInquire", CONFIG.configURL);
      }
      this.parameterConfig = config.ParamJosn;

      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status);
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration(config.ParamJosn);

      if (config.ResourceList) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      _.each(columns, (column, index) => {
        if (
          column.binding === "BomModel" ||
          column.binding === "SupplierName" ||
          column.binding === "CustomerName" ||
          column.binding === "BillNo"
        ) {
          _.extend(column, {
            className: "znl-linkAll-column",
            renderCell: this.openLinkAll(column)
          });
        }
      });

      this.btnMouseEnterMenus = {
        List: [
          {
            name: "转发查价",
            isShow: false,
            click: row => {
              this.ToOtherUser(row);
            }
          },
          {
            name: "转到公海",
            isShow: false,
            click: row => {
              this.ToPublic(row);
            }
          },
          {
            name: "标记无货",
            isShow: true,
            click: row => {
              this.MarkNoStock(row);
            }
          },
          {
            name: "标记缺货",
            isShow: true,
            click: row => {
              this.MarkOutStock(row);
            }
          }
        ],
        Binding: "operate"
      };
      columns.unshift({
        name: "operate",
        dataType: 5,
        binding: "operate",
        width: 36,
        header: "操作",
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: "left",
        className: "znlerp-operate znl-text-center",
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: "znl-table-btn",
            click: () => {}
          }
        ]
      });
      this.defaultConfig = config;

      // 税率
      let TaxRate = this.selectOptionsTaxRate;
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "TaxRate";
        }),
        { dataMap: TaxRate, showDropDown: true }
      );
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit;
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "Unit";
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      );
      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "CurrencyName";
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      );

      if (
        _.find(columns, function(column) {
          return column.binding === "IsNoStock";
        })
      ) {
        _.extend(
          _.find(columns, function(column) {
            return column.binding === "IsNoStock";
          }),
          {
            dataMap: [
              { key: false, value: "否" },
              { key: true, value: "是" }
            ],
            showDropDown: true
          }
        );
      }
      if (
        _.find(columns, function(column) {
          return column.binding === "IsOutStock";
        })
      ) {
        _.extend(
          _.find(columns, function(column) {
            return column.binding === "IsOutStock";
          }),
          {
            dataMap: [
              { key: false, value: "否" },
              { key: true, value: "是" }
            ],
            showDropDown: true
          }
        );
      }
      if (
        _.find(columns, function(column) {
          return column.binding === "IsSubmit";
        })
      ) {
        _.extend(
          _.find(columns, function(column) {
            return column.binding === "IsSubmit";
          }),
          {
            dataMap: [
              { key: false, value: "未报" },
              { key: true, value: "已报" }
            ],
            showDropDown: true
          }
        );
      }
      if (
        _.find(columns, function(column) {
          return column.binding === "IsInquired";
        })
      ) {
        _.extend(
          _.find(columns, function(column) {
            return column.binding === "IsInquired";
          }),
          {
            dataMap: [
              { key: false, value: "未查" },
              { key: true, value: "已查" }
            ],
            showDropDown: true
          }
        );
      }
      if (
        _.find(columns, function(column) {
          return column.binding === "IsForwarded";
        })
      ) {
        _.extend(
          _.find(columns, function(column) {
            return column.binding === "IsForwarded";
          }),
          {
            dataMap: [
              { key: false, value: "否" },
              { key: true, value: "是" }
            ],
            showDropDown: true
          }
        );
      }
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "IsUrgent";
        }),
        {
          dataMap: [
            { key: false, value: "否" },
            { key: true, value: "是" }
          ],
          showDropDown: true
        }
      );

      // 业务员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "CreatedName";
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      );
      _.extend(
        _.find(columns, function(column) {
          return column.binding === "BuyEmpName";
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      );
      _.each(columns, (item, index) => {
        // 采购报价
        if (this.bomQuoteType === "BuyQuoteSalePrice") {
          if (item.binding === "SuggestPrice") {
            item.hasRole = false; // 给销售报价
          }
          if (item.binding === "QuotePriceCust") {
            item.hasRole = true; // 给客户报价
          }
        } else if (
          this.bomQuoteType === "SalesQuote" ||
          this.bomQuoteType === "BuyQuoteCost"
        ) {
          // 销售报价
          if (item.binding === "SuggestPrice") {
            item.hasRole = true;
          }
          if (item.binding === "QuotePriceCust") {
            item.hasRole = false;
          }
        }
      });
      columns = this.renderRequiredColumn(columns);
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;

      if (this.initData || refresh) {
        await this.onBindData();
      }
      this.$emit("page-loading", false);
      this.$nextTick(() => {
        this.$emit("quote-param", this.bomQuoteType, this.purGrossRate);
      });
    },

    // 当前行样式
    rowClassName(obj) {
      if (obj.row.IsUrgent && !obj.row.IsSubmit) {
        return "urgent-row";
      }
      return "";
    },

    async onBindData(pageIndex = 1) {
      this.loadingText = "数据加载中,请稍后...";
      this.loading = true;
      let param = _.extend({
        fieldWhereString: JSON.stringify(this.searchFields),
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        checkStatus: this.checkStatus
      });
      return await this.$post(
        this.$options.config.searchURL,
        param,
        (data, logic) => {
          if (logic.code === 200) {
            this.pageIndex = pageIndex;
            this.itemSource = data.ResultList;
            this.totalCount = data.TotalCount;
          }
        }
      ).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.gridShow = true;
        this.$emit("page-loading", false);
      });
    },

    async onSearch(params = {}) {
      // let rows = this.$refs.flexGrid.getChangedData()
      // if (rows.length > 0) {
      //   this.$confirm('当前数据已发生修改, 是否继续操作?', '提示', {
      //     confirmButtonText: '是',
      //     cancelButtonText: '否',
      //     type: 'warning'
      //   }).then(() => {
      //     _.extend(this.searchFields, params)
      //     this.onBindData(this.pageIndex)
      //   })
      // } else {
      //   _.extend(this.searchFields, params)
      //   this.onBindData(this.pageIndex)
      // }
      _.extend(this.searchFields, params);
      this.onBindData(this.pageIndex);
    },

    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    async onMarketSearch() {
      let rows = this.$refs.flexGrid.getSelectedRows();

      if (rows === null || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选一行或者多行待查价清单，再点击市场查货操作",
          type: "warning"
        });
      }
      let request = [];
      _.each(rows, item => {
        request.push({
          Model: item.BomModel, // 型号，
          Brand: item.BomBrand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.AsQty, // 数量，
          Remark: item.Remark, // 需求说明
          SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
          SourceGuid: item.ASGUID, // 需求明细GUID
          SourceId: item.ASID // 需求明细ID，
        });
      });
      this.$nextTick(function() {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },
    async onSummit() {
      let rows = this.selectedRows;
      if (rows === null || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选要提交报价的数据行!",
          type: "warning"
        });
      } else {
        if (!this.validationData(rows)) {
          return;
        }
        _.each(rows, item => {
          item.IsSubmit = true;
          item.BomQuoteType = this.bomQuoteType;
        });
        this.loadingText = "正在提交报价,请稍等...";
        this.loading = true;
        return this.$post(CONFIG.saveURL, rows, (datas, logdata) => {
          if (logdata.code === 200) {
            this.$message({ message: "报价成功", type: "success" });
            // 清空所有修改过的值清空所有修改过的值
            this.onRefresh();
          } else {
            this.$message({ message: logdata.msg, type: "error" });
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    validationData(val) {
      let ret = true;
      if (val === undefined || val.length <= 0) {
        this.$message({
          message: "请勾选或选中要提交报价的数据行",
          type: "warning"
        });
        ret = false;
      }
      _.each(val, item => {
        if (!this.hasValue(item.Model)) {
          this.$message({ message: "请输入提供型号!", type: "warning" });
          ret = false;
        }
        if (!this.hasValue(item.Brand)) {
          this.$message({ message: "请输入提供品牌!", type: "warning" });
          ret = false;
        }
        if (!this.hasValue(item.QuoteQty)) {
          this.$message({ message: "请输入提供数量!", type: "warning" });
          ret = false;
        }
        if (
          this.bomQuoteType === "BuyQuoteSalePrice" &&
          !this.hasValue(item.QuotePriceCust)
        ) {
          this.$message({
            message: "当前模式为:【采购报价】 请输入给客户报价!",
            type: "warning"
          });
          ret = false;
        } else if (
          (this.bomQuoteType === "SalesQuote" ||
            this.bomQuoteType === "BuyQuoteCost") &&
          !this.hasValue(item.SuggestPrice)
        ) {
          this.$message({
            message: "当前模式为:【销售报价】 请输入给销售报价!",
            type: "warning"
          });
          ret = false;
        }
      });
      return ret;
    },
    onInquire: async function(row) {
      this.$nextTick(function() {
        if (!_.isUndefined(row)) {
          // this.onUpdateRow(row)
          this.onBindData(this.pageIndex);
        }
      });
    },
    onHistoryInquire: async function(row) {
      this.$nextTick(function() {
        if (!_.isUndefined(row)) {
          this.onUpdateRow(row);
        }
      });
    },
    onUpdateRow(row) {
      let rowIndex;
      _.each(this.itemSource, (item, index) => {
        if (item.ASGUID === this.currentItem.ASGUID) {
          rowIndex = index;
        }
      });
      this.itemSource[rowIndex].QuoteQty =
        this.toFloat(row[0].QuoteQty, 2) === 0
          ? null
          : this.toFloat(row[0].QuoteQty, 2);
      this.itemSource[rowIndex].SuggestPrice =
        this.toFloat(row[0].SuggestPrice, 6) === 0
          ? null
          : this.toFloat(row[0].SuggestPrice, 6);
      this.itemSource[rowIndex].IsNoStock = row[0].IsNoStock;
      this.itemSource[rowIndex].IsOutStock = row[0].IsOutStock;
    },
    // 如果下拉按钮全部隐藏，则主按钮也隐藏
    isShowMainBtn(btns) {
      let isShow = false;
      _.each(btns, btn => {
        if (btn.isShow) {
          isShow = true;
          return true;
        }
      });
      return isShow;
    },
    hasRes(code) {
      // return _.some(this.resourceList, page => page === code)
      return true;
    },

    async onSaveRows(rows) {
      // 保存当前页面的值
      this.loadingText = "数据正在保存,请稍等...";
      this.loading = true;
      return this.$post(CONFIG.saveURL, rows, (datas, logdata) => {
        if (logdata.code === 200) {
          this.$message({ message: "保存成功", type: "success" });
          this.itemSource = JSON.parse(JSON.stringify(this.itemSource));
        } else {
          this.$message({ message: logdata.msg, type: "error" });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 供应商报价
      // if (colname === 'NTQuotePrice' && aftervalue !== beforevalue) {
      //   // this.itemSource[rowindex]['BuyPrice'] = this.toFloat(aftervalue, 6)
      //   gridobj.row['NTQuotePrice'] = this.toFloat(aftervalue, 6)
      // }
      // if (colname === 'SuggestPrice' && aftervalue !== beforevalue) {
      //   // this.itemSource[rowindex]['BuyPrice'] = this.toFloat(aftervalue, 6)
      //   gridobj.row['SuggestPrice'] = this.toFloat(aftervalue, 6)
      // }
      if (
        colname === "Model" &&
        !_.isUndefined(aftervalue) &&
        !_.isNull(aftervalue)
      ) {
        this.itemSource[rowindex]["Model"] = aftervalue.toUpperCase();
      }
      // 需求数量
      if (colname === "Qty" && aftervalue !== beforevalue) {
        gridobj.row["Qty"] = this.toInt(aftervalue);
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      let datas = _.find(this.ParamJosn, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "BomWaitToInquire", ParamJson: [datas] },
          data => {
            localStorage.removeItem("bomWaitToInquire");
          }
        );
      }
    },
    // 放到我的待查清单
    ToMyWaitList(row) {
      return this.$post(CONFIG.ToMyWaitListUrl, row, (data, logic) => {
        if (logic.code === 120) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "操作成功", type: "success" });
          this.$refs.flexGrid.clearSelection();
          this.onRefresh();
        }
      });
    },
    // 转给别的查价员
    ToOtherUser(row) {
      if (this.hasValue(row.IsSubmit) && row.IsSubmit) {
        return this.$message({
          message: "已经报价的需求不能转给别的采购",
          type: "error"
        });
      } else {
        let order = { transpond: row };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function() {
          // this.bomWaitTranspondVisible = true
          this.waitNoticedBomVisible = true;
        });
      }
    },
    // 转到公海
    ToPublic(row) {
      return this.$post(CONFIG.ToPublicUrl, row, (data, logic) => {
        if (logic.code === 120) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "操作成功", type: "success" });
          this.$refs.flexGrid.clearSelection();
          this.onRefresh();
        }
      });
    },
    MarkOutStock(row) {
      return this.$post(
        CONFIG.MarkOutStockUrl,
        { ASGUID: row.ASGUID },
        (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "操作成功", type: "success" });
            this.$refs.flexGrid.clearSelection();
            this.onRefresh();
          }
        }
      );
    },
    MarkNoStock(row) {
      return this.$post(
        CONFIG.MarkNoStockUrl,
        { ASGUID: row.ASGUID },
        (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "操作成功", type: "success" });
            this.$refs.flexGrid.clearSelection();
            this.onRefresh();
          }
        }
      );
    },
    BomIsPublic(val) {
      this.onSearch();
      if (this.IsPublic === "2") {
        this.$refs.flexGrid.clearSelection();
        for (var i = 0; i < this.columns.length; i++) {
          if (
            this.columns[i].binding === "Brand" ||
            this.columns[i].binding === "Packaging" ||
            this.columns[i].binding === "MakeYear" ||
            this.columns[i].binding === "SuggestPrice" ||
            this.columns[i].binding === "Quality" ||
            this.columns[i].binding === "DeliveryDate" ||
            this.columns[i].binding === "MPQ" ||
            this.columns[i].binding === "QuoteQty" ||
            this.columns[i].binding === "CurrencyName"
          ) {
            this.columns[i].isReadOnly = true;
          }
        }

        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsNoStock";
          }),
          {
            dataMap: [
              { key: false, value: "--" },
              { key: true, value: "--" }
            ],
            showDropDown: true,
            isCheckedColumn: false,
            isReadOnly: true
          }
        );
        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsOutStock";
          }),
          {
            dataMap: [
              { key: false, value: "--" },
              { key: true, value: "--" }
            ],
            showDropDown: true,
            isCheckedColumn: false,
            isReadOnly: true
          }
        );
        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsSubmit";
          }),
          {
            dataMap: [
              { key: false, value: "--" },
              { key: true, value: "--" }
            ],
            showDropDown: true,
            isCheckedColumn: false,
            isReadOnly: true
          }
        );
        this.$set(this.btnMouseEnterMenus, "List", [
          {
            name: "放到我的待查清单",
            isShow: this.hasRes("ToMyWaitList"),
            click: row => {
              this.ToMyWaitList(row);
            }
          }
        ]);
      } else {
        this.$refs.flexGrid.clearSelection();
        for (var j = 0; j < this.columns.length; j++) {
          if (
            this.columns[j].binding === "Brand" ||
            this.columns[j].binding === "Packaging" ||
            this.columns[j].binding === "MakeYear" ||
            this.columns[j].binding === "SuggestPrice" ||
            this.columns[j].binding === "Quality" ||
            this.columns[j].binding === "DeliveryDate" ||
            this.columns[j].binding === "MPQ" ||
            this.columns[j].binding === "QuoteQty" ||
            this.columns[j].binding === "CurrencyName"
          ) {
            this.columns[j].isReadOnly = false;
          }
        }

        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsSubmit";
          }),
          {
            dataMap: [
              { key: false, value: "已提交" },
              { key: true, value: "未提交" }
            ],
            showDropDown: true,
            isCheckedColumn: true,
            isReadOnly: true
          }
        );
        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsNoStock";
          }),
          {
            dataMap: [
              { key: false, value: "否" },
              { key: true, value: "是" }
            ],
            showDropDown: true,
            isCheckedColumn: true,
            isReadOnly: true
          }
        );
        _.extend(
          _.find(this.columns, column => {
            return column.binding === "IsOutStock";
          }),
          {
            dataMap: [
              { key: false, value: "否" },
              { key: true, value: "是" }
            ],
            showDropDown: true,
            isCheckedColumn: true,
            isReadOnly: true
          }
        );
        this.$set(this.btnMouseEnterMenus, "List", [
          {
            name: "转给别的查价员",
            isShow: this.hasRes("ToOtherUser"),
            click: row => {
              this.ToOtherUser(row);
            }
          },
          {
            name: "转到公海",
            isShow: this.hasRes("ToPublic"),
            click: row => {
              this.ToPublic(row);
            }
          },
          {
            name: "标记无货",
            isShow: this.hasRes("MarkNoStock"),
            click: row => {
              this.MarkNoStock(row);
            }
          },
          {
            name: "标记缺货",
            isShow: this.hasRes("MarkOutStock"),
            click: row => {
              this.MarkOutStock(row);
            }
          }
        ]);
      }
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("bomWaitToInquire");
      await this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          CONFIG.default,
          this.parameterConfig
        )
      );
    },
    async onResetSetting() {
      localStorage.removeItem("bomWaitToInquire");
      await this.$post(this.$options.config.resetConfigURL);
    },
    onResetParams(val) {
      localStorage.removeItem("bomWaitToInquire");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("bomWaitToInquire");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },
    // 获取模块状态
    GetStatus(val) {
      this.$refs.flexGrid.toggleTableShow(val !== 0);
    },
    // 保存模块状态
    saveStatus(val) {
      let Status = val ? 1 : 0;
      let defaultConfig = this.defaultConfig;
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status;
        let statusConfig = this.defaultConfig;
        statusConfig.ColumnConfigs = [];
        this.$post(this.$options.config.saveConfigURL, statusConfig);
      }
    },
    onCheckChange() {
      this.onSearch();
    },
    onRecordInquiry: async function() {
      // 记录询价
      let rows = this.$refs.flexGrid.getSelectedRows();
      let inquireList = [];
      this.$nextTick(function() {
        if (rows.length > 0) {
          _.each(rows, row => {
            inquireList.push({
              PrimaryKey: row.ASGUID,
              SupplierName: null,
              Model: row.BomModel,
              Brand: row.BomBrand,
              Packaging: row.Packaging,
              MakeYear: row.MakeYear,
              Quality: row.Quality,
              InvQty: row.AsQty,
              MPQ: row.MPQ,
              Qty: row.AsQty,
              Price: null,
              SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
              SourceGuid: row.ASGUID, // 需求明细GUID
              SourceId: row.ASID // 需求明细ID，
            });
          });
        } else {
          inquireList.push({
            PrimaryKey: null,
            SupplierName: null,
            Model: null,
            Brand: null,
            Packaging: null,
            MakeYear: null,
            Quality: null,
            InvQty: null,
            MPQ: null,
            Qty: null,
            Price: null,
            SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
            SourceGuid: null, // 需求明细GUID
            SourceId: null // 需求明细ID，
          });
        }
        this.addInquireList(inquireList, function(msg) {
          if (msg === "不能向自己询价") {
            this.$message({ message: msg, type: "warning" });
          }
        });

        // this.addInquire(inquireList[0], function (msg) {
        //   if (msg === '不能向自己询价') {
        //     this.$message({ message: msg, type: 'warning' })
        //   }
        // })
      });
    },

    // 当CS客户端 保存询价成功后调用
    setInquired(ASGuid, supplierInquire) {
      var item = _.find(this.itemSource, item => item.ASGUID === ASGuid);
      if (item) {
        item.IsInquired = true;
        // 刷新询价记录
        this.$emit("cell-click", item);
      }
    },

    // 采纳询价后回调事件 回写待查价界面的数据
    adoptInquireSuccess(val) {
      _.each(this.itemSource, item => {
        if (item.ASGUID === val.ASGUID) {
          this.$set(item, "Model", val.Model);
          this.$set(item, "Brand", val.Brand);
          this.$set(item, "Packaging", val.Packaging);
          this.$set(item, "MakeYear", val.MakeYear);
          this.$set(item, "MPQ", val.MPQ);
          this.$set(item, "Quality", val.Quality);
          this.$set(item, "QuoteQty", val.SumQty);
          this.$set(item, "SuggestPrice", val.AgvPrice);
          this.$set(item, "DeliveryDate", val.DeliveryDate);
          this.$set(item, "QuotePriceCust", val.QuotePriceCust);
          this.$set(item, "SupplierPrice", val.AgvPrice);
        }
      });
    },
    // 参数
    paramConfiguration(val) {
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(val, function(paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }

      var quoteTypeInfo = _.find(val, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "BomQuoteType") {
          return paramjosn;
        }
      });
      if (quoteTypeInfo) {
        this.bomQuoteType = quoteTypeInfo.ParamValue || "BuyQuoteCost"; // 默认 采购报价(成本)
      } else {
        this.bomQuoteType = "BuyQuoteCost";
      }
      var purGrossRateInfo = _.find(val, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PurGrossRate") {
          return paramjosn;
        }
      });
      if (purGrossRateInfo) {
        this.purGrossRate = purGrossRateInfo.ParamValue || 0;
      }
      var dateSearchInfo = _.find(val, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "DateSearchDefault") {
          return paramjosn;
        }
      });
      if (dateSearchInfo) {
        this.searchFields.CreateTimeEnd = this.getDate(0);
        if (dateSearchInfo.ParamValue === "ToDay") {
          this.searchFields.CreateTimeStart = this.getDate(0);
        } else if (dateSearchInfo.ParamValue === "LatestWeek") {
          this.searchFields.CreateTimeStart = this.getDate(6);
        } else {
          this.searchFields.CreateTimeStart = this.getDate(2);
        }
      }
    },

    onChangeStatus(val) {
      this.checkStatus = val;
      this.onSearch();
    },
    onCellClick(row, column, cell, event) {
      if (
        column.columnKey === "BomModel" ||
        column.columnKey === "BomBrand" ||
        column.columnKey === "AsQty"
      ) {
        this.$emit("cell-click", row);
      }
    },
    onBatchSave() {
      let rows = this.$refs.flexGrid.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    selectChange(rows) {
      this.selectedRows = rows;
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.flexGrid.clearSelection();
    }
  },
  mounted() {
    // 挂载一个window 事件，接收CS客户端派遣的event。接收参数为 PrimaryKey, data(supplierInquire)
    on(window, "onCSInqureSaved", e => {
      let { PrimaryKey, data } = e;
      this.setInquired(PrimaryKey, data);
    });
  }
};
</script>

<style lang="scss">
.grid-bomWaitto-inquire-box {
  .el-radio + .el-radio {
    margin-left: 8px !important;
  }
  .el-radio__label {
    font-size: 12px !important;
    padding-left: 3px !important;
  }
  &.znl-grid-component .znl-content .isFieldsSearch .znl-gridModuleHeader {
    line-height: 20px;
  }
  .znl-component-input .el-input .el-input__inner {
    line-height: 20px;
    height: 20px;
  }
}
.model-price-style {
  padding: 0 6px;
  font-size: 13px;
  color: #888;
  font-weight: bold;
}
.model-price-style .font-color {
  color: Red;
}
.model-price-style .font-color-model {
  color: #333;
}

.urgent-row {
  color: red;
  .znl-tablecell {
    span {
      color: red;
    }
    color: red;
  }
}
</style>

