<template>
  <!-- 销售单 -->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('SalesOrder')"
      :is-fields-search="isShowFiedsSearch"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :search-fields="onSearch"
      :show-title-menus="true"
      :total-count="totalCount"
      :totalField="['Amount', 'SaleGrossProfit']"
      checkboxBindingKey="SOGuid"
      element-loading-text="数据加载中,请稍后..."
      gridtype="base"
      boxClass="module-wrapper"
      @current-row-change="onCurrentRowChangeHandle"
      @on-show-trigger="onHideShowColTrigger"
      @cell-click="cellClick"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          v-model="fieldsFilter.inputModel"
          :border="true"
          :clearable="true"
          :disable="false"
          from-type="input"
          inp-perc="100%"
          layout="left"
          placeholder="型号"
          size="mini"
          type="text"
          width="110px"
          @keyup.enter.native="onSearch()"
        ></znl-input>
        <znl-input
          v-model="fieldsFilter.trackCode"
          :border="true"
          :clearable="true"
          :disable="false"
          from-type="input"
          inp-perc="100%"
          layout="left"
          placeholder="跟踪码"
          size="mini"
          type="text"
          width="100px"
          @keyup.enter.native="onSearch()"
        ></znl-input>
        <znl-input
          v-model="fieldsFilter.pONumber"
          :border="true"
          :clearable="true"
          :disable="false"
          from-type="input"
          inp-perc="100%"
          layout="left"
          placeholder="PO号码"
          size="mini"
          type="text"
          width="100px"
          @keyup.enter.native="onSearch()"
        ></znl-input>
        <div class="m-accurate-checbox znl-component-input">
          <znl-input
            v-model="fieldsFilter.CustomerName"
            :border="true"
            :clearable="false"
            :disable="false"
            class="box_input_checkbox"
            from-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="客户名称"
            size="mini"
            type="text"
            width="160px"
            @keyup.enter.native="onSearch()"
          ></znl-input>
          <el-checkbox
            v-model="fieldsFilter.accurate"
            class="accurate-checbox"
            size="small"
            >精确</el-checkbox
          >
        </div>
        <znl-input
          v-model="fieldsFilter.startSODate"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="datepicker"
          inp-perc="100%"
          layout="left"
          placeholder="合同日期从"
          size="mini"
          value-format="yyyy-MM-dd"
          width="100px"
        ></znl-input>
        <span>到</span>
        <znl-input
          v-model="fieldsFilter.endSODate"
          :border="true"
          :clearable="true"
          :disabled="false"
          form-type="datepicker"
          inp-perc="100%"
          layout="left"
          placeholder="合同日期止"
          size="mini"
          value-format="yyyy-MM-dd"
          width="100px"
        ></znl-input>

        <znl-button
          class="box-left-btn search-btn"
          style-type="mac"
          type="multiple"
        >
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="onShowFiedsSearch()"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button
          v-if="isAllowAdd"
          style-type="main"
          @click="onSalesOrderAdd"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加</span>
        </znl-button>

        <znl-button
          :btns="printBtns"
          :height="22"
          :isShow="hasRes('Print')"
          style-type="mac"
          @click="onPrint"
        >
          <i class="iconfont icon-print_btn_ic"></i>
          <span>打印</span>
        </znl-button>

        <znl-button
          :btns="exportBtns"
          :height="22"
          :isShow="hasRes('Export')"
          style-type="mac"
          tip="按搜索条件"
          @click="onExport"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>

        <znl-print
          :is-get-default-param="printInfo.isGetDefaultParam"
          :loading="printLoading"
          :printInfo="printInfo"
          :visible.sync="znlPrintVisible"
          print-type="ORD_SalesOrder"
          title="销售单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </div>
    </znl-table>

    <!--日志dialog-->
    <operate-log
      v-if="operateLogVisible"
      ref="OperateLog"
      :orderGuid="orderGuid"
      :visible="operateLogVisible"
      @close="
        () => {
          operateLogVisible = false;
        }
      "
    ></operate-log>

    <!--发货单编辑-->
    <stk-out-edit
      v-if="stkOutEditVisible"
      :editvisible="stkOutEditVisible"
      edit-type="editType"
      @close="stkOutEditVisible = false"
      @confirm="stkOutEditVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></stk-out-edit>

    <!--销售单编辑-->
    <sales-order-edit
      v-if="salesEditVisible"
      :edit-type="editType"
      :editvisible="salesEditVisible"
      @close="salesEditVisible = false"
      @confirm="
        () => {
          salesEditVisible = false;
        }
      "
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></sales-order-edit>
    <!--销售备货-->
    <sales-order-reserve-add
      v-if="SalesOrderReserveAddVisible"
      :SOGuid="reserveSOGuid"
      :editvisible="SalesOrderReserveAddVisible"
      edit-type="Add"
      @close="
        () => {
          (SalesOrderReserveAddVisible = false), this.onRefresh();
        }
      "
      @confirm="SalesOrderReserveAddVisible = false"
    ></sales-order-reserve-add>
    <!--采购通知列表-->
    <notice-list
      v-if="ordPurchaseNoticeVisible"
      :soGUIDList="SOGuidListStr"
      :visible="ordPurchaseNoticeVisible"
      @close="
        () => {
          ordPurchaseNoticeVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (ordPurchaseNoticeVisible = false), this.onRefresh();
        }
      "
    ></notice-list>
    <!--销售退货-->
    <sales-order-return-edit
      v-if="SalesOrderRetrunVisible"
      :editvisible="SalesOrderRetrunVisible"
      :title="SalesOrderReturnTitle"
      edit-type="Add"
      @close="SalesOrderRetrunVisible = false"
      @confirm="SalesOrderRetrunVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></sales-order-return-edit>

    <!-- 导出 -->
    <export-column
      v-if="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      :visible="setExportColumnVisible"
      title="设置导出列"
      @close="
        () => {
          setExportColumnVisible = false;
        }
      "
      @confirm="
        (v) => {
          setExportColumnVisible = false;
        }
      "
    ></export-column>

    <!-- 单据附件 -->
    <OrderAttachment
      v-if="OrderAttachmentVisible"
      ref="OrderAttachment"
      :Title="'销售单附件'"
      :OrderType="1"
      :OrderId="OrderId"
      :Visible="OrderAttachmentVisible"
      @close="orderAttachmentClose"
    ></OrderAttachment>
  </div>
</template>

<style>
.redLink {
  cursor: pointer;
  color: red;
  text-decoration: underline;
}

.blueLink {
  cursor: pointer;
  color: #0092dd;
  text-decoration: underline;
}
</style>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import SalesOrderEdit from "@c_modules/Sale/SalesOrderEdit";
import OperateLog from "@c_modules/Log/OperateLog";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import NoticeList from "@c_modules/Purchase/NoticeList";
import SalesOrderReserveAdd from "@c_modules/Sale/SalesOrderReserveAdd";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import SalesOrderReturnEdit from "@c_modules/Sale/SalesOrderReturnEdit";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import { exportData, erpTableSetHandler } from "@scripts/methods/common";
import * as urls from "~/lib/urls";
import { isClient } from "~/lib/runtime";
import OrderAttachment from "~/components/common/orderAttachment/OrderAttachment";

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "MTGuid",
  configURL: "SalesOrder/GetConfig",
  saveConfigURL: "SalesOrder/SaveConfig",
  resetParamConfigURL: "SalesOrder/ResetParamConfig",
  searchURL: "SalesOrder/Search",
  deleteURL: "SalesOrder/DeleteOrder",
  onExamineURL: "SalesOrder/Examine",
  onAntiTrialURL: "SalesOrder/AntiTrial",
  onAbortOrderURL: "SalesOrder/AbortOrder",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  SalesGotoStkOutGUID: "SalesOrder/GotoStkOutGUID",
  WebPrintURL: "WebPrint/SalesOrderPrint",
  DownloadPrintFile: "WebPrint/DownloadPrintFile",
  SyncShopSalesOrderURL: "SalesOrder/SyncShopSalesOrder",
};
export default {
  name: "SalesOrder",
  mixins: [getCommonDataMixin, erpTableSetHandler, exportData, printInfoMixin],
  config: CONFIG,
  components: {
    SalesOrderEdit,
    StkOutEdit,
    OperateLog,
    NoticeList,
    SalesOrderReserveAdd,
    ZnlPrint,
    SalesOrderReturnEdit,
    ExportColumn,
    OrderAttachment,
  },
  data() {
    return {
      SOGuid: "",
      SOModel: Object,
      isShowFiedsSearch: false,
      isAllowAdd: false, // 允许新增销售单
      ordPurchaseNoticeVisible: false,
      SalesOrderAddVisible: false, // 是否显示入库编辑页面
      stkOutEditVisible: false,
      SalesOrderRetrunVisible: false,
      SalesOrderReserveAddVisible: false,
      IsContinuousRecord: false,
      columns: [],
      itemSource: [],
      fieldsFilter: {
        inputModel: "",
        CustomerName: "",
        accurate: false,
        startSODate: "",
        endSODate: "",
        pONumber: "",
        trackCode: "",
      },
      setExportColumnVisible: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      operateLogVisible: false,
      orderGuid: "",
      parameterConfig: [],
      //CurrencyNames: [],
      ParamJosn: [],
      TaxRate: [],
      IsAutoPrint: false, // 是否自动打印模板
      salesEditVisible: false,
      editType: "Add",
      SOGuidListStr: String,
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true },
      ],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "ORD_SalesOrder", // 打印模板类型
      }, // end 打印模块参数
      isShowPrint: false,
      reserveSOGuid: "",
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      currencyList: [],
      userList: [],
      taxRateList: [],

      defaultConfig: {},
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: "",
      exportBtns: [
        {
          name: "设置导出列",
          click: () => {
            this.showExportColumn();
          },
        },
      ],
      printBtns: [
        {
          id: 1,
          name: "打印模板设置",
          click: () => {
            this.znlPrintVisible = true;
          },
        },
        {
          id: 2,
          name: "默认打印机设置",
          click: () => {
            this.showPrintSettings("SalesOrder");
          },
        },
      ],
      OrderAttachmentVisible: false, //单据附件
      OrderId: "", //主单Id
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {},
        };
      },
    },
  },
  async created() {
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );

    this.onPrintBtn();
  },
  mounted() {
    this.$nextTick(async () => {
      await this.onInit();
    });
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage(
          "salesOrder",
          this.$options.config.configURL
        );
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 70,
          options: this.currencyList,
        });
      }
      this.userList = _.map(await this.selectOptionsAllUserURL, function (obj) {
        return { key: obj.value, value: obj.value };
      });

      colIndex = _.findIndex(columns, (item) => item.BindField === "SalerName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 70,
          options: this.userList,
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 70,
          options: this.userList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "TaxRate");
      this.taxRateList = await this.getTaxRateListAsync;
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 50,
          options: this.taxRateList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: this.auditedOptions,
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            if (rowData.IsAudited !== undefined) {
              let status =
                _.find(this.options, function (item) {
                  return item.value === rowData.IsAudited;
                }) || {};
              if (rowData.IsAudited === true) {
                //已审
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }
      // colIndex = _.findIndex(columns, item => item.BindField === 'PONumber')
      // if (colIndex > -1) {
      //   _.extend(columns[colIndex], {
      //     event: 'cell-click',
      //     className: 'v-link'
      //   })
      // }

      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "ReserveStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            { key: "未备", value: 1 },
            { key: "部分备", value: 2 },
            { key: "已备", value: 3 },
          ],
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            var status = _.find(this.options, (item) => {
              return item.value === rowData.ReserveStatus;
            });

            if (status) {
              if (rowData.ReserveStatus === 3) {
                //已备
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "StkOutStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            { key: "未发", value: 1 },
            { key: "部分发", value: 2 },
            { key: "已发", value: 3 },
          ],
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            let status = _.find(this.options, function (item) {
              return item.value === rowData.StkOutStatus;
            });
            if (status) {
              if (rowData.StkOutStatus !== 3) {
                //非已发
                return '<div class="redLink">' + status.key + "</div>";
              } else {
                return '<div class="blueLink" >' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "ReturnStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            { key: "未退", value: 1 },
            { key: "部分退", value: 2 },
            { key: "已退", value: 3 },
          ],
          event: "cell-click",
          formatter(rowData, rowIndex, field) {
            let status = _.find(this.options, function (item) {
              return item.value === rowData.ReturnStatus;
            });
            if (status) {
              if (rowData.ReturnStatus === 1) {
                //未退
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          },
        });
      }

      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "ShopOrderSyncStatus"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          width: 50,
          type: "select",
          options: [
            // { key: "未同步", value: null },
            { key: "已同步", value: true },
            { key: "同步失败", value: false },
          ],
          formatter(rowData, rowIndex, field) {
            let status = _.find(this.options, function (item) {
              return item.value === rowData.ShopOrderSyncStatus;
            });
            if (status) {
              //已同步
              if (rowData.ShopOrderSyncStatus == true) {
                return "<div >" + status.key + "</div>";
              }
              //同步失败
              else if (rowData.ShopOrderSyncStatus == false) {
                return "<div style='color:red'>" + status.key + "</div>";
              }
              //未同步
              else {
                return "<div></div>";
              }
            }
            return "<div></div>";
          },
        });
      }

      // 附件列 start
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "OrderAttachment"
      );
      if (colIndex > -1) {
        let _this = this;
        _.extend(columns[colIndex], {
          type: "icon",
          width: 34,
          isFrozen: false,
          canSearch: false,
          classNames: [
            "show-hand icon iconfont icon-pic_btn_ic color-ccc",
            "show-hand icon iconfont icon-pic_btn_ic color-green",
          ],
          status(row) {
            return 1;
          },
          click(row) {
            _this.OrderId = row.SOGuid; //单据Guid
            _this.OrderAttachmentVisible = true; //显示单据附件
          },
        });
      }
      // 附件列 end

      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration();
      let btnMouseEnterMenus = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        btnMouseEnterMenus.push({
          title: "编辑",
          name: "Edit",
          click: (row) => {
            this.onSalesOrderEdit([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        btnMouseEnterMenus.push({
          title: "删除",
          name: "Delete",
          click: (row) => {
            this.onSalesOrderDel(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        btnMouseEnterMenus.push({
          title: "审核",
          name: "Audit",
          click: (row) => {
            this.onPostExamine([row.SOGuid]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        btnMouseEnterMenus.push({
          title: "反审",
          name: "UnAudit",
          click: (row) => {
            this.onPostAntiTrial([row.SOGuid]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Reserve")) {
        btnMouseEnterMenus.push({
          title: "备货",
          name: "Reserve",
          click: (row) => {
            this.onReserve(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Out")) {
        btnMouseEnterMenus.push({
          title: "发货",
          name: "Out",
          click: (row) => {
            this.onStorage([row], false);
          },
        });

        btnMouseEnterMenus.push({
          title: "同步",
          name: "SyncShopSalesOrder",
          click: (row) => {
            if (row.OrderSource !== "商城") {
              this.$message({ message: "非商城订单不可同步!", type: "error" });
              return;
            }
            if (row.StkOutStatus !== 2 && row.StkOutStatus !== 3) {
              this.$message({ message: "单据未发货不可同步!", type: "error" });
              return;
            }
            this.syncShopSalesOrder(row.SOGuid);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("PurchaseNotic")) {
        btnMouseEnterMenus.push({
          title: "采购",
          name: "PurchaseNotic",
          click: (row) => {
            this.onPurchaseNoticeEdit([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Return")) {
        btnMouseEnterMenus.push({
          title: "退货",
          name: "Return",
          click: (row) => {
            this.onStorage([row], true);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        btnMouseEnterMenus.push({
          title: "日志",
          name: "OperateLog",
          click: (row) => {
            this.orderGuid = row.SOGuid;
            this.operateLogVisible = true;
          },
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: btnMouseEnterMenus,
          isFrozen: true,
          canSearch: false,
        },
      ];
      //处理特殊权限
      let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
      if (noSeeProfit) {
        _.remove(columns, (item) => {
          if (item.BindField === "SaleGrossProfit") return true;
        });
      }
      // 设置打印
      this.printInfo.template = this.getPrintTemplate(config.ParamJosn);
      this.printInfo.title = this.getPrintTitle(config.ParamJosn);
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.columns = tempConfig.concat(columns);
      if (
        this.$route.params !== undefined &&
        this.$route.params.poNumber !== undefined
      ) {
        this.fieldsFilter.pONumber = this.$route.params.poNumber;
      }
      this.defaultConfig = config;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;

      // console.log(this.columns);
      this.$emit("page-loading", false);
    },
    //关闭单据附件
    orderAttachmentClose() {
      this.OrderAttachmentVisible = false;
    },
    setPoNumber(poNumber) {
      this.fieldsFilter.inputModel = "";
      this.fieldsFilter.pONumber = poNumber;
      this.fieldsFilter.trackCode = "";
      this.fieldsFilter.startSODate = "";
      this.fieldsFilter.endSODate = "";
      this.onBindData();
    },
    cellClick(cellData) {
      // console.log("cellClick data is ", cellData);
      if (cellData.field === "StkOutStatus") {
        if (cellData.row["StkOutStatus"] === 1) {
          //未发
          if (this.isMainAccount || this.hasRes("Out")) {
            this.onStorage([cellData.row], false);
          }
        } else {
          this.$emit("tab-change", {
            tabName: "StkOut",
            billNo: cellData.row["BillNo"],
          });
        } //部分发或已发
      } else if (cellData.field === "ReturnStatus") {
        if (cellData.row["ReturnStatus"] !== 1) {
          //有退货
          this.$emit("tab-change", {
            tabName: "SalesReturn",
            billNo: cellData.row["BillNo"],
          });
        }
      } else if (cellData.field === "ReserveStatus") {
        if (cellData.row["ReserveStatus"] !== 3) {
          //已备
          if (this.isMainAccount || this.hasRes("Reserve")) {
            this.onReserve(cellData.row);
          }
        }
      } else if (cellData.field === "IsAudited") {
        if (!cellData.row["IsAudited"]) {
          //未审
          if (this.isMainAccount || this.hasRes("Audit")) {
            this.onPostExamine([cellData.row.SOGuid]);
          }
        }
      } else if (
        (cellData.field = "PONumber" && cellData.row.PONumber !== "")
      ) {
        this.switchTab("Purchase");
        this.$router.push({
          path: "OrderMange",
          name: "Purchase/OrderMange",
          params: {
            poNumber: cellData.row.PONumber,
          },
        });
      }
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "SalesOrder", ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("salesOrder");
          }
        );
      }
    },
    onResetSetting() {
      localStorage.removeItem("salesOrder");
      return this.$post(CONFIG.resetConfigURL);
    },
    onResetParams(val) {
      localStorage.removeItem("salesOrder");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    // 获取参数配置
    paramConfiguration() {
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsQueryBarStatus && paramIsQueryBarStatus.ParamValue === "1") {
        this.isShowFiedsSearch = true;
      }

      // 单据打印后自动保存 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "1") {
        this.IsAutoPrint = true;
      }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinuousRecord = true;
      }

      // 默认币种
      let paramCurrencyCode = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "CurrencyCode") {
          return paramjosn.ParamValue;
        }
      });
      if (paramCurrencyCode) {
        this.CurrencyCode = paramCurrencyCode.ParamValue;
      }

      // 默认税率
      let paramTaxRate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "TaxRate") {
          return paramjosn.ParamValue;
        }
      });
      if (paramTaxRate) {
        this.TaxRate = paramTaxRate.ParamValue;
      }

      // 允许新增销售单
      let paramAllowAddSaleOrder = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "AllowAddSaleOrder") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramAllowAddSaleOrder) &&
        this.hasValue(paramAllowAddSaleOrder.ParamValue)
      ) {
        this.isAllowAdd = paramAllowAddSaleOrder.ParamValue === "1";
      }
    },
    onPrint() {
      //网页版打印
      if (!isClient()) {
        this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.SOGuid)) {
        return this.$message({
          message: "请选中要打印的销售单",
          type: "error",
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）

      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "warning" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: row.SOGuid,
          SubCompanyId: titleId,
        };
        // console.log(printParm, "start to print sales order");
        this.printSalesOrder(printParm, () => {});
      }
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      // this.itemSource = [];
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          inputModel: this.fieldsFilter.inputModel,
          pONumber: this.fieldsFilter.pONumber,
          trackCode: this.fieldsFilter.trackCode,
          startSODate: this.fieldsFilter.startSODate,
          endSODate: this.fieldsFilter.endSODate,
          AllowViewFollowBill:
            this.getCompanySetupValue("AllowViewFollowBill") === "1",
        }
      );
      return await this.$post(CONFIG.searchURL, data, (data) => {
        data.ResultList.forEach((item) => {
          if (item.SODate) item.SODate = item.SODate.split(" ")[0]; //合同日期去掉时分秒部分
        });
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if (this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 审核请求
    onPostExamine(guid) {
      if (this.hasValue(guid) && guid.length > 0) {
        this.$post(
          CONFIG.onExamineURL,
          { ListSalesGuid: guid },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "审核成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    // 反审核请求
    onPostAntiTrial(guid) {
      if (this.hasValue(guid) && guid.length > 0) {
        this.$post(
          CONFIG.onAntiTrialURL,
          { ListSalesGuid: guid },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "反审成功", type: "success" });
              this.onBindData(this.pageIndex);
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        );
      }
    },
    onSalesOrderAdd() {
      this.$store.state.visibled = false;
      this.$nextTick(function () {
        this.editType = "addNew";
        this.salesEditVisible = true;
      });
    },
    onSalesOrderEdit(rows) {
      if (_.isUndefined(rows[0])) {
        return this.$message({ message: "请选择一条数据", type: "error" });
      } else {
        this.SOGuid = rows[0].SOGuid;
        let paramOrder = { SOGuid: rows[0].SOGuid };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(function () {
          this.editType = "edit";
          this.salesEditVisible = true;
        });
      }
    },
    // 采购通知
    onPurchaseNoticeEdit(rows) {
      if (rows[0].IsAudited === "1") {
        return this.$message({
          message: "单号:【" + rows[0].BillNo + "】的销售单先审核后再通知采购!",
          type: "warning",
        });
      }
      let guid = [];
      if (_.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选要通知采购的销售单明细",
          type: "warning",
        });
      } else {
        guid = _.map(rows, (item) => {
          return item.SOGuid;
        });
      }
      this.SOGuidListStr = JSON.stringify(guid);
      this.ordPurchaseNoticeVisible = true;
    },
    onSalesOrderDel(row) {
      this.$confirm("确认删除 单号:" + row.BillNo + " 的销售单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var OrderGuidList = [row.SOGuid];
        return this.$post(
          CONFIG.deleteURL,
          { DelSOGuids: OrderGuidList },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }
        );
      });
    },

    onSearch(params = null) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    onRefresh() {
      setTimeout(() => {
        this.onBindData(this.pageIndex);
        this.onCurrentRowChangeHandle();
      }, 500);
    },
    hasRes(code) {
      if (!this.resourceList) {
        return;
      }
      return _.includes(this.resourceList, code);
    },
    onStorage(rows, IsReturn) {
      if (_.isUndefined(rows[0])) {
        return this.$message({
          message: "请选择要发货的销售单",
          type: "warning",
        });
      }
      // 出库
      if (!IsReturn) {
        if (rows[0].IsAudited === false) {
          return this.$message({
            message: "单号:【" + rows[0].BillNo + "】先审核再发货",
            type: "warning",
          });
        }
        if (rows[0].ReserveStatus === 1) {
          return this.$message({
            message: "单号:【" + rows[0].BillNo + "】未备货，不能发货",
            type: "warning",
          });
        }
        if (rows[0].StkOutStatus === 3) {
          return this.$message({
            message: "单号:【" + rows[0].BillNo + "】已发货，不能再发货",
            type: "warning",
          });
        }
        if (rows[0].ReturnStatus === 3) {
          return this.$message({
            message: "单号:【" + rows[0].BillNo + "】已退货，不能再退货",
            type: "warning",
          });
        }
      } else {
        // 退货
        if (
          rows[0].StkOutStatus !== 3 &&
          rows[0].StkOutStatus !== 2 &&
          rows[0].ReturnStatus !== 2
        ) {
          return this.$message({
            message:
              "单号:【" +
              rows[0].BillNo +
              "】的销售单没有出库或已退货，不能退货",
            type: "warning",
          });
        }
      }
      this.editType = "edit";
      let msg = "";
      if (IsReturn) {
        msg = "存在对应未入库的退货单,是否立即查看?";
      } else {
        msg = "存在对应未出库的发货单,是否立即查看?";
      }
      this.$post(
        CONFIG.SalesGotoStkOutGUID,
        { SOGuid: rows[0].SOGuid, IsReturn: IsReturn },
        (data) => {
          if (
            data != null &&
            data.StkOutGUID !== null &&
            data.StkOutGUID !== undefined
          ) {
            this.$confirm("销售单【" + rows[0].BillNo + "】" + msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              let datas = {};
              IsReturn
                ? (datas = {
                    stkOutReturnInfo: {
                      StkOutGUID: data.StkOutGUID,
                      IsReturn: false,
                    },
                  })
                : (datas = { stkOutGUID: data.StkOutGUID });

              this.$store.commit("setParamOrder", datas);
              IsReturn
                ? (this.SalesOrderRetrunVisible = true)
                : (this.stkOutEditVisible = true);
            });
          } else {
            data = {
              SalesOrderInfo: { SOGuid: rows[0].SOGuid, IsReturn: IsReturn },
            };
            this.$store.commit("setParamOrder", data);
            IsReturn
              ? (this.SalesOrderRetrunVisible = true)
              : (this.stkOutEditVisible = true);
          }
        }
      );
    },
    onReserve(row) {
      if (_.isUndefined(row)) {
        return this.$message({
          message: "请选择要备货的销售单",
          type: "warning",
        });
      }
      this.$nextTick(function () {
        this.SalesOrderReserveAddVisible = true;
        this.reserveSOGuid = row.SOGuid;
      });
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },

    async onExport() {
      this.loading = true;
      let param = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: 1,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          inputModel: this.fieldsFilter.inputModel,
          pONumber: this.fieldsFilter.pONumber,
          trackCode: this.fieldsFilter.trackCode,
          startSODate: this.fieldsFilter.startSODate,
          endSODate: this.fieldsFilter.endSODate,
          AllowViewFollowBill:
            this.getCompanySetupValue("AllowViewFollowBill") === "1",
        }
      );
      this.loading = await this.exportData(
        "SalesOrder",
        param,
        this.columns,
        "销售单"
      );
    },

    showExportColumn() {
      this.addColumnsList = this.columns;
      this.setConfigColumns = this.defaultConfig;
      // console.log(this.addColumnsList, this.setConfigColumns);
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.setExportColumnVisible = true;
    },

    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.SOGuid)) {
        this.$emit("selection-changed", row);
      }
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("SalesOrder", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("SalesOrder", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("SalesOrder", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("SalesOrder");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.SOGuid)) {
        return this.$message({
          message: "请选中要打印的销售单",
          type: "error",
        });
      }

      let tableName = this.printInfo.template; // 模板ID
      let titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return;
      }

      if (!this.hasValue(titleId) || titleId <= 0) {
        this.$message({ message: "请选择抬头", type: "error" });
        this.znlPrintVisible = true;
        return;
      }

      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.SOGuid,
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if (data) {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl); // 下载文件
          }
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      });
    },

    // 打印设置
    onPrintBtn() {
      if (!isClient()) {
        this.printBtns = this.printBtns.filter((item) => item.id === 1);
      }
    },
    //同步商城订单状态
    syncShopSalesOrder(soGuid) {
      if (!this.hasValue(soGuid) || soGuid.length == 0) return;

      this.$post(
        CONFIG.SyncShopSalesOrderURL,
        { SOGuid: soGuid },
        (data, logic) => {
          // console.log("data is ", data);
          // console.log("logic is ", logic);
          if (logic.code === 200 && data === true) {
            this.$message({ message: "同步成功！", type: "success" });
            this.onBindData(this.pageIndex);
          } else {
            this.$message({ message: "同步失败！", type: "error" });
          }
        }
      );
    },
  },
};
</script>
<style lang="scss">
</style>

