let shppingColumns = [
  {
    IsExprot: false,
    IsRequired: false,
    IsUpperCase: false,
    allowSorting: true,
    binding: 'Model',
    dataType: 1,
    editable: true,
    header: '型号',
    isFieldSearch: true,
    isReadOnly: true,
    isSystem: false,
    isTag: false,
    name: 'Model',
    sort: 1,
    visible: true,
    align: 'center',
    width: 100
  },
  {
    IsExprot: false,
    IsRequired: false,
    IsUpperCase: false,
    allowSorting: true,
    binding: 'Brand',
    dataType: 1,
    editable: true,
    header: '品牌',
    isFieldSearch: true,
    isReadOnly: true,
    isSystem: false,
    isTag: false,
    name: 'Brand',
    sort: 1,
    visible: true,
    width: 80
  },
  {
    IsExprot: false,
    IsRequired: false,
    IsUpperCase: false,
    allowSorting: true,
    binding: 'InvQty',
    dataType: 1,
    editable: true,
    header: '数量',
    isFieldSearch: true,
    isReadOnly: true,
    isSystem: false,
    isTag: false,
    name: 'InvQty',
    sort: 1,
    visible: true,
    width: 48
  },
  {
    IsExprot: false,
    IsRequired: false,
    IsUpperCase: false,
    allowSorting: true,
    binding: 'Price',
    dataType: 1,
    editable: true,
    header: '单价',
    isFieldSearch: true,
    isReadOnly: true,
    isSystem: false,
    isTag: false,
    name: 'Price',
    sort: 1,
    visible: true,
    width: 48
  }
]

export {
  shppingColumns
}
