<template>
  <div>
    <template>
      <znl-gridmodule
        ref="flexGrid"
        header="待入库清单"
        :columns="columns"
        :grid-show="gridShow"
        :is-btn="false"
        height="100%"
        :action-btns="actionBtns"
        :has-znl-actions="true"
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-multi-rows-check="true"
        :is-fields-search="isShowFiedsSearch"
        :search-fields="searchModel"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :users="$store.state.users"
        :on-init="onInit"
        :is-init="false"
        :on-refresh="onRefresh"
        :on-save-setting="onSaveSetting"
        :on-save-config="onSaveConfig"
        :on-reset-setting="onResetSetting"
        :show-add-row-right-menu="false"
        :show-delete-row-right-menu="false"
        :on-search="onSearch"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
      >
        <div slot="action-form">
          <znl-input
            form-type="input"
            :disabled="false"
            placeholder="请输入型号"
            size="mini"
            layout="left"
            width="120px"
            inp-perc="100%"
            v-model="inputModel"
            :clearable="true"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text"
          ></znl-input>
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            width="120px"
            inp-perc="100%"
            placeholder="--请选择超期天数--"
            popper-class="popperClass"
            :clearable="true"
            :select-options="PrintTemplates"
            @change="onChangeOverdueDay"
            v-model="OverdueDay"
          ></znl-input>
          <znl-button type="multiple" :height="22" style-type="main">
            <znl-button-menu @click="$refs.flexGrid.search()">
              <span>
                <i class="iconfont icon-search_ic"></i>搜索
              </span>
            </znl-button-menu>
            <znl-button-menu :width="7" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
        </div>
      </znl-gridmodule>
      <stk-in-edit
        :title="StkInAddTitle"
        :editvisible="stkInEditVisible"
        v-if="stkInEditVisible"
        :edit-type="editType"
        @confirm="stkInEditVisible=false"
        @close="stkInEditVisible=false"
      ></stk-in-edit>
    </template>
  </div>
</template>
<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
const CONFIG = {
  configURL: 'WaitStkInList/GetConfig',
  saveConfigURL: 'WaitStkInList/SaveConfig',
  resetConfigURL: 'WaitStkInList/ResetConfig',
  default: null,
  searchURL: 'WaitStkInList/Search',
  DropDownURL: 'DictItem/GetItemValue',
  GetStkInByPOLineGuids: 'StkInAdd/GetStkInByPOLineGuids'
}
export default {
  name: 'WaitStkInList',
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {
    StkInEdit
  },
  data () {
    return {
      isShowFiedsSearch: false,
      editType: 'edit',
      StkInAddTitle: '编辑入库单',
      stkInEditVisible: false,
      actionBtns: [
      ],
      inputModel: '', // 需要查询的型号
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false,
      resourceList: [],
      OverdueDay: 0, // 超期天数
      PrintTemplates: [{
        value: '三天以上',
        key: 3
      },
      {
        value: '一周以上',
        key: 7
      }, {
        value: '一个月以上',
        key: 30
      }]
    }
  },
  computed: {
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
          return {
            data: {},
            extra: {}
          }
        }
    },
    height: String,
    isRefresh: Boolean // 刷新页面
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        // config = await this.$post(CONFIG.configURL)
        config = await this.isSaveStorage('waitStkInList', CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.resourceList = config.ResourceList
      this.actionBtns = [
        {
          name: '入库',
          isShow: this.hasRes('In'),
          iconName: 'iconfont icon-buy-wh_btn_ic',
          click: () => { this.onStkIn() }
        }]
      if (_.find(columns, function (column) { return column.binding === 'IsCompletedLable' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsCompletedLable' }),
          {
            dataMap: [{ 'key': '0', 'value': '否' },
            { 'key': '1', 'value': '是' }],
            showDropDown: true
          })
      }
      if (_.find(columns, function (column) { return column.binding === 'IsAuditedName' })) {
        _.extend(_.find(columns, function (column) { return column.binding === 'IsAuditedName' }),
          {
            dataMap: [{ 'key': '未审核', 'value': '未审核' },
            { 'key': '已审核', 'value': '已审核' }],
            showDropDown: true
          })
      }
      // 采购员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'BuyerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      // 添加当前库存量列
      let qtyIndex = _.findIndex(columns, item => item.name === 'InvQty')
      columns.splice(qtyIndex, 0, {
        'name': 'InvQty',
        'dataType': 5,
        'binding': 'InvQty',
        'width': 80,
        'header': '当前库存量',
        'visible': false,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'buttons': [
          {
            'content': (d) => '',
            'className': 'wj-link',
            'click': (row) => {
            }
          }
        ]
      })
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        POLineModel: this.inputModel,
        OverdueDay: this.OverdueDay
      })
      return this.$post(CONFIG.searchURL, data, (data) => {
        for (let i = 0; i < data.ResultList.length; i++) {
          data.ResultList[i].PODate = this.dateFormat(data.ResultList[i].PODate)
        }
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    cancelAllChecked () {
      this.$refs.flexGrid.cancelAllChecked()
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('waitStkInList')
      return this.$post(CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    onResetSetting () {
      localStorage.removeItem('waitStkInList')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('waitStkInList')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    doStkIn (rows) {
      var lastCustomerName = rows[0].SupplierName
      var isDiff = false
      _.each(rows, r => {
        if (lastCustomerName !== r.SupplierName) {
          isDiff = true
        }
      })
      if (isDiff) {
        return this.$message({ message: '请选择相同客户的数据进行操作', type: 'error' })
      }

      var guids = _.map(rows, item => { return item.POLineGuid })
      return this.$post(CONFIG.GetStkInByPOLineGuids, { POLineGuids: guids }, (data) => {
        // console.log(data, 'data')
        if (_.isNumber(data)) {
          switch (data) {
            case 1:
              return this.$message({ message: '请选择相同客户的数据进行操作!', type: 'error' })
            case 2:
              return this.$message({ message: '选中的单据中, 存在未审核的单据, 请审核后,再入库!', type: 'error' })
            case 3:
              return this.$message({ message: '选中的单据中, 存在撤消入库的单据, 不能入库!', type: 'error' })
            case 4:
              return this.$message({ message: '选中的单据中, 存在已全部入库的单据, 不能再次入库!', type: 'error' })
            default:
              return this.$message({ message: '未知原因：' + data, type: 'error' })
          }
        } else {
          let order = { POModels: data }
          this.$store.commit('setParamOrder', order)
          this.$store.state.visibled = false
          this.$nextTick(function () {
            this.stkInEditVisible = true
            // this.openTab('Stk/StkInAdd', {}) // 显示编辑页面
          })
        }
      })
    },
    onStkIn () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 0 || rows[0] === undefined) {
        return this.$message({ message: '请选择一条数据进行操作', type: 'warning' })
      }
      this.doStkIn(rows)
    },
    dateFormat (value) {
      if (value === '') {
        return value
      }
      try {
        let date = new Date(Date.parse(value))
        if (date) {
          let month = (date.getMonth() + 1).toString()
          let day = date.getDate().toString()
          if (month.length === 1) { month = '0' + month }
          if (day.length === 1) { day = '0' + day }
          return date.getFullYear().toString() + '-' + month + '-' + day
        }
      } catch (error) {
        return value
      }
    },
    onChangeOverdueDay (val) {
      this.OverdueDay = val.key
      this.$refs.flexGrid.search()
    }
  }
}
</script>

<style lang="scss">
</style>
