<template>
<!-- 云价格 -->
  <znl-table
    ref="table"
    gridtype="base"
    :columns="columns"
    :item-source="itemSource"
    :on-page-changed="onBindData"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    v-loading="loading"
    element-loading-text="数据加载中,请稍后..."
    :show-title-menus="true"
    :header-menus="getResetTableHeaderMenu('bomcloundPrice')"
    @on-show-trigger="onHideShowColTrigger"
    :drag-done="onDragDone"
    @drag-col-position="(val)=>{onSaveConfig(val,true)}"
    @on-config-save="(val)=>{onSaveConfig(val,false)}"
    @on-reset-click="onResetTableConfigClick"
  >
    <div slot="header" class="v-table-toolbar" style="display: inline-block;">
      <znl-button style="float:left;margin-top: 4px;" style-type="main" tip="采纳云价格列表中的报价" :height='22' @click="OnAdoptPrice()">
          <span>采纳报价</span>
      </znl-button>
      <div style="margin-left: 25px;float:left;">
        <span class="model-price-style">型号:<span class="font-color-model">{{partNo}} </span></span>
        <span class="model-price-style">参考价:<span class="font-color">￥{{Price}}</span></span>
      </div>
    </div>
   </znl-table>
</template>
<script>
import { mixin as znlMethodsMixin, watchMixin as znlMethodswatch } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { openLinkAll } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'PartQuotes/GetConfig',
  saveConfigURL: 'PartQuotes/SaveConfig',
  resetConfigURL: 'PartQuotes/ResetConfig',
  searchURL: 'PartQuotes/Search',
  saveURL: 'PartQuotes/SaveAdopt', // 保存采纳过的报价
  getReferencePriceUrl: 'PartQuotes/GetReferencePrice'
}
export default {
  name: 'BomcloundPrice',
  mixins: [znlMethodsMixin, znlMethodswatch, getCommonDataMixin, openLinkAll], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
  },
  data () {
    return {
      actionBtns: [],
      columns: [],
      itemSource: [],
      Size: 5,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loading: false,
      partNo: '--',
      Price: '--',
      stkInquiry: {// 询价参数传递
        SupplierName: '', // 供应商名称
        Model: '', // Model
        Brand: '',
        Packaging: '',
        MakeYear: '',
        Quality: '',
        DeliveryDate: '',
        InvQty: 0,
        Qty: 0,
        MPQ: ''
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.isMini ? 5 : this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    initData: {
      tayp: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMini: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.onInit()
  },
  methods: {
    async onInit (config) {
      if (!config) {
        config = await this.isSaveStorage('bomcloundPrice', CONFIG.configURL)
      }
      // 获取模块打开或者关闭的状态
      let columns = config.ColumnConfigs
      let tempConfig = [
        {
          width: 40,
          type: 'button',
          title: '操作',
          btnTxt: '询价',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onInquiry(row)
          }
        }
      ]
      columns = tempConfig.concat(columns)
      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      var noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier');
      _.remove(columns,column=>{
        if (noSeeSupplier && column.BindField == SupplierName)
          return true;
      })
      //   if (column.binding === 'Model' ||
      //     column.binding === 'SupplierName' ||
      //     column.binding === 'CustomerName' ||
      //     column.binding === 'BillNo') {
      //     _.extend(column,
      //       {
      //         className: 'znl-linkAll-column',
      //         renderCell: this.openLinkAll(column)
      //       })
      //   }
      // })

      this.defaultConfig = config
      this.columns = columns
      this.actionBtns = []
      this.role = config.Role
      this.pageSize = config.PageSize
      this.$refs.table.init()
      this.initData && this.onBindData()
    },
    onBindData (pageIndex) {
      //this.$refs.table.cleanSort()
      this.partNo = this.searchModel.Model
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchModel),
        Model: this.searchModel.Model,
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      this.$post(CONFIG.searchURL, data, (data) => {
        if (_.isUndefined(data)) {
          this.itemSource = []
        } else {
          this.itemSource = data.ResultList || []
        }
        this.pageIndex = pageIndex
        this.totalCount = data.TotalCount
        this.loading = false
      })

      this.$post(CONFIG.getReferencePriceUrl, { Model: this.searchModel.Model }, (data) => {
        if (data !== null && data.UnitPrice !== null && data.UnitPrice !== '') {
          this.Price = data.UnitPrice
        } else {
          this.Price = '--'
        }
      })
    },
    onHeadSearch (params) {
      this.searchModel = params
      this.onBindData(1)
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    hasValue (val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },
    async  OnAdoptPrice () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 0 || (rows.length > 0 && rows[0] === undefined)) {
        return this.$message({ message: '请选择一条数据进行采纳', type: 'warning' })
      }
      if (rows.length > 1) {
        return this.$message({ message: '只能选择一条数据进行采纳', type: 'warning' })
      }
      let isDiff = false
      let diffMode = ''
      _.each(rows, item => {
        if (item.PartNo !== this.searchModel.Model) {
          diffMode = item.PartNo
          isDiff = true
        }
      })
      if (isDiff) {
        await this.$confirm(
          '采纳型号' +
          diffMode +
          '与需求型号' +
          this.searchModel.Model +
          '不一致，是否继续采纳?',
          '采纳提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          return this.onSaveRows(rows)
        })
      } else {
        return await this.onSaveRows(rows)
      }
    },

    async onSaveRows (rows) {
      if (rows.length > 0) {
        let json = {}
        _.each(rows, item => {
          let info = {
            BDLineGUID: this.searchModel.BDLineGUID,
            SuggestPrice: item.UnitPrice,
            CurrencyCode: item.Currency
          }
          json = info
        })
        return this.$post(CONFIG.saveURL, json, (datas, logic) => {
          if (logic.code === 200) {
            this.$emit('emit-datas', datas)
            this.$refs.flexGrid.clearSelection()
            this.$message({ message: '保存成功', type: 'success' })
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
      }
    },
    onInquiry: async function (row) { // 询价
      if (row) {
        let price = ''
        if (this.hasValue(row.UnitPriceText)) { price = this.toFloat(row.UnitPriceText.replace('￥', ''), 6) }
        this.$nextTick(function () {
          this.stkInquiry.SupplierName = row.SupplierName
          this.stkInquiry.Model = row.PartNo
          this.stkInquiry.Brand = row.Brand
          this.stkInquiry.Packaging = row.Packaging
          this.stkInquiry.MakeYear = row.MakeYear
          this.stkInquiry.Quality = row.Quality
          this.stkInquiry.DeliveryDate = row.DeliveryDate
          this.stkInquiry.InvQty = row.Qty
          this.stkInquiry.Price = price
          this.stkInquiry.QQ = row.QQ
          this.stkInquiry.Telephone = row.Telephone
          this.stkInquiry.Contact = row.Contact
          this.addInquire(this.stkInquiry, function (msg) {
            if (msg === '不能向自己询价') {
              this.$message({ message: msg, type: 'warning' })
            }
          })
        })
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("bomcloundPrice", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("bomcloundPrice", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("bomcloundPrice", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("bomcloundPrice")
    }
  }
}
</script>

<style lang="scss">
.model-price-style {
  padding: 0 6px;
  font-size: 13px;
  color: #888;
  font-weight: bold;
}
.model-price-style .font-color {
  color: Red;
}
.model-price-style .font-color-model {
  color: #333;
}
.cloundPriceClass {
  .znl-tablecell {
    color: Red;
  }
}
</style>
