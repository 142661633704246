<template>
  <!-- 待通知的需求物料 -->
  <znl-dialog
    width="920px"
    height="600px"
    :visible="visible"
    :loading="sendloading"
    :loading-text="sendLoadingText"
    class="dialog-waitnoticedbom-box"
    title="待通知查价"
    :append-to-body="true"
    :lock-scroll="true"
    :is-footer-show="false"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      height="100%"
      gridtype="action"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :columns="columns"
      layout-type="other"
      :item-source="itemSource"
      :is-fields-search="false"
      :is-multi-rows-check="true"
      :is-show-frombtns="false"
      :search-fields="searchModel"
      role="1"
      :is-action-form="false"
      :users="$store.state.users"
      :on-init="onInit"
      :is-init="true"
      :loading="loading"
      v-show="showPage"
      element-loading-text="数据加载中,请稍后..."
      :show-save-row-right-menu="false"
      :show-delete-row-right-menu="false"
      :grid-show="gridShow"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      @change="getGridDataList"
      @edit:cell-ended="onCellEdited"
    >
      <div slot="from-module">
        <div>查价员列表</div>
        <el-row class="myrow sales">
          <el-checkbox-group v-if="true" v-model="checkedSales">
            <el-checkbox
              v-for="item in salesDatas"
              :key="item.userId"
              :title="item.userName + '  近7天内 未报数：' +  (item.noticed-item.submitted) + ' | 已报数：' + item.submitted"
              :label="item.userId"
              class="sales-item"
            >
              {{item.userName}}&nbsp;(
              <span class="all">{{(item.noticed-item.submitted)}}</span> |
              <span>{{item.submitted}}</span>&nbsp;)
            </el-checkbox>
          </el-checkbox-group>
        </el-row>

        <el-row>
          <el-col :span="3">需求物料列表</el-col>
          <el-col :span="21" style="text-align: right;">
            <el-checkbox
              v-model="isNotNotified"
              @change="$refs.flexGrid.search()"
              class="mr5"
            >仅显示未通知</el-checkbox>
            <znl-button style-type="mac" @click="updateBuyEmp" class="mr5 ml5">
              <span>
                <i class="iconfont icon-table_set_btn_n"></i>设置查价人
              </span>
            </znl-button>
            <znl-button style-type="main" @click="onSave" class="mr5">
              <span>
                <i class="iconfont icon-notice_btn_ic"></i>发送通知
              </span>
            </znl-button>
            <!-- 先隐藏 取消通知查价 -->
            <znl-button style-type="mac" @click="onCancel" class="mr5">
              <span>
                <i class="iconfont icon-close_btn"></i>取消通知
              </span>
            </znl-button>
          </el-col>
        </el-row>
      </div>
      <bom-pro-relation
        :visible="BomProRelationVisible"
        v-if="BomProRelationVisible"
        :is-show="false"
        :is-fields-search="false"
        @close="()=>{BomProRelationVisible=false}"
        @confirm="(val)=>{val&&(BomProRelationVisible=false)}"
      ></bom-pro-relation>
    </znl-gridmodule>
    <!-- <div slot="footer" class="fontLeft">
      <el-row type="flex" v-if="!this.isTranspond">
        <el-col :span="20">
          <div class="fontColor" style="padding-top:5px;">
            1.如果设置了产品类型相关采购员，相应产品类型需求物料的查价人会自动设定为相关采购员
            <br />2.第一条不满足时，物料产品型号如果采购过，会自动设定为最后一次采购的采购员
          </div>
        </el-col>
        <el-col :span="4">
          <a href="javascript:void(0)" @click="ShowBomCheckUser">查看产品类型对应采购</a>
        </el-col>
      </el-row>
    </div>-->
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import BomProRelation from "@c_modules/BOM/BomProRelation";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { renderRequiredColumn } from "~assets/scripts/methods/common";

const CONFIG = {
  default: null,
  searchURL: "BomDetailLine/GetWaitNoticedBom",
  saveUrl: "BomWaitToInquire/Add",
  cancelNoticeUrl: "BomWaitToInquire/Del",
  getNoticeInfoUrl: "BomWaitToInquire/GetNoticeInfo"
};
export default {
  mixins: [znlMethodsMixin, getCommonDataMixin, renderRequiredColumn],
  components: {
    BomProRelation
  },
  data() {
    return {
      config: CONFIG,
      columns: [],
      itemSource: [],
      gridShow: false,
      BuyEmpID: [],
      UserID: this.$store.state.accountInfo.UserID, // 当前用户ID
      BuyEmpName: "",
      BuyEmpList: [],
      BomProRelationVisible: false,
      selectOptions: [],
      salesDatas: [],
      checkedSales: [],
      showPage: false,
      sendloading: true,
      sendLoadingText: "",
      isNotNotified: true,
      loading: false
    };
  },
  watch: {
    bomGuid(value) {
      if (!_.isEmpty(value)) {
        this.onBindData();
      }
    },
    bomLineGuids(value) {
      if (!_.isEmpty(value)) {
        this.onBindData();
      }
    }
  },
  props: {
    visible: Boolean,
    initData: {
      type: Boolean,
      default: true
    },

    isTranspond: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bomGuid: "", // 需求GUID
    bomLineGuids: "", // 需求明细GUID
    isRefresh: Boolean // 是否刷新列表
  },
  methods: {
    onInit: async function() {
      this.sendloading = true;
      this.sendLoadingText = "数据加载中,请稍后...";
      let gtidConfig = [
        {
          CTitle: "BDLineGUID",
          BindField: "BDLineGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true
        },
        {
          CTitle: "CurrencyCode",
          BindField: "CurrencyCode",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true
        },
        {
          CTitle: "产品类型",
          BindField: "ProductCategory",
          IsShow: !this.isTranspond,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "型号",
          BindField: "Model",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 120,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "需求量",
          BindField: "Qty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 2,
          DataType: 2,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "库存量",
          BindField: "InvQty",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 3,
          DataType: 2,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "已通知量",
          BindField: "NoticedQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 4,
          DataType: 2,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "本次通知量",
          BindField: "AsQty",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 5,
          DataType: 2,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: "采购员ID",
          BindField: "BuyEmpID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "加急",
          BindField: "IsUrgent",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 40,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "查价员",
          BindField: "BuyerName",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 140,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: true
        },
        {
          CTitle: "货期要求",
          BindField: "DeliveryRemark",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 120,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },
        {
          CTitle: "需求说明",
          BindField: "Remark",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        },

        {
          CTitle: "转发备注",
          BindField: "ForwardRemark",
          IsShow: this.isTranspond,
          IsReadonly: false,
          ColumnWidth: 200,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false
        }
      ];
      let columns = this.flexGridColumnsHandler(gtidConfig);
      this.isTranspond
        ? ""
        : columns.unshift({
            name: "delete",
            dataType: 5,
            binding: "delete",
            width: 50,
            header: "操作",
            visible: true,
            isReadOnly: true,
            buttons: [
              {
                content: "移除",
                className: "znl-table-btn-success",
                click: row => {
                  this.onDelete(row);
                }
              }
            ]
          });

      _.extend(
        _.find(columns, column => {
          return column.binding === "IsUrgent";
        }),
        {
          isCheckedColumn: true,
          isReadOnly: true
        }
      );
      _.extend(
        _.find(columns, col => {
          return col.binding === "BuyerName";
        }),
        {
          multiple: true,
          mapping: "BuyerNameList",
          separator: ",",
          showDropDown: true
        }
      );
      columns = this.renderRequiredColumn(columns);
      this.columns = columns;
      // await this.$post(CONFIG.selectOptionsUrl, {}, (data) => {
      //   this.selectOptions = _.map(data, item => {
      //     return {
      //       key: item.UserID,
      //       value: item.UserName // + ' <span class="m-ipt-hide">(' + item.SubmitCount + '/' + item.TaskCount + ')</span>'
      //     }
      //   })
      //   if (this.isTranspond) {
      //     for (var i = 0; i <= this.selectOptions.length; i++) {
      //       if (this.selectOptions[i].key === this.UserID) {
      //         this.selectOptions.splice(i, 1)
      //         break
      //       }
      //     }
      //   }
      // })

      this.selectOptions = this.selectOptionsAllUserURL;
      _.each(this.selectOptions, item => {
        this.salesDatas.push({
          userId: item.key,
          userName: item.value,
          noticed: "-",
          submitted: "-"
        });
      });

      this.onBindData();
      this.getSalesNoticeInfo();
    },
    onSearch(params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      return this.onBindData(1);
    },
    onRefresh() {
      return this.onBindData(this.pageIndex);
    },
    onBindData() {
      this.loading = true;
      let param = {
        BOMGUID: this.bomGuid,
        BDLineGUIDS:
          this.bomLineGuids === "" ? [] : JSON.parse(this.bomLineGuids),
        IsNotNotified: this.isNotNotified
      };
      return this.$post(this.config.searchURL, param, (data, logic) => {
        let itemSource = data === null ? [] : data;
        if (itemSource.length > 0) {
          _.each(itemSource, item => {
            // 计算本次通知量
            item.AsQty = item.AsQty || 0;
            this.$set(item, "AsQty", item.AsQty);
            item.BuyerName = "[" + item.NoticedEmpIDs + "]";
            item["BuyerNameList"] = [];
            if (this.selectOptions && this.selectOptions.length > 0) {
              let lists = _.map(this.selectOptions, opt => {
                return {
                  key: opt.key,
                  value: opt.value
                };
              });
              this.$set(item, "BuyerNameList", lists);
            }
          });
        }

        this.itemSource = itemSource;
        this.gridShow = true;
        this.showPage = true;
        this.loading = false;
        this.sendloading = false;
        this.$emit("page-loading", false);
      });
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 需求数量
      if (colname === "AsQty" && aftervalue !== beforevalue) {
        this.itemSource[rowindex]["AsQty"] = this.toFloat(aftervalue);
      }
    },
    setSelectOptions() {
      this.BuyEmpID = JSON.parse(
        this.itemSource.length > 0 ? this.itemSource[0].BuyerName : []
      );
    },
    onChangeUser(val) {
      this.BuyEmpID = val;
    },

    updateBuyEmp() {
      let selected = "[]";
      if (this.hasValue(this.checkedSales) && this.checkedSales.length > 0) {
        selected = "[" + this.checkedSales.join(",") + "]";
      }
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows.length <= 0) {
        rows = this.itemSource;
      }
      _.each(rows, item => {
        this.$set(item, "BuyerName", selected);
        let lists = _.map(this.selectOptions, opt => {
          return {
            key: opt.key,
            value: opt.value
          };
        });
        this.$set(item, "BuyerNameList", lists);
      });
      this.checkedSales = [];
    },

    getGridDataList(val) {
      console.log(val);
    },
    ShowBomCheckUser() {
      this.BomProRelationVisible = true;
    },
    async onSave() {
      let request = [];
      _.each(this.itemSource, item => {
        let m = {
          BDLineGUID: item.BDLineGUID,
          AsQty: item.AsQty,
          BomQty: item.Qty - (item.NoticedQty || 0),
          IsUrgent: !!item.IsUrgent
        };
        if (item.BuyerName !== "" && !_.isUndefined(item.BuyerName)) {
          m.NoticedEmpUserIds = JSON.parse(item.BuyerName);
          m.NoticedEmpList = this.getEmpList(
            item.BuyerNameList,
            m.NoticedEmpUserIds
          );
        }
        m.Model = item.Model;
        m.DeliveryRemark = item.DeliveryRemark;
        m.ForwardRemark = item.ForwardRemark;
        m.IsForwarded = this.isTranspond;
        m.Brand = item.Brand;
        m.DeliveryDate = item.DeliveryDate;
        m.Packaging = item.Packaging;
        m.MakeYear = item.MakeYear;
        m.Quality = item.Quality;
        m.MPQ = item.MPQ;
        m.CurrencyCode = item.CurrencyCode;
        request.push(m);
      });

      const flag = await this.validationData(request);
      if (!flag) {
        return;
      }

      this.sendLoadingText = "正在发送通知,请稍等...";
      this.sendloading = true;
      await this.$post(
        CONFIG.saveUrl,
        { AssistList: request },
        (data, logic) => {
          this.sendloading = false;
          if (logic.code === 200) {
            if (this.isTranspond) {
              this.$message({ message: "转发成功", type: "success" });
            } else {
              this.$message({ message: "通知成功", type: "success" });
            }
            this.$emit("save-success", true);
            this.$emit("close", true);
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        }
      ).finally(() => {
        this.sendloading = false;
      });
    },
    // 验证数据
    async validationData(val) {
      let ret = true;
      if (val === undefined || val.length <= 0) {
        this.$message({ message: "没有要通知查价的需求物料", type: "warning" });
        ret = false;
      }

      let col = _.filter(val, function(item) {
        return item.NoticedEmpList.length > 0;
      });
      let count = this.itemSource.length - col.length;
      if (count > 0) {
        await this.$confirm(
          "当前有【" + count + "】条需求物料尚未指定查价员,是否继续操作?",
          "提示",
          {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning"
          }
        )
          .then(() => {
            ret = true;
          })
          .catch(() => {
            ret = false;
          });
      }
      // let _values = _.some(val, item => item.NoticedEmpList.length > 0);
      // if (!_values) {
      //   await this.$confirm(
      //     "当前需求物料尚未指定查价员,是否继续操作?",
      //     "提示",
      //     {
      //       confirmButtonText: "是",
      //       cancelButtonText: "否",
      //       type: "warning"
      //     }
      //   )
      //     .then(() => {
      //       ret = true;
      //     })
      //     .catch(() => {
      //       ret = false;
      //     });
      // } else {
      // }
      _.each(val, item => {
        if (!this.hasValue(item.AsQty) || item.AsQty <= 0) {
          this.$message({
            message: "型号：" + item.Model + " 本次通知量必须大于0",
            type: "warning"
          });
          ret = false;
        }
      });
      return ret;
    },
    getEmpList(itemSource, userIds) {
      let list = [];
      if (itemSource.length > 0 && userIds && userIds.length > 0) {
        _.each(userIds, uid => {
          let item = _.find(itemSource, m => m.key === uid);
          item && list.push(item);
        });
      }
      return list;
    },
    setBuyEmpDisabled(source, isPublic, allowALL) {
      if (allowALL) {
        _.each(source, item => {
          item.disabled = false;
        });
      } else {
        if (isPublic) {
          _.each(source, item => {
            item.disabled = item.key !== 0;
          });
        } else {
          _.each(source, item => {
            item.disabled = item.key === 0;
          });
        }
      }
    },
    onDelete(row) {
      _.find(this.itemSource, (item, i) => {
        if (item.BDLineGUID === row.BDLineGUID) {
          this.itemSource.splice(i, 1);
          return item;
        }
      });
    },
    onCancel() {
      let selectedRow = this.$refs.flexGrid.getSelectedRows();
      if (!selectedRow || selectedRow.length === 0) {
        this.$message({ message: "请勾选要取消的操作项!", type: "warning" });
        return;
      }
      let lineGUIDS = [];
      _.each(selectedRow, m => {
        lineGUIDS.push(m.BDLineGUID);
      });
      if (lineGUIDS.length > 0) {
        this.$confirm(
          "确定要取消【" + lineGUIDS.length + "】个已通知查价的型号吗?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).then(() => {
          this.sendLoadingText = "正在取消通知,请稍等...";
          this.sendloading = true;
          return this.$post(
            CONFIG.cancelNoticeUrl,
            { BDLineGUIDs: lineGUIDS },
            (data, logic) => {
              this.sendloading = false;
              if (logic.code === 200) {
                this.$message({ message: "已成功取消通知！", type: "success" });
                this.onBindData(1);
              } else {
                this.$message({ message: logic.msg, type: "error" });
              }
            }
          ).catch(e => {
            this.sendloading = false;
          });
        });
      }
    },
    onselectionChanged(val) {
      // console.log("val", val);
      // console.log("this.checkedSales", this.checkedSales);
      // this.checkedSales = [];
    },
    async getSalesNoticeInfo() {
      let vm = this;
      this.$post(CONFIG.getNoticeInfoUrl, {}, (data, logic) => {
        if (logic.code === 200) {
          let items = [];
          _.each(this.salesDatas, item => {
            let noticed = 0;
            let submitted = 0;
            let obj = _.find(data, m => m.BuyEmpID === item.userId);
            if (obj) {
              // this.$set(item, "noticed", obj.NoticedCount)
              // this.$set(item, "submitted", obj.SubmittedCount)
              noticed = obj.NoticedCount;
              submitted = obj.SubmittedCount;
            }
            let newItem = _.extend({}, item, {
              noticed: noticed + "",
              submitted: submitted + ""
            });
            items.push(newItem);
          });

          vm.$set(vm.$data, "salesDatas", items);
          console.log(vm.salesDatas);
        } else {
          let msg = logic.msg || "统计查价通知信息失败";
          this.showError(msg);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.dialog-waitnoticedbom-box {
  .znl-dialog.el-dialog {
    transform: translate(0);
    margin-top: -230px !important;
    margin-left: -400px !important;
  }
  .el-select__tags-text .m-ipt-hide {
    display: none;
  }
  .fontColor {
    color: #c9c9c9;
    font-size: 14px;
    line-height: initial;
  }
  .fontIndent {
    text-indent: 2em;
  }
  .fontLeft {
    text-align: left;
  }
  .el-dialog__body > div {
    overflow: hidden !important;
  }
  .znl-action-form {
    .el-table-box > div {
      margin-left: 5px;
    }
    & > div {
      height: 30px !important;
      position: relative;
      .form_Row_W100 {
        position: absolute;
        right: 0;
      }
    }
  }
  // .el-table__header-wrapper{
  //   display: block;
  // }
  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 14px 10px;
  }
  .myrow {
    width: 100%;
  }
  .sales {
    max-height: 125px;
    overflow-y: scroll;
    margin-bottom: 2px;
    border: 1px solid #ccc;
    padding: 0 5px;
    border-radius: 2px;
    margin-bottom: 10px;
    ul {
      li {
        display: inline-block;
        width: 150px;
      }
    }
    .sales-item {
      width: 170px;
      margin: 0 !important;
    }
    .all {
      color: red;
    }
  }
  .title-tips {
    font-size: 12px;
    padding-left: 5px;
    color: #ccc;
  }
}
</style>

