import { isClient } from "~/lib/runtime";

export default {
  methods: {},
  watch: {
    //监控窗口高度改变，因而计算组件的大小
    // "$store.state.domRect.clientHeight": function(val, oldVal) {
    //   if (this.onresize && typeof this.onresize === "function") {
    //     const h = isClient()
    //       ? this.$store.state.domRect.clientHeight
    //       : this.$store.state.domRect.clientHeight - 50;
    //     this.onresize(h);
    //   }
    // },
    "$store.state.domRect": {
      handler: function(val, oldVal) {
        if (this.onresize && typeof this.onresize === "function") {
          // const h = isClient()
          //   ? this.$store.state.domRect.clientHeight
          //   : this.$store.state.domRect.clientHeight - 50;
          this.onresize();
        }
      },
      deep: true
    }
  }
};
