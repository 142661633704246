<template>
  <znl-dialog
    :visible="p_visible"
    :savetype='savetype'
    :title="title"
    height="620px"
    width='650px'
    class="AccountAuthor"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :append-to-body="true"
    @close="()=>{$emit('close')}">

      <znl-gridmodule ref="flexGrid"
              :has-znl-btns='false'
              layout-type="other"
              :visible="visible"
              :is-init="true"
              height="100%"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :search-fields="searchModel"
              :is-multi-rows-check="true"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :users="$store.state.users"
              :on-init="onInit"
              :on-search="onSearch"
              :on-page-changed="onBindData"
              @close="()=>{$emit('close')}"
              @confirm="()=>{onSave()}"
              @selection-changed="d=>{$emit('selection-changed',d)}">
      </znl-gridmodule>

    <div slot="footer">
      <znl-button style-type="main" :disabled="confirmDisabled" @click="onSave" :height="33" :width="60" >确定</znl-button>
    </div>
  </znl-dialog>
</template>
<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  PK: 'UserID',
  configURL: 'UserRole/GetConfig',
  saveConfigURL: 'UserRole/SaveConfig',
  resetConfigURL: 'UserRole/ResetConfig',
  searchURL: 'AccountManage/GetAccountList',
  saveURL: 'UserRole/Save',
  checkSaveUrl: 'BomCheckUser/Save',
  getUserRole: 'UserRole/GerUserRole'
  // GetUserIDs: 'BomCheckUser/GetUserIDs'
}
export default {
  name: 'UserRole',
  components: {
  },
  mixins: [propSyncMixin, getCommonDataMixin],
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      fieldsFilter: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      dialogShow: false,
      searchModel: {},
      loading: true,
      elementLoadingText: '数据加载中,请稍后...',
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      confirmDisabled: false,
      userIDs: [],
      clearUserOldResource: false // 是否清楚该用户原本已设置的权限
    }
  },
  props: {
    savetype: String,
    title: String,
    roleModel: {
      type: Object
    },
    checkModel: {
      type: Object
    },
    isRefresh: Boolean,
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    initData: {
      type: Boolean,
      default: false
    }

  },
  watch: {
    isRefresh: {
      handler (value) {
        if (!_.isEmpty(this.UserAccount)) {
          this.init()
        }
      },
      immediate: true
    },
    visible (val) {
      if (val) {
        this.dialogShow = true
      }
    }
  },
  methods: {
    async onInit (config, refresh) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage('userSelect', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns

      this.role = config.Role
      this.pageSize = 1000000 // 显示所有员工
      this.savetype === '1' ? await this.GetUserIDs() : await this.getRoleUser()
      this.onBindData(1)
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      this.onBindData()
    },
    onBindData (pageIndex = 1) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          Account: this.Account
          // 外部搜索条件
        }
      )

      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
        this.loading = false
        if (!_.isUndefined(this.userIDs) && this.userIDs.length > 0 && !_.isUndefined(this.itemSource)) {
          _.each(this.userIDs, item => {
            let userInfo = _.filter(this.itemSource, data => { return data.UserID === item })
            if (userInfo.length > 0) {
              this.$set(userInfo[0], '_Checked', true)
            }
          })
        }
      })
    },
    async getRoleUser () {
      this.$post(CONFIG.getUserRole, {RoleID: this.roleModel.RoleID}, (data) => {
        this.userIDs = data
      })
    },
    async GetUserIDs () {
      // if (this.checkModel.CID !== null) {
      //   this.$post(CONFIG.GetUserIDs, {CID: this.checkModel.CID}, (data) => {
      //     this.userIDs = data
      //   })
      // } else {
      let buyEmpID = (this.checkModel.BuyEmpID === undefined ? '' : this.checkModel.BuyEmpID).split(',')
      _.each(buyEmpID, item => {
        if (item !== '') { this.userIDs = this.userIDs.concat(this.toInt(item)) }
      })
      // }
    },
    onSave () {
      this.clearUserOldResource = true
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (this.savetype === '1') {
        this.onCheckUserSave(rows)
      } else {
        this.onConfirmSave(rows)
      }
    },
    onConfirmSave (rows) {
      let saveDate = {RoleID: this.roleModel.RoleID, UserIDs: [], ClearOldResource: this.clearUserOldResource}
      if (!_.isUndefined(rows) && rows.length > 0 && !_.isUndefined(rows[0])) {
        _.each(rows, item => {
          saveDate.UserIDs.push(item.UserID)
        })
      }
      return this.$post(CONFIG.saveURL, saveDate, (data) => {
        if (data === undefined || data === '' || data === false) {
          this.$message({ message: '保存失败', type: 'error' })
          this.$emit('close')
          this.confirmDisabled = false
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('close')
          this.$emit('confirm', true)
          this.confirmDisabled = false
        }
      }, () => {
        this.confirmDisabled = false
      })
    },
    onCheckUserSave (rows) {
      let saveDate = {
        CID: this.checkModel.CID,
        BuyEmpID: '',
        BuyEmpName: '' }
      if (!_.isUndefined(rows) && rows.length > 0 && !_.isUndefined(rows[0])) {
        let BuyEmpID = ''
        let BuyEmpName = ''
        _.each(rows, item => {
          BuyEmpID += item.UserID + ','
          BuyEmpName += item.UserName + ','
        })
        saveDate.BuyEmpID = BuyEmpID
        saveDate.BuyEmpName = BuyEmpName
      }
      if (this.checkModel.CID !== null && this.checkModel.CID !== undefined) {
        return this.$post(CONFIG.checkSaveUrl, saveDate, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '保存成功', type: 'success' })
            this.$emit('close')
            this.$emit('save-success', true)
            this.confirmDisabled = false
          } else {
            this.$message({ message: logic.msg, type: 'error' })
            this.$emit('close')
            this.confirmDisabled = false
          }
        }, () => {
          this.confirmDisabled = false
        })
      } else {
        this.$emit('save-date', saveDate)
        this.$emit('close')
        this.confirmDisabled = false
      }
    }

  }
}
</script>
