<template>
  <div>
    <!--采购退货编辑框-->
    <znl-dialog
      title="采购退货单"
      :visible="editvisible"
      subhead="(橙色底为必填项)"
      height="640px"
      width="1000px"
      class="dialog-znl-purchase-return-edit"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="
        (val) => {
          $emit('close', val), onResetStkin();
        }
      "
    >
      <znl-gridmodule
        ref="flexGrid"
        gridtype="action"
        height="100%"
        class="purchase-return-edit-min-grid-box m-border-box"
        :has-znl-btns="false"
        layout-type="other"
        :from-height="150"
        :from-btns="fromBtns"
        element-loading-text="正在保存,请稍等..."
        :columns="columns"
        :item-source="itemSource"
        :edit-row-index="editRowIndex"
        :is-alter-ver="true"
        :on-init="onInit"
        :loading="loading"
        :qtyAbs="true"
        :qtyClear="true"
        from="StkIn"
        :on-deleted-checked-rows="
          () => {
            CalcPay(false);
          }
        "
        @page-loading="
          (d) => {
            $emit('page-loading', d);
          }
        "
        @edit:cell-ended="onCellEdited"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style="border: 1px solid #d8d8d8">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style="
              border-bottom: 1px solid #e6e6e6;
              height: 40px;
              line-height: 40px;
            "
          >
            <el-col :span="5" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    ref="ReceiveTime"
                    layout="left"
                    placeholder="退货日期"
                    form-type="datepicker"
                    :readonly="false"
                    :disabled="false"
                    :is-must-fill="true"
                    v-model="StkIn.ReceiveTime"
                    width="100%"
                    tabindex="1"
                    :border="true"
                    title="*退货日期："
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="5">
              <znl-input
                size="mini"
                title-width="60px"
                ref="ReceiveUserID"
                width="92%"
                layout="left"
                form-type="select"
                placeholder="请选择"
                title="*出库员："
                popper-class="popperClass"
                :is-must-fill="true"
                :border="true"
                tabindex="2"
                :select-options="selectOptionsAllUserURL"
                v-model="StkIn.ReceiveUserID"
              ></znl-input>
            </el-col>

            <el-col :span="5">
              <znl-input
                title-width="65px"
                form-type="input"
                :disabled="!IsManual"
                placeholder="默认自动生成 "
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="单据编号："
                tabindex="5"
                width="100%"
                :border="true"
                v-model="StkIn.BillNo"
                :on-icon-click="isAllow && onBillIconClick"
                type="text"
              ></znl-input>
            </el-col>

            <el-col :span="5">
              <znl-input
                title-width="80px"
                form-type="input"
                :readonly="true"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="退货金额："
                tabindex="6"
                :border="true"
                v-model="StkIn.PayablesAmount"
                width="100%"
                type="text"
              ></znl-input>
            </el-col>

            <el-col :span="6">
              <znl-input
                title-width="65px"
                layout="left"
                ref="CurrencyCode"
                form-type="select"
                :disabled="true"
                width="67%"
                placeholder="请选择"
                popper-class="popperClass"
                title="*币 种："
                tabindex="7"
                :border="true"
                :is-must-fill="true"
                @change="CurrencyCodeChange"
                :select-options="selectOptionsCurrencyCode"
                v-model="StkIn.CurrencyCode"
              ></znl-input>
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="this.IsExchangeRateRMB"
                placeholder
                size="mini"
                layout="left"
                :border="true"
                :is-must-fill="false"
                tabindex="8"
                title
                width="28%"
                v-model="StkIn.ExchangeRate"
                type="text"
              ></znl-input>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row
                class="form_Row_W100"
                type="flex"
                style="margin-bottom: 2px"
              >
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="znlAutocomplete"
                    @change="autocompleteChange"
                    custom-item="my-item-zh"
                    :fetch-suggestions="querySearch"
                    @select="suggestionsSelect"
                    :disabled="true"
                    placeholder="输入供应商简称或拼音字母,并按回车键查询"
                    size="mini"
                    layout="left"
                    tabindex="3"
                    :is-must-fill="true"
                    :title="this.SupplierNameTitle"
                    :border="true"
                    width="100%"
                    v-model="StkIn.SupplierName"
                    type="text"
                  >
                    <i slot="suffix" class="iconfont icon-edit_btn_ic"></i>
                  </znl-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="20" class="min-boder-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col :span="8">
                  <znl-input
                    ref="TransportCompany"
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    width="97%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="货运公司："
                    :border="true"
                    tabindex="9"
                    :is-must-fill="false"
                    :disabled="false"
                    :select-options="selectOptionsTransportCompany"
                  ></znl-input>
                </el-col>
                <el-col :span="8">
                  <znl-input
                    ref="ShippingNo"
                    title-width="73px"
                    form-type="input"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    tabindex="10"
                    :is-must-fill="false"
                    :disabled="false"
                    title="货运单号："
                    width="90%"
                    type="text"
                  ></znl-input>
                </el-col>
                <el-col :span="6">
                  <znl-input
                    title-width="44px"
                    layout="left"
                    ref="StkInTaxRate"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="税 点："
                    :border="true"
                    :is-mut-fill="false"
                    tabindex="14"
                    @change="taxRateChange"
                    :select-options="selectOptionsTaxRate"
                    v-model="StkIn.TaxRate"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row
                class="form_Row_W100"
                type="flex"
                style="margin-bottom: 2px"
              >
                <el-col>
                  <znl-input
                    title-width="70px"
                    class="m-txt-remark"
                    form-type="input"
                    placeholder="退货原因"
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="退货原因："
                    tabindex="4"
                    :border="true"
                    :rows="2"
                    width="100%"
                    v-model="StkIn.ReturnReason"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col :span="16" v-show="true">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    class="m-txt-remark"
                    placeholder="备注说明"
                    size="mini"
                    layout="left"
                    tabindex="11"
                    :is-must-fill="false"
                    title="备注说明："
                    :border="true"
                    v-model="StkIn.Remark"
                    :rows="2"
                    width="95%"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div slot="from-content">
          <label style="font-size: 12px; color: red; margin-right: 8px"
            >数量为负数表示为退货操作!</label
          >
          <el-checkbox
            size="small"
            v-model="IsPrint"
            @change="onPrintValue()"
            :isShow="hasRes('Print')"
            style="margin-right: 5px"
          >
            <label for="IsPrint">保存后并打印</label>
          </el-checkbox>

          <znl-button
            style-type="mac"
            :height="22"
            :isShow="hasRes('Print')"
            type="multiple"
          >
            <znl-button-menu @click="onPrint">
              <span> <i class="iconfont icon-print_btn_ic"></i>打印 </span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            :height="22"
            :disabled="isDisabled"
            @click="stkinSaveAndStorage"
            :isShow="hasRes('Print')"
            style-type="minor"
          >
            <span> <i class="iconfont icon-save_btn_ic"></i>保存并出库 </span>
          </znl-button>

          <znl-button
            :height="22"
            :disabled="isDisabled"
            @click="stkinSave"
            :isShow="hasRes('Print')"
            style-type="main"
          >
            <span> <i class="iconfont icon-save_btn_ic"></i>保存 </span>
          </znl-button>

          <znl-print
            :visible.sync="znlPrintVisible"
            :printInfo="printInfo"
            :loading="printLoading"
            :init-param="false"
            :is-get-default-param="true"
            print-type="STK_In"
            title="入库单打印设置"
            @set-print-param="setPrintParam"
            @confirm-print="confirmPrint"
          ></znl-print>
        </div>
      </znl-gridmodule>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { renderRequiredColumn } from "~assets/scripts/methods/common";

import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { getabsleft, getabstop } from "~assets/scripts/utils/dom";
import autocomplete from "@c_common/znlGrid/autocomplete";
import pasteDataAcquisition from "@c_common/znlGrid/commonComponents/pasteDataAcquisition";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";

const CONFIG = {
  PK: "StkInLineID",
  configURL: "OrdPurchaseReturnLine/GetConfig",
  StkInSaveAndStorageURL: "PurchaseReturnEdit/Save", // 保存
  Search: "PurchaseReturnEdit/Search",
  StkInSearchByLineguid: "PurchaseReturnEdit/GetInlineByStkInGUIDS",
  configJsonURL: "OrdPurchaseReturn/GetConfig",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  CreateByPOGUID: "StkInAdd/CreateByPOGUID",
  NewInfo: "PurchaseReturnEdit/NewInfo",
  ReturnByPOLineGuids: "PurchaseReturnEdit/ReturnByPOLineGuids",
};
const defaultStkIn = {
  BillNo: "", // 采购单号
  ReceiveTime: new Date().toLocaleDateString(), // 入库日期
  ReceiveUserID: "",
  Receiver: "", // 采购员
  PayablesAmount: "", // 应付金额
  SupplierName: "", // 供应商
  Contact: "", // 联系人
  PaidAmount: "", // 实付金额
  Address: "", // 地址
  Telephone: "", // 联系电话
  PaymentType: "", // 付款方式
  TaxRate: null, // 税点
  Remark: "", // 备注
  CurrencyCode: "RMB", // 币种
  ExchangeRate: "1", // 汇率
  DiscountAmount: null, // 折扣金额
  IsAdd: true, // 是否新增
  isAllowStorge: true, // 是否为保存并入库
  LineList: [], // 保存入库详情明细行
  PDID: "",
  ReturnReason: "", // 退货原因
  CBGUID: "", // 收款账号,
};
export default {
  name: "OrdPurchaseReturnEdit",
  config: CONFIG,
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
  ],
  components: {
    autocomplete,
    pasteDataAcquisition,
    ZnlPrint,
  },
  data() {
    return {
      isDisabled: false,
      IsContinuousRecord: false,
      dialogLoading: true,
      autocompleteShow: false,
      autocompleteSearchData: "",
      startTime: 0,
      dialogCompanySelectVisible: false,
      loading: false,
      InTypes: "", // 1:客户，2：供应商，3:两者都是的公司
      StkIn: {
        // 入库单对象
        BillNo: "", // 采购单号
        ReceiveTime: new Date().toLocaleDateString(), // 入库日期
        ReceiveUserID: this.$store.state.accountInfo.UserID, // 采购员ID
        Receiver: this.$store.state.accountInfo.UserName, // 采购员
        PayablesAmount: "", // 应付金额
        SupplierName: "", // 供应商
        Contact: "", // 联系人
        PaidAmount: "", // 实付金额
        Address: "", // 地址
        Telephone: "", // 联系电话
        PaymentType: "", // 付款方式
        TaxRate: null, // 税点
        Remark: "", // 备注
        ReturnReason: "", // 退货原因
        CurrencyCode: "RMB", // 币种
        ExchangeRate: "1", // 汇率
        DiscountAmount: null, // 折扣金额
        IsAdd: true, // 是否新增
        isAllowStorge: true, // 是否为保存并入库
        LineList: [], // 保存入库详情明细行
        PDID: "",
        stkInGUID: "", // 入库单ID
      },
      SelectSearchModel: {
        CompanyName: "",
      },
      SupplierNameTitle: " 供应商：",
      paramTax: null,
      paramQtyType: "自有",
      paramQtyUnit: "PCS",
      ParamJosn: [],
      ParamJosnLine: [],
      IsPrint: false,
      resourceList: [],
      // 绑定列表中的下拉框
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      isPopup: false,
      IsExchangeRateRMB: true,
      fromBtns: [],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: false, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "STK_In", // 打印模板类型
      },
      GCode: "StkIn",
      // end 打印模块参数
    };
  },
  props: {
    title: String,
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    stkInGUID: String,
    stkInGUIDLine: Array, // 入库明细对象
    showInAddBystkin: Boolean, // 当从入库列表中传过来的值时刷新
    isRefresh: Boolean, // 订货入库
  },
  watch: {
    // dialogLoading(val) {
    //   if (!val) {
    //     this.$nextTick(() => {
    //       this.$refs.flexGrid.doLayout();
    //     });
    //   }
    // },
    showInAddBystkin: {
      // 刷新页面(从入库列表中传过来的数据)
      handler(value) {
        if (!_.isEmpty(this.stkInGUID)) {
          this.isPopup = true;
          this.LoadEditData(this.stkInGUID);
        }
      },
      immediate: true,
    },

  },
  methods: {
    onInit: async function () {
      let config = await this.isSaveStorage(
        "ordPurchaseReturnLine",
        CONFIG.configURL
      );
      let configs = await this.isSaveStorage(
        "ordPurchaseReturn",
        CONFIG.configJsonURL
      );
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      // 获取参数配置
      this.ParamJosn = configs.ParamJosn;
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn;
      this.resourceList = config.ResourceList.map((item) => item.Code);
      // 隐藏状态列
      _.each(columns, (item, index) => {
        if (item.binding === "StatusName") {
          item.hasRole = false;
          item.visibled = false;
        }
      });
      // 库存类型
      let stkTypeColumn = _.find(
        columns,
        (column) => column.name === "InvType"
      );
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: this.selectStorageType,
          showDropDown: true,
        });
      // 仓库
      let WarehouseOptions = this.selectOptionsWarehouseOptions;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "InvPosition";
        }),
        { dataMap: WarehouseOptions, showDropDown: true }
      );
      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit;

      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Unit";
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      );
      // 品质
      let QualityDropDown = await this.selectOptionsQuality;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Quality";
        }),
        { dataMap: QualityDropDown, showDropDown: true }
      );
      this.defaultConfig = config;
      columns = this.renderRequiredColumn(columns);

      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkInGUID
      ) {
        let stkinguid = this.$store.state.paramOrder.stkInGUID;
        let order = {};
        this.$store.commit("setParamOrder", order);
        this.LoadEditData(stkinguid);
      }

      // 从入库单主单退货 编辑退货单
      if (this.$store.state.paramOrder.stkInReturnInfo) {
        let info = this.$store.state.paramOrder.stkInReturnInfo;
        this.$store.commit("setParamOrder", {});
        this.LoadReturnInfo(info);
      }
      // 根据入库单明细GUID加载实体
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkInGUIDLine
      ) {
        let stkinguidLine = this.$store.state.paramOrder.stkInGUIDLine;
        let order = {};
        let isreturn = false;
        if (this.$store.state.paramOrder.IsReturn) {
          isreturn = this.$store.state.paramOrder.IsReturn;
        }
        this.$store.commit("setParamOrder", order);
        this.LoadLineAddData(stkinguidLine, isreturn);
      }
      // 从采购单退货
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.POModels
      ) {
        let info = this.$store.state.paramOrder.POModels;
        this.$store.commit("setParamOrder", {});
        this.LoadPurchaseData(info);
      }
      //从采购明细退货
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.purchaseOrderLines
      ) {
        let purchaseOrderLines =
          this.$store.state.paramOrder.purchaseOrderLines;
        this.$store.commit("setParamOrder", {});
        this.LoadPurchaseOrderLines(purchaseOrderLines);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkOutLines
      ) {
        let stkOutLines = this.$store.state.paramOrder.stkOutLines;
        this.$store.commit("setParamOrder", {});
        this.$nextTick(function () {
          _.each(stkOutLines, (items) => {
            items.SalesPrice = items.PriceInTax;
            items.PriceNoTax = items.BuyPrice;
            items.Price = items.BuyPrice;
            let amount = this.toFloat(
              this.ConvertNumber(items.BuyPrice) *
                this.ConvertNumber(items.Qty),
              2
            );
            items.Amount = amount;
            items.Status = 1;
          });

          this.StkIn.SupplierName = stkOutLines[0].SupplierInfo;
          this.StkIn.LineList = stkOutLines;
          this.itemSource = stkOutLines;
          this.CalcPay(true);
          this.onBindData();
        });
      }

      this.fromBtns = [
        {
          name: "删除选择行",
          iconName: "icon-delete_btn_ic",
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
            let Amount = 0;
            _.each(this.itemSource, (item, i) => {
              Amount += Number(item.Amount);
            });
            this.StkIn.PayablesAmount = Amount;
          },
        },
      ];
      this.$nextTick(() => {
        this.dialogLoading = false;
      });
    },

    onBindData(pageIndex = 1) {
      this.StkIn.LineList = this.itemSource;
      this.$emit("page-loading", false);
    },

    // 供应商名称联想
    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post(
          "CompanyContacts/SearchCompanyContacts",
          {
            FieldWhereString: JSON.stringify({
              CompanyName: queryString,
            }),
            PageIndex: 1,
            PageSize: 20,
            CompanyTypes: "2,3",
          },
          (data) => {
            this.restaurants = data.ResultList;
            let restaurants = this.restaurants;
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            // 调用 callback 返回建议列表的数据
            _.each(results, (item) => {
              item.value = item.CompanyName;
            });
            cb(results);
            _.delay(() => {
              this.$refs.znlAutocomplete.$el
                .querySelector(".el-input__inner")
                .focus();
            }, 100);
          }
        );
      }
      this.startTime = new Date();
    },

    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0;
      };
    },

    suggestionsSelect(item) {},

    CurrencyCodeChange(val) {
      // 币种改变选项事件
      let itemVal = val ? val.key : this.StkIn.CurrencyCode; // 选中的值
      if (!this.hasValue(itemVal)) {
        itemVal = "RMB";
      }
      if (itemVal === "RMB") {
        this.IsExchangeRateRMB = true;
      } else {
        this.IsExchangeRateRMB = false;
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) {
        return item.key === itemVal;
      });
      this.StkIn.ExchangeRate = info.ExchangeRate;
    },

    taxRateChange(val) {
      // PriceNoTax 未税
      // Price 含税
      // Qty 数量
      // Amount 小计金额
      val ? (this.StkIn.TaxRate = val.key) : (this.StkIn.TaxRate = null);
      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].PriceNoTax)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].PriceNoTax);
            source[i].Price = _priceIntax;
          } else if (this.hasValue(source[i].Price)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].Price);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].Price
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcPay(false);
      }
    },

    save(isStorge) {
      // 保存并入库
      var vm = this;
      if (this.StkIn.LineList.length > 0) {
        this.StkIn.IsAllowStroge = isStorge;
        vm.loading = true;
        this.StkIn.IsReturn = true;
        return this.$post(
          CONFIG.StkInSaveAndStorageURL,
          this.StkIn,
          (data, logic) => {
            vm.loading = false;
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "保存成功", type: "success" });
              this.$emit("save-success", true);
              // this.StkIn.stkInGUID = data
              if (this.IsPrint) {
                this.onPrint();
              }
              if (this.IsContinuousRecord) {
                this.onResetStkin(); // 重置
              } else {
                this.$emit("close");
              }
            }
          }
        ).finally(() => {
          vm.loading = false;
        });
      }
    },

    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
    },

    confirmPrint(data) {
      this.onPrint();
    },

    onPrint() {
      var tableName = this.printInfo.template; // 模板ID
      var InGuid = this.StkIn.stkInGUID;
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请先设置打印模板", type: "error" });
      } else if (InGuid === "" || InGuid === null || InGuid === undefined) {
        this.$message({ message: "请先保存后才能打印", type: "error" });
      } else {
        let printParm = {
          TableName: tableName,
          Guid: InGuid,
          SubCompanyId: this.printInfo.title,
        };
        this.printStkIn(printParm, () => {});
      }
    },

    hasRes(code) {
      return _.some(this.resourceList, (page) => page.Code === code);
    },

    stkinSave() {
      // 保存
      if (this.checkData()) {
        this.save(false);
      }
    },

    stkinSaveAndStorage() {
      // 保存并入库
      if (this.checkData()) {
        this.save(true);
      }
    },

    checkData() {
      let ret = true;
      this.onbuyerChange();
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false;
        return this.$message({
          message: "请修改填写错误的单元格内容后再保存",
          type: "error",
        });
      } else if (!this.hasValue(this.StkIn.SupplierName)) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({ message: "供应商名不能为空", type: "warning" });
      } else if (!this.hasValue(this.StkIn.ReceiveTime)) {
        ret = false;
        this.$refs.ReceiveTime.handleFocus();
        this.$message({ message: "请选择入库日期", type: "warning" });
      } else if (
        this.StkIn.ReceiveUserID <= 0 ||
        this.StkIn.ReceiveUserID === undefined ||
        this.StkIn.ReceiveUserID === "" ||
        this.StkIn.ReceiveUserID === null
      ) {
        ret = false;
        this.$refs.ReceiveUserID.handleFocus();
        this.$message({ message: "请选择入库员", type: "warning" });
      } else if (
        this.StkIn.CurrencyCode === undefined ||
        this.StkIn.CurrencyCode === "" ||
        this.StkIn.CurrencyCode === 0
      ) {
        ret = false;
        this.$refs.CurrencyCode.handleFocus();
        this.$message({ message: "请选择币种", type: "warning" });
      } else {
        let rows = this.itemSource;
        if (rows.length > 0) {
          let rowId = 0;
          _.some(rows, (item) => {
            rowId = rowId + 1;
            if (item["Model"] === null || item["Model"] === undefined) {
              ret = false;
              this.$message({
                message: "单据详情第" + rowId + "行 型号不能为空",
                type: "warning",
              });
            } else if (!isFinite(item["Qty"]) || !this.hasValue(item["Qty"])) {
              ret = false;
              this.$message({
                message: "单据详情第" + rowId + "行 数量不能为空",
                type: "warning",
              });
            } else if (
              !isFinite(item["Price"]) ||
              !isFinite(item["PriceNoTax"]) ||
              !this.hasValue(item["Price"])
            ) {
              ret = false;
              this.$message({
                message:
                  "单据详情第" + rowId + "行 未税价必须是数值型且不能为空",
                type: "warning",
              });
            }
          });
          this.StkIn.LineList = this.itemSource;
        } else {
          ret = false;
          this.loading = false;
          return this.$message({
            message: "请添加入库单详情",
            type: "warning",
          });
        }
      }
      return ret;
    },

    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit;
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType;
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.PriceNoTax)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.PriceNoTax);
        item.Price = _price === 0 ? null : _price;
      } else if (!this.hasValue(item.PriceNoTax) && this.hasValue(item.Price)) {
        // 含税算未税
        item.PriceNoTax = this.CalcPrice(item.Price);
      }
      item.Amount = this.toFloat(this.CalcAmout(item.Qty, item.PriceNoTax), 2);
      this.CalcPay(false);
    },

    // 根据入库单明细GUID加载实体
    LoadLineAddData: async function (guid, isreturn) {
      // 根据入库单明细销售退货操作
      let param = { InLineGuids: guid, IsReturn: isreturn };
      await this.$post(CONFIG.StkInSearchByLineguid, param, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({
            message: logic.msg || "加载明细失败，请关闭窗口重试",
            type: "error",
          });
        }

        let o = _.extend({}, defaultStkIn, data);
        o.IsReturn = true;
        o.ReceiveUserID = this.toInt(o.ReceiveUserID);
        this.$nextTick(function () {
          this.itemSource = o.LineList;
          this.onBindData();
          this.StkIn.SupplierName = data.SupplierName;
          this.CalcPay(true);
          o.IsAdd = false;
          o.isAllowStorge = o.Status !== 3; // 已入库的禁用
        });

        this.StkIn = o;
      });
    },

    LoadReturnInfo: async function (info) {
      let stkIn = null;
      await this.$post(CONFIG.NewInfo, info, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({
            message: logic.msg || "加载明细失败，请关闭窗口重试",
            type: "error",
          });
          return false;
        }

        stkIn = data;
      });
      if (stkIn === null) {
        return false;
      }

      if (!this.hasValue(stkIn)) {
        return false;
      }
      this.StkIn = stkIn;
      this.itemSource = stkIn.LineList;
      this.$nextTick(function () {
        this.onBindData();
        this.StkIn.IsAdd = false;
        this.StkIn.isAllowStorge = this.StkIn.Status !== 3; // 已入库的禁用
      });
    },

    // 根据入库单GUID加载实体
    LoadEditData: async function (guid) {
      let stkIns = null;
      await this.$post(CONFIG.Search, { StkInGUID: guid }, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({
            message: logic.msg || "加载明细失败，请关闭窗口重试",
            type: "error",
          });
        }
        stkIns = data;
      });
      if (stkIns === null || stkIns === undefined) {
        return false;
      }
      if (stkIns.IsAudited || stkIns.Status === 2 || stkIns.Status === 3) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.StkIn = stkIns;
      this.itemSource = stkIns.LineList;
      this.$nextTick(function () {
        this.onBindData();
        this.StkIn.IsAdd = false;
        this.StkIn.isAllowStorge = this.StkIn.Status !== 3; // 已入库的禁用
        this.StkIn.stkInGUID = guid;
      });
    },

    // 根据采购单加载实体
    LoadPurchaseData: async function (info) {
      return this.$post(CONFIG.CreateByPOGUID, info, (item, logic) => {
        if (logic.code === 200) {
          let newlist = [];
          let rowID = 1;
          _.each(item.LineList, (data) => {
            let newItem = data;
            newItem.BeforeFirstQtyValue = data.Qty;
            newItem.RowGuid = rowID++;
            newlist.push(newItem);
          });
          item.LineList = newlist;
          if (!this.hasValue(item.ReceiveUserID)) {
            item.ReceiveUserID = this.$store.state.accountInfo.UserID;
          }
          if (!this.hasValue(item.Receiver)) {
            item.Receiver = this.$store.state.accountInfo.UserName;
          }
          this.StkIn = _.extend({}, defaultStkIn, item);
          this.$nextTick(function () {
            _.each(this.StkIn.LineList, (p) => {
              p.POBillNO = item.BillNo;
            });
            this.StkIn.BillNo = null;
            this.itemSource = this.StkIn.LineList;

            this.onBindData();
            this.CalcPay(true);
            this.StkIn.IsAdd = true;
          });
        } else {
          return this.$message({ message: logic.msg, type: "error" });
        }
      });
    },

    CalcPriceInTax(price) {
      // 计算含税价
      let nums = price * (1 + this.StkIn.TaxRate / 100);
      return this.toFloat(nums, 6);
    },

    CalcPrice(priceintax) {
      // 计算未税
      let nums = priceintax / (1 + this.StkIn.TaxRate / 100);
      return this.toFloat(nums, 6);
    },

    CalcAmout(qty, price) {
      // 计算金额
      let nums = qty * price;
      return this.toFloat(nums, 2);
    },

    CalcPay(isEdit) {
      // 计算应付金额
      this.StkIn.PayablesAmount = null;
      _.each(isEdit ? this.StkIn.LineList : this.itemSource, (o, index) => {
        this.StkIn.PayablesAmount += this.toFloat(o.Amount, 2);
      });
      this.StkIn.PayablesAmount = this.toFloat(this.StkIn.PayablesAmount, 2);
    },

    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null
        ? 0
        : this.toFloat(num);
    },

    onResetStkin() {
      // 重置入库实体
      this.StkIn.BillNo = ""; // 采购单号
      this.StkIn.PayablesAmount = ""; // 应付金额
      this.StkIn.SupplierName = ""; // 供应商
      this.StkIn.Contact = ""; // 联系人
      this.StkIn.PaidAmount = ""; // 实付金额
      this.StkIn.Address = ""; // 地址
      this.StkIn.Telephone = ""; // 联系电话
      this.StkIn.PaymentType = ""; // 付款方式
      this.StkIn.TaxRate = null; // 税点
      this.StkIn.Remark = ""; // 备注
      this.StkIn.DiscountAmount = null; // 折扣金额
      this.StkIn.IsAdd = true; // 是否新增
      this.StkIn.LineList = []; // 保存入库详情明细行
      this.StkIn.PDID = "";
      this.StkIn.CBGUID = ""; // 收款账号
      this.itemSource = [];
      this.StkIn.stkInGUID = "";
      this.StkIn.ReturnReason = ""; // 退货原因
    },

    // 单元格结束触发事件
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 修改的行
      if (!gridobj) return;
      let rowData = gridobj.row;

      // 数量
      let numbers = this.ConvertNumber(rowData.Qty);
      // 含税单价
      let price = this.ConvertNumber(rowData.Price);
      // 未税
      let priceNoTax = this.ConvertNumber(rowData.PriceNoTax);

      if (colname === "Price") {
        // 含税算未税
        // 由含税计算出未税价
        priceNoTax = this.CalcPrice(aftervalue);
        this.itemSource[rowindex]["Price"] = this.toFloat(aftervalue, 6);
        this.itemSource[rowindex]["PriceNoTax"] = priceNoTax;
      }
      if (colname === "PriceNoTax") {
        // 未税算含税
        // 由未税计算出含税价
        price = this.CalcPriceInTax(priceNoTax);
        this.itemSource[rowindex]["PriceNoTax"] = this.toFloat(aftervalue, 6);
        this.itemSource[rowindex]["Price"] = price;
      }
      if (colname === "Qty") {
        numbers = this.ConvertNumber(aftervalue);
      }
      let AmountSummary = this.CalcAmout(numbers, price);
      this.itemSource[rowindex]["Amount"] = this.toFloat(AmountSummary, 2);

      this.CalcPay(false);
    },

    SearchCompany() {
      // 弹出公司搜索页面
      this.InTypes = "2,3"; // 供应商和供应商客户
      this.dialogCompanySelectVisible = true;
      this.SelectSearchModel.CompanyName = this.StkIn.SupplierName;
    },

    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },

    onbuyerChange() {
      let buyer = _.find(this.selectOptionsAllUserURL, (item) => {
        return item.key === Number(this.StkIn.ReceiveUserID);
      });
      if (!_.isUndefined(buyer)) {
        this.StkIn.Receiver = buyer.value;
      }
    },

    onBillIconClick() {
      if (this.StkIn.IsAdd) {
        this.IsManual = !this.IsManual;
        if (!this.IsManual) {
          this.StkIn.BillNo = "";
        }
      } else {
        this.$message({ message: "单据编号生成后不可以修改！", type: "error" });
      }
    },

    autocompleteChange(val) {
      this.startTime = new Date();
      _.delay(() => {
        this.endTime = new Date();
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val);
        }
      }, 800);
    },

    autocompleteSearch(isSearch, data) {
      if (!isSearch) return;
      this.autocompleteSearchData = data;
      if (data.length > 0) {
        this.$refs.autocompleteSelect.$el.style.left =
          getabsleft(this.$refs.znlAutocomplete.$el) + 65 + "px";
        this.$refs.autocompleteSelect.$el.style.top =
          getabstop(this.$refs.znlAutocomplete.$el) + 30 + "px";
      } else {
        this.autocompleteShow = false;
      }
    },

    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("ordPurchaseReturn");
          }
        );
      }
    },
    // 根据采购单明细退货
    LoadPurchaseOrderLines: async function (guids) {
      let param = { POLineGuids: guids };
      await this.$post(CONFIG.ReturnByPOLineGuids, param, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({
            message: logic.msg || "加载明细失败，请关闭窗口重试",
            type: "error",
          });
        }

        let o = _.extend({}, defaultStkIn, data);
        o.IsReturn = true;
        o.ReceiveUserID = this.toInt(o.ReceiveUserID);
        this.$nextTick(function () {
          this.itemSource = o.LineList;
          this.onBindData();
          this.StkIn.SupplierName = data.SupplierName;
          this.CalcPay(true);
          o.IsAdd = false;
          o.isAllowStorge = o.Status !== 3; // 已入库的禁用
        });
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss">
</style>

