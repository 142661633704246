<template>
  <div class="znl-inquire-search">
    <znl-table
      ref="table"
      v-loading="loading"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :show-title-menus="true"
      :total-count="totalCount"
      box-class="znl-inquire-search"
      element-loading-text="数据加载中,请稍后..."
      gridtype="action"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      @dbclick="
        (e) => {
          $emit('dbl-click', e.rowData);
        }
      "
      @select-change="selectChange"
      @on-supplierName-click="onSupplierNameCellClick"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('Inquire')"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <!-- 型号 -->
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="onSearch()"
          type="text"
        />
        <znl-input
          form-type="select"
          placeholder="请选择查价人"
          width="100"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="selectOptionsAllUserURL"
          :border="true"
          clearable
          @change="
            (sel) => {
              searchFields.CreatedName = sel ? sel.value : '';
              onSearch();
            }
          "
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="供应商名称"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.SupplierName"
          @keyup.enter.native="onSearch()"
          type="text"
        />
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="询价时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.CreatedTimeStart"
        ></znl-input>
        <span style="font-size: small">到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="询价时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="searchFields.CreatedTimeEnd"
        ></znl-input>
        <el-checkbox v-model="searchFields.UnPriced">未报价</el-checkbox>
        <el-checkbox v-model="searchFields.IsAccept">已采纳</el-checkbox>
        <el-checkbox v-model="searchFields.IsGot">已拿货</el-checkbox>
        <znl-button
          type="multiple"
          :height="22"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="
              () => {
                this.isShowFiedsSearch = !this.isShowFiedsSearch;
              }
            "
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button
          :height="22"
          style-type="main"
          tip="新增"
          @click="$refs.enquiryView.onOpen({})"
        >
          新增
        </znl-button>
        <znl-button
          :height="22"
          style-type="main"
          tip="保存"
          class="save-self-btn"
          @click="saveInquires"
        >
          <i class="iconfont icon-save_btn_ic"></i>保存
        </znl-button>
        <znl-button
          :height="22"
          style-type="mac"
          tip="删除"
          @click="delInquires"
        >
          删除
        </znl-button>
      </div>
    </znl-table>
    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>
    <!-- 采购入库 -->
    <stk-in-edit
      v-if="stkInEditVisible"
      :editvisible="stkInEditVisible"
      edit-type="edit"
      @close="stkInEditVisible = false"
      @confirm="stkInEditVisible = false"
      @save-success=""
    />
    <!-- 新增客户需求 -->
    <znl-bom-master-edit
      title="新增客户需求"
      :editvisible="bomEditVisible"
      v-if="bomEditVisible"
      edit-type="addNew"
      :adddata="bomMasterDetail"
      @confirm="bomEditVisible = false"
      @close="bomEditVisible = false"
    />
  </div>
</template>

<script>
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import EnquiryView from "@c_modules/Enquiry/index"; //查看供应商
import StkInEdit from "@c_modules/Stk/StkInEdit"; //编辑入库单
import ZnlBomMasterEdit from "@c_modules/BOM/BomMasterEdit"; //编辑Bom
import data from "../../common/print/data";

const CONFIG = {
  configURL: "Inquire/GetConfig",
  searchURL: "Inquire/Search",
  // StockVMIAdd: "StockVMI/AddList",
  // saveURL: "InquireAdopt/SaveByAdoptQty", // 按采纳量采纳
  // saveSingleURL: "InquireAdopt/SaveByAdoptInfo" // 按单条询价采纳
};

export default {
  name: "InquireSearch",
  components: {
    EnquiryView,
    StkInEdit,
    ZnlBomMasterEdit,
  },
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,
  data() {
    return {
      loading: false,
      columns: [],
      itemSource: [],
      searchFields: {
        //查询条件
        Model: "", //型号
        CreatedByID: "", //查价人ID
        CreatedName: "", //查价人
        SupplierName: "", //供应商
        // CreatedTime: '', //查价时间
        CreatedTimeStart: "",
        CreatedTimeEnd: "",
        UnPriced: false, //未报价
        IsAccept: false, //已采纳
        IsGot: false, //已拿货
      },
      fieldsFilter: {},
      pageIndex: 1,
      totalCount: 0,
      pageSize: 30,
      isShowFiedsSearch: false, //字段过滤
      selectedRows: [], //已选行

      stkInEditVisible: false, //是否显示入库编辑窗口
      bomEditVisible: false, //是否显示bom编辑窗口
      bomMasterDetail: [], //bom列表

      inquiry: {
        // 询价参数传递
        SupplierName: "", // 供应商名称
        Model: "", // Model
        Brand: "",
        Packaging: "",
        MakeYear: "",
        Quality: "",
        DeliveryDate: "",
        InvQty: 0,
        Qty: 0,
        MPQ: "",
        Price: 0,
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    };
  },
  props: {
    initData: {
      //是否初始化
      type: Boolean,
      default: true,
    },
    height: {
      //高度
      type: String,
      default: () => {
        return "100%";
      },
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function,
    },
  },
  async mounted() {
    await this.onInit(); //初始化
  },
  watch: {
    //监视窗口高度并处理
    "$store.state.domRect.contentHeightWithoutTab": {
      handler: function (val, oldVal) {
        if (this.$refs.table) this.$refs.table.init(); //ZnlTable初始化
      },
    },
  },
  methods: {
    async onInit(config) {
      this.loading = true;
      //载入表头设置
      config = await this.isSaveStorage("Inquire", CONFIG.configURL);
      //增加固定的列
      let tempConfig = [
        //操作列
        // {
        //   width: 34,
        //   type: 'menus',
        //   title: '操作',
        //   btnTxt: '',
        //   visible: true,
        //   isDeal: false,
        //   config: mouseBtn,
        //   isFrozen: true
        // }
      ];
      // if (this.hasRes('StkOutAdd')) {
      tempConfig.push({
        width: 28,
        type: "button",
        title: "采",
        btnTxt: "采",
        isFrozen: true,
        click: (row) => {
          this.onAccept(row);
        },
      });
      // }
      // if (this.hasRes('StkOutAdd')) {
      tempConfig.push({
        width: 28,
        type: "button",
        title: "报",
        btnTxt: "报",
        isFrozen: true,
        click: (row) => {
          this.onQuote(row);
        },
      });
      // }

      let columns = config.ColumnConfigs
        ? tempConfig.concat(config.ColumnConfigs)
        : tempConfig;

      // 表格值的点击 start
      let index = _.findIndex(
        columns,
        (item) => item.BindField === "SupplierName"
      );
      if (index > -1) {
        _.extend(columns[index], {
          IsReadonly: true, //只读
          event: "on-supplierName-click",
          className: "v-cell-link",
        });
      }

      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      // let guid = null;
      // if (this.sourceType === 1) {
      //   guid = this.searchModel.BDLineGUID;
      // } else if (this.sourceType === 2) {
      //   guid = this.searchModel.ASGUID;
      // } else {
      //   guid = this.searchModel.PNGUID;
      // }

      let filterLast = _.extend({}, this.fieldsFilter, {
        // Brand: this.searchModel.Brand,
        // Packaging: this.searchModel.Packaging,
        // CustomerName: this.searchModel.CustomerName
      });
      // const {Keywords, KeywordsField} = this.searchModel;
      // if (Keywords && KeywordsField) {
      //   filterLast[KeywordsField] = Keywords;
      // }
      let searchFields = { ...this.searchFields };
      if (!searchFields.UnPriced)
        searchFields = _.omit(searchFields, ["UnPriced"]);
      if (!searchFields.IsAccept)
        searchFields = _.omit(searchFields, ["IsAccept"]);
      if (!searchFields.IsGot) searchFields = _.omit(searchFields, ["IsGot"]);

      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          // Model: this.inputModel,
          // IsAnalyzer: false,
          // SourceType: this.sourceType,
          // // 需求来源GUID  1.需求明细 2.询价通知明细 3.采购通知明细
          // SourceGUID: guid
        }
      );
      console.log("查询条件：", data);

      return await this.$post(CONFIG.searchURL, data, (data) => {
        _.each(data.ResultList, (item) => {
          // 给AdoptQty赋初始值 避免采纳时获取不到采纳数量 。
          if (item.AdoptQty === undefined) {
            item.AdoptQty = null;
          }
        });
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("set-total-count", this.totalCount);
        console.log(data);
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
        this.$emit("grid-show", true);
      });
    },
    createdNameChange(sel) {
      console.log(sel);
    },
    onSupplierNameCellClick(e) {
      if (e.field !== "SupplierName") {
        return;
      }
      if (this.hasValue(e.row.SupplierName)) {
        let row = e.row;
        console.log("点击供应商...", row);
        // this.onStkVMIInquiry([row])
        this.$refs.enquiryView.onOpen(row);
      }
    },
    async onAccept(row) {
      //采购
      // console.log('采...', row);
      this.onStkIn([row]);
    },
    onStkIn(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = [];
      }
      let rfq = { RFQ_InquireModel: rows };
      this.$store.commit("setParamOrder", rfq);
      this.$nextTick(function () {
        this.stkInEditVisible = true;
      });
    },
    async onQuote(row) {
      //报价
      this.onBomLine([row]);
    },
    onBomLine(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = [];
      }
      this.bomEditVisible = true;
      this.$nextTick(() => {
        this.bomMasterDetail = rows;
      });
    },
    async selectChange(rows) {
      this.selectedRows = rows;
    },
    //保存询价列表
    async saveInquires() {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        await this.$post("Inquire/EditList", rows, (data, logic) => {
          if (logic.code === 200) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.$refs.table.cleanEdit();
            this.onBindData();
          } else {
            return this.$message({ message: logic.msg, type: "error" });
          }
        });
      } else {
        this.$message({
          message: "没有需要保存的记录。",
          type: "warning",
        });
      }
    },
    //删除询价
    async delInquires() {
      // let sel = this.$refs.table.getCheckedRows();
      // getActiveRow
      // getChangedData
      // console.log(this.$refs.table.getCheckedRows(),
      //   this.$refs.table.getActiveRow(),
      //   this.$refs.table.getChangedData(),
      // );
      if (this.selectedRows.length > 0) {
        try {
          await this.$confirm("您确定要删除此询价记录吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            // dangerouslyUseHTMLString: true
          });
          //删除
          await this.$post(
            "Inquire/Delete",
            this.selectedRows.map((item) => item.IQGUID),
            (data, logic) => {
              if (logic.code === 200) {
                this.$refs.table.cleanAllCheck();
                this.selectedRows = [];
                this.$message({
                  message: "删除成功！",
                  type: "success",
                });
                this.onBindData();
              } else {
                return this.$message({ message: logic.msg, type: "error" });
              }
            }
          );
        } catch {}
      } else {
        this.$message({
          message: "请至少选择一个询价！",
          type: "warning",
        });
      }
    },
    //询价查看窗口保存后
    async onConfirm(data) {
      console.log("弹出窗口保存了...", data);
      this.onBindData();
    },

    //#region 表格设置的事件
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("Inquire", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("Inquire", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("Inquire", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("Inquire");
    },
    //#endregion
  },
};
</script>

<style lang="scss" scoped>
.znl-inquire-search {
  .v-table-toolbar {
    padding: 4px;
  }
}
</style>
