<template>
<znl-dialog
  :visible="visible"
  width='800px'
  height='500px'
  class="dialog-log-grid-box dialog-grid-footer-box m-operate-log"
  title="操作日志"
  :footer-height="0"
  :is-footer-show="false"
  @close="()=>{$emit('close')}">
 <znl-gridmodule
    ref="flexGrid"
    gridtype="base"
    :columns="columns"
    height="100%"
    :max-height="460"
    :grid-show="gridShow"
    :has-znl-actions="false"
    :has-znl-btns='false'
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-multi-rows-check="false"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :users="$store.state.users"
    :on-init="onInit"
    :is-init="true"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-before-add-row="onBeforeAddRow"
    :on-page-changed="onBindData"
    @page-loading="d=>{$emit('page-loading', d)}"
    @selection-changed="d=>{$emit('selection-changed',d)}">
  </znl-gridmodule>
</znl-dialog>
</template>
<script>
import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'OperateLog/GetConfig',
  saveConfigURL: 'OperateLog/SaveConfig',
  resetConfigURL: 'OperateLog/ResetConfig',
  searchURL: 'OperateLog/GetLog'
}

export default {
  name: 'OperateLog',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      fieldsFilter: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {
          OrderGuid: ''
        }
      }
    },
    height: String,
    orderGuid: String,
    refreshLog: Boolean,
    visible: Boolean
  },
  watch: {
    refreshLog: {
      handler (value) {
        if (!_.isEmpty(this.orderGuid)) {
          this.onBindData()
        }
      },
      immediate: true
    },
    orderGuid: {
      handler (value) {
        if (!_.isEmpty(value)) {
          this.onBindData()
        }
      },
      immediate: true
    }
  },
  methods: {
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        OrderGuid: this.orderGuid
      }, {FieldWhereString: JSON.stringify(this.searchFields), OrderGuid: this.orderGuid})
      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
        this.gridShow = true
      })
    }
  }
}
</script>

<style lang="scss">
.m-operate-log{
  .el-pagination--small {
    height: auto !important;
  }
}
</style>

