<template>
  <div>
  <znl-dialog
    :visible="visible"
    title="取消未出数量"
    width="730"
    height="600"
    class="znl-dialog-cancel-order"
    :close-on-click-modal="false"
    :is-footer-show="true"
    :showCancelButton="false"
    :showConfirmButton="false"
    :append-to-body="true"
    @close="doClose()"
    >
    <znl-gridmodule
      gridtype="action"
      ref="flexGrid"
      :table-handle="false"
      height="100%"
      layout-type="other"
      element-loading-text="正在保存,请稍等..."
      class="cancel-order-qty-box"
      :has-znl-btns="false"
      :from-height="260"
      :from-btns="[]"
      :columns="columns"
      :item-source="itemSource"
      :on-init="onInit"
      :loading="loading"
      :users="$store.state.users"
      @edit:cell-ended="onCellEdited"
    >
      <div slot="from-module">
        <el-row
          type="flex"
          justify="space-around"
        >
          <el-col :span="24" class="min-preboderleftnone-box">
            <el-row class="form_Row_W100" type="flex">
              <el-col>
                <znl-input
                  title-width="70px"
                  form-type="input"
                  custom-item="my-item-zh"
                  ref="Customerbinding"
                  size="mini"
                  layout="left"
                  title="客户名称："
                  width="100%"
                  tabindex="1"
                  v-model="orderInfo.CustomerName"
                  :disabled="true"
                  type="text"
                >
                </znl-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="space-around"
        >
          <el-col :span="12">
            <znl-input
              title-width="70px"
              form-type="input"
              custom-item="my-item-zh"
              ref="BillNo"
              size="mini"
              layout="left"
              title="单号："
              width="200"
              tabindex="1"
              v-model="orderInfo.BillNo"
              type="text"
              :disabled="true"
            >
            </znl-input>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-checkbox
              size="small"
              title="是否显示出该销售单所有的销售明细"
              @change="onShowAllLineChanged()"
              v-model="showAllLine"
              style="margin-right: 8px !important;"
            >
            显示所有明细
            </el-checkbox>

            <znl-button :height="22" @click="onSave(false)" style-type="minor" :disabled="isEmpty(this.itemSource)">
              <i class="iconfont icon-save_btn_ic"></i>
              <span>保存</span>
            </znl-button>

            <znl-button :height="22" @click="onSave(true)" style-type="main" :disabled="isEmpty(this.itemSource)">
              <i class="iconfont icon-save_btn_ic"></i>
              <span>保存并关闭</span>
            </znl-button>
          </el-col>
        </el-row>
      </div>
    </znl-gridmodule>
    <!-- 底部 -->
    <div slot="footer" class="my-footer">
      说明：当您的订单还有未出库的数量，但是这些库存有不需要再进行出库的时候，可以在这里输入您要取消的数量。保存后，此销售单的单据状态将会重新计算。<span style="color:red">已备货的数量要先取消备货，才能进行取消</span>
    </div>
  </znl-dialog>
</div>
</template>

<script>
const CONFIG = {
  getUrl: "SalesOrderLine/GetCancelQtyResult",
  saveUrl: "SalesOrderLine/SaveCancelQty",
}

export default {
  name: "CancelOrderLineQty",
  mixins: [],
  config: CONFIG,
  data() {
    return {
      orderInfo: {
        CustomerName: "",
        BillNo: "",
        LineList: [
          // {Model: "LM358",Brand: "TI",Qty: 100,OutQty: 50,CancelQty: null,}
        ]
      },
      itemSource: [],
      columns: [
        {
          header: "型号",
          binding: "Model",
          name: "Model",
          isReadOnly: true,
          width: 180,
          dataType: 1,
          visible: true,
          align: "left",
        },
        {
          header: "品牌",
          binding: "Brand",
          name: "Brand",
          isReadOnly: true,
          width: 100,
          dataType: 1,
          visible: true,
          className: ""
        },
        {
          header: "订单数量",
          binding: "Qty",
          name: "Qty",
          isReadOnly: true,
          width: 80,
          dataType: 2,
          visible: true,
          className: "number-left"
        },
        {
          header: "已出库数量",
          binding: "OutQty",
          name: "OutQty",
          isReadOnly: true,
          width: 80,
          dataType: 2,
          visible: true,
          className: "number-left"
        },
        {
          header: "已备货数量",
          binding: "ReservedQty",
          name: "ReservedQty",
          isReadOnly: true,
          width: 80,
          dataType: 2,
          visible: true,
          className: "number-left"
        },
        {
          header: "可取消数量",
          binding: "CancelableQty",
          name: "CancelableQty",
          isReadOnly: true,
          width: 80,
          dataType: 2,
          visible: true,
          className: "number-left"
        },
        {
          header: "取消数量",
          binding: "CancelQty",
          name: "CancelQty",
          isReadOnly: false,
          width: 80,
          dataType: 2,
          visible: true,
          className: "number-left"
        },
      ],
      autocompleteShow: false,
      loading: false,
      showAllLine: false,
      isSaved: false,
    }
  },
  props: {
    visible: Boolean,
    salesOrderLineGuid: {
      type: Array,
      default: () => {
        return [];
        // return ["152c65bd-e6d2-4a76-98cf-60a5aae2bb51", "33c1e255-a1a0-4d07-974e-dca66a8cb0a7"]
      }
    }
  },
  methods: {
    async onInit() {
      // setTimeout(() => {
      //   this.itemSource = this.orderInfo.LineList;
      // }, 100);
      this.getAndBindData();
    },
    async getAndBindData () {
      if (this.isEmpty(this.salesOrderLineGuid)) {
        this.showError("参数有误，请重试");
        return false;
      }
      try {
        await this.$post(CONFIG.getUrl, {SOLineGuidList: this.salesOrderLineGuid}, (data, logic) => {
          if (logic.code != 200) {
            this.showError(logic.msg || "获取单据数据失败，请重试");
            return false;
          }
          if (data == null) {
            this.showError("获取不到单据数据，请重试");
            return false;
          }
          this.orderInfo = data;
          this.bindData();
        });
      } catch (e) {
        console.log(e, '异常');
        this.showError(e.message);
      }
    },
    bindData () {
      let list = [];
      for (let i = 0; i < this.orderInfo.LineList.length; i++) {
        let item = this.orderInfo.LineList[i];
        if (this.showAllLine || item.CancelableQty > 0) {
          list.push(item);
        }
      }
      this.itemSource = list;
    },

    // 单元格编辑
    onCellEdited (afterValue, beforeValue, colName, gridObj) {
      var row = gridObj.row;
      if (colName == "CancelQty") {
        if (afterValue > row.CancelableQty) {
          this.$message({
            message: `取消数量${afterValue}超出可取消数量${row.CancelableQty}，已修改为${row.CancelableQty}`,
            type: 'warning'
          });
          row.CancelQty = row.CancelableQty;
        }
      }
    },

    // 保存
    async onSave (isClose) {
      var canSave = false;
      for (let i = 0; i < this.itemSource.length; i++) {
        var item = this.itemSource[i];
        if (item.CancelQty > 0) {
          canSave = true;
          break;
        }
      }

      if (!canSave) {
        this.showError("请输入取消数量");
        return false;
      }

      try {
        let vm = this;
        await this.$post(CONFIG.saveUrl, this.orderInfo, (data, logic) => {
          if (logic.code != 200) {
            vm.showError(logic.msg || "保存失败");
          } else if(data == null || data <= 0) {
            vm.showError("取消失败，请重试");
          }
          else {
            vm.showSuccess("保存成功");
            vm.isSaved = true;
            if (isClose) {
              vm.doClose();
            } else {
              vm.getAndBindData();
            }
          }
        });
      } catch (error) {
        this.showError("保存发生异常。" + error.message);
      }
      this.loading = false;
    },
    onShowAllLineChanged () {
      this.bindData();
    },
    doClose () {
      this.$emit('close', this.isSaved);
    },
    isEmpty (array) {
      return array === null || array === undefined || array.length === 0;
    }
  },
  mounted() {}
}
</script>

<style lang="scss">
.znl-dialog-cancel-order{
  .znl-dialog__footer{
    text-align: left;
  }

  .my-footer {
    text-align: left;
    line-height: 20px;
    margin-top: 10px;
  }

  .cancel-order-qty-box{
    font-size: 12px;
    height: 100%;
    .el-input--mini .el-input__inner, .el-input--mini .el-input__inner:hover{
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
    .znl-frombtns {
      display: none;
    }
    .number-left {
      text-align: left;
    }
    .el-input.is-disabled .el-input__inner {
      cursor: default;
      background-color: #fff;
    }
  }
}

</style>
