<template>
    <znl-lists
      class="bommaster-lists"
      :on-init="onInit"
      :is-init="false"
      :columns="columns"
      :item-source="itemSource"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      footer-position="right"
      pagination-position="top"
      list-tip='可拖放到左边BOM文件夹'
      :on-page-changed="onBindData"
      :btn-mouse-enter-menus="btnMouseEnterMenus"
      :is-fields-search="false"
      :is-multi-rows-check="false"
      @cell-click="onCellClick">

        <bom-master-edit
          title="编辑客户需求"
          :editvisible="bomMasterDetailEditVisibleTwo"
          edit-type="edit"
          v-if="bomMasterDetailEditVisibleTwo"
          @confirm="bomMasterDetailEditVisibleTwo=false"
          @close="bomMasterDetailEditVisibleTwo=false, onBindData(1)">
        </bom-master-edit>

        <wait-noticed-bom
          :visible='waitNoticedBomVisible'
          v-if="waitNoticedBomVisible"
          :bomLineGuids="bdLinedGuidsStr"
          :bomGuid="BomGuid"
          @close="()=>{waitNoticedBomVisible=false}"
          @confirm="(val)=>{val&&(waitNoticedBomVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}">
        </wait-noticed-bom>

    </znl-lists>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import BomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import WaitNoticedBom from '@c_modules/BOM/BomWaitNoticed'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  configURL: 'BomMaster/GetConfig',
  saveConfigURL: 'BomMaster/SaveConfig',
  resetConfigURL: 'BomMaster/ResetConfig',
  searchURL: 'BomMaster/Search',
  deleteUrl: 'BomMaster/DeleteList',
  completeUrl: 'BomMaster/Complete',
  configURL_detailLine: 'BomDetailLine/GetConfig'
}

export default {
  name: 'BomMaster',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: { BomMasterEdit, WaitNoticedBom },
  data() {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      currentItem: [],
      Size: 5,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loadingInstance: {},
      command: '',
      gridShow: false,
      bomMasterDetailEditVisibleTwo: false,
      btnMouseEnterMenus: {
        List: []
      },
      BomGuid: '',
      bdLinedGuidsStr: '',
      waitNoticedBomVisible: false,
      detailLineResourceList: [] // 需求物料模块权限
    }
  },
  computed: {
    pageSize: {
      get() {
        return this.Size
      },
      set(val) {
        this.Size = val
      }
    }
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    isActivity: Boolean,
    header: {
      type: String,
      default: () => {
        return ''
      }
    },
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
      {
        type: Object,
        default: () => {
          return {}
        }
      },
    height: String,
    fieldsFilter: Object,
    folderId: {
      type: Number,
      default: () => {
        return 0
      }
    },
    bomConfig: Object
  },
  watch: {
    folderId(val) {
      this.onSearch()
    }
  },
  methods: {
    async onInit(config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage('BomMaster', this.$options.config.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      _.extend(_.find(columns, (item) => { return item.binding === 'BFileName' }), {
        buttons: (row, col) => {
          return [{
            icon: row[col.binding] ? '<i class="iconfont icon-excel_ic"></i>' : '',
            name: row[col.binding],
            class: 'downLoad-btn',
            click: (row, col) => {
              this.ondownload(row)
            }
          }]
        }
      })
      _.each(columns, item => {
        if (item.binding === 'CopyNumber') {
          item.element = (item) => {
            if (!_.isUndefined(item) && !_.isNull(item)) {
              return '<span style="color:#0B62EF">' + item + '</span>'
            }
          }
        }
        if (item.binding === 'QuotePriceText' || item.binding === 'BuyerPriceText') {
          item.element = (item) => {
            if (!_.isUndefined(item) && !_.isNull(item)) {
              return '<span style="color:red">' + this.toFloat(item, 6) + '</span>'
            }
          }
        }
      })
      _.extend(
        _.find(columns, (column) => {
          return column.binding === 'IsUrgent'
        }),
        {
          isCheckedColumn: true,
          isReadOnly: true
        }
      )

      this.btnMouseEnterMenus = {
        List: [
          {
            name: '修改',
            isShow: this.hasRes('AddBom'),
            iconName: 'icon-bomlist_edit_btn',
            click: (rows) => {
              this.onUpdate(rows)
            }
          },
          {
            name: '删除',
            isShow: this.hasRes('deleteBom'),
            iconName: 'icon-delete_btn_ic',
            click: (rows) => {
              this.onDelete(rows)
            }
          },
          {
            name: '标记完成',
            iconName: 'icon-tag_btn_ic',
            isShow: this.hasRes('SetCompleted'),
            click: (rows) => {
              this.onComplete(rows)
            }
          },
          {
            name: '下载文件',
            iconName: 'icon-uploading-wh_btn_ic',
            isShow: this.hasRes('DownloadFile'),
            click: (rows) => {
              this.ondownload(rows)
            }
          }
        ],
        Binding: 'operate'      }

      columns.unshift({
        'name': 'operate',
        'dataType': 5,
        'binding': 'operate',
        'width': 36,
        'header': '操作',
        'visible': true,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'className': 'znlerp-operate',
        'buttons': [{
          icon: '<i class="iconfont icon-left_nav_show_btn"></i>',
          click: () => {
          }
        }]
      })

      this.columns = columns
      this.role = config.Role
      this.pageSize = this.pageSize
      this.PageIndex = config.PageIndex
      if (this.initData || refresh) {
        this.onBindData()
      }
    },
    editBomMaster() {
      this.$nextTick(function () {
        this.bomMasterDetailEditVisible = false
        this.bomMasterDetailEditVisibleTwo = true
      })
    },
    onSelectionChange(currentItem) {
      this.currentItem = currentItem
    },
    async onBindData(pageIndex = 1) {
      this.$emit('model-loading', true)
      let filterFields = {
        // 外部搜索条件
        Keyword: this.fieldsFilter.Keyword,
        FolderId: this.folderId
      }
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[key.substring(0, 1).toUpperCase() + key.substring(1)] = value
        })
      }
      let response = await this.$post(this.$options.config.searchURL, _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, filterFields))
      _.delay(() => {
        this.$emit('model-loading', false)
      }, 200)
      this.pageIndex = pageIndex
      this.itemSource = response.ResultList
      this.totalCount = response.TotalCount
      this.checkedRows = []
      this.checkedRowsCount = 0
      this.$emit('page-loading', false)
      this.gridShow = true
    },
    async onSearch(params = {}) {
      this.searchFields = {}
      _.extend(this.searchFields, params)
      await this.onBindData(1)
      this.checkedRows = []
      this.checkedRowsCount = 0
    },
    // 删除
    onDelete(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: '请选择要操作的行', type: 'warning' })
      }
      this.$confirm('确定要删除当前BOM订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let guids = []
        guids.push(rows.BOMGUID)
        this.$post(CONFIG.deleteUrl, { ListBomGuid: guids, QuoteStatus: rows.QuoteStatus }, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '操作成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
      })
    },
    onInquire: async function (row) {
      this.$nextTick(function () {
        if (!_.isUndefined(row)) {
          // this.onUpdateRow(row)
          this.onBindData()
        }
      })
    },
    // 修改
    onUpdate(rows) {
      let order = { BOMGUID: rows.BOMGUID }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.bomMasterDetailEditVisibleTwo = true
      })
    },
    // 通知
    onNoticedBom(rows) {
      if (rows === undefined || rows.length <= 0) {
        return this.$message({ message: '请勾选要通知查价的需求订单', type: 'warning' })
      }
      this.BomGuid = rows.BOMGUID
      this.waitNoticedBomVisible = true
    },
    // 标记完成
    onComplete(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: '请选择要操作的行', type: 'warning' })
      }
      this.$confirm('确定要将当前BOM订单标记完成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let guids = []
        guids.push(rows.BOMGUID)
        this.$post(CONFIG.completeUrl, { ListBomGuid: guids }, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '操作成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
      })
    },
    ondownload(rows) {
      if (_.isUndefined(rows)) {
        this.$message({ message: '请选择要操作的行', type: 'warning' })
      }
      if (rows.BomUrl !== null && rows.BomUrl !== undefined && rows.BomUrl !== '') {
        this.downFile({ FileName: rows.BFileName, FileUrl: 'http:' + rows.BomUrl },
          function (res) {
          })
      } else {
        this.$message({ message: '您尚未上传BOM文件,不能操作此项', type: 'error' })
      }
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem('BomMaster')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting() {
      localStorage.removeItem('BomMaster')
      await this.$post(this.$options.config.resetConfigURL)
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex)
    },
    hasRes(code) {
      return _.some(this.resourceList, page => page === code)
    },
    hasResDetail(code) {
      return _.some(this.detailLineResourceList, page => page === code)
    },
    QuotePriceText: async function (row) {
      this.$nextTick(function () {
        if (!_.isUndefined(row)) {
          this.onUpdateRow(row)
        }
      })
    },
    onUpdateRow(row) {
      let rowIndex
      _.each(this.itemSource, (item, index) => {
        if (item.BOMGUID === this.currentItem.BOMGUID) {
          rowIndex = index
        }
      })
      this.itemSource[rowIndex].QuotePriceText = this.toFloat(row.QuotePriceText, 6)
      this.itemSource[rowIndex].QuoteStatus = row.QuoteStatus
      this.itemSource[rowIndex].BuyerPriceText = this.toFloat(row.BuyerPriceText, 6)
      this.itemSource[rowIndex].GrossInterestRate = row.GrossInterestRate
    },
    onCellClick(row, column, cell, event) {
      if (column.columnKey === 'BomName' || column.columnKey === 'CustomerName' || column.columnKey === 'BillNo') {
        this.$emit('cell-click', row)
      }
    },
  }
}
</script>

<style lang="scss">
.bommaster-lists {
  min-height: 100px;
  .znl-lists-wrapper {
    height: 142px;
    .znl-list-body {
      height: 107px;
      background-color: #fff;
    }
  }
  .znl-list-footer.erp_list_footer .znl-pagination .el-pager li.active,
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    line-height: 22px !important;
  }

  .downLoad-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    .iconfont {
      margin-right: 3px;
    }
  }
  .erp_list_row {
    cursor: -webkit-grab !important;
  }
}
.znlerp-operate {
  .cell {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .el-button {
    border: none;
    padding: 0;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    .iconfont {
      color: #ed9e00;
    }
  }
}
</style>

