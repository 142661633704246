<template>
  <div class="global-cargo-content">
    <!-- <img :src="globalCargoPng" class="globalCargo" @click="onGlobalCargo()" />
    <img
      :src="globalCargoHelpPng"
      class="globalCargoHelp"
      title="什么是【AI全球找料】服务？
“BOM.AI·让天下没有难找的物料”！
正能量电子网基于电子元器件行业大数据，提供的一项全球找料服务。您仅
需要支付1分钱（最低）即可体验AI时代超级找货能力"
    />

    <GlobalCargoDialog :visible.sync="dialogVisible"></GlobalCargoDialog> -->
  </div>
</template>

<script>
import { isClient } from "~/lib/runtime";
import GlobalCargoDialog from "./global-cargo-dialog.vue";

export default {
  name: "global-cargo",
  components: {
    GlobalCargoDialog,
  },
  data() {
    return {
      globalCargoPng: require("@/assets/images/globalCargo.png"),
      globalCargoHelpPng: require("@/assets/images/globalCargo_help.png"),
      dialogVisible: false,
    };
  },
  methods: {
    //ai全球找料
    onGlobalCargo() {
      if (isClient()) {
        this.globalCargo();
      } else {
        // 不用弹窗，打开浏览器新页签即可
        // this.dialogVisible = true;

        let url = "https://mm.bom.ai:8080/#/GlobalCargo";
        if (process.env.NODE_ENV !== "production")
          url = "http://test.bom.ai:8080/#/GlobalCargo";
        window.open(url);
      }
    },
  },
};
</script>

<style>
.global-cargo-content {
  height: 22px;
  display: inline-block;
}
.globalCargo {
  cursor: pointer;
}

.globalCargoHelp {
  cursor: pointer;
  margin-left: 2px;
}
</style>
