var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('znl-layout-multiple',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],ref:"layout",staticClass:"p-bom-manage",attrs:{"element-loading-text":"页面加载中,请稍后..."}},[_c('Split',{staticClass:"split",style:({height:_vm.modelContentHeight.pageContent+'px'}),attrs:{"direction":"vertical"},on:{"onDragEnd":_vm.onDragEndBySales}},[_c('SplitArea',{attrs:{"size":20.5,"minSize":20.5}},[_c('multiple',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],staticStyle:{"height":"100%"},attrs:{"name":"BomMaster"}},[_c('znl-bom-manage-module',{ref:"BomMaster",staticClass:"BomManage",class:_vm.multiplerows[0].isHide ? 'BomManage_hide' : 'BomManage_show',attrs:{"is-activity":true},on:{"itemSourceChange":_vm.itemSourceChange,"page-loading":function (d) {
          _vm.pageLoadingMaster = d;
        },"cell-click":_vm.onBomMasterCellClick}}),(_vm.isErpClient)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],staticClass:"old-edition",staticStyle:{"right":"150px","top":"15px"},on:{"click":function($event){return _vm.oldEdition()}}},[_vm._v("切换到旧版")]):_vm._e()],1)],1),_c('SplitArea',{attrs:{"size":45.5,"minSize":45.5}},[_c('multiple',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],staticStyle:{"height":"100%"},attrs:{"name":"BomDetailLine"}},[_c('znl-bom-detail-line',{ref:"BomDetailLine",staticClass:"BomManage",class:_vm.multiplerows[1].isHide ? 'BomManage_hide' : 'BomManage_show',attrs:{"is-activity":true,"is-mini":true,"is-analyzer":true,"searchModel":_vm.bomMasterLine.searchModel,"searchInput":_vm.bomMasterLine.searchInput},on:{"callEvent":_vm.callEvent,"page-loading":function (d) {
          _vm.pageLoadingDetail = d;
        },"update:searchModel":function($event){return _vm.$set(_vm.bomMasterLine, "searchModel", $event)},"update:search-model":function($event){return _vm.$set(_vm.bomMasterLine, "searchModel", $event)},"AdoptEnd":_vm.onAdoptEnd,"cell-click":_vm.onBomLineCellClick,"save-success":function (d) {
          _vm.saveSuccess(d);
        }}})],1)],1),_c('SplitArea',{attrs:{"size":33,"minSize":33}},[_c('multiple',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],staticStyle:{"height":"100%"},attrs:{"name":"BomTabMultiple"}},[_c('el-tabs',{ref:"Tabs",staticClass:"tab-btnstyle-box nav-common-ui",attrs:{"name":"Tabs"},on:{"tab-click":_vm.pageSearch},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.hasRight('StockQuery'))?_c('el-tab-pane',{attrs:{"label":"我的库存 (按型号)","name":"StockQuery"}},[_c('znl-stock',{ref:"StockQuery",staticClass:"BomManage",class:_vm.multiplerows[7].isHide ? 'BomManage_hide' : 'BomManage_show',attrs:{"is-fields-search":false,"init-data":false,"is-adopt":true,"searchModel":_vm.stockSearchModel,"height":"100%"},on:{"save-success":function (val) {
              _vm.emitDatas(val);
            },"switch-to-hide":function (val) {
              _vm.switchToHide(val, 7);
            },"ChkStkGUID":function (val) {
              this$1.onChkStkGUID(val);
            }}})],1):_vm._e(),(_vm.hasRight('StockVMIMini'))?_c('el-tab-pane',{attrs:{"label":"供应商库存 (按型号)","name":"StockVMIMini"}},[(_vm.stockVMIMiniInitData)?_c('stock-vmi',{ref:"stockVMIMini",attrs:{"gridtype":"base","is-operate":false,"is-adopt":true},on:{"onStockVMIAdopt":function (val) { return _vm.emitDatas(val); }}}):_vm._e()],1):_vm._e(),(_vm.hasRight('StkInquireRecord'))?_c('el-tab-pane',{attrs:{"label":"询价记录 (按型号)","name":"StkInquireRecord","height":"100%"}},[_c('znl-stk-inquire-record',{ref:"StkInquireRecord",staticClass:"BomManage",class:_vm.multiplerows[6].isHide ? 'BomManage_hide' : 'BomManage_show',attrs:{"height":"220px","header":"询价记录","is-fields-search":false,"is-mini":true,"source-type":1,"has-operation-column":false,"is-multi-rows-check":false,"is-init":false,"is-adopt":true,"searchModel":_vm.inquireSearchModel},on:{"switch-to-hide":function (val) {
              _vm.switchToHide(val, 6);
            },"save-success":function (val) {
              this$1.emitDatas(val);
            }}})],1):_vm._e(),(_vm.hasRight('PastStkOut'))?_c('el-tab-pane',{attrs:{"label":"出库记录 (按型号)","name":"PastStkOut","height":"100%"}},[(_vm.stkOutDetailsInitData)?_c('stk-out-details',{ref:"PastStkOut",attrs:{"is-operate":false}}):_vm._e()],1):_vm._e(),(_vm.hasRight('BomMastLineSelect'))?_c('el-tab-pane',{attrs:{"label":"报价记录 (按型号)","name":"BomMastLineSelect","height":"100%"}},[(_vm.bomDetailsInitData)?_c('bom-quote-details',{ref:"BomMastLineSelect",attrs:{"is-show-header":false,"is-show-operate-col":false}}):_vm._e()],1):_vm._e(),(_vm.hasRight('StockQuery'))?_c('el-tab-pane',{attrs:{"label":"采购报价反馈 (按当前需求)","name":"BomAssistQuoted","height":"100%"}},[(_vm.purchaseQuoteInitData)?_c('sts-purchase-quote',{ref:"BomAssistQuoted",staticClass:"sts-purchase-quote",staticStyle:{"height":"100%"},attrs:{"is-search":false}}):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }