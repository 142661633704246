var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('znl-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dialog-sales-order-reserve-add-box",attrs:{"close-on-click-modal":false,"element-loading-text":_vm.loadingText,"is-footer-show":false,"visible":_vm.editvisible,"height":"90vh","title":"销售备货","width":"90vw"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('znl-layout-multiple',{staticClass:"m-multiple-box"},[_c('Split',{staticClass:"split-content",attrs:{"direction":"vertical"},on:{"onDragEnd":_vm.onDragEndBySales}},[_c('SplitArea',{staticClass:"sales-order-line",attrs:{"size":33}},[_c('znl-sales-order-line-reserve',{ref:"salesOrderLine",attrs:{"searchModel":_vm.salesOrderLineSearchModel,"header":"待备货物料"},on:{"selection-changed":_vm.salesOrderLineChange,"on-submit-reserve":function (val, callback) {
                this$1.onSubmitReserve(val, callback);
              },"on-automatch":function (val) {
                this$1.onAutomatch(val);
              },"page-loading":function (d) {
                this$1.loading = d;
              }}})],1),_c('SplitArea',{staticClass:"znl-multiple-stock-select",attrs:{"size":33}},[_c('stock-select',{ref:"stockSelect",attrs:{"height":220,"init-data":false,"is-init":true,"searchModel":_vm.stockSearchModel,"header":"批次库存","page-position":"top"},on:{"update:searchModel":function($event){_vm.stockSearchModel=$event},"update:search-model":function($event){_vm.stockSearchModel=$event},"stock-check-row":function (val) {
                this$1.StockCheckRow(val);
              }}})],1),_c('SplitArea',{staticClass:"sale-order-reserve-stock",attrs:{"size":33}},[_c('znl-sale-order-reserve-stock',{ref:"saleOrderReserveStock",attrs:{"init-data":false,"is-init":true,"searchModel":_vm.stockReserveSearchModel,"SOGuid":_vm.SOGuid,"initData":true,"page-position":"top"},on:{"close":function (val) {
                this$1.onClose(val);
              },"loading":function (val) {
                this$1.saleOrderReserveStockResetTableH();
                this$1.loading = val;
              },"cell-click":_vm.onReserveStockCellClick,"selected-stock-Line":function (val) {
                this$1.TotalSelectStockQty(val);
              },"auto-change-Qty":function (val) {
                this$1.autoChangeQty(val);
              },"on-changed-qty":function (solineGUID, qty) {
                this$1.changeQty(solineGUID, qty);
              }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }