<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('BomWaitToInquire')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar" v-if="this.isSearch">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="通知时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.CreateTimeStart"
        ></znl-input>
        <span style="font-size:small">到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="通知时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="searchFields.CreateTimeEnd"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="需求型号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="onBindData()"
          type="text"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="需求单号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.BillNo"
          @keyup.enter.native="onBindData()"
          type="text"
        ></znl-input>
        <znl-input
          layout="left"
          form-type="select"
          :disabled="false"
          :border="true"
          width="78px"
          :height="22"
          placeholder="选择状态"
          popper-class="popperClass"
          :clearable="false"
          :multiple="true"
          :tags-clear="false"
          :select-options="selectOptions"
          @change="onChangeStatus"
          v-model="checkStatus"
        ></znl-input>
        <znl-input
          :border="true"
          v-model="searchFields.BuyEmpName"
          form-type="select"
          width="78px"
          :clearable="true"
          :select-options="userOptions"
          tabindex="4"
          placeholder="选择查价员"
        ></znl-input>
        <znl-button type="multiple" :height="22" class="box-left-btn search-btn">
          <znl-button-menu @click="onBindData()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  configURL: "BomWaitToInquire/GetConfig",
  resetParamConfigURL: "BomWaitToInquire/ResetParamConfig",
  configURLFeedback: "BomPurchaseFeedback/GetConfig",
  saveConfigURLFeedback: "BomPurchaseFeedback/SaveConfig",
  resetConfigURLFeedback: "BomPurchaseFeedback/ResetConfig",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  searchURL: "BomWaitToInquire/Search"
};
export default {
  name: "StsPurchaseQuote",
  mixins: [znlMethodsMixin, getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {},
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ParamJosn: [],
      columns: [],
      itemSource: [],
      loadingText: "",
      loading: false,
      searchFields: {
        Model: "",
        BillNo: "",
        IsMy: false,
        CreateTimeStart: "",
        CreateTimeEnd: "",
        BuyEmpName: ""
      },
      isShowFiedsSearch: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      selectOptions: [
        { key: 1, value: "已查" },
        { key: 2, value: "未查" },
        { key: 3, value: "已报" },
        { key: 4, value: "未报" },
        { key: 5, value: "加急" }
      ],
      userOptions: [],
      checkStatus: [],
      status: [
        { key: "是", value: true },
        { key: "否", value: false }
      ],
      currencyList: [],
      userList: [],
      taxRateList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: true
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    async onInit(config, refresh) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage("bomWaitToInquire", CONFIG.configURL);
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      // 业务员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function(
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value };
      });
      this.userOptions = UserAllowUsers;
      let columns = config.ColumnConfigs;

      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "IsInquired"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 40,
          options: this.status
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsSubmit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 40,
          options: this.status
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsUrgent");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 40,
          options: this.status
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsNoStock");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 40,
          options: this.status
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsOutStock");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 40,
          options: this.status
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "CreatedName");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.userList
        });
      }
      colIndex = _.findIndex(
        columns,
        item => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Unit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.qtyUnitList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "TaxRate");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 74,
          options: this.taxRateList
        });
      }
      this.parameterConfig = config.ParamJosn;
      this.columns = columns;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$emit("page-loading", false);
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loadingText = "数据加载中,请稍后...";
      this.loading = true;
      let param = _.extend({
        fieldWhereString: JSON.stringify(this.searchFields),
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        checkStatus: this.checkStatus
      });
      return await this.$post(
        this.$options.config.searchURL,
        param,
        (data, logic) => {
          if (logic.code === 200) {
            this.pageIndex = pageIndex;
            this.itemSource = data.ResultList;
            this.totalCount = data.TotalCount;
          }
        }
      ).finally(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },
    async onHeadSearch(params) {
      if (this.hasValue(params) && this.hasValue(params.BDLineGUID)) {
        this.searchFields.BDLineGUID = params.BDLineGUID;
        this.searchFields.IsSubmit = params.IsSubmit;
        this.searchFields.CanBomAssistQuoted = params.CanBomAssistQuoted;
      }
      await this.onBindData(1);
    },
    onChangeStatus(val) {
      this.checkStatus = val;
      this.onSearch();
    },

    async onSearch(params = {}) {
      if (this.hasValue(params)) {
        _.extend(this.searchFields, params);
      }
      this.onBindData(this.pageIndex);
    },

    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("BomWaitToInquire", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("BomWaitToInquire", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("BomWaitToInquire", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("BomWaitToInquire");
    }
  }
};
</script>

<style lang="scss">
</style>
