<template>
  <znl-dialog
    :visible="visible"
    @close="()=>{$emit('close')}"
    height="580px"
    width="880px"
    title="批量收款"
    subhead="操作提示：请在本次收款栏填写实收金额！"
    :close-on-click-modal="false"
  >
    <znl-gridmodule
      gridtype="action"
      ref="flexGrid"
      layout-type="other"
      height="480px"
      :from-height="100"
      :is-show-frombtns="false"
      slot="znl-dialog"
      :has-znl-btns="false"
      :is-init="true"
      v-loading="loading"
      :element-loading-text="loadingText"
      :columns="columns"
      :on-page-changed="onBindData"
      :item-source="itemSource"
      :edit-row-index="editRowIndex"
      :show-summary="true"
      :summary-method="billBySum"
      :search-fields="searchModel"
      :on-init="onInit"
      :isShowPagination="isShowPagination"
      @edit:cell-ended="onCellEdited"
    >
      <div slot="from-module">
        <el-row type="flex" :gutter="20">
          <!-- left -->
          <el-col :span="8">
            <el-row>
              <znl-input
                from-type="input"
                :disable="false"
                title-width="70px"
                title="分派金额："
                placeholder="输入分派金额"
                size="mini"
                layout="left"
                width="79%"
                :clearable="true"
                :border="true"
                v-model="AssignAmount"
                type="text"
              ></znl-input>
              <znl-button
                style-type="mac"
                @click="onAssign"
                :height="22"
                tip="当分派大于本次收款额,视为新增预收款"
                class="ml4"
              >
                <i class="iconfont icon-edit_btn_ic"></i>
                <span>分派</span>
              </znl-button>
            </el-row>
            <!-- 左1 -->
            <el-row>
              <znl-input
                layout="left"
                form-type="datepicker"
                width="100%"
                placeholder="收款时间"
                popper-class="popperClass"
                title="收款时间："
                title-width="70px"
                :is-must-fill="false"
                :border="true"
                :clearable="true"
                v-model="StkOut.ReceiveOn"
              ></znl-input>
              <!-- 左2 -->
            </el-row>
            <el-row>
              <znl-input
                form-type="select"
                v-if="systemConfig.hasFinance"
                placeholder="请选择"
                v-model="StkOut.CBGUID"
                title="收款账户："
                :is-must-fill="true"
                layout="left"
                :border="true"
                :clearable="true"
                title-width="70px"
                :select-options="selectOptionsBankAccountAndName"
                width="100%"
              ></znl-input>

              <znl-input
                placeholder
                v-if="!systemConfig.hasFinance"
                v-model="StkOut.Remark"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="false"
                width="100%"
                :border="true"
                title="收款备注："
                :clearable="true"
              ></znl-input>
            </el-row>
          </el-col>

          <!-- 中 -->
          <el-col :span="7" :offset="1">
            <!-- 中1 -->
            <el-row>
              <znl-input
                placeholder
                v-model="StkOut.PaymentSum"
                layout="left"
                type="text"
                title-width="70px"
                :border="true"
                :disabled="true"
                width="100%"
                title="实收款："
              ></znl-input>
            </el-row>
            <!-- 中2 -->
            <el-row>
              <znl-input
                placeholder
                v-model="respaysumAmount"
                layout="left"
                type="text"
                :border="true"
                title-width="70px"
                :disabled="true"
                width="100%"
                title="预收款："
              ></znl-input>
            </el-row>
            <el-row>
              <znl-input
                placeholder
                v-model="StkOut.OffedAmount"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="respaysumAmount<=0"
                width="100%"
                :border="true"
                title="冲销额："
                :clearable="(respaysumAmount > 0)"
                @blur="offedAmountBlur"
              ></znl-input>
            </el-row>
          </el-col>

          <!-- right v-if='isFinRecPay'-->
          <el-col :span="7" :offset="1">
            <el-row>
              <znl-input
                layout="left"
                form-type="select"
                :disabled="false"
                :border="true"
                title-width="70px"
                width="75%"
                title="收据模板："
                placeholder="-请选择-"
                popper-class="popperClass"
                :clearable="true"
                :select-options="PrintTemplates"
                v-model="PrintTemplate"
              ></znl-input>
              <znl-button style-type="mac" @click="onPrint" :height="22" class="ml4">
                <i class="iconfont icon-print_btn_ic"></i>
                <span>打印</span>
              </znl-button>
            </el-row>
            <el-row>
              <znl-input
                from-type="input"
                :disable="false"
                title-width="70px"
                title="新增预收款："
                placeholder
                size="mini"
                layout="left"
                width="75%"
                :disabled="true"
                :clearable="true"
                :border="true"
                v-model="StkOut.RecInAdvance"
                type="text"
              ></znl-input>
              <i class="iconfont icon-table_help" title="输入分派金额大于本次收款总额时,剩下的结存额将自动新增一笔预收款单"></i>
            </el-row>
            <el-row>
              <znl-input
                placeholder
                v-if="systemConfig.hasFinance"
                v-model="StkOut.Remark"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="false"
                width="100%"
                :border="true"
                title="收款备注："
                :clearable="true"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </znl-gridmodule>
    <div slot="footer">
      <span style="font-size: 14px;margin-right: 10px;">
        本次收款总额：
        <span style="font-weight: 600;color: red;">{{StkOut.TotalAmount}}</span>
      </span>
      <znl-button style-type="main" @click="StkBatchSave" :height="30" :disabled="this.loading">
        <i class="iconfont icon-table_money_ic"></i>
        <span>确定收款</span>
      </znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  configURL: "OutReceive/GetConfig",
  saveConfigURL: "OutReceive/SaveConfig",
  resetConfigURL: "OutReceive/ResetConfig",
  searchURL: "OutReceive/Search",
  searchSumAmountURL: "StkBatchPay/SumAmount",
  stkSaveURL: "OutReceive/Save",
  getPrintTemplatesURL: "PrintTemplate/Search"
};
export default {
  name: "StkTransferList",
  config: CONFIG,
  mixins: [getCommonDataMixin],
  components: {},
  data() {
    return {
      StkOut: {
        // 收款对象
        Remark: "", // 备注
        LineList: [], // 保存入库详情明细行
        ReceiveOn: new Date().toLocaleDateString(),
        PaymentSum: 0,
        CBGUID: "",
        CBName: "",
        OffedAmount: null,
        TotalAmount: null,
        RecInAdvance: null
      },
      bankAccontOptions: [],
      AssignAmount: "",
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {
        inputModel: ""
      },
      users: [],
      respaysumAmount: 0,
      PrintTemplate: "", // 打印模板
      PrintTemplates: [],
      stkoutSelectItem: [], // 选中的数据
      loading: true,
      loadingText: "数据加载中,请稍后...",
      systemConfig: {
        hasFinance: false
      }
    };
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isShowPagination: Boolean,
    visible: {
      type: Boolean,
      defauly: false
    },
    isReturn: false, // 是否从退货单界面打开
    custName: null
  },
  methods: {
    onInit: async function() {
      let config = await this.isSaveStorage(
        "stkBatchReceive",
        CONFIG.configURL
      );
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.defaultConfig = config;
      this.columns = columns;
      this.onInitPrintTemplate();

      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.StkOutGUIDS
      ) {
        let guid = this.$store.state.paramOrder.StkOutGUIDS;
        this.onBindData(guid);
        if (!this.isReturn) {
          this.GetCustAdvanceAmount();
        }
        this.$store.commit("setParamOrder", {});
      }
    },
    onBindData(guid) {
      this.itemSource = [];
      let data = { StkOutGUIDS: guid, IsReturn: this.isReturn };
      this.$post(CONFIG.searchURL, data, datas => {
        this.StkOut.LineList = datas;
        this.itemSource = this.StkOut.LineList;
        this.onCalcQty();
      }).finally(() => {
        this.loading = false;
      });
    },

    GetCustAdvanceAmount() {
      if (
        this.custName !== null &&
        this.custName !== "" &&
        this.custName !== undefined
      ) {
        this.$post(
          CONFIG.searchSumAmountURL,
          { SearchType: 2, CompanyName: this.custName },
          data => {
            this.respaysumAmount = data.InCome;
          }
        );
      }
    },

    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 更新调出数量
      let rowData = gridobj.row;
      let amount = this.ConvertNumber(rowData.Amount);
      let payamount = this.ConvertNumber(rowData.ReceiveAmount);
      let disamount = this.ConvertNumber(rowData.DiscountAmount);
      // 金额
      let theunpayamountIndex = gridobj.getColumn("UnReceived").index;
      if (colname === "ThisReceive") {
        this.itemSource[rowindex]["ThisReceive"] = this.toFloat(aftervalue, 6);
        let thedisamount = this.ConvertNumber(rowData.ThisDiscount);
        let unpayamount =
          amount - payamount - disamount - thedisamount - aftervalue;
        gridobj.setCellData(
          rowindex,
          theunpayamountIndex,
          this.ConvertNumber(unpayamount).toString()
        );
      }
      if (colname === "ThisDiscount") {
        this.itemSource[rowindex]["ThisDiscount"] = this.toFloat(aftervalue, 6);
        let thepayamount = this.ConvertNumber(rowData.ThisReceive);
        let unpayamount =
          amount - payamount - disamount - thepayamount - aftervalue;
        gridobj.setCellData(
          rowindex,
          theunpayamountIndex,
          this.ConvertNumber(unpayamount).toString()
        );
      }

      this.onCalcQty();
    },
    onCalcQty() {
      // 计算总量
      this.$nextTick(() => {
        let tempSource = this.itemSource;
        let totalAmount = 0;
        _.each(tempSource, t => {
          totalAmount += Number(this.ConvertNumber(t.ThisReceive));
        });
        this.StkOut.TotalAmount = totalAmount;
        this.StkOut.PaymentSum =
          this.StkOut.TotalAmount - this.StkOut.OffedAmount;
        this.StkOut.PaymentSum = parseFloat(this.StkOut.PaymentSum.toFixed(2));
      });
    },
    StkBatchSave() {
      // 调动保存
      // console.log('保存', this)
      this.StkOut.IsReturn = this.isReturn;
      if (this.StkOut.ReceiveOn === undefined || this.StkOut.ReceiveOn === "") {
        return this.$message({ message: "请选择收款时间", type: "error" });
      }
      if (
        this.systemConfig.hasFinance &&
        (this.StkOut.CBGUID === undefined ||
          this.StkOut.CBGUID === "" ||
          this.StkOut.CBGUID === 0)
      ) {
        return this.$message({ message: "请选择收款账户", type: "error" });
      }
      if (
        this.hasValue(this.StkOut.OffedAmount) &&
        this.StkOut.OffedAmount > this.StkOut.TotalAmount
      ) {
        return this.$message({
          message: "冲销金额不能大于收款总额",
          type: "error"
        });
      }
      if (!this.isReturn && this.StkOut.PaymentSum < 0) {
        return this.$message({ message: "实收金额不能小于0", type: "error" });
      }
      this.StkOut.LineList = this.itemSource;
      this.loadingText = "正在保存,请稍等...";
      this.loading = true;
      return this.$post(CONFIG.stkSaveURL, this.StkOut, (data, logic) => {
        if (data > 0) {
          this.$message({ message: "批量收款成功", type: "success" });
          this.$emit("confirm", true);
        } else {
          this.$message({
            message: (logic && logic.msg) || "批量收款失败",
            type: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
        this.loadingText = "数据加载中,请稍后...";
      });
    },
    // 分派金额
    onAssign() {
      if (!this.hasValue(this.AssignAmount) || this.AssignAmount <= 0) {
        return this.$message({ message: "请输入待分派金额", type: "error" });
      }
      let assign = this.AssignAmount;
      let source = this.itemSource;

      _.each(source, item => {
        item.ThisReceive = 0;
        if (assign > 0) {
          // 本次收款=应收金额-已收金额-已折扣金额
          let unReceived = item.Amount;
          // 减去 已收额
          if (this.hasValue(item.ReceiveAmount) && item.ReceiveAmount > 0) {
            unReceived -= item.ReceiveAmount.toFixed(2);
          }
          // 减去 折扣额
          if (this.hasValue(item.DiscountAmount) && item.DiscountAmount > 0) {
            unReceived -= item.DiscountAmount.toFixed(2);
          }
          if (assign > unReceived) {
            item.ThisReceive = unReceived;
          } else {
            item.ThisReceive = assign;
          }
          assign = assign - item.ThisReceive;
          // assign -= item.ThisReceive.toFixed(2)
        }
        // 计算未收款 = 应收金额 - 已收金额
        item.UnReceived = item.Amount - item.ReceiveAmount - item.ThisReceive;
        console.log(item);
      });
      if (assign > 0) {
        this.StkOut.RecInAdvance = assign;
      } else {
        this.StkOut.RecInAdvance = null;
      }
      this.itemSource = JSON.parse(JSON.stringify(source));
      this.onCalcQty();
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = [];
      let rows = this.$refs.flexGrid.getSelectedRows();
      // console.log(rows)
      if (_.isUndefined(rows[0])) {
        if (!this.stkoutSelectItem) {
          // 选中行
          return this.$message({
            message: "请至少选择一条数据进行操作",
            type: "error"
          });
        } else {
          guid.push(this.stkoutSelectItem.StkOutLineGUID);
        }
      } else {
        guid = _.map(rows, item => {
          return item.StkOutLineGUID;
        });
      }
      return guid;
    },
    onPrint() {
      var tableName = this.PrintTemplate; // 模板ID
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (tableName === "--请选择打印模板--" || tableName === "") {
        this.$message({ message: "请选择打印模板", type: "error" });
      } else if (_.isUndefined(rows[0])) {
        this.$message({ message: "请选择要打印的单据", type: "error" });
      } else {
        // console.log(rows)
        this.printReceipts({ TableName: tableName, List: rows });
      }
    },
    onInitPrintTemplate() {
      return this.$post(
        this.$options.config.getPrintTemplatesURL,
        { Type: "ReceiveTemplate" },
        data => {
          let res = [];
          _.each(data, item => {
            res.push({
              key: item.TableName,
              value: item.TName
            });
          });
          this.PrintTemplates = res;
          if (this.PrintTemplates.length > 0) {
            this.PrintTemplate = this.PrintTemplates[0].key;
          }
        }
      );
    },
    offedAmountBlur() {
      if (
        this.StkOut.OffedAmount > this.respaysumAmount &&
        this.respaysumAmount > 0
      ) {
        this.StkOut.OffedAmount = this.respaysumAmount;
      }
      // 退货  金额为负数
      let d = this.StkOut.TotalAmount - this.StkOut.OffedAmount;
      if (d < 0 && !this.isReturn) {
        this.StkOut.OffedAmount = this.StkOut.TotalAmount;
      }
      this.onCalcQty();
    },
    billBySum(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (column.property === "Amount") {
            // Amount：应收
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "ReceiveAmount") {
            // 已收
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "DiscountAmount") {
            // 已折扣
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "ThisReceive") {
            // 本次收款
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "UnReceived") {
            // 未收金额
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else {
            sums[index] = "";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    }
  },
  async created() {},
  async mounted() {
    this.systemConfig.hasFinance =
      this.getCompanySetupValue("HasFinance") === "1";
  }
};
</script>

<style lang="scss" >
</style>
