<template>
  <!-- 销售退货明细 -->
  <div class="module-wrapper m-sales-return-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-sales-return-order-line"
      checkboxBindingKey="StkOutLineGUID"
      :columns="columns"
      :item-source="itemSource"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('SalesReturnLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 退货明细</span>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";

const Config = {
  configURL: "SalesReturnLine/GetConfig",
  resetParamConfigURL: "SalesReturnLine/ResetParamConfig",
  searchURL: "SalesReturnLine/Search"
};
export default {
  name: "SalesReturnLine",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: Config,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      resourceList: [],
      StkOutGUID: "",
      QtyUnit: [],
      QtyUnitDropDown: [],
      ParamJosn: [],
      parameterConfig: [],
      QtyType: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      console.log("init-SalesOrderReturnLine");
      if (!config) {
        config = await this.isSaveStorage("SalesReturnLine", Config.configURL);
      }
      this.ParamJosn = config.ParamJosn;
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      this.columns = columns;
      this.$refs.table.init();
      this.parameterConfig = config.ParamJosn;
    },
    onBindData(pageIndex = 1) {
      if (!this.hasValue(this.StkOutGUID)) {
        return;
      }
      //this.$refs.table.cleanSort()
      let data = _.extend({
        StkOutGUID: this.StkOutGUID
      });
      return this.$post(Config.searchURL, data, data => {
        this.itemSource = data === undefined ? [] : data;
      });
    },
    onRefresh() {
      this.onBindData(this.pageIndex);
    },
    onHeadSearch(outGuid) {
      if (outGuid) {
        this.StkOutGUID = outGuid;
        this.onBindData();
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("SalesReturnLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("SalesReturnLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("SalesReturnLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("SalesReturnLine");
    }
  }
};
</script>

<style lang="scss">
</style>
