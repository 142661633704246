<template>
  <div class="znl-folder-tree">
    <znl-tree :datas="treeData"
      :delete-config="deleteConfig"
      v-loading="loading"
      element-loading-text=" "
      @delete-after="onDeleteAfter"
      @edit-end="onEditEnd"
      @add-folder="onFolderAdd"
      @start="onDragStart"
      @end="onDragEnd"
      @node-click="onNodeClick"
      @draggable-in="onDragIn"
      @drag-in="onDragIn"
      @drag-end="onDragEnd"
    ></znl-tree>
  </div>
</template>
<script>
  const CONFIG = {
    searchURL: 'BomFolder/Search',
    saveURL: 'BomFolder/Save',
    deleteURL: 'BomFolder/Delete',
    updateFolder: 'BomMaster/UpdateFolder'
  }
  export default{
    name: 'BomFolderTree',
    data () {
      return {
        loading: false,
        treeData: [], // 树结构数据
        oldTreeData: [], // 树结构数据，请求后端是才改变
        dragBeforeFolders: [] // 文件夹拖动前
      }
    },
    methods: {
      deleteConfig (node) {
        return {
          // tipText: '删除该文件后，如果该文件夹下面有文件，删除后，会将这些文件移到上一级目录，是否删除？'
          tipText: '删除该文件夹后，文件会将移到上一级目录，是否删除？'
        }
      },
      onBindData () {
        this.loading = true
        this.$post(CONFIG.searchURL, data => {
          this.treeData = data
          this.oldTreeData = JSON.parse(JSON.stringify(data))
          _.delay(() => {
            this.loading = false
          }, 200)
        })
      },
      // 新增
      onFolderAdd (parentNode, newNode) {
        let folders = [{
          FolderId: 0,
          Name: newNode.Name,
          ParentId: parentNode.FolderId
        }]
        this.$post(CONFIG.saveURL, folders, (data, logic) => {
          if (logic.code === 120) {
            this.$message({ message: logic.msg, type: 'error' })
            this.bindTreeFromOldData()
          } else {
            this.onBindData()
          }
        })
      },
      // 改名
      onEditEnd (node) {
        if (node.Name === '') {
          this.bindTreeFromOldData()
          return
        }
        let folders = [{
          FolderId: node.FolderId,
          Name: node.Name,
          ParentId: node.ParentId
        }]
        this.$post(CONFIG.saveURL, folders, (data, logic) => {
          if (logic.code === 120) {
            this.$message({ message: logic.msg, type: 'error' })
            this.bindTreeFromOldData()
          } else {
            this.onBindData()
          }
        })
      },
      // 文件夹删除后
      onDeleteAfter (node) {
        this.$post(CONFIG.deleteURL, {FolderId: node.FolderId}, (data) => {
          this.onBindData()
        })
      },
      // 文件夹拖动
      onDragStart (nodes) {
        this.dragBeforeFolders = nodes
      },
      onNodeClick (node) {
        // console.log(node)
        this.$emit('folder-change', node)
      },
      // 文件夹拖动结束时
      onDragEnd (originFolderId, targetFolderId) {
        if (targetFolderId === null || targetFolderId === -1 || originFolderId === targetFolderId) {
          return
        }
        if (targetFolderId === originFolderId) {
          return
        }
        // 如果是文件夹拖动
        let folder = {
          FolderId: originFolderId,
          ParentId: targetFolderId
        }
        this.$post(CONFIG.saveURL, [folder], (data, logic) => {
          if (logic.code === 120) {
            this.$message({ message: logic.msg, type: 'error' })
            this.bindTreeFromOldData()
          } else {
            this.onBindData()
          }
        })
      },
      // 文件拖进来时, 外部文件
      onDragIn (targetFolderId, objIn) {
        if (_.isUndefined(objIn) || objIn === null) {
          this.bindTreeFromOldData()
          return
        }
        if (objIn.FolderId !== targetFolderId) {
          let sd = {
            BOMGUID: objIn.BOMGUID,
            FolderId: targetFolderId
          }
          this.$post(CONFIG.updateFolder, sd, (data) => {
            this.bindTreeFromOldData()
            this.$emit('trigger-search', targetFolderId)
          })
        } else {
          this.bindTreeFromOldData()
        }
      },
      bindTreeFromOldData () {
        this.treeData = JSON.parse(JSON.stringify(this.oldTreeData))
      }
    },
    mounted () {
      this.onBindData()
    }
  }
</script>
<style lang="scss">
.znl-folder-tree{
  display: flex;
  flex-direction: column;
  .znl-tree{
    flex: 1;
  }
}
</style>
