<template>
  <div style="display: inline-block">
    <znl-button :btns="btns" :height="22" style-type="mac" @click="onPrintLabel">
      <i class="iconfont icon-print_btn_ic"></i>
      <span>打标签</span>
    </znl-button>

    <!-- 发货单打印设置 -->
    <!-- <znl-print
      :printInfo="printInfo"
      :visible.sync="znlPrintVisible"
      :print-type="templateType"
      title="打标签默认设置"
      :show-sub-company="false"
      @confirm-print="confirmPrint"
    ></znl-print> -->
  </div>
</template>
<script>
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
// import {isClient} from "~/lib/runtime";

const CONFIG = {
  searchUrl: "PrintTemplate/Search",
  getStkOutLineListUrl: "StkOutLine/GetList"
}

export default {
  name: "printLabelButton",
  components: {
    ZnlPrint
  },
  props: {
    templateType: {
      type: String,
      default: "StkOutLabel"
    }
  },
  data () {
    return {
      btns: [],
      setBtn: {
        id: 10086,
        name: "打标签默认设置",
        click: () => {
          this.znlPrintVisible = true
        }
      },

      defaultPrinterSet: {
        id: 10087,
        name: "默认打印机设置",
        click: () => {
          this.showPrintSettings(this.templateType);
        }
      },

      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      dictCode: "",
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "", // 打印模板类型
        templateName: ""
      }, // end 打印模块参数
    }
  },
  methods: {
    async onInit () {
      this.bindTemplates();
    },
    async bindTemplates () {
      await this.$post(CONFIG.searchUrl, {Type: this.templateType}, data => {
        this.btns = []
        if (data && data.length > 0) {
          for ( let i = 0; i < data.length; i++) {
            let item = data[i];
            this.btns.push({
              id: i + 1,
              name: item.TName,
              TID: item.TID,
              TName: item.TName,
              TableName: item.TableName,
              IsDefault: item.IsDefault,
              click: () => {
                this.onBtnsClick(item)
              }
            })
          }
        }
        // this.btns.push(this.setBtn)
        this.btns.push(this.defaultPrinterSet);
      })
    },

    async onPrintLabel() {
      this.$emit('get-selected', rows => {
        if (rows === undefined || rows === null || rows.length === 0) {
          return this.$message({
            message: "请选中要打印的数据",
            type: "error",
          });
        }

        if (this.printInfo.template === undefined || this.printInfo.template === null || this.printInfo.template === "") {
          let item = this.btns.find(a => a.IsDefault && a.id !== 10086);
          if (item === undefined || item === null) {
            item = this.btns.find(a => a.id !== 10086);
          }
          this.printInfo.template = item.TableName;
          this.printInfo.templateName - item.TName;
        }

        this.doPrint(rows);
      });
    },

    async doPrint(rows) {
      let tableName = this.printInfo.template; // 模板ID
      if (!this.hasValue(tableName)) {
        this.$message({message: "请选择打印标签模板", type: "error"});
        return false;
      } else if (!this.printInfo.isGetDefaultParam) {
        this.printInfo.isGetDefaultParam = true;
        return false;
      }

      let dataSource = [];
      switch (this.templateType) {
        case "StkOutLabel":
          dataSource = await this.getStkOutLabelDS(rows);
          this.dictCode = "StkOutLabel";
        break;
      }

      if (dataSource === null || dataSource === undefined || dataSource.length === 0) {
        this.$message({message: "未能找到待打印的数据", type: "error"});
        return false;
      }
      this.printDataSource({
        TableName: this.printInfo.template,
        DataSource: dataSource,
        TemplateName: this.printInfo.templateName,
        DictCode: this.dictCode
      });
    },

    onBtnsClick (template) {
      this.printInfo.template = template.TableName;
      this.printInfo.templateName = template.TName;
      this.onPrintLabel();
    },

    confirmPrint(data, printInfo) {
      this.printInfo.template = printInfo.template;
      this.printInfo.templateName = printInfo.templateName;
      this.znlPrintVisible = false;
    },

    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkOutGUID)) {
        return this.$message({
          message: "请选中要打印的发货单",
          type: "error",
        });
      }

      let tableName = this.printInfo.template; // 模板ID
      let titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({message: "请选择打印模板", type: "error"});
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.StkOutGUID
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if (data) {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl) // 下载文件
          }
        } else {
          this.$message({message: logic.msg, type: "error"});
        }
      })
    },

    // 获取打发货单标签的数据源
    async getStkOutLabelDS (selectedRows) {
      let guids = selectedRows.map(m => m.StkOutGUID);
      try {
        let data = await this.$post(CONFIG.getStkOutLineListUrl, {StkOutGUIDS: guids});
        return data;
      } catch (e) {
        this.$message({message: e.msg, type: "error"});
        return null;
      }
    }
  },
  async mounted () {
    await this.onInit();
  }
}
</script>
