<template>
  <div class="module-wrapper m-purchase-return-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-purchase-return-line"
      :columns="columns"
      :item-source="itemSource"
      :totalField="['Amount','Qty']"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('OrdPurchaseReturnLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 退货明细</span>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  configURL: "OrdPurchaseReturnLine/GetConfig",
  searchURL: "OrdPurchaseReturnLine/InLineSearch"
};
export default {
  name: "ZNLOrdPurchaseReturnLine",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  components: {},
  config: CONFIG,
  data() {
    return {
      columns: [],
      itemSource: [],
      resourceList: [],
      StkInGUID: "",
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage(
          "ordPurchaseReturnLine",
          CONFIG.configURL
        );
      }
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      this.columns = columns;
      this.$refs.table.init();
      this.$emit("page-loading", false);
    },
    async onBindData(pageIndex = 1) {
      if (!this.hasValue(this.StkInGUID)) {
        return;
      }
      //this.$refs.table.cleanSort()
      let data = _.extend(
        {},
        {
          StkInGUID: this.StkInGUID
        }
      );
      return await this.$post(CONFIG.searchURL, data, data => {
        this.itemSource = data === undefined ? [] : data;
      });
    },
    onHeadSearch(stkInGuid) {
      if (stkInGuid) {
        this.StkInGUID = stkInGuid;
        this.onBindData();
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("OrdPurchaseReturnLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("OrdPurchaseReturnLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("OrdPurchaseReturnLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("OrdPurchaseReturnLine");
    }
  }
};
</script>

<style lang="scss">
</style>
