<template>
  <znl-dialog
    :visible="visible"
    height="550px"
    width="900px"
    @close="()=>{$emit('close')}"
    class="m-batchpay-box"
    title="批量付款"
    subhead="操作提示：请在本次付款栏填写实付金额！"
    :close-on-click-modal="false"
  >
    <znl-gridmodule
      gridtype="action"
      ref="flexGrid"
      slot="znl-dialog"
      layout-type="other"
      height="450px"
      :from-height="100"
      :is-show-frombtns="false"
      :has-znl-btns="false"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCountLog"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      @selection-changed="d=>{$emit('selection-changed',d)}"
      :is-init="true"
      v-loading="loading"
      :element-loading-text="loadingText"
      :columns="columns"
      :item-source="itemSource"
      :show-summary="true"
      :summary-method="billBySum"
      :is-fields-search="false"
      :is-multi-rows-check="true"
      :is-multi-rows-check-fixed="false"
      :show-add-row-button="false"
      :show-delete-row-button="false"
      :search-fields="searchModel"
      :on-init="onInit"
      :has-znl-actions="true"
      @edit:cell-ended="onCellEdited"
    >
      <div slot="action-form">
        <el-row type="flex" style="width:100%">
          <el-col :span="10">
            <znl-input
              from-type="input"
              :disable="false"
              placeholder="请输入要分派的金额"
              size="mini"
              layout="left"
              width="180px"
              inp-perc="100%"
              :clearable="true"
              :border="true"
              v-model="AssignAmount"
              type="text"
            ></znl-input>
            <znl-button style-type="mac" @click="onAssign" :height="22" class="ml4">
              <span>
                <i class="iconfont icon-edit_btn_ic"></i>分派金额
              </span>
            </znl-button>
          </el-col>
        </el-row>
      </div>
      <div slot="from-module">
        <el-row type="flex" :gutter="20">
          <!-- left -->
          <el-col :span="8">
            <!-- 左1 -->
            <el-row>
              <znl-input
                form-type="datepicker"
                :disabled="false"
                title="付款时间："
                value-format="yyyy-MM-dd"
                layout="left"
                title-width="70px"
                width="100%"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                inp-perc="100%"
                v-model="StkIn.PaidTime"
              ></znl-input>
              <!-- 左2 -->
            </el-row>
            <el-row>
              <znl-input
                form-type="select"
                v-if="systemConfig.hasFinance"
                placeholder="请选择"
                v-model="StkIn.CBGUID"
                title="付款账户："
                :is-must-fill="true"
                layout="left"
                :clearable="true"
                title-width="70px"
                :border="true"
                :select-options="selectOptionsBankAccountAndName"
                type="text"
                width="100%"
              ></znl-input>
            </el-row>
            <el-row></el-row>
          </el-col>
          <!-- 中 -->
          <el-col :span="7" :offset="1">
            <!-- 中1 -->
            <el-row>
              <znl-input
                v-model="StkIn.PaymentSum"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="true"
                width="100%"
                :border="true"
                title="实付金额："
              ></znl-input>
            </el-row>
            <!-- 中2 -->
            <el-row>
              <znl-input
                placeholder
                v-model="StkIn.Remark"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="false"
                width="100%"
                :border="true"
                title="备注："
                :clearable="true"
              ></znl-input>
            </el-row>
            <el-row></el-row>
          </el-col>

          <!-- right v-if='isFinRecPay'-->
          <el-col :span="8" :offset="1">
            <!-- 右1 -->
            <el-row>
              <znl-input
                v-model="respaysumAmount"
                layout="left"
                type="text"
                title-width="70px"
                :disabled="true"
                width="100%"
                :border="true"
                title="预付金额："
              ></znl-input>
            </el-row>
            <!-- 右2 -->
            <el-row>
              <znl-input
                v-model="StkIn.PayInAdvance"
                layout="left"
                type="text"
                title-width="70px"
                :border="true"
                :disabled="!isFinRecPay"
                width="100%"
                title="冲销金额："
                :clearable="true"
                @blur="onInAdvance"
              ></znl-input>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </znl-gridmodule>

    <div slot="footer">
      <span style="font-size: 14px;margin-right: 10px;">
        本次付款总额：
        <span style="font-weight: 600;color: red;">{{StkIn.TotalPaidAmount}}</span>
      </span>
      <znl-button style-type="main" @click="StkBatchSave" :height="30" :disabled="this.loading">
        <i class="iconfont icon-table_money_ic"></i>
        <span>确定付款</span>
      </znl-button>
    </div>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMinix } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  configURL: "StkBatchPay/GetConfig",
  saveConfigURL: "StkBatchPay/SaveConfig",
  resetConfigURL: "StkBatchPay/ResetConfig",
  searchURL: "StkBatchPay/Search",
  searchSumAmountURL: "StkBatchPay/SumAmount",
  stkSaveURL: "StkBatchPay/BatchPaymentUpdate"
};
export default {
  name: "StkTransferList",
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {},
  data() {
    return {
      StkIn: {
        // 付款对象
        PayablesAmount: "", // 应付金额
        SupplierName: "", // 供应商
        Remark: "", // 备注
        CurrencyCode: "RMB", // 币种
        ExchangeRate: "1", // 汇率
        DiscountAmount: 0, // 折扣金额
        LineList: [], // 保存入库详情明细行
        PaidTime: new Date().toLocaleDateString(),
        PaymentSum: 0,
        CBGUID: "",
        CBName: "",
        PayInAdvance: null,
        TotalPaidAmount: null
      },
      totalCountLog: 1,
      AssignAmount: "",
      checkQty: 0, // 选中行的数量
      refreshChecklist: false, // 是否刷新选择列表
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {
        inputModel: ""
      },
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      users: [],
      usableUser: [],
      pickerOptions0: {},
      respaysumAmount: 0,
      loading: true,
      loadingText: "数据加载中,请稍后...",
      systemConfig: {
        hasFinance: false
      }
    };
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    pageSize: Number,
    pageIndex: Number,
    totalCount: Number,
    height: Number,
    stkInGUIDS: Array, // 库存对象
    isFinRecPay: Boolean, // 是否需要刷新页面
    companyAmount: 0,
    showstkBatchPay: Boolean // 是否需要刷新页面
  },
  watch: {
    stkInGUIDS: {
      handler(value) {
        // 根据传入参数加载页面
        if (!_.isEmpty(value)) {
          this.onBindData();
        }
      },
      immediate: true
    },

  },
  methods: {
    onInit: async function() {
      let config = await this.isSaveStorage("stkBatchPay", CONFIG.configURL);
      // let config = await this.$post(CONFIG.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.defaultConfig = config;
      this.columns = columns;
      this.itemSource = [];
      this.initData;
    },
    onBindData() {
      this.itemSource = [];
      this.StkIn.LineList = [];
      let data = { StkInGUIDS: this.stkInGUIDS };
      return this.$post(CONFIG.searchURL, data, datas => {
        this.StkIn.LineList = datas;
        this.itemSource = this.StkIn.LineList;
        this.respaysumAmount = this.companyAmount;
        this.onCalcQty();
      }).finally(() => {
        this.loading = false;
      });
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 更新调出数量
      let rowData = gridobj.row;
      let amount = this.ConvertNumber(rowData.Amount);
      let payamount = this.ConvertNumber(rowData.PayAmount);
      let disamount = this.ConvertNumber(rowData.DiscountAmount);
      // 金额
      let theunpayamountIndex = gridobj.getColumn("UnPay").index;
      if (colname === "ThisPayAmount") {
        this.itemSource[rowindex]["ThisPayAmount"] = this.toFloat(
          aftervalue,
          6
        );
        let thedisamount = this.ConvertNumber(rowData.ThisDiscountAmount);
        let unpayamount =
          amount - payamount - disamount - thedisamount - aftervalue;
        gridobj.setCellData(
          rowindex,
          theunpayamountIndex,
          this.ConvertNumber(unpayamount)
            .toFixed(2)
            .toString()
        );
      }
      if (colname === "ThisDiscountAmount") {
        this.itemSource[rowindex]["ThisDiscountAmount"] = this.toFloat(
          aftervalue,
          6
        );
        let thepayamount = this.ConvertNumber(rowData.ThisPayAmount);
        let unpayamount =
          amount - payamount - disamount - thepayamount - aftervalue;
        gridobj.setCellData(
          rowindex,
          theunpayamountIndex,
          this.ConvertNumber(unpayamount)
            .toFixed(2)
            .toString()
        );
      }
      this.onCalcQty();
    },
    onCalcQty() {
      // 计算总量
      let tempSource = this.itemSource;
      this.totalCountLog = tempSource.length;
      this.StkIn.TotalPaidAmount = 0;
      _.each(tempSource, t => {
        this.StkIn.TotalPaidAmount += Number(
          this.ConvertNumber(t.ThisPayAmount)
        );
      });
      this.StkIn.PaymentSum =
        this.StkIn.TotalPaidAmount - this.StkIn.PayInAdvance;
      this.StkIn.PaymentSum = this.StkIn.PaymentSum.toFixed(2);
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("stkBatchPay");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      );
    },
    onResetSetting() {
      localStorage.removeItem("stkBatchPay");
      return this.$post(CONFIG.resetConfigURL);
    },
    StkBatchSave() {
      // 调动保存
      if (this.StkIn.PaidTime === undefined || this.StkIn.PaidTime === "") {
        return this.$message({ message: "请选择付款时间", type: "error" });
      }
      if (
        this.systemConfig.hasFinance &&
        (this.StkIn.CBGUID === undefined ||
          this.StkIn.CBGUID === "" ||
          this.StkIn.CBGUID === 0)
      ) {
        return this.$message({ message: "请选择付款账户", type: "error" });
      }
      if (
        this.hasValue(this.StkIn.PayInAdvance) &&
        this.StkIn.PayInAdvance > this.StkIn.TotalPaidAmount
      ) {
        return this.$message({
          message: "冲销金额不能大于付款总额",
          type: "error"
        });
      }
      if (!this.hasValue(this.StkIn.PaymentSum)) {
        return this.$message({ message: "请输入实付金额", type: "error" });
      }
      this.StkIn.LineList = this.itemSource;
      this.loadingText = "正在保存,请稍等...";
      this.loading = true;
      return this.$post(CONFIG.stkSaveURL, this.StkIn, (data, logic) => {
        if (data > 0) {
          this.$message({ message: "批量付款成功", type: "success" });
          this.$emit("confirm", true);
        } else {
          this.$message({
            message: (logic && logic.msg) || "批量付款失败",
            type: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
        this.loadingText = "数据加载中,请稍后...";
      });
    },
    onSearch() {
      this.$nextTick(function() {
        let itemsources = this.StkIn.LineList;
        let newItemSource = [];
        _.each(itemsources, item => {
          if (
            item.Model.toUpperCase().search(
              this.fieldsFilter.inputModel.toUpperCase()
            ) > -1
          ) {
            newItemSource.push(item);
          }
        });
        this.itemSource = newItemSource;
      });
    },
    onAssign() {
      this.$nextTick(function() {
        let itemsources = this.itemSource;
        let newItemSource = [];
        if (this.AssignAmount > 0) {
          let assignAmount = this.AssignAmount;
          _.each(itemsources, item => {
            let unPay = parseFloat(
              (
                this.ConvertNumber(item.Amount) -
                this.ConvertNumber(item.PayAmount) -
                this.ConvertNumber(item.DiscountAmount) -
                this.ConvertNumber(item.ThisDiscountAmount)
              ).toFixed(2)
            );

            if (assignAmount > 0) {
              if (assignAmount > unPay) {
                item.ThisPayAmount = unPay;
                item.UnPay = 0;
              } else {
                item.ThisPayAmount = assignAmount;
                item.UnPay = parseFloat(
                  (unPay - assignAmount).toFixed(2)
                ).toString();
              }
            }
            if (assignAmount <= 0) {
              item.ThisPayAmount = 0;
              item.UnPay = unPay.toString();
            }
            assignAmount = parseFloat(
              (assignAmount - this.ConvertNumber(item.ThisPayAmount)).toFixed(2)
            );
            newItemSource.push(item);
          });
        }
        this.itemSource = newItemSource;
        this.onCalcQty();
      });
    },
    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null
        ? (0).toFixed(2)
        : num;
    },

    onInAdvance() {
      if (this.StkIn.PayInAdvance > this.respaysumAmount) {
        this.StkIn.PayInAdvance = this.respaysumAmount;
      }
      if (this.StkIn.PayInAdvance > this.StkIn.TotalPaidAmount) {
        this.StkIn.PayInAdvance = this.StkIn.TotalPaidAmount;
      }
      this.onCalcQty();
    },
    onIsCanResPay() {
      return this.respaysumAmount > 0;
    },
    billBySum(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (column.property === "Amount") {
            // Amount：应付
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "PayAmount") {
            // 已付
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "DiscountAmount") {
            // 已折扣
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "ThisPayAmount") {
            // 本次付款
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else if (column.property === "UnPay") {
            // 未付
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                sums[index] = "";
              }
            }, 0);
          } else {
            sums[index] = "";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    }
  },

  mounted() {
    this.systemConfig.hasFinance =
      this.getCompanySetupValue("HasFinance") === "1";
  }
};
</script>

<style lang="scss">
.m-batchpay-box {
  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 15px 30px;
  }
  .znl-fromstyle {
    margin-bottom: 5px;
  }
}
</style>
