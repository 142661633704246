<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
    <div class="second-tab-div">
      <stock :init-data="false" @page-loading="setClientSelectLoading"></stock>
    </div>
  </znl-layout-spa>
</template>
<script>
import Stock from "@c_modules/QDW/TencentQDW";

export default {
  name: "VTencentQDW",
  components: {
    Stock
  },
  data() {
    return {
      pageData: [],
      pageLoading: false,
      activeName: "ProductList"
    };
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    }
  },
  methods: {
    pageSearch() {},
    setClientSelectLoading(val) {
      this.pageLoading = val;
    }
  },
  async created() {}
};
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_variables.scss";
</style>
