<template>
  <znl-module header="询/报/成交记录"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :is-action-from='false' 没有actionfrom时隐藏
              @selection-changed="d=>{this.$emit('selection-changed',d)}">

    <div slot="action-buttons">
      <el-row>
      <el-button type="default"
                 size="mini"
                 @click="onBomAdd">
        <i class="iconfont icon-add_btn_ic"></i> <span class="ver">添加询报价</span>
      </el-button>
      </el-row>
    </div>

  </znl-module>
</template>


<script>
// import ZnlModule from '@c_common/znlGrid/znlModule'
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { Button } from 'element-ui'

const CONFIG = {
  configURL: 'Track/GetConfig',
  saveConfigURL: 'Track/SaveConfig',
  resetConfigURL: 'Track/ResetConfig',
  searchURL: 'Track/Search'
}

export default {
  name: 'ZNLTrack',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    elButton: Button
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      columnColors: [{
        'field': 'BomQty',
        'color': '#EE7700'
      }, {
        'field': 'QuotePrice',
        'color': '#EE7700'
      }],
      rowColors: [
        // {
        //   'color': 'red',
        //   'className': 'text-blod',
        //   'when': function (data) {
        //     return data.SType && data.SType === 3
        //   }
        // }
      ],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1'
    }
  },
  methods: {
    onInit: async function (config) {
      // let config = await this.$post(CONFIG.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      columns.push({
        'name': 'operate',
        'dataType': 5,
        'binding': 'operate',
        'width': 60,
        'header': '操作',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'buttons': [{
          'content': '报',
          'className': (rowData) => rowData.IsQuoteed === true ? 'znl-table-btn-success' : '',
          'click': (rowData) => {
            this.csRequest('to-quote', rowData)
          }
        }, {
          'content': '出',
          'className': (rowData) => rowData.IsStkOuted === true ? 'znl-table-btn-success' : '',
          'click': (rowData) => {
            this.csRequest('to-out', rowData)
          }
        }, {
          'content': '收',
          'className': (rowData) => rowData.IsReceived === true ? 'znl-table-btn-success' : '',
          'click': (rowData) => {
            this.csRequest('to-rec', rowData)
          }
        }, {
          'content': '退',
          'className': (rowData) => rowData.IsReturn === true ? 'znl-table-btn-success' : '',
          'click': (rowData) => {
            this.csRequest('to-return', rowData)
          }
        }]
      })
      this.defaultConfig = config
      this.columns = columns
      this.pageSize = config.PageSize
      this.role = config.Role
      this.initData && this.onBindData()
    },
    onBomAdd () {
      // TODO
    }
  }
}
</script>

<style lang="scss">
</style>
