<template>
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <!-- @select-change="selectChange" -->
    <znl-table
      ref="table"
      checkboxBindingKey="nlpId"
      :showCheck="this.isOperate"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="商机时间从"
          size="mini"
          value-format="yyyy-MM-dd"
          layout="left"
          width="94px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="searchFields.StartTime"
          @keyup.enter.native="onSearch()"
        ></znl-input>至
        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="商机时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="94px"
          :border="true"
          :clearable="true"
          inp-perc="100%"
          v-model="searchFields.EndTime"
          @keyup.enter.native="onSearch()"
        ></znl-input>
        <!-- <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="180px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>

        <znl-input
          title-width="70px"
          layout="left"
          ref="CurrencyCode"
          form-type="select"
          width="84px"
          placeholder="报价状态"
          popper-class="popperClass"
          :border="true"
          :clearable="true"
          :select-options="selectOptions"
          v-model="searchFields.QuotedStatus"
        ></znl-input>-->

        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
        </znl-button>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  PK: "TQDGuid",
  configURL: "TencentQDWInquiry/GetConfig",
  searchURL: "TencentQDWInquiry/Search"
};

export default {
  name: "TencentQDW_Inquiry",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      searchFields: {
        Model: "",
        QuotedStatus: null,
        StartTime: this.getDate(0),
        EndTime: this.getDate(0)
      },
      selectedRows: [], // 选中的数据
      loading: true,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      selectOptions: [{ key: 1, value: "已报价" }, { key: 0, value: "未报价" }],
      isDisabled: false
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isOperate: {
      type: Boolean,
      default: false
    }
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    async onBindData(pageIndex = 1) {
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StartTime: this.searchFields.StartTime,
          EndTime: this.searchFields.EndTime
        }
      );
      return await this.$post(CONFIG.searchURL, data, datas => {
        this.pageIndex = pageIndex;
        this.itemSource = datas.ResultList;
        this.totalCount = datas.TotalCount;
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    async onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.searchFields = params;
          return this.onBindData(1);
        });
      } else {
        this.searchFields = params;
        return this.onBindData(1);
      }
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage(
          "TencentQDW_Inquiry",
          CONFIG.configURL
        );
      }

      let columns = config.ColumnConfigs;
      //this.initColumns(columns);
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;

      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },

    // initColumns(columns) {
    //   let colIndex = _.findIndex(
    //     columns,
    //     item => item.BindField === "originalText"
    //   );
    //   if (colIndex > -1) {
    //     _.extend(columns[colIndex], {
    //       formatter(item) {
    //         console.log(item);
    //         return item.originalText;
    //       }
    //     });
    //   }
    // },

    onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.searchFields = params;
          return this.onBindData(1);
        });
      } else {
        this.searchFields = params;
        return this.onBindData(1);
      }
    },

    clearSelectedRows() {
      this.onCleaSelectCount();
    },

    // 勾选选择框事件
    selectChange(rows) {
      _.each(rows, a => {
        a.pageIndex = this.pageIndex;
      });
      if (this.selectedRows.length <= 0) this.selectedRows = rows;
      else {
        //过滤当前页数据
        this.selectedRows = _.filter(this.selectedRows, a => {
          return a.pageIndex != this.pageIndex;
        });
        //添加当前页数据
        this.selectedRows = this.selectedRows.concat(rows);
        //从小到大 排序
        this.selectedRows.sort(function(a, b) {
          return a.pageIndex - b.pageIndex;
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("TencentQDWInquiry", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("TencentQDWInquiry", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("TencentQDWInquiry", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("TencentQDWInquiry");
    }
  }
};
</script>

<style lang="scss">
</style>

