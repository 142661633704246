<template>
  <znl-dialog
    :visible="statementvisible"
    class="saleout-createdstatement-box"
    height="186px"
    width="380px"
    title="生成对账单"
    @close="val=>{$emit('close', val)}"
  >
     <!-- :fetch-suggestions="querySearch" -->
    <div class="saleout-Statement-padd">
      <el-row class="saleout-input-padd2">
        <el-col :span="24">
          <znl-input
            title-width="70px"
            form-type="select"
            ref="znlAutocomplete"
            @select="suggestionsSelect"
            custom-item="my-item-zh"
            :disabled="fromSalesOrderOpen"
            size="mini"
            layout="left"
            :is-must-fill="true"
            :title="this.companyTitle"
            :clearable="true"
            width="291px"
            inp-perc="100%"
            :border="true"
            v-model="Statement.CustomerName"
            :select-options="statementCompany"
            type="text"
          >
            <!-- <i slot="suffix" style="display: none"></i>
            <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="onIconClick"></i>-->
          </znl-input>
        </el-col>
      </el-row>

      <el-row class="saleout-input-padd2">
        <el-col :span="24">
          <znl-input
            title-width="70px"
            ref="CurrencyCode"
            form-type="select"
            :disabled="false"
            width="180px"
            placeholder="请选择"
            title="币 种："
            :border="true"
            tabindex="10"
            :is-must-fill="true"
            :clearable="true"
            v-model="Statement.CurrencyCode"
            :select-options="selectOptionsCurrencyCode"
          ></znl-input>
        </el-col>
      </el-row>

      <el-row class="saleout-input-padd2">
        <el-col :span="24">
          <znl-input
            form-type="datepicker"
            :disabled="false"
            :is-must-fill="true"
            :title="this.dateTitle"
            :placeholder="this.dateType"
            value-format="yyyy-MM-dd"
            layout="left"
            width="170px"
            title-width="70px"
            :border="true"
            :clearable="true"
            v-model="Statement.StartOutOn"
            inp-perc="100%"
          ></znl-input>
          <span style="font-size: 12px">到</span>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            :placeholder="this.dateType"
            value-format="yyyy-MM-dd"
            layout="left"
            width="100px"
            :is-must-fill="true"
            :border="true"
            :clearable="true"
            v-model="Statement.EndOutOn"
            inp-perc="100%"
          ></znl-input>
        </el-col>
      </el-row>
    </div>

    <div slot="footer">
      <znl-button :height="28" :width="90" style-type="main" @click="onGenerateStatement">生成对账单</znl-button>
      <znl-button :height="28" :width="50" @click="()=>{$emit('close')}">取消</znl-button>
    </div>

    <!-- 销售对账单新增_编辑_查看 -->
    <statement-order-edit
      ref="statementEdit"
      v-if="statementOrderVisible"
      :accountVisible="statementOrderVisible"
      @close="statementOrderVisible=false"
      @save-success="saveSuccess"
    ></statement-order-edit>

    <!-- 采购对账单新增_编辑_查看 -->
    <purcha-statement-edit
      ref="purchastatementEdit"
      :accountVisible="purchastatementsVisible"
      @close="purchastatementsVisible=false"
      @save-success="saveSuccess"
    ></purcha-statement-edit>

    <!-- 选择客户 -->
    <!-- <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      :companyType="InTypes"
      :title="dlgTitle"
      @close="()=>{dialogCompanySelectVisible=false}"
      :searchModel="SelectSearchModel"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
      :CompanyName="Statement.CustomerName"
      @confirm="companyChecked"
    ></d-company-contact-select> -->
  </znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as common } from '~assets/scripts/methods/common'
import StatementOrderEdit from '@c_modules/Sale/StatementOrderEdit'
import PurchaStatementEdit from '@c_modules/Purchase/StatementOrderEdit'
import { dateTimeFormat } from '@scripts/methods/common'
const CONFIG = {
  PK: 'CreatedStatementID',
  InSearchURL: 'PurchaseStatementLine/LoadStatementCompany',
  OutSearchURL: 'SalesStatementLine/LoadStatementCompany'
}
export default {
  name: 'CreatedStatement',
  mixins: [znlMethodsMixin, getCommonDataMixin, common, dateTimeFormat],
  components: {
    StatementOrderEdit,
    PurchaStatementEdit
  },
  props: {
    statementvisible: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      statementCompany: [],
      statementType: '',
      Statement: {
        CustomerGUID: '',
        CustomerName: '',
        CurrencyCode: 'RMB',
        CurrencyName: '人民币',
        StartOutOn: '',
        EndOutOn: '',
        MatchOperation: 'Equal'
      },  // 弹出对账单
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      fieldsFilter: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false,
      fromSalesOrderOpen: false,
      dlgTitle: '客户名称',
      companyTitle: '客户名称：',
      dateType: '出库日期',
      dateTitle: '出库日期：',
      CompanyName: '',
      SelectSearchModel: {
        CompanyName: '',
        Address: '',
        ContactName: '',
        Telephone: '',
        CompanyTypeStr: ''
      },
      statementOrderVisible: false, //  销售对账单新增_编辑_查看
      purchastatementsVisible: false, //  采购对账单新增编辑
      dialogCompanySelectVisible: false,  // 选择客户
      InTypes: '1,3' // 1:客户，2：供应商，3:两者都是的公司
    }
  },
  watch: {
  },
  methods: {
    async onQuotedRows (item, statementType) {
      this.statementType = statementType
      if (this.statementType === 2) {
        this.InTypes = '2,3'
        this.companyTitle = '供应商名称：'
        this.dlgTitle = '供应商名称'
        this.dateType = '入库日期'
        this.dateTitle = '入库日期：'
      } else {
        this.InTypes = '1,3'
        this.companyTitle = '客户名称：'
        this.dlgTitle = '客户名称'
        this.dateType = '出库日期'
        this.dateTitle = '出库日期：'
        this.msg = '请选择客户名称'
      }
      var startDate = this.formatDateTime(new Date(item.StartOutOn), 'yyyy-MM-dd')
      var endDate = this.formatDateTime(new Date(item.EndOutOn), 'yyyy-MM-dd')
      this.Statement.StartOutOn = startDate
      this.Statement.EndOutOn = endDate
      this.Statement = item

      await this.$post(this.statementType === 2 ? CONFIG.InSearchURL : CONFIG.OutSearchURL, {
        StatementDateStart: startDate,
        StatementDateEnd: endDate
      }, (data) => {
        //判断Statement.CustomerName在不在可以对账的列表里面
        console.log(data);
        var companyNameChecked = false
        this.statementCompany = _.map(data, items => {
          if (items.CompanyName == this.Statement.CustomerName) {
            companyNameChecked = true
          }
          return {
            key: items.CompanyName,
            value: items.CompanyName
          }
        })
        if (data == null || data === undefined || data.length == 0) {

          if (this.statementType == 2) {
            this.$message({ message: startDate + "至" + endDate + "，该期间没有需要对账的供应商", type: 'error' })
          } else {
            this.$message({ message: startDate + "至" + endDate + "，该期间没有需要对账的客户", type: 'error' })
          }
        }
        if (data && data.length == 0 || !companyNameChecked) {
          this.Statement.CustomerName = ''
        }
      })

    },
    onGenerateStatement () {
      if (!this.hasValue(this.Statement.CustomerName)) {
        return this.$message({ message: '请选择' + this.dlgTitle, type: 'error' })
      } else if (!this.hasValue(this.Statement.CurrencyCode)) {
        return this.$message({ message: '请选择币种', type: 'error' })
      } else if (!this.hasValue(this.Statement.StartOutOn)) {
        return this.$message({ message: '请选择' + this.dateType + '开始时间', type: 'error' })
      } else if (!this.hasValue(this.Statement.EndOutOn)) {
        return this.$message({ message: '请选择' + this.dateType + '结束时间', type: 'error' })
      } else {
        if (this.statementType === 1) {
          this.statementOrderVisible = true
          this.$nextTick(() => {
            this.$refs.statementEdit.loadSalesOutInfo(this.Statement)
          })
        } else {
          this.purchastatementsVisible = true
          this.$refs.purchastatementEdit.loadPurchaseInfo(this.Statement)
        }
        this.$emit('close')
      }
    },
    saveSuccess () {
      this.$emit('save-success', true)
    },

    suggestionsSelect (item) {
      console.log(item)
      this.companyChecked([item])
    },
    onIconClick () {
      this.InTypes = '1,3' // 客户和供应商客户
      this.dialogCompanySelectVisible = true
      this.SelectSearchModel.CompanyName = this.Statement.CustomerName
      this.CompanyName = this.Statement.CustomerName
    },
    companyChecked (item) {
      if (this.hasValue(item)) {
        if (this.hasValue(item.CompanyName)) {
          // console.log(item, 'item1')
          this.Statement.CustomerName = item.CompanyName
        }
      }
    },
  }
}
</script>

<style lang="scss">
.saleout-createdstatement-box {
  .saleout-Statement-padd {
    padding: 0px 0 0 20px;
    .saleout-input-padd2 {
      padding: 2px 0;
    }
  }
  .znl-button {
    margin-left: 10px;
    margin-top: 10px;
  }
}
</style>

