<template>
  <div>
    <!--销售退货单编辑框-->
    <znl-dialog
      title="销售退货单"
      :visible="editvisible"
      subhead="(橙色底为必填项)"
      height="640px"
      width="1000px"
      class="dialog-salereturnedit-box"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="val=>{$emit('close', val)}"
    >
      <znl-gridmodule
        ref="flexGrid"
        header="编辑销售退货单"
        height="100%"
        gridtype="action"
        class="grid-data-salereturnedit-box m-border-box"
        layout-type="other"
        :has-znl-btns="false"
        :from-height="150"
        element-loading-text="正在保存,请稍等..."
        :from-btns="fromBtns"
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :on-init="onInit"
        :loading="loading"
        @click.native.stop="autocompleteShow=false"
        @page-loading="d=>{$emit('page-loading', d)}"
        @edit:cell-ended="onCellEdited"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style=" border: 1px solid #d8d8d8;">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style="border-bottom: 1px solid #e6e6e6; height: 40px;line-height: 40px;"
          >
            <el-col :span="5" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    ref="ShipTime"
                    title-width="70px"
                    layout="left"
                    placeholder
                    form-type="datepicker"
                    :readonly="false"
                    :disabled="false"
                    :border="true"
                    :is-must-fill="true"
                    tabindex="1"
                    v-model="StkOut.ShipTime"
                    width="100%"
                    title="*退货日期："
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="5">
              <znl-input
                ref="ShipUserID"
                title-width="60px"
                layout="left"
                form-type="select"
                width="92%"
                placeholder="请选择"
                popper-class="popperClass"
                title="*入库员："
                :disabled="false"
                :border="true"
                tabindex="2"
                :is-must-fill="true"
                :select-options="selectOptionsAllUserURL"
                v-model="StkOut.ShipUserID"
              ></znl-input>
            </el-col>

            <el-col :span="5">
              <znl-input
                title-width="65px"
                form-type="input"
                :disabled="!IsManual"
                placeholder="默认自动生成 "
                size="mini"
                layout="left"
                :is-must-fill="false"
                :border="true"
                title="单据编号："
                tabindex="5"
                width="100%"
                v-model="StkOut.BillNo"
                :on-icon-click="onBillIconClick"
                type="text"
              ></znl-input>
            </el-col>

            <el-col :span="5">
              <znl-input
                title-width="80px"
                form-type="input"
                :readonly="true"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="退货金额："
                :border="true"
                tabindex="6"
                width="100%"
                v-model="StkOut.Amount"
                type="text"
              ></znl-input>
            </el-col>

            <el-col :span="6">
              <znl-input
                title-width="65px"
                layout="left"
                ref="CurrencyCode"
                form-type="select"
                :disabled="true"
                width="67%"
                placeholder="请选择"
                popper-class="popperClass"
                title="*币 种："
                :border="true"
                tabindex="7"
                :is-must-fill="true"
                @change="CurrencyCodeChange"
                :select-options="selectOptionsCurrencyCode"
                v-model="StkOut.CurrencyCode"
              ></znl-input>

              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="this.IsExchangeRateRMB"
                placeholder
                size="mini"
                layout="left"
                :border="true"
                tabindex="8"
                :is-must-fill="false"
                title
                width="28%"
                v-model="StkOut.ExchangeRate"
                type="text"
              ></znl-input>
            </el-col>
          </el-row>

          <el-row class="formContainer m-borderbg-box" type="flex" justify="space-around">
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row class="form_Row_W100" type="flex" style="margin-bottom: 2px;">
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="znlAutocomplete"
                    custom-item="my-item-zh"
                    :disabled="true"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="true"
                    :title="this.CustomerNameTitle"
                    width="100%"
                    :border="true"
                    tabindex="3"
                    v-model="StkOut.CustomerName"
                    type="text"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="20" class="min-boder-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col :span="8">
                  <znl-input
                    ref="TransportCompany"
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    width="97%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="货运公司："
                    :border="true"
                    :is-must-fill="false"
                    :disabled="false"
                    tabindex="9"
                    :select-options="selectOptionsTransportCompany"
                    v-model="StkOut.TransportCompany"
                  ></znl-input>
                </el-col>
                <el-col :span="8">
                  <znl-input
                    ref="ShippingNo"
                    title-width="73px"
                    form-type="input"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="false"
                    :disabled="false"
                    title="货运单号："
                    width="90%"
                    tabindex="10"
                    v-model="StkOut.ShippingNo"
                    type="text"
                  ></znl-input>
                </el-col>
                <el-col :span="6">
                  <znl-input
                    ref="TaxRate"
                    v-model="StkOut.TaxRate"
                    :border="true"
                    :select-options="selectOptionsTaxRate"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="17"
                    title="税 点："
                    title-width="44px"
                    width="100%"
                    @change="onTaxRateChange"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row class="formContainer m-borderbg-box" type="flex" justify="space-around">
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row class="form_Row_W100" type="flex" style="margin-bottom: 2px;">
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    class="m-txt-remark"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="退货原因："
                    :border="true"
                    tabindex="4"
                    :rows="2"
                    width="100%"
                    v-model="StkOut.ReturnReason"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100">
                <el-col :span="24">
                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="24" v-show="false">
                      <el-col :span="3">
                        <span style="font-size: 12px;padding-left:10px">运费结算：</span>
                      </el-col>
                      <el-col :span="20">
                        <el-row class="form_el-radio">
                          <el-col :span="5">
                            <el-radio :label="1">我方垫付</el-radio>
                          </el-col>
                          <el-col :span="5">
                            <el-radio :label="2">客户支付</el-radio>
                          </el-col>
                          <el-col :span="5">
                            <el-radio :label="3">我方支付</el-radio>
                          </el-col>
                          <el-col :span="5">
                            <el-radio :label="4">供应商支付</el-radio>
                          </el-col>
                          <el-col :span="5">
                            <el-radio :label="5">无需运费</el-radio>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100 form_Row_lineH" type="flex">
                    <el-col :span="16">
                      <!-- <znl-input
                                    title-width="70px"
                                    ref="OtherFeeAmount"
                                    form-type="input"
                                    placeholder=""
                                    size="mini"
                                    layout="left"
                                    :is-must-fill="false"
                                    :border="true"

                                    title="运费金额："
                                    width="97%"
                                    :clearable="true"
                                    @clear="()=>{StkOut.OtherFeeAmount='';CalcStkOutAmoutAndGrossProfit(false)}"
                                    @blur="CalcStkOutAmoutAndGrossProfit(false)"
                                    v-model="StkOut.OtherFeeAmount"
                                    type="text">
                      </znl-input>-->
                      <el-row class="form_Row_W100" type="flex">
                        <el-col v-show="true">
                          <znl-input
                            title-width="70px"
                            form-type="input"
                            class="m-txt-remark"
                            placeholder
                            size="mini"
                            layout="left"
                            :is-must-fill="false"
                            title="备注说明："
                            :border="true"
                            tabindex="11"
                            :rows="2"
                            width="95%"
                            v-model="StkOut.Remark"
                            type="textarea"
                          ></znl-input>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div slot="from-content">
          <label style="font-size: 12px;color: red;margin-right: 8px;">数量为负数表示为退货操作!</label>
          <el-checkbox
            size="small"
            v-model="IsPrint"
            @change="onPrintValue()"
            :isShow="hasRes('Print')"
            style="margin-right:5px"
          >
            <label for="IsPrint">保存后并打印</label>
          </el-checkbox>
          <znl-button style-type="mac" :height="22" :isShow="hasRes('Print')" type="multiple">
            <znl-button-menu @click="onPrint">
              <span>
                <i class="iconfont icon-print_btn_ic"></i>打印
              </span>
            </znl-button-menu>
            <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            :disabled="isDisabled"
            :height="22"
            @click="stkoutSaveAndOut"
            :isShow="hasRes('Print')"
            style-type="minor"
            style="margin-left:5px"
          >
            <span>
              <i class="iconfont icon-save_btn_ic"></i>保存并入库
            </span>
          </znl-button>

          <znl-button
            :height="22"
            @click="stkoutSave"
            :isShow="hasRes('Print')"
            style-type="mac"
            :disabled="isDisabled"
          >
            <span>
              <i class="iconfont icon-save_btn_ic"></i>保存
            </span>
          </znl-button>
        </div>
        <znl-print
          :visible.sync="znlPrintVisible"
          :printInfo="printInfo"
          :loading="printLoading"
          :init-param="false"
          :is-get-default-param="printInfo.isGetDefaultParam"
          print-type="STK_Out"
          title="发货单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </znl-gridmodule>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import { renderRequiredColumn } from '~assets/scripts/methods/common'
import autocomplete from '@c_common/znlGrid/autocomplete'
import ZnlPrint from '@c_common/znlGrid/commonComponents/printTemplates'

const CONFIG = {
  PK: 'StkOutLineID',
  configURL: 'SalesReturnLine/GetConfig',
  configJsonURL: 'SalesReturn/GetConfig',
  SalesOrderReturnSaveURL: 'SalesReturnEdit/Add',
  SalesOrderReturnLineSeachURL: 'SalesReturnEdit/GetStkOutLineSeach',
  SavePageParamURL: 'PageParam/Save', // 保存页面参数配置
  SalesGotoStkOut: 'SalesOrder/GotoStkOut',
  NewInfo: 'SalesReturnEdit/NewInfo',
  Search: 'SalesReturnEdit/Search',
}

const defaultStkOut = {
  BillNo: '', // 销售单号
  ShipTime: new Date().toLocaleDateString(), // 出货日期
  Shipper: '', // 销售员
  ShipUserID: '',
  Amount: null, // 应收金额
  CustomerGUID: '', // 客户guid
  CustomerName: '', // 客户名称
  ReceiptType: '', // 收款方式
  ReceivedAmount: null, // 实收金额
  CustomerBankNo: '', // 收款帐户
  Address: '', // 收货地址
  ExpectReceiptDate: null, // 预计收款日期
  GrossProfit: null, // 利润总额
  DiscountAmount: null, // 折扣金额
  Contact: '', // 联系人
  Telephone: '', // 联系电话
  CustomerOrderNo: '', // 客户订单号
  TaxRate: null, // 税点
  OtherFeeAmount: null, // 其它费用
  TransportCompany: '', // 快递公司
  ShippingNo: '', // 快递单号
  CurrencyCode: '', // 币种
  ExchangeRate: '1', // 汇率
  Remark: '', // 销售备注
  ReturnReason: '', // 退货原因
  IsAllowOut: true,
  LineList: [], // 出库单详情
  IsAdd: true, // 是否新增
  PDID: '',
  CBGUID: '' // 收款账号,
}
export default {
  name: 'SalesOrderReturnEdit',
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin, renderRequiredColumn],
  components: {
    autocomplete,
    ZnlPrint
  },
  data() {
    return {
      selectOptionsAddress: [],
      dialogLoading: true,
      IsContinuousRecord: false,
      autocompleteShow: false,
      dialogCompanySelectVisible: false,
      InTypes: '', // 1:客户，2：供应商，3:两者都是的公司
      StkOut: {
        BillNo: '', // 销售单号
        ShipTime: new Date().toLocaleDateString(), // 出货日期
        ShipUserID: this.$store.state.accountInfo.UserID, // 销售员ID
        Shipper: this.$store.state.accountInfo.UserName, // 销售员
        Amount: null, // 应收金额
        CustomerGUID: '', // 客户guid
        CustomerName: '', // 客户名称
        ReceiptType: '', // 收款方式
        ReceivedAmount: null, // 实收金额
        CustomerBankNo: '', // 收款帐户
        Address: '', // 收货地址
        ExpectReceiptDate: null, // 预计收款日期
        GrossProfit: null, // 利润总额
        DiscountAmount: null, // 折扣金额
        Contact: '', // 联系人
        Telephone: '', // 联系电话
        Mobile: '', // 手机号码
        CustomerOrderNo: '', // 客户订单号
        TaxRate: null, // 税点
        OtherFeeAmount: null, // 其它费用
        TransportCompany: '', // 快递公司
        ShippingNo: '', // 快递单号
        CurrencyCode: '', // 币种
        ExchangeRate: '1', // 汇率
        Remark: '', // 销售备注
        ReturnReason: '', // 退货原因
        LineList: [], // 出库单详情
        IsAdd: true, // 是否新增
        IsAllowOut: true, // 是否允许出库
        PDID: '',
        CBGUID: '',    // 收款账号,
        stkOutGUID: '', // 发货单ID
        IsTakeOutPrint: false, // 是否带出上次价格
        ExistSalesOrder: false // 是否先做销售单
      },
      IsStkOut: false, // 是否允许负库存出库
      IsCustomerNameRequired: false, // 公司名称是否必填
      CustomerNameTitle: '客户名称：',
      TransportCompanyOptions: [], // 快递公司
      resourceList: [],
      paramTax: '',
      paramQtyType: '自有',
      paramQtyUnit: 'PCS',
      ParamJosn: [],
      ParamJosnLine: [],
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      loading: false,
      fromBtns: [
      ],
      IsExchangeRateRMB: true,
      startTime: 0,
      endTime: 0,
      autocompleteSearchData: '',
      CompanyName: '',
      SelectSearchModel: {
        CompanyName: '',
        Address: '',
        ContactName: '',
        Telephone: '',
        CompanyTypeStr: ''
      },
      IsPrint: false, // 保存后继续打印
      // visibled: this.$store.state.visibled,
      IsstkOutGUIDLine: false, // 是否是销售退货
      isDisabled: false,
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      GCode: 'StkOut',
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: 'STK_Out' // 打印模板类型
      } // end 打印模块参数
    }
  },
  props: {
    editType: String,
    title: String,
    editvisible: {
      type: Boolean,
      defaule: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    stkOutGUIDLine: Array, // 出库明细
    stkOutGUID: String
  },
  watch: {

    dialogLoading(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.flexGrid.doLayout()
        })
      }
    }
  },
  methods: {
    // 初始化
    onInit: async function () {
      let config = await this.isSaveStorage('salesReturnLine', CONFIG.configURL)
      let configs = await this.isSaveStorage('salesReturn', CONFIG.configJsonURL)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 隐藏状态列

      _.each(columns, (item, index) => {
        if (item.binding === 'StatusName') {
          item.hasRole = false
          item.visibled = false
        }
      })
      // 获取参数配置
      this.ParamJosn = configs.ParamJosn
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn
      // 下拉列表
      // 采购员
      let UserAllowUsers = this.selectOptionsAllUserURL
      // console.log('采购员', UserAllowUsers)
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'BuyerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      // 单位
      let QtyUnitDropDown = await this.selectOptionsQtyUnit
      // console.log('单位', QtyUnitDropDown)
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: this.selectStorageType,
          showDropDown: true
        })

      // 品质
      let QualityDropDown = await this.selectOptionsQuality
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Quality'
        }),
        { dataMap: QualityDropDown, showDropDown: true }
      )
      this.defaultConfig = config
      columns = this.renderRequiredColumn(columns)
      this.columns = columns
      if (this.$store.state.paramOrder) {
        // 从发货单主单退货 编辑退货单
        if (this.$store.state.paramOrder.stkOutReturnInfo) {
          this.IsstkOutGUIDLine = true
          let info = this.$store.state.paramOrder.stkOutReturnInfo
          this.$store.commit('setParamOrder', {})
          this.LoadReturnInfo(CONFIG.NewInfo, info)
        }
        if (this.$store.state.paramOrder && this.$store.state.paramOrder.stkOutGuid) {
          let info = { StkOutGUID: this.$store.state.paramOrder.stkOutGuid }
          this.$store.commit('setParamOrder', {})
          this.LoadReturnInfo(CONFIG.Search, info)
        }
        // 从发货单明细退货
        if (this.$store.state.paramOrder.stkOutGUIDLine) {
          this.IsstkOutGUIDLine = true
          let info = this.$store.state.paramOrder.stkOutGUIDLine
          let isreturn = false
          if (this.$store.state.paramOrder.IsReturn) {
            isreturn = this.$store.state.paramOrder.IsReturn
          }
          this.$store.commit('setParamOrder', {})
          this.LoadLineAddData(info, isreturn)
        }
        // 从销售单退货
        if (this.$store.state.paramOrder.SalesOrderInfo) {
          this.IsstkOutGUIDLine = true
          let info = this.$store.state.paramOrder.SalesOrderInfo
          this.$store.commit('setParamOrder', {})
          this.LoadSaleData(info)
          // this.isAllow = false // 编辑禁用选择
        }
      }

      this.fromBtns = [
        {
          name: '删除选择行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), data => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1)
                  return
                }
              })
            })
            let Amount = 0
            _.each(this.itemSource, (item, i) => {
              Amount += Number(item.Amount)
            })
            this.StkOut.Amount = Amount
          }
        }

      ]
      this.$nextTick(() => {
        this.dialogLoading = false
      })
    },

    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title
      this.printInfo.template = printInfo.template
    },

    confirmPrint(data) {
      this.onPrint()
    },
    // 打印模板
    onPrint() {
      var tableName = this.printInfo.template// 模板ID
      var OutGuid = this.StkOut.StkOutGUID// 销售单ID

      if (!this.hasValue(tableName)) {
        this.$message({ message: '请选择打印模板', type: 'error' })
        this.znlPrintVisible = true;
        return false
      } else if (OutGuid === '' || OutGuid === null || OutGuid === undefined) {
        this.$message({ message: '先保存后才能打印', type: 'error' })
        return false
      } else {
        let printParm = { TableName: tableName, Guid: OutGuid, SubCompanyId: this.printInfo.title }
        this.printStkOut(printParm, () => { })
        return true
      }
    },

    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.Price)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.Price)
        item.PriceInTax = _price === 0 ? null : _price
      } else if (!this.hasValue(item.Price) && this.hasValue(item.PriceInTax)) {
        // 含税算未税
        item.Price = this.CalcPrice(item.PriceInTax)
      }
      item.GrossProfit = this.CalcGrossProfit(
        item.Price,
        item.BuyPrice,
        item.Qty,
        item.ShareFee
      )
      // console.log(item.GrossProfit, ' item.GrossProfit')
      item.Amount = this.toFloat(this.CalcAmout(item.Qty, item.Price), 2)
      this.CalcPay(false)
      this.CalcStkOutAmoutAndGrossProfit(false)
    },
    // 绑定数据
    onBindData() {
      this.StkOut.LineList = this.itemSource
      this.$emit('page-loading', false)
    },

    CurrencyCodeChange(val) {
      // 币种改变选项事件
      let itemVal = val ? val.key : this.StkOut.CurrencyCode // 选中的值
      if (!this.hasValue(itemVal)) { itemVal = 'RMB' }
      if (itemVal === 'RMB') {
        this.IsExchangeRateRMB = true
      } else {
        this.IsExchangeRateRMB = false
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) { return item.key === itemVal })
      this.StkOut.ExchangeRate = info.ExchangeRate
    },

    onTaxRateChange(val) {
      // Qty 数量
      // Price 未税
      // PriceInTax 含税
      // Amount 小计金额
      // GrossProfit 利润
      //  console.log('this.StkOut.TaxRate2222 ', this.StkOut.TaxRate)
      val ? (this.StkOut.TaxRate = val.key) : (this.StkOut.TaxRate = null);

      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].Price)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].Price);
            source[i].PriceInTax = _priceIntax;
          } else if (this.hasValue(source[i].PriceInTax)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].PriceInTax);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].PriceInTax
          ).toFixed(2);
          source[i].GrossProfit = this.CalcGrossProfit(
            source[i].Price,
            source[i].BuyPrice,
            source[i].Qty,
            source[i].ShareFee,
            source[i].Discount
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcStkOutAmoutAndGrossProfit(false);
      }
    },

    // 保存订单
    Save(isOut) {
      this.StkOut.IsAllowOut = isOut // 是否允许出库
      var vm = this
      vm.loading = true
      return this.$post(CONFIG.SalesOrderReturnSaveURL, this.StkOut, (data, logic) => {
        vm.loading = false
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          // vm.StkOut.stkOutGUID = data
          this.$emit('save-success', true)
          if (vm.IsPrint) {
            // vm.onPrint()
          }
          if (!this.IsContinuousRecord) {
            this.$emit('close')
          } else {
            vm.onResetStkout() // 重置
          }
        }
      }).finally(() => {
        vm.loading = false
      })
    },
    // 保存订单
    stkoutSave() {
      // 保存
      if (this.checkData()) {
        this.Save(false)
      }
    },
    // 保存并出库
    stkoutSaveAndOut() {
      if (this.checkData()) {
        this.Save(true)
      }
    },
    checkData() {
      let ret = true
      this.onSaleChange()
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false
        return this.$message({ message: '请修改填写错误的单元格内容后再保存', type: 'error' })
      } else if (this.IsCustomerNameRequired && !this.hasValue(this.StkOut.CustomerName)) {
        ret = false
        this.$refs.znlAutocomplete.handleFocus()
        this.$message({ message: '请填写客户名称', type: 'warning' })
      } else if (!this.hasValue(this.StkOut.ShipTime)) {
        ret = false
        this.$refs.ShipTime.handleFocus()
        this.$message({ message: '请选择退货日期', type: 'warning' })
      } else if (!this.hasValue(this.StkOut.ShipUserID)) {
        ret = false
        this.$refs.ShipUserID.handleFocus()
        this.$message({ message: '请选择发货员', type: 'warning' })
      } else if (this.StkOut.CurrencyCode === undefined || this.StkOut.CurrencyCode === '' || this.StkOut.CurrencyCode === 0
      ) {
        ret = false
        this.$refs.CurrencyCode.handleFocus()
        this.$message({ message: '请选择币种', type: 'warning' })
      } else if (this.StkOut.ShippingNo !== undefined && this.StkOut.ShippingNo !== '' && (this.StkOut.TransportCompany === undefined || this.StkOut.TransportCompany === '')
      ) {
        ret = false
        this.$refs.TransportCompany.handleFocus()
        this.$message({ message: '已填写快递单号,请选择快递公司', type: 'warning' })
      } else if (this.StkOut.TransportCompany !== undefined && this.StkOut.TransportCompany !== '' && (this.StkOut.ShippingNo === undefined || this.StkOut.ShippingNo === '')
      ) {
        ret = false
        this.$refs.ShippingNo.handleFocus()
        this.$message({ message: '已选择快递公司,请填写快递单号', type: 'warning' })
      } else {
        let rows = this.itemSource
        if (rows.length > 0) {
          let rowId = 0
          let messageStr = ''
          _.find(rows, item => {
            rowId = rowId + 1
            if (!this.hasValue(item['Model'])) {
              messageStr = '单据详情第' + rowId + '行 请填写出货型号'
              ret = false
            } else if (!isFinite(item['Qty']) || !this.hasValue(item['Qty'])) {
              messageStr = '单据详情第' + rowId + '行 数量必须为数值型且不能为空'
              ret = false
              return
            } else if (!this.hasValue(item['Price']) || !this.hasValue(item['PriceInTax'])) {
              messageStr = '单据详情第' + rowId + '行 未税价必须为数值型且不能为空'
              ret = false
            } else if (!_.isUndefined(item['ShareFee']) && !isFinite(item['ShareFee'])) {
              messageStr = '单据详情第' + rowId + '行 分摊费必须为数值型'
              ret = false
            } else if (!_.isUndefined(item['BuyPrice']) && !isFinite(item['BuyPrice'])) {
              messageStr = '单据详情第' + rowId + '行 采购价必须为数值型'
              ret = false
            } else if (!this.IsstkOutGUIDLine) {

            }
          })
          this.StkOut.LineList = rows
          if (messageStr.length > 0) {
            ret = false
            this.$message({ message: messageStr, type: 'warning' })
          }
        } else {
          ret = false
          this.$message({ message: '请添加出库单详情', type: 'warning' })
        }
      }
      return ret
    },
    //主单
    LoadReturnInfo: async function (url, info) {
      let stkOuts = await this.$post(url, info)
      if (stkOuts.IsAudited || stkOuts.Status === 2 || stkOuts.Status === 3) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
      this.StkOut.IsAdd = false
      this.StkOut = stkOuts
      this.StkOut.IsReturn = true
      this.itemSource = stkOuts.LineList
      this.$nextTick(function () {
        this.onBindData()
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
      })
    },
    // 根据出库单明细GUID加载实体
    LoadLineAddData: async function (guid, isreturn) {
      await this.$post(CONFIG.SalesOrderReturnLineSeachURL, {
        OutLineGuids: guid,
        IsReturn: isreturn
      }, (stkOuts, logic) => {
        if (logic.code !== 200) {
          this.$message({message: logic.msg, type: 'error'})
          return false
        }
        this.StkOut = _.extend({}, defaultStkOut, stkOuts)
        this.StkOut.IsReturn = true
        this.StkOut.ShipUserID = this.toInt(stkOuts.ShipUserID)
        this.$nextTick(function () {
          this.itemSource = stkOuts.LineList
          this.onBindData()
          this.CalcStkOutAmoutAndGrossProfit(true)
          this.StkOut.IsAdd = false
          // this.isAllow = false // 编辑禁用选择
          this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
        })
      })
    },
    // 从销售单点击退货
    LoadSaleData: async function (info) {
      return this.$post(CONFIG.SalesGotoStkOut, info, (data, logic) => {
        if (logic.code === 200) {
          let rowID = 0
          _.each(data.LineList, (item, i) => {
            rowID++
            item.RowGuid = rowID
            item.SalesModel = item.Model
            item.Model = item.OutModel
            item.Amount = this.CalcAmout(item.Qty, item.PriceInTax)
          })
          this.StkOut = _.extend({}, defaultStkOut, data)
          this.StkOut.IsReturn = true
          this.StkOut.ShipUserID = this.toInt(data.SalerID)
          // 禁用编辑单元格
          // for (var i = 0; i < this.columns.length; i++) {
          //   if (this.columns[i].binding === 'SupplierInfo' ||
          //       this.columns[i].binding === 'ShareFee' ||
          //       this.columns[i].binding === 'Remark' ||
          //       this.columns[i].binding === 'Remark2' ||
          //       this.columns[i].binding === 'Remark3') {
          //     this.columns[i].isReadOnly = false
          //   } else {
          //     this.columns[i].isReadOnly = true
          //   }
          // }
          this.$nextTick(function () {
            this.itemSource = this.StkOut.LineList
            this.onBindData()
            this.CalcStkOutAmoutAndGrossProfit(true)
            this.StkOut.IsAdd = false
          })
        } else {
          return this.$message({ message: logic.msg, type: 'error' })
        }
      })
    },

    hasRes(code) {
      return _.some(this.resourceList, page => page.Code === code)
    },

    CalcPriceInTax(price) {
      // 计算含税价
      return this.toFloat(price * (1 + Number(this.StkOut.TaxRate) / 100), 6)
    },
    CalcPrice(priceintax) {
      // 计算未税
      return this.toFloat(priceintax / (1 + Number(this.StkOut.TaxRate) / 100), 6)
    },
    CalcAmout(qty, salesPrice) {
      // 计算金额
      return this.toFloat(qty * salesPrice, 2)
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.StkOut.Amount = 0
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        this.StkOut.Amount += this.toFloat(o.Amount, 2)
      })
    },
    CalcGrossProfit(price, buyprice, qty, shareFee) {
      // 计算毛利润
      let rate = 1
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate)
      }
      let profit = (this.toFloat(price) * rate - this.toFloat(buyprice)) * qty
      profit = profit - this.toFloat(shareFee)
      return this.toFloat(profit, 2)
    },
    ConvertNumber(num) {
      // 转出数据格式
      return (_.isNaN(num) || _.isUndefined(num))
        ? null
        : this.toFloat(num, 6)
    },

    // 重置页面 清除数据
    onResetStkout() {
      this.StkOut.ReturnReason = ''// 退货原因
      this.StkOut.stkOutGUID = ''
      this.StkOut.BillNo = '' // 销售单号
      this.StkOut.Amount = null // 应收金额
      this.StkOut.CustomerGUID = '' // 客户guid
      this.StkOut.CustomerName = '' // 客户名称
      this.StkOut.ReceiptType = '' // 收款方式
      this.StkOut.ReceivedAmount = null // 实收金额
      this.StkOut.CustomerBankNo = '' // 收款帐户
      this.StkOut.Address = '' // 收货地址
      this.StkOut.ExpectReceiptDate = '' // 预计收款日期
      this.StkOut.GrossProfit = null // 利润总额
      this.StkOut.DiscountAmount = null // 折扣金额
      this.StkOut.Contact = '' // 联系人
      this.StkOut.Telephone = '' // 联系电话
      this.StkOut.Mobile = '' // 手机号码
      this.StkOut.CustomerOrderNo = '' // 客户订单号
      this.StkOut.OtherFeeAmount = null // 其它费用
      this.StkOut.TransportCompany = '' // 快递公司
      this.StkOut.ShippingNo = '' // 快递单号
      this.StkOut.Remark = '' // 销售备注
      this.StkOut.IsAdd = true // 是否新增
      this.StkOut.LineList = []
      this.itemSource = []
      this.StkOut.CBGUID = ''
    },

    // 单元格结束触发事件
    onCellEdited(aftervalue, beforevalue, colname, gridobj, rowindex, colindex) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 修改的行
      // let rowData = gridobj.rows[rowindex].dataItem
      // console.log(gridobj)
      if (!gridobj) return
      let rowData = gridobj.row

      let numbers = this.ConvertNumber(rowData.Qty)
      let priceIntax = this.ConvertNumber(rowData.PriceInTax)
      let price = this.ConvertNumber(rowData.Price)
      let buyprice = this.ConvertNumber(rowData.BuyPrice)
      let shareFee = isNaN(this.ConvertNumber(rowData.ShareFee))
        ? 0
        : this.ConvertNumber(rowData.ShareFee)
      if (colname === 'SalesModel') {
        this.itemSource[rowindex]['SalesModel'] = aftervalue.toUpperCase()
      }
      if (colname === 'Price') {
        // 由未税计算出含税价
        priceIntax = this.toFloat(this.CalcPriceInTax(aftervalue), 6)

        this.itemSource[rowindex]['Price'] = this.toFloat(aftervalue, 6)
        this.itemSource[rowindex]['PriceInTax'] = priceIntax
      }
      if (colname === 'PriceInTax') {
        // 含税算未税
        // 由含税计算出未税价
        price = this.toFloat(this.CalcPrice(aftervalue), 6)
        this.itemSource[rowindex]['PriceInTax'] = this.toFloat(aftervalue, 6)
        this.itemSource[rowindex]['Price'] = price
      }
      if (colname === 'Qty') {
        numbers = this.ConvertNumber(aftervalue)
        this.itemSource[rowindex]['PurchaseAmount'] = this.CalcAmout(numbers, buyprice)
      }

      // 采购价算采购总额
      if (colname === 'BuyPrice') {
        buyprice = this.ConvertNumber(aftervalue)
        this.itemSource[rowindex]['PurchaseAmount'] = this.CalcAmout(numbers, buyprice)
      }

      let AmountSummary = this.CalcAmout(numbers, priceIntax)
      this.itemSource[rowindex]['Amount'] = this.toFloat(AmountSummary, 2)

      let grossSummary = this.CalcGrossProfit(price, buyprice, numbers, shareFee)
      this.itemSource[rowindex]['GrossProfit'] = grossSummary

      this.CalcStkOutAmoutAndGrossProfit(false)
    },
    CalcStkOutAmoutAndGrossProfit(isEdit) {
      // 计算金额和毛利润, 分摊费
      this.StkOut.Amount = 0.0 // 应收
      this.StkOut.GrossProfit = 0.0 // 利润总额
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        this.StkOut.Amount += this.toFloat(o.Amount, 2)
        this.StkOut.GrossProfit += this.toFloat(o.GrossProfit, 2)
      })
      let GrossProfitSummary =
        this.StkOut.GrossProfit -
        this.ConvertNumber(this.StkOut.DiscountAmount) -
        this.ConvertNumber(this.StkOut.OtherFeeAmount)
      this.StkOut.GrossProfit = this.toFloat(GrossProfitSummary, 2)
      this.StkOut.Amount = this.toFloat(this.StkOut.Amount, 2)
      this.CalcProfitMargins()
    },

    CalcProfitMargins() {
      let rate = 1
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate)
      }
      let tempSource = this.itemSource

      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          // 利润率=(未税单价*汇率-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
          let price = this.toFloat(tempSource[i].Price)
          let buyPrice = this.toFloat(tempSource[i].BuyPrice)
          if (price > 0 && rate > 0) {
            tempSource[i].ProfitMargins = this.GetProfitRate(price, buyPrice, rate)
          } else {
            tempSource[i].ProfitMargins = ''
          }
        }
        this.itemSource = tempSource
      }
    },

    hasValue(val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },

    onSaleChange() {
      // 销售员选中事件
      let buyer = _.find(this.selectOptionsAllUserURL, item => {
        return item.key === Number(this.StkOut.ShipUserID)
      })
      if (!_.isUndefined(buyer)) { this.StkOut.Shipper = buyer.value }
    },

    onBillIconClick() {
      if (this.StkOut.IsAdd) {
        this.IsManual = !this.IsManual
        if (!this.IsManual) {
          this.StkOut.BillNo = ''
        }
      } else {
        this.$message({ message: '单据编号生成后不可以修改！', type: 'error' })
      }
    },

    autocompleteChange(val) {
      this.startTime = new Date()
      _.delay(() => {
        this.endTime = new Date()
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val)
        }
      }, 800)
    },

    autocompleteSearch(isSearch, data) {
      if (!isSearch) return
      this.autocompleteSearchData = data
      if (data.length > 0) {
      } else {
        this.autocompleteShow = false
      }
    },

    // 连续录单模式保存到参数配置
    // onContinuousRecordValue () {
    //   let datas = _.find(this.ParamJosn, function (paramjosn) {
    //     if (paramjosn && paramjosn.ParamCode === 'IsContinuousRecord') {
    //       return paramjosn
    //     }
    //   })
    //   if (this.hasValue(datas)) {
    //     datas.ParamValue = this.IsContinuousRecord ? 1 : 0
    //     this.$post(CONFIG.SavePageParamURL, { GCode: this.GCode, ParamJson: [datas] }, (data) => {
    //       localStorage.removeItem('salesReturn')
    //     })
    //   }
    // },
    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsAutoPrint') {
          return paramjosn
        }
      })
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0
        this.$post(CONFIG.SavePageParamURL, { GCode: this.GCode, ParamJson: [datas] }, (data) => {
          localStorage.removeItem('salesReturn')
        })
      }
    }

  },
  mounted() {
  },
  created() {
  }
}
</script>
<style lang="scss">
</style>

