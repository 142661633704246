 <template>
  <znl-layout-spa :page-loading="pageLoading">
    <div class="second-tab-div">
      <FileLibrary
        ref="fileLibrary"
        :init-data="false"
        @page-loading="
          (d) => {
            pageLoading = d;
          }
        "
      ></FileLibrary>
    </div>
  </znl-layout-spa>
</template>

<script>
import FileLibrary from "@c_modules/Stk/FileLibrary"; //资料库页面

// 窗口大小监控mixin
import Resize from "~assets/scripts/mixins/resize";
import { isClient } from "~/lib/runtime";

export default {
  name: "VZnlFileLibrary",
  components: {
    FileLibrary,
  },
  mixins: [Resize],
  data() {
    return {
      pageLoading: true,
    };
  },
  methods: {
    onresize() {
      //从store取得domRect，在计算
      const height = isClient()
        ? this.$store.state.domRect.clientHeight
        : this.$store.state.domRect.clientHeight - 50;

      if (this.$refs.fileLibrary) {
        this.$refs.fileLibrary.$refs.table.boxHeight = height - 10;
      }
    },
  },
  mounted() {

  },
  async created() {},
};
</script>
