<template>
  <!-- 销售单明细 -->
  <!-- :totalField="['Amount','Qty','GrossProfit']" -->
  <div class="module-wrapper m-sales-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :show-title-menus="true"
      box-class="m-sales-order-line"
      :header-menus="getResetTableHeaderMenu('SalesOrderLine')"
      @on-show-trigger="onHideShowColTrigger"
      :showCheck="true"
      checkbox-binding-key="SOLineGuid"
      element-loading-text="数据加载中,请稍后..."
      v-loading="isLoading"
      :totalField="[
        'Amount',
        'Qty',
        'NoticeQty',
        'PurchaseQty',
        'StkInQty',
        'StkOutQty',
        'UnStkOutQty',
      ]"
      :drag-done="onDragDone"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 销售明细 </span>
        <!-- <znl-button style-type="mac" :isShow="hasRes('Print')" type="multiple">
          <znl-button-menu @click="onPrint">
            <i class="iconfont icon-print_btn_ic"></i>
            <span>打印</span>
          </znl-button-menu>
        </znl-button>-->

        <znl-button
          v-if="isMainAccount || hasRes('Out')"
          style-type="mac"
          :height="22"
          @click="onSalesOrderLineOut()"
        >
          <i class="iconfont icon-sell-wh_btn_ic"></i><span>出库</span>
        </znl-button>

        <znl-button
          v-if="isMainAccount || hasRes('Add') || hasRes('Edit')"
          style-type="mac"
          :height="22"
          @click="onCancelQty()"
        >
          <i class="iconfont icon-sell-wh_btn_ic"></i><span>取消未出数量</span>
        </znl-button>

        <znl-button
          v-if="isMainAccount || hasRes('Add') || hasRes('Edit')"
          style-type="mac"
          :loading="true"
          :height="22"
          @click="onBindData()"
        >
          <i class="iconfont icon-table_refresh_btn_n" title="刷新"></i>
        </znl-button>
      </div>
    </znl-table>

    <!--发货单编辑-->
    <StkOutEdit
      v-if="stkOutEditVisible"
      :editvisible="stkOutEditVisible"
      edit-type="editType"
      @close="stkOutEditVisible = false"
      @confirm="stkOutEditVisible = false"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
    ></StkOutEdit>

    <!-- 取消未出数量 -->
    <cancel-order-line-qty
      v-if="cancelOrderLineQtyVisible"
      :visible="cancelOrderLineQtyVisible"
      :salesOrderLineGuid="soLineGuidList"
      @close="val => onCancelQtyClose(val)"
    ></cancel-order-line-qty>
  </div>
</template>
<script>
// import PrintTemplate from '@c_modules/PPM/PrintTemplate'
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import * as urls from "~/lib/urls";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import CancelOrderLineQty from './CancelOrderLineQty';

const CONFIG = {
  salesOrderConfigURL: "SalesOrder/GetConfig",
  configURL: "SalesOrderLine/GetConfig",
  searchURL: "SalesOrderLine/Search",
  pdfURL: "SalesOrderLine/DownloadTrackCodePdf",
  apiBaseUrl: urls.getErpApiBaseUrl(),
  GetStkOutBySOLineGuids: "SalesOrderLine/GetStkOutBySOLineGuids",
};

export default {
  name: "SalesOrderLine",
  config: CONFIG,
  mixins: [getCommonDataMixin, erpTableSetHandler],
  components: {
    // PrintTemplate
    CancelOrderLineQty,
    StkOutEdit,
    CancelOrderLineQty,
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      parameterConfig: [],
      soGuid: null,
      CompanyName: "",
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      resourceList: [],
      stkOutEditVisible: false,
      cancelOrderLineQtyVisible: false,
      isLoading: false,
      soLineGuidList: [],
    };
  },
  async mounted() {
    await this.onInit();
    await this.getOutResource();
  },
  methods: {
    onInit: async function (config) {
      if (!config || config === undefined) {
        config = await this.isSaveStorage("salesOrderLine", CONFIG.configURL);
      }
      let columns = config.ColumnConfigs;
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }

      //处理特殊权限
      if (!this.isMainAccount) {
        let noSeeProfit = this.getSpecialResourceByCode("NoSeeProfit");
        if (noSeeProfit) {
          _.remove(columns, (item) => {
            if (item.BindField == "GrossProfit")
              // 利润
              return true;
            else if (item.BindField == "GrossProfitRate")
              // 利润率
              return true;
            else if (item.BindField == "CostPrice")
              // 采购价
              return true;
          });
        }
      }
      this.columns = columns;
      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },
    hasRes(code) {
      if (!this.resourceList) {
        return;
      }
      return _.includes(this.resourceList, code);
    },
    async onBindData(pageIndex = 1) {
      if (!this.hasValue(this.soGuid)) {
        return;
      }
      let data = _.extend(
        {},
        {
          SOGuid: this.soGuid,
        }
      );
      this.isLoading = true;
      try {
        return await this.$post(CONFIG.searchURL, data, (data) => {
          this.itemSource = data === undefined ? [] : data;
        });
      } catch {
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async onHeadSearch(soGuid, companyName) {
      if (soGuid) {
        this.soGuid = soGuid;
        await this.onBindData();
      }
      if (companyName) {
        this.CompanyName = companyName;
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("SalesOrderLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("SalesOrderLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("SalesOrderLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("SalesOrderLine");
    },
    onPrint() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows.length == 0) {
        return this.$message({
          message: "请选中要打印的销售明细",
          type: "error",
        });
      }
      var soLineID = "";
      var trackCodeIsNull = false;
      _.each(rows, (data) => {
        soLineID += data.SOLineID + ",";
        if (data.TrackCode == undefined || data.TrackCode == "") {
          trackCodeIsNull = true;
        }
      });
      if (trackCodeIsNull) {
        this.$message({ message: "打印的数据跟踪码不能为空", type: "error" });
        return;
      }
      this.$post(
        CONFIG.pdfURL,
        { SOGuid: this.soGuid, SOLineId: soLineID },
        (data, logicmsg) => {
          if (data != undefined && data != "") {
            this.downFile(
              {
                FileName: "销售明细跟踪码.pdf",
                FileUrl: `${CONFIG.apiBaseUrl}` + data,
              },
              function (res) {}
            );
          } else if (logicmsg != undefined && logicmsg.msg != undefined) {
            this.$message({ message: logicmsg.msg, type: "error" });
          }
        }
      );
      // let fileUrl = `${CONFIG.apiBaseUrl}SalesOrderLine/DownloadTrackCodePdf?companyId=` + this.$store.state.accountInfo.CompanyID + "&soGuid=" + this.soGuid + "&soLineID=" + soLineID
      // this.downFile({
      //   FileName: '销售明细跟踪码.pdf',
      //   FileUrl: fileUrl,
      // }, function (res) { })

      // this.$refs.PrintTemplate.initPrintDetails(rows, this.CompanyName, true)
    },
    //销售明细出库
    onSalesOrderLineOut() {
      // 获取选中的行 先判断勾选再判断选中
      let guids = [];
      let rows = this.$refs.table.getCheckedRows();
      if (rows === undefined || rows === null || rows.length === 0) {
        return this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error",
        });
      }
      guids = _.map(rows, (item) => {
        return item.SOLineGuid;
      });

      this.$post(
        CONFIG.GetStkOutBySOLineGuids,
        { SOLineGuids: guids },
        (data, logic) => {
          if (logic.code === 200) {
            //如果有对应的发货单信息则提示用户，并加载发货单
            if (
              data != null &&
              data.StkOutGUID !== undefined &&
              data.StkOutGUID !== null
            ) {
              this.$confirm("销售明细存在未出库的发货单，是否打开？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                let info = { stkOutGUID: data.StkOutGUID };
                this.$store.commit("setParamOrder", info);
                this.stkOutEditVisible = true;
              });
            }
            //如果没有发货单信息则生成发货单信息
            else {
              let info = { SalesOrderLineInfo: { SOLineGuids: guids } };
              this.$store.commit("setParamOrder", info);
              this.stkOutEditVisible = true;
            }
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        }
      );
    },
    //取当前账户是否有发货权限
    async getOutResource() {
      let salesOrderConfig = await this.isSaveStorage(
        "salesOrder",
        CONFIG.salesOrderConfigURL
      );
      if (
        this.hasValue(salesOrderConfig.ResourceList) &&
        salesOrderConfig.ResourceList.length > 0
      ) {
        let temp = salesOrderConfig.ResourceList.map((item) => item.Code);
        if (temp.includes("Out")) this.resourceList.push("Out");
      }
    },

    // 取消未出数量
    async onCancelQty () {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === undefined || rows === null || rows.length === 0) {
        return this.$message({
          message: "请勾选要取消的销售明细",
          type: "error",
        });
      }
      this.soLineGuidList = _.map(rows, (item) => {
        return item.SOLineGuid;
      });
      console.log(this.soLineGuidList, 'this.soLineGuidList');
      this.cancelOrderLineQtyVisible = true;
    },
    // 关闭弹窗
    onCancelQtyClose(isChanged) {
      this.cancelOrderLineQtyVisible = false;
      if (isChanged) {
        this.onBindData();
      }
    }
  },
};
</script>

<style lang="scss">
</style>
