
<template>
  <!-- 待查价 -->
  <div class="module-wrapper m-wait-inquire">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="action"
      box-class="m-wait-inquire"
      :showCheck="true"
      checkboxBindingKey="ASGUID"
      v-loading="loading"
      :element-loading-text="loadingText"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      :totalField="['AsQty','QuoteQty','SuggestPrice','SupplierPrice']"
      @on-refresh-click="onSearch"
      @current-row-change="onCurrentRowChangeHandle"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('BomWaitToInquire')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <!-- 市场查货 -->
        <znl-button style-type="mac" @click="onMarketSearch()">
          <span>
            <i class="iconfont icon-search_ic"></i> 市场查货
          </span>
        </znl-button>
        <!-- 记录询价 -->
        <znl-button style-type="mac" @click="onRecordInquiry()">
          <span>
            <i class="iconfont icon-add_btn_ic"></i> 记录询价
          </span>
        </znl-button>
        <znl-button
          ref="batchMarkOperation"
          style-type="mac"
          :btns="batchMarkButtons"
          :height="22"
          @click="onBatchMark"
          tip="批量标记无货、缺货、已报"
        >
          <i class="iconfont icon-select-wh_btn_ic"></i>
          <span>批量标记</span>
        </znl-button>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="通知时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.CreateTimeStart"
        ></znl-input>
        <span style="font-size:small">到</span>
        <znl-input
          form-type="datepicker"
          :disabled="false"
          value-format="yyyy-MM-dd"
          placeholder="通知时间"
          size="mini"
          layout="left"
          width="90px"
          :clearable="true"
          :border="true"
          inp-perc="100%"
          v-model="searchFields.CreateTimeEnd"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="需求型号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="需求单号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.BillNo"
          @keyup.enter.native="onSearch()"
          type="text"
        ></znl-input>
        <znl-input
          layout="left"
          form-type="select"
          :disabled="false"
          :border="true"
          width="78px"
          placeholder="选择状态"
          popper-class="popperClass"
          :clearable="false"
          :multiple="true"
          :tags-clear="false"
          :select-options="selectOptions"
          v-model="checkStatus"
        ></znl-input>
        <znl-button type="multiple" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="()=>{this.isShowFiedsSearch=!this.isShowFiedsSearch}"
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-button style-type="main" class="save-self-btn" @click="onBatchSave()">
          <span>
            <i class="iconfont icon-save_btn_ic"></i>保存修改
          </span>
        </znl-button>
        <!-- 提交报价 -->
        <znl-button style-type="main" @click="onSummit()" v-if="true">
          <span>
            <i class="iconfont icon-select-wh_btn_ic"></i>提交报价
          </span>
        </znl-button>
        <znl-button style-type="mac" @click="onSetMyInquire()">
          <i class="iconfont icon-save_btn_ic"></i>
          <span>认&nbsp;领&nbsp;</span>
        </znl-button>&nbsp;
        <el-checkbox v-model="searchFields.IsMy">分配给我的</el-checkbox>
        <GlobalCargo style="position: relative;top: 7px;"></GlobalCargo>
      </div>
    </znl-table>
    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>

  </div>
</template>
<style>
</style>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";
import EnquiryView from "@c_modules/Enquiry/index"
import {isClient} from "~/lib/runtime";
import GlobalCargo from "~/components/common/global-cargo/index";

const CONFIG = {
  configURL: "BomWaitToInquire/GetConfig",
  searchURL: "BomWaitToInquire/Search",
  saveURL: "BomWaitToInquire/Save",
  markOutStockUrl: "BomWaitToInquire/MarkOutStock",
  markNoStockUrl: "BomWaitToInquire/MarkNoStock",
  markQuotedUrl: "BomWaitToInquire/MarkQuoted",
  goToMyWaitList: "BomWaitToInquire/GoToMyWaitList"
};

export default {
  name: "PurchaseOrder",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {EnquiryView, GlobalCargo},
  data() {
    return {
      isShowFiedsSearch: false,
      searchFields: {
        Model: "",
        BillNo: "",
        IsMy: true,
        CreateTimeStart: "",
        CreateTimeEnd: ""
      },
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      orderGuid: "",
      refreshLog: true,
      parameterConfig: [],
      Isdisabled: !this.$store.state.accountInfo.IsMainAccount,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,

      selectOptions: [
        { key: 1, value: "已查" },
        { key: 2, value: "未查" },
        { key: 3, value: "已报" },
        { key: 4, value: "未报" },
        { key: 5, value: "加急" }
      ],
      loading: false,
      currencyList: [],
      userList: [],
      taxRateList: [],
      loadingText: "数据加载中,请稍后...",
      checkStatus: [4],
      // 1.SalesQuote 销售报价
      // 2.BuyQuoteCost 采购报价 (进价)
      // 3.BuyQuoteSalePrice 采购报价 (售价)
      bomQuoteType: "BuyQuoteCost", // 报价模式
      purGrossRate: 0, // 采购固定毛利率
      batchMarkButtons: [
        {
          name: "批量标记无货",
          iconName: "circle-i no-stock-i",
          click: () => {
            this.onMarkNoStockBatch();
          },
        },
        {
          name: "批量标记缺货",
          iconName: "circle-i no-miss-i",
          click: () => {
            this.onMarkOutStockBatch();
          },
        },
        {
          name: "批量标记已报",
          iconName: "circle-i no-quote-i",
          click: () => {
            this.onMarkQuotedBatch();
          },
        },
      ]
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
    this.userList = _.map(await this.selectOptionsAllUserURL, function(obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      this.loading = true;
      if (!config || config === undefined) {
        config = await this.isSaveStorage("bomWaitToInquire", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = -1;
      colIndex = _.findIndex(columns, item => item.BindField === "IsInquired");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "未查", value: false },
            { key: "已查", value: true }
          ],
          formatter(rowData, rowIndex, field) {
            if (rowData.IsInquired != undefined) {
              var status = _.find(this.options, function(item) {
                return item.value === rowData.IsInquired;
              });
              if (rowData.IsInquired == true) {
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="blueLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          }
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsSubmit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "未报", value: false },
            { key: "已报", value: true }
          ],
          formatter(rowData, rowIndex, field) {
            if (rowData.IsSubmit != undefined) {
              var status = _.find(this.options, function(item) {
                return item.value === rowData.IsSubmit;
              });
              if (rowData.IsSubmit == true) {
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="blueLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          }
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsNoStock");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "否", value: false },
            { key: "是", value: true }
          ],
          formatter(rowData, rowIndex, field) {
            if (rowData.IsNoStock != undefined) {
              var status = _.find(this.options, function(item) {
                return item.value === rowData.IsNoStock;
              });
              if (rowData.IsNoStock == false) {
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          }
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsOutStock");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "否", value: false },
            { key: "是", value: true }
          ],
          formatter(rowData, rowIndex, field) {
            if (rowData.IsOutStock != undefined) {
              var status = _.find(this.options, function(item) {
                return item.value === rowData.IsOutStock;
              });
              if (rowData.IsOutStock == false) {
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          }
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "IsUrgent");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "否", value: false },
            { key: "是", value: true }
          ],
          formatter(rowData, rowIndex, field) {
            if (rowData.IsUrgent != undefined) {
              var status = _.find(this.options, function(item) {
                return item.value === rowData.IsUrgent;
              });
              if (rowData.IsUrgent == false) {
                return "<div >" + status.key + "</div>";
              } else {
                return '<div class="redLink">' + status.key + "</div>";
              }
            }
            return "<div></div>";
          }
        });
      }

      let mouseBtn = [];
      mouseBtn.push({
        title: "标记无货",
        name: "MarkNoStock",
        click: row => {
          this.onMarkNoStock([row.ASGUID]);
        }
      });
      mouseBtn.push({
        title: "标记缺货",
        name: "MarkOutStock",
        click: row => {
          this.onMarkOutStock([row.ASGUID]);
        }
      });
      mouseBtn.push({
        title: "标记已报",
        name: "MarkQuoted",
        click: row => {
          this.onMarkQuoted([row.ASGUID]);
        }
      });
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true
        }
      ];
      columns = tempConfig.concat(columns);

      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.columns = columns;

      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.$nextTick(() => {
        this.loading = false;
        this.$emit("page-loading", false);
      });
    },

    onCheckChange() {
      this.onSearch();
    },

    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },

    async onBindData(pageIndex = 1) {
      let data = _.extend({
        fieldWhereString: JSON.stringify(this.searchFields),
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        checkStatus: this.checkStatus
      });
      this.loading = true;
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if(this.$refs.table) this.$refs.table.cleanClickRow();
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
    },
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    onSaveRows(rows) {
      // 保存当前页面的值
      this.loadingText = "数据正在保存,请稍等...";
      this.loading = true;
      return this.$post(CONFIG.saveURL, rows, (datas, logdata) => {
        if (logdata.code === 200) {
          this.$message({ message: "保存成功", type: "success" });
          this.itemSource = JSON.parse(JSON.stringify(this.itemSource));
        } else {
          this.$message({ message: logdata.msg, type: "error" });
        }
      }).finally(() => {
        this.loading = false;
        this.loadingText = "数据加载中,请稍后...";
      });
    },
    onRefresh() {
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },
    onChange(val) {
      if (val) {
        this.isLikeSearch = false;
      } else {
        this.isLikeSearch = true;
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      }
      if (row) {
        this.$emit("selection-changed", row);
      }
    },
    // 提交报价
    onSummit() {
      // 1.SalesQuote 销售报价
      // 2.BuyQuoteCost 采购报价 (进价)
      // 3.BuyQuoteSalePrice 采购报价 (售价)
      let rows = this.$refs.table.getCheckedRows();
      if (!this.validationData(rows)) {
        return;
      }
      _.each(rows, item => {
        item.IsSubmit = true;
        item.BomQuoteType = this.bomQuoteType;
      });
      this.loadingText = "正在提交报价,请稍等...";
      this.loading = true;
      return this.$post(CONFIG.saveURL, rows, (datas, logdata) => {
        if (logdata.code === 200) {
          this.$message({ message: "报价成功", type: "success" });
          // 清空所有修改过的值清空所有修改过的值
          this.onRefresh();
        } else {
          this.$message({ message: logdata.msg, type: "error" });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 保存提交时验证数据
    validationData(rows) {
      let ret = true;
      if (!this.hasValue(rows) || rows.length <= 0) {
        this.$message({
          message: "请勾选或选中要提交报价的查价需求",
          type: "warning"
        });
        ret = false;
      }
      let nobuyEmpNames = _.filter(rows, item => {
        return !this.hasValue(item.BuyEmpName);
      });
      if (nobuyEmpNames.length > 0) {
        this.$message({
          message: "请勾选上被认领(已指派查价员)的查价需求",
          type: "warning"
        });
        ret = false;
      }

      _.each(rows, item => {
        if (!this.hasValue(item.Model)) {
          this.$message({ message: "请输入提供型号!", type: "warning" });
          ret = false;
        } else if (!this.hasValue(item.QuoteQty)) {
          this.$message({ message: "请输入提供数量!", type: "warning" });
          ret = false;
        } else if (
          this.bomQuoteType === "BuyQuoteSalePrice" &&
          !this.hasValue(item.QuotePriceCust)
        ) {
          this.$message({
            message: "当前模式为:【采购报价】 请输入给客户报价!",
            type: "warning"
          });
          ret = false;
        } else if (
          (this.bomQuoteType === "SalesQuote" ||
            this.bomQuoteType === "BuyQuoteCost") &&
          !this.hasValue(item.SuggestPrice)
        ) {
          this.$message({
            message: "当前模式为:【销售报价】 请输入给销售报价!",
            type: "warning"
          });
          ret = false;
        }
      });
      return ret;
    },
    // 采纳询价后回调事件 回写待查价界面的数据
    adoptInquireSuccess(val) {
      _.each(this.itemSource, item => {
        if (item.ASGUID === val.ASGUID) {
          this.$set(item, "Model", val.Model);
          this.$set(item, "Brand", val.Brand);
          this.$set(item, "Packaging", val.Packaging);
          this.$set(item, "MakeYear", val.MakeYear);
          this.$set(item, "MPQ", val.MPQ);
          this.$set(item, "Quality", val.Quality);
          this.$set(item, "QuoteQty", val.SumQty);
          this.$set(item, "SuggestPrice", val.AgvPrice);
          this.$set(item, "DeliveryDate", val.DeliveryDate);
          this.$set(item, "QuotePriceCust", val.QuotePriceCust);
          this.$set(item, "SupplierPrice", val.AgvPrice);
        }
      });
    },
    onRecordInquiry: async function() {
      // 记录询价
      let rows = this.$refs.table.getCheckedRows();
      let inquireList = [];
      this.$nextTick(function() {
        if (rows.length > 0) {
          _.each(rows, row => {
            inquireList.push({
              PrimaryKey: row.ASGUID,
              SupplierName: null,
              Model: row.BomModel,
              Brand: row.BomBrand,
              Packaging: row.Packaging,
              MakeYear: row.MakeYear,
              Quality: row.Quality,
              InvQty: row.AsQty,
              MPQ: row.MPQ,
              Qty: row.AsQty,
              Price: null,
              SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
              SourceGuid: row.ASGUID, // 需求明细GUID
              SourceId: row.ASID // 需求明细ID，
            });
          });
        } else {
          inquireList.push({
            PrimaryKey: null,
            SupplierName: null,
            Model: null,
            Brand: null,
            Packaging: null,
            MakeYear: null,
            Quality: null,
            InvQty: null,
            MPQ: null,
            Qty: null,
            Price: null,
            SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
            SourceGuid: null, // 需求明细GUID
            SourceId: null // 需求明细ID，
          });
        }
        if(isClient()) {
          this.addInquireList(inquireList, function(msg) {
            if (msg === "不能向自己询价") {
              this.$message({ message: msg, type: "warning" });
            }
          });
        } else {
          let editItem = {}
          if(inquireList.length > 0) {
            const data = inquireList[0]
            data.SourceGuid = data.ASGUID
            data.SourceId = data.ASID
            Object.keys(data).forEach(key => editItem[key] = data[key])
          }
          editItem.SourceType = 2
          this.$refs.enquiryView.onOpen(editItem)
        }
      });
    },
    onConfirm(data) {
      console.log(11)
    },
    async onMarketSearch() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === null || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选一行或者多行待查价清单，再点击市场查货操作",
          type: "warning"
        });
      }
      let request = [];
      _.each(rows, item => {
        request.push({
          Model: item.BomModel, // 型号，
          Brand: item.BomBrand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.AsQty, // 数量，
          Remark: item.Remark, // 需求说明
          SourceType: 2, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
          SourceGuid: item.ASGUID, // 需求明细GUID
          SourceId: item.ASID // 需求明细ID，
        });
      });
      this.$nextTick(function() {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },
    getMarkGuidList() {
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length == 0) {
        this.$message({
          message: "请选择您要标记的查价需求",
          type: "warning"
        });
        return false;
      }
      let list = _.filter(rows, a => !a.IsSubmit);
      if (list.length == 0) {
        this.$message({message: "请选择未报价的需求进行标记", type: "warning"});
        return false;
      }
      return _.map(list, a => a.ASGUID);
    },
    // 标记缺货
    onMarkOutStockBatch() {
      let asGuidList = this.getMarkGuidList();
      if (asGuidList == false || asGuidList.length == 0) {
        return false;
      }
      this.onMarkOutStock(asGuidList);
    },
    onMarkOutStock(asGuidList) {
      return this.$confirm("您确定要标记缺货吗?", "标记缺货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return this.$post(
          CONFIG.markOutStockUrl,
          { ASGuidList: asGuidList },
          (data, logic) => {
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "标记成功", type: "success" });
              this.onRefresh();
              this.$refs.table.cleanAllCheck()
            }
          }
        );
      });
    },

    // 标记无货
    onMarkNoStockBatch () {
      let asGuidList = this.getMarkGuidList();
      if (asGuidList == false || asGuidList.length == 0) {
        return false;
      }
      this.onMarkNoStock(asGuidList);
    },
    onMarkNoStock(asGuidList) {
      return this.$confirm("您确定要标记无货吗?", "标记无货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return this.$post(
          CONFIG.markNoStockUrl,
          { ASGuidList: asGuidList },
          (data, logic) => {
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "标记成功", type: "success" });
              this.onRefresh();
              this.$refs.table.cleanAllCheck()
            }
          }
        );
      });
    },

    // 标记已报
    onMarkQuotedBatch () {
      console.log(this.$refs.table, 'this.$refs.table.');
      let asGuidList = this.getMarkGuidList();
      if (asGuidList == false || asGuidList.length == 0) {
        return false;
      }
      this.onMarkQuoted(asGuidList);
    },
    onMarkQuoted(asGuidList) {
      return this.$confirm("您确定要标记已报吗?", "标记已报", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return this.$post(
          CONFIG.markQuotedUrl,
          { ASGuidList: asGuidList },
          (data, logic) => {
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "标记成功", type: "success" });
              this.onRefresh();
              this.$refs.table.cleanAllCheck()
            }
          }
        );
      });
    },
    // 设置为我的待查价
    onSetMyInquire() {
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length <= 0) {
        return this.$message({
          message: "请勾选或选中要认领的查价需求",
          type: "warning"
        });
      }
      let buyEmpNames = _.filter(rows, item => {
        return this.hasValue(item.BuyEmpName);
      });
      if (buyEmpNames.length > 0) {
        return this.$message({
          message: "请勾选上未被认领(无指派查价员)的查价需求",
          type: "warning"
        });
      }
      let info = [];
      _.each(rows, item => {
        info.push({ ASGUID: item.ASGUID });
      });
      return this.$post(CONFIG.goToMyWaitList, info, (data, logic) => {
        if (logic.code === 120) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "操作成功", type: "success" });
          this.onRefresh();
        }
      });
    },
    onBatchMark () {
      this.$refs.batchMarkOperation.unfoldSelete();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("BomWaitToInquire", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("BomWaitToInquire", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("BomWaitToInquire", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("BomWaitToInquire");
    }
  }
};
</script>

<style lang="scss">
.m-wait-inquire {
  .redLink {
    color: red;
    text-decoration: none;
  }
  .blueLink {
    color: #0092dd;
    text-decoration: none;
  }
  .circle-i {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #ee7700;
    border-radius: 50%;
    font-size: 10px !important;
    color: #ee7700;
    background-color: #fff;
    top: 0 !important;
    padding-left: 2px;
  }
  .no-stock-i::before{
    content: "无";
    color: red;
    border-color: red;
  }
  .no-stock-i{
    border-color: red;
  }
  .no-miss-i::before{
    content: "缺";
  }
  .no-quote-i::before{
    content: "报";
    color: green;
  }
  .no-quote-i{
    border-color: green;
  }
}
</style>

