<template>
  <!-- 蓝色表示已报给客户的物料,红色表示该物料毛利润率低于设定值(系统参数设置) -->
  <znl-gridmodule
    ref="flexGrid"
    :columns="columns"
    :element-loading-text="loadingText"
    :has-znl-actions="true"
    :height="height"
    :is-btn="true"
    :is-fields-search="isShowFiedsSearch"
    :is-init="true"
    :is-multi-rows-check="true"
    :is-multi-rows-check-fixed="false"
    :item-source="itemSource"
    :loading="loading"
    :on-init="onInit"
    :on-page-changed="onBindData"
    :on-refresh="onRefresh"
    :on-reset-setting="onResetSetting"
    :on-save-config="onSaveConfig"
    :on-save-row="onSaveRows"
    :on-save-rows="onSaveRows"
    :on-save-setting="onSaveSetting"
    :on-search="onSearch"
    :page-index="pageIndex"
    :page-size="pageSize"
    :role="role"
    :search-fields="searchModel"
    :show-delete-row-right-menu="false"
    :show-save-row-right-menu="false"
    :table-row-class-name="tableRowClassName"
    :total-count="totalCount"
    checkboxBindingKey="BDLineGUID"
    class="grid-bomdetailLine-box"
    gridtype="action"
    header="需求物料"
    loading-type="animation"
    pagination-position="top"
    tooltip-content="无货/缺货的需求物料字体为红色,毛利润率低于设定值的需求物料字体为蓝色"
    @page-loading="val=>{$emit('page-loading', val)}"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @cell-click="onCellClick"
    @edit:cell-ended="onCellEdited"
  >
    <div slot="heading-slot">
      <znl-input
        v-model="inputModel"
        :border="true"
        :clearable="true"
        :disable="false"
        :is-upper-case="true"
        class="box_input_checkbox"
        from-type="input"
        inp-perc="100%"
        layout="left"
        placeholder="请输入型号"
        size="mini"
        type="text"
        width="100px"
        @keyup.enter.native="$refs.flexGrid.search()"
      ></znl-input>
      <el-checkbox v-model="searchModel.AllBom" style="padding-left:5px;" @change="BomSearch">所有物料</el-checkbox>
      <!-- 状态 -->
      <znl-input
        v-model="status"
        :border="true"
        :clearable="false"
        :disabled="false"
        :height="22"
        :multiple="true"
        :select-options="selectOptions"
        :tags-clear="false"
        form-type="select"
        layout="left"
        placeholder="选择状态"
        popper-class="popperClass"
        width="90px"
        @change="onChangeStatus"
      ></znl-input>
      <znl-button :height="22" class="box-left-btn search-btn" style-type="mac" type="multiple">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
        <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
          <i class="iconfont icon-arrow-drop-down_ic"></i>
        </znl-button-menu>
      </znl-button>
      <!-- 秒配 -->
      <znl-button
        v-if="hasRes('MatchPrice')"
        :height="22"
        style-type="main"
        tip="快速匹配型号价格后,请点击右边保存按钮"
        @click="onGetMatchPrice()"
      >
        <span>匹配价格</span>
      </znl-button>
      <!-- 市场查货 -->
      <znl-button
        v-if="hasRes('MarketSearch')"
        :height="22"
        style-type="mac"
        @click="onMarketSearch()"
      >
        <span>
          <i class="iconfont icon-search_ic"></i> 市场查货
        </span>
      </znl-button>
      <!-- 通知采购 -->
      <!-- v-if="hasResBom('PurchaseNotic')" -->
      <znl-button
        v-if="hasRes('PurchaseNotic')"
        :btns="noticeznlbtns"
        :height="22"
        class="noticeznlbtns"
        style-type="mac"
        @click="onNotice(false)"
      >
        <span>
          <i class="iconfont icon-notice_btn_ic"></i> 通知查价
        </span>
      </znl-button>
      <!-- 打印报价单 -->
      <znl-button
        v-if="hasRes('OnPrint')"
        :btns="printznlbtns"
        :height="22"
        style-type="mac"
        @click="printStkOutALL=false,onPrint()"
      >
        <span>
          <i class="iconfont icon-print_btn_ic"></i>打印报价
        </span>
      </znl-button>
      <znl-print
        :is-get-default-param="printInfo.isGetDefaultParam"
        :loading="printLoading"
        :printInfo="printInfo"
        :visible.sync="znlPrintVisible"
        print-type="Quotation"
        title="报价单打印设置"
        @set-print-param="setPrintParam"
        @confirm-print="confirmPrint"
      ></znl-print>

      <!-- 更多操作 -->
      <znl-float-list v-show="isShowMainBtn(outznlbtns)" :lists="outznlbtns" style="width:100px">
        <znl-button :height="22" style-type="mac">
          <i class="iconfont icon-left_nav_show_btn"></i>
          <span>更多操作</span>
        </znl-button>
      </znl-float-list>
      <GlobalCargo style="margin-left:-25px"></GlobalCargo>
      <!-- <el-checkbox v-model="AllNoPriceBom" @change="PriceSearch">所有未报价物料</el-checkbox> -->
    </div>
    <div>
      <stk-out-edit
        v-if="stkOutEditVisible"
        :autoAddNewRow="false"
        :edit-type="editType"
        :editvisible="stkOutEditVisible"
        :title="StkOutAddTitle"
        @close="stkOutEditVisible=false"
        @confirm="stkOutEditVisible=false"
      ></stk-out-edit>
      <stk-in-edit
        v-if="stkInEditVisible"
        :autoAddNewRow="false"
        :edit-type="editType"
        :editvisible="stkInEditVisible"
        :title="StkInAddTitle"
        @close="stkInEditVisible=false"
        @confirm="stkInEditVisible=false"
      ></stk-in-edit>
      <wait-noticed-bom
        v-if="waitNoticedBomVisible"
        :bomGuid="BomGuid"
        :bomLineGuids="bdLinedGuidsStr"
        :is-transpond="false"
        :visible="waitNoticedBomVisible"
        @close="()=>{waitNoticedBomVisible=false}"
        @confirm="(val)=>{val&&(waitNoticedBomVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"
        @save-success="onRefresh"
      ></wait-noticed-bom>

      <bom-pro-relation
        v-if="BomProRelationVisible"
        :is-show="true"
        :visible="BomProRelationVisible"
        @close="()=>{BomProRelationVisible=false}"
        @confirm="(val)=>{val&&(BomProRelationVisible=false)}"
      ></bom-pro-relation>

      <sales-order-edit
        v-if="salesEditVisible"
        :autoAddNewRow="false"
        :edit-type="editType"
        :editvisible="salesEditVisible"
        :title="SalesOrderAddTitle"
        @close="salesEditVisible=false"
        @confirm="()=>{salesEditVisible=false,onBindData(pageIndex)}"
        @save-success="RefreshSalesOrderList"
      ></sales-order-edit>

      <znl-data-import
        :type="zznlImportType"
        :show="zznlShowDataImport"
        @close="zznlShowDataImport = false"
      />
    </div>
  </znl-gridmodule>
</template>
<script>
import {mixin as znlMethodsMixin} from "@c_common/znlGrid/scripts/znlMethods";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import WaitNoticedBom from "@c_modules/BOM/BomWaitNoticed";
import BomProRelation from "@c_modules/BOM/BomProRelation";
import SalesOrderEdit from "@c_modules/Sale/SalesOrderEdit";

import {mixin as getCommonDataMixin} from "~assets/scripts/methods/getCommonData";
import {mixin as common, openLinkAll} from "~assets/scripts/methods/common";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates.vue";
import {getErpApiBaseUrl} from "~/lib/urls";
import {isClient} from "~/lib/runtime";
import * as urls from "~/lib/urls";
import GlobalCargo from "~/components/common/global-cargo/index";
import GlobalCargoDialog from "~/components/common/global-cargo/global-cargo-dialog";

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  configURL: "BomDetailLine/GetConfig",
  saveConfigURL: "BomDetailLine/SaveConfig",
  resetConfigURL: "BomDetailLine/ResetConfig",
  searchURL: "BomDetailLine/Search",
  saveURL: "BomDetailLine/Save",
  getMatchPriceUrl: "BomMaster/GetGetMatchPrice",
  bomConfigURL: "BomMaster/GetConfig",
  getTakeGoodsURL: "BomDetailLine/GetTakeGoodsPrint",
  deleteURL: "BomDetailLine/Del",
  exportBOMUrl: "Export/ExportBOM",
  DownloadPrintFile:'WebPrint/DownloadPrintFile',
};

export default {
  name: "BomDetailLine",
  mixins: [znlMethodsMixin, getCommonDataMixin, common, openLinkAll],
  config: CONFIG,
  components: {
    StkOutEdit,
    StkInEdit,
    WaitNoticedBom,
    ZnlPrint,
    BomProRelation,
    SalesOrderEdit,
    GlobalCargo,
    GlobalCargoDialog
  },
  data() {
    return {
      menus: [],
      editType: "addNew",
      StkOutAddTitle: "新增发货单",
      stkOutEditVisible: false, // 新增发货单弹窗
      StkInAddTitle: "新增入库单",
      stkInEditVisible: false,
      SalesOrderAddTitle: "新增销售单",
      salesEditVisible: false,
      outznlbtns: [],
      noticeznlbtns: [],
      columns: [],
      printznlbtns: [], // 打印报价单按钮下的子按钮
      printTakeGoodsALL: false, // 按照整张单打印拿货单
      itemSource: [],
      editRowIndex: -1,
      searchFields: {
        Model: ""
      },
      columnColors: [],
      rowColors: [],
      currentItem: [],
      summaryColumns: [],
      Size: 30,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loading: false,
      loadingInstance: {},
      command: "",
      gridShow: false,
      bdLinedGuidsStr: "",
      BomGuid: "",
      waitNoticedBomVisible: false,
      AllNoPriceBom: false,

      printStkOutALL: false, // 按照整张单打印报价单
      loadingText: "数据加载中,请稍后...",
      resourceList: [],
      isShowFiedsSearch: false,
      BomProRelationVisible: false,
      selectOptions: [],
      // status: [],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: false, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "Quotation" // 打印模板
      },
      // end 打印模块参数
      // 启用保存成功之后的回调事件
      isEnableSaveSuccessAfterEvent: false,
      minProfitMargin: null // 最低毛利润率
    };
  },
  computed: {
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      }
    },
    status: {
      get() {
        return this.searchModel.status;
      },
      set(val) {
        this.$set(this.searchModel, "status", val);
      }
    },
    inputModel: {
      get() {
        return this.searchInput.inputModel;
      },
      set(val) {
        this.$set(this.searchInput, "inputModel", val);
      }
    }
  },
  watch: {
    QtyUnitDropDown(val) {
      _.each(this.columns, column => {
        if (column.binding === "Unit") {
          this.$set(column, "dataMap", val);
        }
      });
    },
    selectOptionsTaxRate(val) {
      _.each(this.columns, column => {
        if (column.binding === "TaxRate") {
          this.$set(column, "dataMap", val);
        }
      });
    },
    selectOptionsAllUserURL(val) {
      _.each(this.columns, column => {
        if (column.binding === "CreatedName") {
          this.$set(column, "dataMap", val);
        }
      });
    },
    selectOptionsCurrencyCode(val) {
      _.each(this.columns, column => {
        if (column.binding === "CurrencyName") {
          this.$set(column, "dataMap", val);
        }
      });
    },
    selectOptionsQtyUnit(val) {
      _.each(this.columns, column => {
        if (column.binding === "Unit") {
          this.$set(column, "dataMap", val);
        }
      });
    }
  },
  props: {
    isMini: {
      type: Boolean,
      default: false
    },
    isActivity: Boolean,
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    searchInput: {
      type: Object,
      default: () => {
        return {};
      }
    },

    height: String
  },
  methods: {
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    async onInit(config, refresh) {
      if (!config) {
        config = await this.isSaveStorage("bomDetailLine", CONFIG.configURL);
      }
      this.resourceList = config.ResourceList.map(item => item.Code);
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.defaultConfig = config;
      // 系统参数(最低毛利润率)
      this.minProfitMargin = this.getCompanySetupValue("MinProfitMargin");
      // 税率
      let TaxRate = this.selectOptionsTaxRate;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "TaxRate";
        }),
        {dataMap: TaxRate, showDropDown: true}
      );
      // 单位
      let QtyUnitDropDown = this.selectOptionsQtyUnit;
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Unit";
        }),
        {dataMap: QtyUnitDropDown, showDropDown: true}
      );
      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (
        obj,
        index
      ) {
        return {key: obj.value, value: obj.value};
      });
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "CurrencyName";
        }),
        {dataMap: CurrencyNames, showDropDown: true}
      );

      // 录入人
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
        obj,
        index
      ) {
        return {key: obj.value, value: obj.value};
      });
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "CreatedName";
        }),
        {dataMap: UserAllowUsers, showDropDown: true}
      );

      this.printznlbtns = [
        {
          name: "按整张单",
          isShow: true,
          click: () => {
            this.printStkOutALL = true;
            this.onPrint();
          }
        },
        {
          name: "打印设置",
          isShow: true,
          click: () => {
            this.znlPrintVisible = true;
          }
        },
        {
          name: "按导入格式打印",
          isShow: true,
          click: () => {
            this.onPrintByFormat();
          }
        }
      ];
      this.noticeznlbtns = [
        {
          name: "按整张单",
          isShow: true,
          click: () => {
            this.onNotice(true);
          }
        },
        {
          name: "产品类型设置",
          isShow: true,
          click: () => {
            this.BomProRelationVisible = true;
          }
        }
      ];
      this.outznlbtns = [
        {
          name: "发货",
          // iconName: 'icon-sell-wh_btn_ic',
          isShow: this.hasRes("StkOut"),
          click: () => {
            this.onAddStkOut();
          }
        },
        {
          name: "按整张单发货",
          // iconName: 'icon-sell-wh_btn_ic',
          isShow: this.hasRes("StkOut"),
          click: () => {
            this.onAddStkOutAll();
          }
        },
        {
          name: "入库",
          // iconName: 'icon-buy-wh_btn_ic',
          isShow: this.hasRes("StkIn"),
          click: () => {
            this.onAddStkIn();
          }
        },
        {
          name: "按整张单入库",
          // iconName: 'icon-buy-wh_btn_ic',
          isShow: this.hasRes("StkIn"),
          click: () => {
            this.onAddStkInAll();
          }
        },
        {
          name: "出销售单",
          // iconName: 'icon-buy-wh_btn_ic',
          isShow: true, // this.hasRes('GotoSaleOrder')
          click: () => {
            this.onAddSaleOrder();
          }
        },
        {
          name: "打印拿货单",
          // iconName: 'icon-print_btn_ic',
          isShow: this.hasRes("OnPrintTakeGoods"),
          click: () => {
            this.printTakeGoodsALL = false;
            this.onPrintTake();
          }
        },
        {
          name: "按整张单打印",
          isShow: this.hasRes("OnPrintTakeGoods"),
          // iconName: 'icon-print_btn_ic',
          click: () => {
            this.printTakeGoodsALL = true;
            this.onPrintTake();
          }
        },

        // {
        //   name: '新增一行',
        //   isShow: true,
        //   // iconName: 'icon-print_btn_ic',
        //   click: () => {
        //     this.addNewRow()
        //   }
        // },
        // {
        //   name: '删除一行',
        //   isShow: true,
        //   // iconName: 'icon-print_btn_ic',
        //   click: () => {
        //     this.onDelete()
        //   }
        // },
        {
          name: "导入需求",
          isShow: this.hasRes("Import"),
          // iconName: 'icon-leading-in-wh_btn_ic',
          click: () => {
            this.onImport();
          }
        }
      ];
      // })
      _.each(columns, column => {
        if (column.binding === "LiChuangPriceText") {
          column.renderCell = (h, cellObj) => {
            return h("a", {
              class: "text-decoration",
              domProps: {
                innerHTML: cellObj.row.LiChuangPriceText
              },
              attrs: {
                href: cellObj.row.LiChuangURL,
                target: "_Blank"
              }
            });
          };
        }
        if (column.binding === "DigikeyPriceText") {
          column.renderCell = (h, cellObj) => {
            return h("a", {
              class: "text-decoration",
              domProps: {
                innerHTML: cellObj.row.DigikeyPriceText
              },
              attrs: {
                href: cellObj.row.DigiKeyURL,
                target: "_Blank"
              }
            });
          };
        }
        if (column.binding === "YunHanPriceText") {
          column.renderCell = (h, cellObj) => {
            return h("a", {
              class: "text-decoration",
              domProps: {
                innerHTML: cellObj.row.YunHanPriceText
              },
              attrs: {
                href: cellObj.row.YunHanURL,
                target: "_Blank"
              }
            });
          };
        }

        // 处理特殊权限
        let hideCols = ",CustomerName,Contact,Telephone,Email,Address,QQ,";
        _.each(columns, (item, index) => {
          if (
            this.getSpecialResourceByCode("NoSeeCustomer") &&
            hideCols.indexOf(item.binding) > 0
          ) {
            item.hasRole = false;
          }
        });

        // 处理特殊权限   false 展开表格全部列的时候不显示（用于权限控制）
        let hideBuyPrice = "BuyPrice"; // 供应商报价
        let hideRate = "ProfitRate"; // 不看毛利率
        let hideProfitAmount = "ProfitAmountLine"; // 不看毛利额
        if (
          !this.getSpecialResourceByCode("ViewBuyPrice") &&
          hideBuyPrice.indexOf(column.binding) > -1
        ) {
          column.hasRole = false;
        }
        // if (
        //   this.getSpecialResourceByCode("NoSeeProfit") &&
        //   (column.binding === hideRate ||
        //     column.binding === hideProfitAmount ||
        //     column.binding === hideBuyPrice)
        // ) {
        //   column.hasRole = false;
        // }

        // 没有 匹配价格的权限,隐藏 云价格/立创价/云汉
        if (!this.hasRes("MatchPrice")) {
          if (
            column.binding === "DscQuotePriceText" ||
            column.binding === "LiChuangPriceText" ||
            column.binding === "YunHanPriceText" ||
            column.binding === "DigikeyPriceText"
          ) {
            column.hasRole = false;
          }
        }

        if (
          column.binding === "Model" ||
          column.binding === "SupplierName" ||
          column.binding === "CustomerName" ||
          column.binding === "BillNo"
        ) {
          _.extend(column, {
            className: "znl-linkAll-column",
            renderCell: this.openLinkAll(column)
          });
        }
      });

      this.selectOptions = [
        {key: "未查价", value: "未查价"},
        {key: "已查价", value: "已查价"},
        {key: "未税", value: "未税"},
        {key: "含税", value: "含税"},
        {key: "采购未报", value: "采购未报"},
        {key: "采购已报", value: "采购已报"},
        {key: "销售未报", value: "销售未报"},
        {key: "销售已报", value: "销售已报"},
        {key: "未出货", value: "未出货"},
        {key: "部分出货", value: "部分出货"},
        {key: "全部出货", value: "全部出货"},
        {key: "未入库", value: "未入库"},
        {key: "部分入库", value: "部分入库"},
        {key: "全部入库", value: "全部入库"}
      ];

      this.columns = columns;
      this.role = config.Role;
      this.pageSize = this.Size = config.PageSize;

      if (this.initData || refresh) {
        this.onBindData();
      } else {
        this.$emit("page-loading", false);
      }
    },
    async onBindData(pageIndex = 1) {
      let filterFields = {
        // 外部搜索条件
        AllNoPriceBom: this.AllNoPriceBom ? 1 : 0,
        AllBom: this.searchModel.AllBom ? 1 : 0,
        Status: this.status,
        Model: this.inputModel
      };
      if (this.filterFields) {
        _.each(this.filterFields, (value, key) => {
          filterFields[
          key.substring(0, 1).toUpperCase() + key.substring(1)
            ] = value;
        });
      }
      this.CurrentItem = [];

      var json = JSON.stringify(this.searchFields);

      let response = await this.$post(
        this.$options.config.searchURL,
        _.extend(
          {
            FieldWhereString: json,
            PageIndex: pageIndex,
            PageSize: this.pageSize
          },
          filterFields
        )
      );
      if (response !== undefined) {
        let itemSource = response.ResultList;
        _.each(itemSource, item => {
          item = _.extend(item, {
            DscQuotePriceText: item.DscQuotePriceText || "",
            DigikeyPriceText: item.DigikeyPriceText || "",
            LiChuangPriceText: item.LiChuangPriceText || "",
            YunHanPriceText: item.YunHanPriceText || "",
            BuyPrice: this.toFloat(item.BuyPrice, 6) || "",
            ProposalPrice: item.ProposalPrice || "",
            NTQuotePrice: item.NTQuotePrice || "",
            DigikeyURL: item.DigikeyURL || "",
            LiChuangURL: item.LiChuangURL || "",
            YunHanURL: item.YunHanURL || ""
          });
        });
        this.pageIndex = pageIndex;
        this.itemSource = itemSource;
        this.totalCount = response.TotalCount;
        this.checkedRows = [];
        this.checkedRowsCount = 0;
        this.$emit("page-loading", false);
        this.gridShow = true;
      }
    },
    async onSearch(params = {}) {
      _.extend(this.searchFields, params);
      await this.onBindData(1);
      this.checkedRows = [];
      this.checkedRowsCount = 0;
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("bomDetailLine");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      );
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("bomDetailLine");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      );
    },
    async onResetSetting() {
      localStorage.removeItem("bomDetailLine");
      await this.$post(this.$options.config.resetConfigURL);
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    RefreshSalesOrderList() {
      this.onRefresh();
      // this.$emit('save-success', true)
    },
    async onMarketSearch() {
      let rows = this.$refs.flexGrid.getSelectedRows();

      if (rows === null || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({
          message: "请勾选一行或者多行客户需求，再点击市场查货操作",
          type: "warning"
        });
      }
      let request = [];
      _.each(rows, item => {
        request.push({
          SourceGuid: item.BDLineGUID, // 需求明细GUID，
          Model: item.Model, // 型号，
          SourceId: item.BDLineID, // 需求明细ID，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.Qty, // 数量，
          Remark: item.Remark // 需求说明
        });
      });

      this.$nextTick(function () {
        this.openMarketSearch({Requests: request, ClearRequests: false});
      });
    },
    BomSearch(val) {
      this.onSearch();
    },
    onSelectionChange(currentItem) {
      this.currentItem = currentItem;
    },
    hasRes(code) {
      return _.some(this.resourceList, page => page === code);
    },
    onPrint() {
      var tableName = this.printInfo.template; // 模板ID
      var guid = this.onSelectRow(); // 客户需求详情单ID集合
      if (guid.length === 0) {
        return false;
      }
      // 按照整单打时，取主单GUID
      if (this.printStkOutALL === true) {
        let rows = this.$refs.flexGrid.getSelectedRows();
        if (rows !== null && rows.length > 0 && rows[0] !== undefined) {
          guid = [rows[0].BOMGUID];
        }
      }

      if (!this.hasValue(tableName)) {
        this.$message({
          message: "请在打印设置里面选择打印模板",
          type: "warning"
        });
      } else if (_.isUndefined(guid) || guid.length === 0) {
        this.$message({message: "请选择要打印的单据", type: "error"});
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {

        const params = {
          TableName: tableName,
          Guids: guid,
          IsWholeBill: this.printStkOutALL,
          SubCompanyId: this.printInfo.title
        }
        if(isClient()) {
          this.printQuote(params);
        } else {
          this.onWepPrint(params)
        }
      }
    },

    onWepPrint(params) {
      const data = this.$post('WebPrint/QuotePrint', params)

      this.$post('WebPrint/QuotePrint', params, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if(data)
          {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl) // 下载文件
          }
        }
        else{
          this.$message({ message: logic.msg, type: "error" });
        }
      })
    },

    // 根据导入格式， 打印
    onPrintByFormat() {
      var guid = this.onSelectRow(); // 客户需求详情单ID集合
      if (guid.length === 0) {
        return false;
      }
      let bomGuid = "";
      // 按照整单打时，取主单GUID
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows !== null && rows.length > 0 && rows[0] !== undefined) {
        bomGuid = rows[0].BOMGUID;
      }

      // 导出导出
      this.$post(CONFIG.exportBOMUrl, {BOMGuid: bomGuid}, (data, logic) => {
        if (data) {
          let baseurl = getErpApiBaseUrl();
          this.downFile({
            FileUrl: baseurl + "Export/DownloadBOM?id=" + data,
            FileName: "BOM 报价单.xls"
          });
        } else {
          this.$message({message: logic.msg, type: "error"});
        }
      });
    },

    onImport: async function () {
      // 导入客户需求
      this.$nextTick(function () {
        this.importData({importType: "BOM"});
      });
    },
    onInquire: async function (row) {
      this.$nextTick(function () {
        if (!_.isUndefined(row)) {
          // this.onUpdateRow(row)
          this.onBindData(this.pageIndex);
        }
      });
    },
    onUpdateRow(stk) {
      _.each(this.itemSource, (item, index) => {
        if (item.BDLineGUID === this.currentItem.BDLineGUID) {
          if (stk.BuyPrice !== null && stk.BuyPrice !== "") {
            this.$set(item, "BuyPrice", this.toFloat(stk.BuyPrice, 6));
            this.$set(item, "IQGuidList", stk.IQGuidList);
          } else {
            this.$set(item, "BuyPrice", null);
            this.$set(item, "IQGuidList", []);
          }
        }
      });
    },
    // 生成发货单
    onAddStkOut() {
      let bomLinGuis = this.onSelectRow(); // 选中行的bdlineguid数组
      if (bomLinGuis !== null && bomLinGuis.length > 0) {
        let data = {BDLineGuids: bomLinGuis};
        this.$store.state.visibled = false;
        this.$store.commit("setParamOrder", {bomInfo: data});
        this.$nextTick(function () {
          this.editType = "addNew";
          this.stkOutEditVisible = true;
        });
      }
    },
    // 按整张需求单生成发货单
    onAddStkOutAll() {
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows !== null && rows.length > 0) {
        let data = {BOMGUID: rows[0].BOMGUID};
        this.$store.state.visibled = false;
        this.$store.commit("setParamOrder", {bomInfo: data});
        this.$nextTick(function () {
          this.editType = "addNew";
          this.stkOutEditVisible = true;
        });
      }
    },
    // 生成入库单
    onAddStkIn() {
      let bomLinGuis = this.onSelectRow(); // 选中行的bdlineguid数组
      if (bomLinGuis !== null && bomLinGuis.length > 0) {
        let data = {BDLineGuids: bomLinGuis};
        this.$store.state.visibled = false;

        this.$store.commit("setParamOrder", {bomInfo: data});
        this.$nextTick(function () {
          this.editType = "addNew";
          this.stkInEditVisible = true;
        });
      }
    },
    // 按整张需求单生成入库单
    onAddStkInAll() {
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows !== null && rows.length > 0) {
        let data = {BOMGUID: rows[0].BOMGUID};
        this.$store.state.visibled = false;
        this.$store.commit("setParamOrder", {bomInfo: data});
        this.$nextTick(function () {
          this.editType = "addNew";
          this.stkInEditVisible = true;
        });
      }
    },
    // 生成销售单
    onAddSaleOrder() {
      let bomLinGuis = this.onSelectRow(); // 选中行的bdlineguid数组
      if (bomLinGuis !== null && bomLinGuis.length > 0) {
        this.$store.state.visibled = false;
        let data = {BDLineGuids: bomLinGuis};
        this.$store.commit("setParamOrder", {bomInfo: data});
        this.$nextTick(function () {
          // this.$refs.flexGrid.clearSelection()
          this.editType = "edit";
          this.salesEditVisible = true;
          this.SalesOrderAddTitle = "新增销售单";
        });
      }
    },
    onSelectRow() {
      let guid = []; // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (!this.hasValue(this.CurrentItem)) {
        this.$message({
          message: "请至少选择一条数据进行操作",
          type: "warning"
        });
        return [];
      }
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem.BDLineGUID) {
          // 选中行
          this.$message({
            message: "请至少选择一条数据进行操作",
            type: "warning"
          });
          return [];
        } else {
          // this.$refs.flexGrid.clearSelection()
          guid.push(this.CurrentItem.BDLineGUID);
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].CustomerName;
        var isDiff = false;
        _.each(rows, r => {
          if (lastCustomerName !== r.CustomerName) {
            isDiff = true;
          }
        });
        if (isDiff) {
          this.$message({
            message: "请选择相同客户的数据进行操作",
            type: "warning"
          });
          return [];
        }
        guid = _.map(rows, item => {
          return item.BDLineGUID;
        });
      }

      return guid;
    },
    onSelectRowAll() {
      let guid = []; // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem.BDLineGUID) {
          // 选中行
          return this.$message({
            message: "请至少选择一条数据进行操作",
            type: "warning"
          });
        } else {
          guid.push(this.CurrentItem.BOMGUID);
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].CustomerName;
        var isDiff = false;
        _.each(rows, r => {
          if (lastCustomerName !== r.CustomerName) {
            isDiff = true;
          }
        });
        if (isDiff) {
          return this.$message({
            message: "请选择相同客户的数据进行操作",
            type: "warning"
          });
        }
        guid = _.map(rows, item => {
          return item.BOMGUID;
        });
      }
      // 去掉重复的主单GUID
      var result = [];
      var isRepeated;
      var len = guid.length;
      var i = 0;
      for (; len > i; i++) {
        isRepeated = false;
        var j = 0;
        var len2 = result.length;
        for (; j < len2; j++) {
          if (guid[i] === result[j]) {
            isRepeated = true;
            break;
          }
        }
        if (!isRepeated) {
          result.push(guid[i]);
        }
      }
      return result;
    },
    onPrintTake() {
      let hasAdopt = false;
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows.length === 0 || (rows.length > 0 && rows[0] === undefined)) {
        return this.$message({
          message: "请至少选择一条数据进行操作",
          type: "warning"
        });
      }
      let guids = [];
      _.each(rows, item => {
        if (
          item.BDLineGUID !== null &&
          item.BDLineGUID !== undefined &&
          item.BDLineGUID !== ""
        ) {
          guids.push(item.BDLineGUID);
        }
        if (this.hasValue(item.IQGuidList) && item.IQGuidList.length > 0) {
          hasAdopt = true;
        }
      });

      if (!hasAdopt) {
        //  return this.$message({ message: '选中的数据还没有采纳供应商报价', type: 'warning' })
      }

      let param = {};

      // 按照整单打时，取主单GUID
      if (this.printTakeGoodsALL === true) {
        param.BomGUID = rows[0].BOMGUID;
      } else {
        param.BDLineGuids = guids;
      }
      this.$post(CONFIG.getTakeGoodsURL, param, data => {
        if (data === undefined || data.length <= 0) {
          return this.$message({
            message: "选中的数据还没有采纳询价记录",
            type: "warning"
          });
        }
        this.printTakeGoods({TakeGoods: data});
      });
    },
    // 通知采购
    onNotice(isAll) {
      let rows = this.$refs.flexGrid.getSelectedRows();
      if (rows === undefined || rows.length <= 0) {
        return this.$message({
          message: "请选择要通知查价的需求明细",
          type: "warning"
        });
      }
      // isAll true 为整单通知 false 为选中项
      this.bdLinedGuidsStr = "";
      this.BomGuid = "";
      if (isAll) {
        this.BomGuid = rows[0].BOMGUID;
      } else {
        let bdLineGUIDs = []; // 选中行的bdlineguid数组
        bdLineGUIDs = _.map(rows, item => {
          return item.BDLineGUID;
        });
        this.bdLinedGuidsStr = JSON.stringify(bdLineGUIDs);
      }
      this.waitNoticedBomVisible = true;
    },
    addNewRow() {
      this.$refs.flexGrid.addRow(true);
    },
    onDelete() {
      var rows = this.$refs.flexGrid.getSelectedRows();
      if (rows.length > 0) {
        _.each(rows, data => {
          if (data["_PKIndex"] === -1) {
            _.each(this.itemSource, (item, i) => {
              if (_.isEqual(item, data)) {
                this.itemSource.splice(i, 1);
                return;
              }
            });
          }
        });
      }
      if (_.isUndefined(rows) || rows.length === 0) {
        return this.$message({
          message: "请至少选择一条数据进行操作",
          type: "warning"
        });
      }
      let lists = [];
      _.each(rows, item => {
        if (item.BDLineGUID && !_.isUndefined(item.BDLineGUID)) {
          lists.push(item.BDLineGUID);
        }
      });
      if (lists.length > 0) {
        this.$confirm("确认删除" + lists.length + "条数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return this.$post(CONFIG.deleteURL, {BDLineGUIDS: lists}, data => {
            if (data > 0) {
              this.$refs.flexGrid.clearSelection();
              this.$message({message: "删除成功", type: "success"});
              this.onBindData(this.pageIndex);
            } else {
              this.$message({message: "删除失败", type: "error"});
            }
          });
        });
      }
    },
    isEmptyRow(row) {
      let hasval = false;
      let skipKeys = ["isEdit", "_PKIndex", "_PKRowindex"];
      for (var i in row) {
        if (_.some(skipKeys, m => m === i)) {
          continue;
        }
        if (!_.isUndefined(row[i]) && row[i] !== null && row[i] !== "") {
          hasval = true;
          break;
        }
      }
      return !hasval;
    },
    checkData() {
      let isPass = true;
      // let rows = []
      if (this.itemSource.length > 0) {
        let messageStr = "";
        _.each(this.itemSource, (item, index) => {
          if (messageStr.length > 0) {
            return true;
          }
          // 判断是否为空行
          if (!this.isEmptyRow(item)) {
            if (!this.hasValue(item["Model"])) {
              messageStr = "需求明细第" + (index + 1) + "行 型号不能为空";
            } else if (!this.hasValue(item["Qty"])) {
              messageStr = "需求明细第" + (index + 1) + "行 需求量不能为空";
            } else if (!isFinite(item["Qty"])) {
              messageStr =
                "需求内容第" + (index + 1) + "行 需求量必须为数字类型";
            } else if (item["Qty"] <= 0) {
              messageStr = "需求明细第" + (index + 1) + "行 需求量需大于0";
            }
          }
        });
        if (messageStr.length > 0) {
          isPass = false;
          this.$message({message: messageStr, type: "warning"});
        }
      }
      return isPass;
    },
    onSaveRows(rows) {
      if (this.checkData()) {
        // _.each(rows, item => {
        //   if (!this.hasValue(item.BOMGUID)) {
        //     item.BOMGUID = this.searchModel.BOMGUID
        //   }
        // })
        // 保存当前页面的值
        return this.$post(
          CONFIG.saveURL,
          {lineList: rows},
          (datas, logdata) => {
            if (logdata.code === 200) {
              if (this.isEnableSaveSuccessAfterEvent) {
                // 返回出 主单要更新的字段值
                var json = {
                  BOMGUID: datas.BOMGUID,
                  QuoteStatus: datas.QuoteStatus,
                  QuotePriceText: datas.QuotePriceText,
                  BuyerPriceText: datas.BuyerPriceText,
                  GrossInterestRate: datas.GrossInterestRate
                };
                this.$emit("save-success", json);
              }
              this.$message({message: "保存成功", type: "success"});
              // 清空所有修改过的值清空所有修改过的值
              // _.each(this.itemSource, item => {
              //   item.IsAdopt = false
              // })
              // this.onRefresh()
            } else {
              this.$message({message: logdata.msg, type: "error"});
            }
          }
        );
      } else {
        return false;
      }
    },
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      let taxRate = 0;

      // 供应商报价
      if (colname === "BuyPrice" && aftervalue !== beforevalue) {
        // this.itemSource[rowindex]['BuyPrice'] = this.toFloat(aftervalue, 6)
        gridobj.row["BuyPrice"] = this.toFloat(aftervalue, 6);

        // 获取供应商报价,给客户未税价, 计算毛利率
        if (
          this.hasValue(gridobj.row["NTQuotePrice"]) &&
          this.hasValue(gridobj.row["BuyPrice"]) &&
          aftervalue !== ""
        ) {
          gridobj.row["ProfitRate"] = this.getProfitRate(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"]
          );
          // 计算毛利额
          gridobj.row["ProfitAmountLine"] = this.CalcProfitAmount(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"],
            gridobj.row["Qty"]
          );
        } else {
          gridobj.row["ProfitRate"] = "";
          gridobj.row["ProfitAmountLine"] = "";
        }
        this.isEnableSaveSuccessAfterEvent = true;
      }
      // 给客户报价(含税)
      if (colname === "QuotePrice" && aftervalue !== beforevalue) {
        taxRate = gridobj.row["TaxRate"];
        let priceNotTax = this.CalcPriceNotTax(aftervalue, taxRate);
        gridobj.row["QuotePrice"] = this.toFloat(aftervalue, 6);
        gridobj.row["NTQuotePrice"] = this.toFloat(priceNotTax, 6);
        // 获取供应商报价,给客户未税价, 计算毛利率
        if (
          this.hasValue(gridobj.row["NTQuotePrice"]) &&
          this.hasValue(gridobj.row["BuyPrice"]) &&
          aftervalue !== ""
        ) {
          gridobj.row["ProfitRate"] = this.getProfitRate(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"]
          );
          // 计算毛利额
          gridobj.row["ProfitAmountLine"] = this.CalcProfitAmount(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"],
            gridobj.row["Qty"]
          );
        } else {
          gridobj.row["ProfitRate"] = "";
          gridobj.row["ProfitAmountLine"] = "";
        }
        this.isEnableSaveSuccessAfterEvent = true;
      }
      // 给客户报价(未税)
      if (colname === "NTQuotePrice" && aftervalue !== beforevalue) {
        taxRate = gridobj.row["TaxRate"];
        let priceInTax = this.CalcPriceInTax(aftervalue, taxRate);
        gridobj.row["NTQuotePrice"] = this.toFloat(aftervalue, 6);
        gridobj.row["QuotePrice"] = this.toFloat(priceInTax, 6);
        // 获取供应商报价,给客户未税价, 计算毛利率
        if (
          this.hasValue(gridobj.row["NTQuotePrice"]) &&
          this.hasValue(gridobj.row["BuyPrice"]) &&
          aftervalue !== ""
        ) {
          gridobj.row["ProfitRate"] = this.getProfitRate(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"]
          );
          // 计算毛利额
          gridobj.row["ProfitAmountLine"] = this.CalcProfitAmount(
            gridobj.row["NTQuotePrice"],
            gridobj.row["BuyPrice"],
            gridobj.row["Qty"]
          );
        } else {
          gridobj.row["ProfitRate"] = "";
          gridobj.row["ProfitAmountLine"] = "";
        }
        this.isEnableSaveSuccessAfterEvent = true;
      }

      if (
        colname === "Model" &&
        !_.isUndefined(aftervalue) &&
        !_.isNull(aftervalue)
      ) {
        // this.itemSource[rowindex]['Model'] = aftervalue.toUpperCase()
      }
      // 建议报价
      if (colname === "ProposalPrice" && aftervalue !== beforevalue) {
        gridobj.row["ProposalPrice"] = this.toFloat(aftervalue, 6);
      }
      // 需求数量
      if (colname === "Qty" && aftervalue !== beforevalue) {
        gridobj.row["Qty"] = this.toInt(aftervalue);
        // 计算毛利额
        gridobj.row["ProfitAmountLine"] = this.CalcProfitAmount(
          gridobj.row["NTQuotePrice"],
          gridobj.row["BuyPrice"],
          gridobj.row["Qty"]
        );
        this.isEnableSaveSuccessAfterEvent = true;
      }
    },
    CalcProfitAmount(quoteprice, buyprice, qty) {
      quoteprice = this.toFloat(quoteprice);
      buyprice = this.toFloat(buyprice);
      qty = this.toFloat(qty);
      let price = (quoteprice - buyprice) * qty;
      return this.toFloat(price, 2);
    },
    // 计算含税价
    CalcPriceInTax(price, taxRate) {
      return this.toFloat(price * (1 + taxRate / 100), 6);
    },
    // 计算未税
    CalcPriceNotTax(priceintax, taxRate) {
      return this.toFloat(priceintax / (1 + taxRate / 100), 6);
    },
    // 秒配
    onGetMatchPrice() {
      let models = [];
      let rows = [];
      let checkrows = this.$refs.flexGrid.getSelectedRows();
      if (checkrows !== undefined && checkrows.length > 0) {
        rows = checkrows;
      } else {
        rows = this.itemSource;
      }
      _.each(rows, item => {
        if (item.Model !== undefined) {
          if (
            !_.find(models, item => {
              return item.PartNo === item.Model;
            })
          ) {
            models.push({PartNo: item.Model.toUpperCase()});
          }
        }
      });
      if (models.length > 0) {
        this.loadingText = "正在匹配型号价格,请稍后...";
        this.loading = true;
        this.$post(CONFIG.getMatchPriceUrl, models, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({message: logic.msg, type: "error"});
          } else {
            if (data !== undefined && data.length > 0) {
              var falg = false;
              _.each(rows, item => {
                _.each(data, val => {
                  if (
                    !_.isUndefined(val.PartNo) &&
                    !_.isNull(val.PartNo) &&
                    !_.isUndefined(item.Model) &&
                    item.Model.toUpperCase().trim() ===
                    val.PartNo.toUpperCase().trim()
                  ) {
                    let priceArray = [];
                    _.each(val.PartInfoPrice, i => {
                      this.setMatchPrice(i, item);
                      if (
                        !_.isUndefined(i.WType) &&
                        !_.isNull(i.WType) &&
                        !_.isUndefined(i.Price) &&
                        !_.isNull(i.Price)
                      ) {
                        let priceInfo = {
                          Type: i.WType,
                          InvQty: i.InvQty,
                          Price: i.Price,
                          Url: i.Url
                        };
                        priceArray.push(priceInfo);
                      }
                    });
                    if (priceArray.length > 0) {
                      item.MatchPriceJson = "";
                      falg = true;
                      item.MatchPriceJson = JSON.stringify(priceArray);
                    }
                  }
                });
              });
              if (!falg) {
                this.$message({
                  message: "抱歉,暂时匹配不到列表中的型号价格!",
                  type: "warning"
                });
              }
            }
          }
          this.loading = false;
          this.$nextTick(() => {
            this.loadingText = "数据加载中,请稍后...";
          });
        });
      } else {
        this.$message({
          message: "请选择勾选上要操作的数据!",
          type: "warning"
        });
      }
    },
    // 设置 匹配价格
    setMatchPrice(priceInfo, item) {
      if (
        priceInfo.WType === 0 &&
        !_.isUndefined(priceInfo.Price) &&
        !_.isNull(priceInfo.Price) &&
        priceInfo.Price !== ""
      ) {
        // 云价格
        this.$set(item, "DscQuotePriceText", priceInfo.Price);
      }
      if (
        priceInfo.WType === 1 &&
        !_.isUndefined(priceInfo.Price) &&
        !_.isNull(priceInfo.Price) &&
        priceInfo.Price !== ""
      ) {
        // 得捷
        if (
          !_.isUndefined(priceInfo.InvQty) &&
          !_.isNull(priceInfo.InvQty) &&
          priceInfo.InvQty > 0
        ) {
          this.$set(
            item,
            "DigikeyPriceText",
            priceInfo.Price + " / " + priceInfo.InvQty
          );
        } else {
          this.$set(item, "DigikeyPriceText", priceInfo.Price);
        }
        this.$set(item, "DigikeyURL", priceInfo.Url);
      }
      if (
        priceInfo.WType === 2 &&
        !_.isUndefined(priceInfo.Price) &&
        !_.isNull(priceInfo.Price) &&
        priceInfo.Price !== ""
      ) {
        // 立创
        if (
          !_.isUndefined(priceInfo.InvQty) &&
          !_.isNull(priceInfo.InvQty) &&
          priceInfo.InvQty > 0
        ) {
          this.$set(
            item,
            "LiChuangPriceText",
            priceInfo.Price + " / " + priceInfo.InvQty
          );
        } else {
          this.$set(item, "LiChuangPriceText", priceInfo.Price);
        }
        this.$set(item, "LiChuangURL", priceInfo.Url);
      }
      if (
        priceInfo.WType === 3 &&
        !_.isUndefined(priceInfo.Price) &&
        !_.isNull(priceInfo.Price) &&
        priceInfo.Price !== ""
      ) {
        //  云汉
        if (
          !_.isUndefined(priceInfo.InvQty) &&
          !_.isNull(priceInfo.InvQty) &&
          priceInfo.InvQty > 0
        ) {
          this.$set(
            item,
            "YunHanPriceText",
            priceInfo.Price + " / " + priceInfo.InvQty
          );
        } else {
          this.$set(item, "YunHanPriceText", priceInfo.Price);
        }
        this.$set(item, "YunHanURL", priceInfo.Url);
      }
    },

    // 如果下拉按钮全部隐藏，则主按钮也隐藏
    isShowMainBtn(btns) {
      let isShow = false;
      _.each(btns, btn => {
        if (btn.isShow) {
          isShow = true;
          return true;
        }
      });
      return isShow;
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    },
    onChangeStatus(val) {
      this.checkStatus = val;
    },
    // 保存模块状态
    saveStatus(val) {
      let Status = val ? 1 : 0;
      if (Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status;
        let statusConfig = this.defaultConfig;
        statusConfig.ColumnConfigs = [];
        this.$post(this.$options.config.saveConfigURL, statusConfig);
      }
    },
    // 采纳 采购报价反馈记录后 回调事件
    adoptBomAssistCallback(val) {
      _.each(this.itemSource, item => {
        if (item.BDLineGUID === val.BDLineGUID) {
          this.$set(item, "BuyPrice", this.toFloat(val.SuggestPrice, 6));
          this.$set(item, "BuyerName", val.BuyEmpName);
          this.$set(item, "BuyerQuoteTime", val.BuyerQuoteTime);
          this.$set(item, "BuyerRemark", val.BuyerRemark);

          if (!this.hasValue(item.Brand)) {
            this.$set(item, "Brand", val.Brand);
          }
          if (!this.hasValue(item.Packaging)) {
            this.$set(item, "Packaging", val.Packaging);
          }
          if (!this.hasValue(item.MakeYear)) {
            this.$set(item, "MakeYear", val.MakeYear);
          }
          if (!this.hasValue(item.MPQ)) {
            this.$set(item, "MPQ", val.MPQ);
          }
          if (!this.hasValue(item.Quality)) {
            this.$set(item, "Quality", val.Quality);
          }
          if (!this.hasValue(item.DeliveryDate)) {
            this.$set(item, "DeliveryDate", val.DeliveryDate);
          }
        }
      });
    },
    tableRowClassName(obj) {
      if (
        this.hasValue(obj.row.ProfitRate) &&
        this.hasValue(this.minProfitMargin) &&
        this.toFloat(this.minProfitMargin, 2) >=
        this.toFloat(obj.row.ProfitRate, 2)
      ) {
        return "blueTableCell";
      } else if (
        !this.hasValue(obj.row.BuyPrice) &&
        this.hasValue(obj.row.BuyerQuoteTime)
      ) {
        return "redTableCell";
      }
    },
    onCellClick(row, column, cell, event) {
      this.$emit("callEvent",{
        type:'line-click-row',
        data:row,
      });
      this.$emit("cell-click", row);
    }
  }
};
</script>

<style lang="scss">
.grid-bomdetailLine-box {
  .redTableCell {
    .znl-tablecell {
      color: red;
    }
  }

  .blueTableCell {
    .znl-tablecell {
      color: #0000ff;
    }
  }

  .el-radio__label {
    font-size: 12px !important;
    padding-left: 3px !important;
  }

  .znl-component-input .el-input .el-input__inner {
    line-height: 20px;
    height: 20px;
  }

  .noticeznlbtns .wj-right-menu {
    width: auto !important;
  }

  .znl-gridModuleHeader {
    padding-left: 8px;
    background: #e6e6e6;
  }

  .znl-content .isFieldsSearch {
    padding-left: 0px;
  }
}
</style>

