<template>
  <znl-layout-multiple
    ref="layout"
    element-loading-text="页面加载中,请稍后..."
    v-loading="pageLoading"
    class="p-bom-manage"
  >

    <Split
      class="split"
      :style="{height:modelContentHeight.pageContent+'px'}"
      direction="vertical"
      @onDragEnd="onDragEndBySales"
    >
      <SplitArea
        :size="20.5"
        :minSize="20.5"
      >
        <multiple
          v-show="!pageLoading"
          name="BomMaster"
          style="height:100%;"
        >
          <znl-bom-manage-module
            @itemSourceChange="itemSourceChange"
            ref="BomMaster"
            class="BomManage"
            :class="multiplerows[0].isHide ? 'BomManage_hide' : 'BomManage_show'"
            :is-activity="true"
            @page-loading="
          (d) => {
            pageLoadingMaster = d;
          }
        "
            @cell-click="onBomMasterCellClick"
          ></znl-bom-manage-module>

          <a
            class="old-edition"
            style="right: 150px; top: 15px"
            v-show="!pageLoading"
            v-if="isErpClient"
            @click="oldEdition()"
          >切换到旧版</a>
        </multiple>
      </SplitArea>
      <SplitArea
        :size="45.5"
        :minSize="45.5"
      >
        <!-- 需求物料 -->
        <multiple
          style="height:100%"
          v-show="!pageLoading"
          name="BomDetailLine"
        >
          <znl-bom-detail-line
            ref="BomDetailLine"
            class="BomManage"
            :class="multiplerows[1].isHide ? 'BomManage_hide' : 'BomManage_show'"
            :is-activity="true"
            :is-mini="true"
            :is-analyzer="true"
            @callEvent="callEvent"
            @page-loading="
          (d) => {
            pageLoadingDetail = d;
          }
        "
            :searchModel.sync="bomMasterLine.searchModel"
            :searchInput="bomMasterLine.searchInput"
            @AdoptEnd="onAdoptEnd"
            @cell-click="onBomLineCellClick"
            @save-success="
          (d) => {
            saveSuccess(d);
          }
        "
          ></znl-bom-detail-line>
        </multiple>
      </SplitArea>
      <SplitArea
        :size="33"
        :minSize="33"
      >
        <!-- 参考信息 -->
        <multiple
          style="height:100%"
          name="BomTabMultiple"
          v-show="!pageLoading"
        >
          <el-tabs
            class="tab-btnstyle-box nav-common-ui"
            ref="Tabs"
            name="Tabs"
            v-model="activeName"
            @tab-click="pageSearch"
          >
            <el-tab-pane
              label="我的库存 (按型号)"
              name="StockQuery"
              v-if="hasRight('StockQuery')"
            >
              <znl-stock
                ref="StockQuery"
                class="BomManage"
                :is-fields-search="false"
                :init-data="false"
                :is-adopt="true"
                :searchModel="stockSearchModel"
                height="100%"
                :class="
              multiplerows[7].isHide ? 'BomManage_hide' : 'BomManage_show'
            "
                @save-success="
              (val) => {
                emitDatas(val);
              }
            "
                @switch-to-hide="
              (val) => {
                switchToHide(val, 7);
              }
            "
                @ChkStkGUID="
              (val) => {
                this.onChkStkGUID(val);
              }
            "
              ></znl-stock>
            </el-tab-pane>
            <el-tab-pane label="供应商库存 (按型号)" name="StockVMIMini" v-if="hasRight('StockVMIMini')">
              <stock-vmi
                ref="stockVMIMini"
                gridtype="base"
                v-if="stockVMIMiniInitData"
                :is-operate="false"
                :is-adopt="true"
                @onStockVMIAdopt="val => emitDatas(val)"
              ></stock-vmi>
            </el-tab-pane>

            <el-tab-pane
              label="询价记录 (按型号)"
              name="StkInquireRecord"
              height="100%"
              v-if="hasRight('StkInquireRecord')"
            >
              <znl-stk-inquire-record
                ref="StkInquireRecord"
                class="BomManage"
                height="220px"
                header="询价记录"
                :is-fields-search="false"
                :is-mini="true"
                :source-type="1"
                :has-operation-column="false"
                :is-multi-rows-check="false"
                :is-init="false"
                :is-adopt="true"
                :class="
              multiplerows[6].isHide ? 'BomManage_hide' : 'BomManage_show'
            "
                @switch-to-hide="
              (val) => {
                switchToHide(val, 6);
              }
            "
                @save-success="
              (val) => {
                this.emitDatas(val);
              }
            "
                :searchModel="inquireSearchModel"
              ></znl-stk-inquire-record>
            </el-tab-pane>

            <!-- <el-tab-pane
          label="云价格 (按型号)"
          name="CloundPrice"
          height="100%"
          v-if="hasRight('CloundPrice')"
        >
          <clound-price
            class="BomManage"
            ref="CloundPrice"
            v-if="cloundPriceInitData"
            :init-data="false"
            :is-mini="true"
            :searchModel="cloundPriceSearchModel"
          ></clound-price>
        </el-tab-pane>-->

            <el-tab-pane
              label="出库记录 (按型号)"
              name="PastStkOut"
              height="100%"
              v-if="hasRight('PastStkOut')"
            >
              <stk-out-details
                ref="PastStkOut"
                v-if="stkOutDetailsInitData"
                :is-operate="false"
              ></stk-out-details>
            </el-tab-pane>

            <!-- <el-tab-pane label="入库记录 (按型号)" name="PastStkIn" height="100%" v-if="hasRight('PastStkIn')">
          <stk-in-details ref="PastStkIn" v-if="stkInDetailsInitData" :is-operate="false"></stk-in-details>
        </el-tab-pane>-->

            <el-tab-pane
              label="报价记录 (按型号)"
              name="BomMastLineSelect"
              height="100%"
              v-if="hasRight('BomMastLineSelect')"
            >
              <bom-quote-details
                ref="BomMastLineSelect"
                :is-show-header="false"
                v-if="bomDetailsInitData"
                :is-show-operate-col="false"
              ></bom-quote-details>
            </el-tab-pane>
            <el-tab-pane
              label="采购报价反馈 (按当前需求)"
              name="BomAssistQuoted"
              height="100%"
              v-if="hasRight('StockQuery')"
            >
              <sts-purchase-quote
                style="height:100%"
                v-if="purchaseQuoteInitData"
                class="sts-purchase-quote"
                ref="BomAssistQuoted"
                :is-search="false"
              ></sts-purchase-quote>
            </el-tab-pane>
          </el-tabs>
        </multiple>
      </SplitArea>
    </Split>
  </znl-layout-multiple>
</template>
<script>
import ZnlBomManageModule from "@c_modules/BOM/BomManageModule";
import ZnlBomDetailLine from "@c_modules/BOM/BomDetailLine";

import ZnlStock from "@c_modules/Stk/StkStock_Mini";
import ZnlStkInquireRecord from "@c_modules/Stk/StkInquireRecord";

import { mixin as common } from "~assets/scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { switchToLegacyPage } from "~/lib/native";

import StockVmi from "@c_modules/Stk/StockVMI";
import StkOutDetails from "@c_modules/Stk/StkOutDetails";
import StkInDetails from "@c_modules/Stk/StkInDetails";
import BomQuoteDetails from "@c_modules/BOM/BomQuoteDetails";
import StsPurchaseQuote from "@c_modules/Sts/StsPurchaseQuote_v1";
import PubStock from "@c_modules/StkExt/PubStkStock_Mini_v1";
import CloundPrice from "@c_modules/BOM/BomCloundPrice_v1";
import StkInquireRecord from "@c_modules/Stk/StkInquireRecord_v1";
import Stock from "@c_modules/Stk/StkStock_Mini_v1";
import { isClient } from "~/lib/runtime";

const CONFIG = {
  pageCode: "VBomMaster",
};
export default {
  name: "VBomMaster",
  mixins: [common, getCommonDataMixin],
  components: {
    ZnlBomManageModule,
    ZnlBomDetailLine,
    ZnlStkInquireRecord,
    ZnlStock,
    //ZnlStkInRecord,
    //ZnlCloundPrice,
    //ZnlStkOutRecord,
    //ZnlPubStock,
    //ZnlQuoteRecord,
    //znlBomAssistQuoted,
    switchToLegacyPage,
    //ZnlStockVmi,

    StockVmi,
    StkOutDetails,
    StkInDetails,
    BomQuoteDetails,
    StsPurchaseQuote,
    PubStock,
    CloundPrice,
    StkInquireRecord,
    Stock,
  },

  data() {
    return {
      BomDetailLineHeight: 0,
      BomMasterHeight: 0,
      multiplerows: [
        {
          hasRight: this.hasRight("BomMaster"), // BOM管理模块
          isHide: false,
        },
        {
          hasRight: this.hasRight("BomDetailLine"), // 需求物料模块
          isHide: false,
        },
        {
          hasRight: this.hasRight("CloundPrice"), // 云价格
          isHide: false,
        },
        {
          hasRight: this.hasRight("PastStkOut"), // 出库记录
          isHide: false,
        },
        {
          hasRight: this.hasRight("PastStkIn"), // 入库记录
          isHide: false,
        },
        {
          hasRight: true, // 给客户的报价记录
          isHide: false,
        },
        {
          hasRight: this.hasRight("StkInquireRecord"), // 向供应商的询价记录
          isHide: false,
        },
        {
          hasRight: this.hasRight("StockQuery"), // 我的库存
          isHide: false,
        },
        {
          hasRight: this.hasRight("StockVMIMini"), // 供应商库存  // StockVMIMini
          isHide: false,
        },
        {
          hasRight: this.hasRight("PubStkStockMini"), // 推广的库存
          isHide: false,
        },
        {
          hasRight: this.hasRight("BomAssistQuoted"), // 采购报价反馈
          isHide: false,
        },
      ],
      pageData: [],
      pageLoadingMaster: true,
      pageLoadingDetail: true,
      tabObject: {},
      activeName: "StockQuery",
      bomDetailHeight: "auto",
      bomDetailHeightValue: 0, //数字型
      tabHeight: "250px",
      activeBomMaster: true,
      activeBomDetailLine: true,
      bomMasterLine: {
        searchModel: {
          AllBom: true,
          status: [],
        },
        searchInput: {
          inputModel: "",
        },
      },
      bomMasterDetail: {
        searchModel: {
          data: {},
          extra: {},
        },
      },
      inquireSearchModel: {
        // 供应商报价记录
        Model: "",
      },
      stockSearchModel: {
        // 我的库存
        Model: "",
      },
      stockVMISearchModel: {
        // 供应库存
        Model: "",
      },
      PubStockSearchModel: {
        // 推广的库存
        Model: "",
      },
      salesSearchModel: {
        // 销售记录
        Model: "",
      },
      purchaseSearchModel: {
        // 采购记录
        Model: "",
      },
      cloundPriceSearchModel: {
        // 云价格
        Model: "",
        BDLineGUID: "",
      },
      quoteRecordSearchModel: {
        // 报价记录
        Model: "",
      },
      bomAssistQuotedSearchModel: {
        // 采购报价反馈
        // Model: '',
        BDLineGUID: "",
      },
      stockVMIMiniInitData: false,
      stkOutDetailsInitData: false,
      stkInDetailsInitData: false,
      bomDetailsInitData: false,
      purchaseQuoteInitData: false,
      pubStockInitData: false,
      cloundPriceInitData: false,
      stkInquireRecordInitData: false,
      isErpClient: false,
      modelContentHeight: {
        bom: 0,
        material: 0,
        tabs: 0,
        pageContent: 0,
      },
    };
  },
  props: {
    hasOperationColumn: {
      type: Object,
      default() {
        return {
          StockQuery: true,
          StockVMIMini: true,
          PastInquire: true,
        };
      },
    },
  },
  computed: {
    pageLoading() {
      return this.pageLoadingMaster || this.pageLoadingDetail;
    },
  },
  methods: {
    init() {
      this.modelContentHeight = this.getModelContentHeight();
    },

    /* 通讯事件 */
    callEvent(opts){
      let {type,data} = opts;
      switch(type){
        case "line-click-row":
          this.$refs.BomMaster.resetQuery(data);
          break;
      }
    },

    onDragEndBySales() {
      this.$refs.PastStkOut && this.$refs.PastStkOut.$refs.table.init();
      this.$refs.BomMastLineSelect &&
        this.$refs.BomMastLineSelect.$refs.table.init();
      this.$refs.BomAssistQuoted &&
        this.$refs.BomAssistQuoted.$refs.table.init();
      // this.$refs.BomMaster && this.$refs.BomMaster.$refs.flexGrid.init();
    },

    itemSourceChange() {
    },

    getModelContentHeight() {
      let bomH = 0.2;
      let materialH = 0.4;
      let tabsH = 0.4;
      let windowHeight = document.documentElement.clientHeight;
      let headDom = document.querySelector("#p-header-tabs-contents");
      let headHeight = headDom ? headDom.getBoundingClientRect().height : 40;
      let countHeight = windowHeight - headHeight;
      let modelContentHeight = {
        bom: countHeight * bomH,
        material: countHeight * materialH,
        tabs: countHeight * tabsH,
        pageContent: countHeight,
      };
      return modelContentHeight;
    },

    heightComputed(name, isHide) {
      //   debugger
      const debug = 0;
      const positionMap = {
        BomMaster: 0,
        BomDetailLine: 1,
      };
      const rows = this.multiplerows;
      const stat = Object.entries(positionMap)
        .reduce(
          (array, [name, index]) => (
            array.push(rows[index].isHide ? 0 : 1), array
          ),
          []
        )
        .join("");

      debug &&
        console.log(
          "isClient",
          isClient(),
          this.$store.state.domRect.clientHeight,
          this.$store.state.domRect.contentHeightWithoutTab
        );

      const topOffsetHeight = 7;
      const bottomOffsetHeight = 13;
      const clientHeight =
        (isClient()
          ? this.$store.state.domRect.clientHeight
          : this.$store.state.domRect.contentHeightWithoutTab) -
        topOffsetHeight -
        bottomOffsetHeight;
      debug &&
        console.log("change", name, "clientHeight", clientHeight, "stat", stat);

      const mainMinHeight = 32;
      const detailMinHeight = 32;
      const mainFixedHeight = 205; // 201 + 2 + 5
      const tabFixedHeight = 250;

      const statMap = {
        "01": () => {
          const detailHeight = clientHeight - mainMinHeight - tabFixedHeight;
          return {
            case: 1,
            BomDetailLine: detailHeight,
            Tabs: tabFixedHeight,
          };
        },
        10: () => {
          const tabHeight = clientHeight - mainFixedHeight - detailMinHeight;
          return {
            case: 2,
            BomDetailLine: detailMinHeight,
            Tabs: tabHeight,
          };
        },
        "00": () => {
          const tabHeight = clientHeight - mainMinHeight - detailMinHeight;
          return {
            case: 3,
            BomDetailLine: detailMinHeight,
            Tabs: tabHeight,
          };
        },
        11: () => {
          const detailHeight = clientHeight - mainFixedHeight - tabFixedHeight;
          return {
            case: 4,
            BomDetailLine: detailHeight,
            Tabs: tabFixedHeight,
          };
        },
      };

      let setValues = statMap[stat]();
      debug && console.log("setValues", setValues);

      const setBlockMap = {
        BomDetailLine: (h) => {
          this.bomDetailHeight = h + "px";
          this.bomDetailHeightValue = h;
          debug && console.log("setDetail", this.bomDetailHeight);
        },
        Tabs: (h) => {
          this.tabHeight = h + "px";
          debug && console.log("setTabs", this.tabHeight);
        },
      };
      Object.keys(setBlockMap).forEach((key) =>
        setBlockMap[key](setValues[key])
      );
    },

    onBomMasterCellClick(item) {
      this.$set(this.bomMasterLine.searchModel, "BOMGUID", item.BOMGUID);
      this.$set(this.bomMasterLine.searchModel, "AllBom", false);
      this.$set(this.bomMasterLine.searchModel, "status", []);
      this.$set(this.bomMasterLine.searchInput, "inputModel", "");
        this.$set(this.bomMasterLine.searchInput, "AllBom", false);

    },
    queryCloundPriceSearchModel() {
      // 云价格查询
      if (
        !_.isEqual(
          this.cloundPriceSearchModel.Model,
          this.bomMasterDetail.searchModel.Model && this.hasRight("CloundPrice")
        )
      ) {
        this.cloundPriceSearchModel.Model =
          this.bomMasterDetail.searchModel.Model;
        this.cloundPriceSearchModel.BDLineGUID =
          this.bomMasterDetail.searchModel.BDLineGUID;
        this.$nextTick(() => {
          this.$refs.CloundPrice.onHeadSearch(this.cloundPriceSearchModel);
        });
      }
    },
    querySalesSearchModel() {
      // 出库记录查询
      if (
        !_.isEqual(
          this.salesSearchModel.Model,
          this.bomMasterDetail.searchModel.Model
        ) &&
        this.hasRight("PastStkOut")
      ) {
        this.salesSearchModel.Model = this.bomMasterDetail.searchModel.Model;
        this.$nextTick(() => {
          this.$refs.PastStkOut.onHeadSearch(this.salesSearchModel);
        });
      }
    },
    queryPurchaseSearchModel() {
      // 采购记录查询
      if (
        !_.isEqual(
          this.purchaseSearchModel.Model,
          this.bomMasterDetail.searchModel.Model
        ) &&
        this.hasRight("PastStkIn")
      ) {
        this.purchaseSearchModel.Model = this.bomMasterDetail.searchModel.Model;
        this.$nextTick(() => {
          this.$refs.PastStkIn.onHeadSearch(this.purchaseSearchModel);
        });
      }
    },
    queryQuoteRecordSearchModel() {
      //  报价记录查询
      if (
        !_.isEqual(
          this.quoteRecordSearchModel.Model,
          this.bomMasterDetail.searchModel.Model &&
            this.hasRight("BomDetailLine")
        )
      ) {
        this.quoteRecordSearchModel.Model =
          this.bomMasterDetail.searchModel.Model;
        this.$nextTick(() => {
          this.$refs.BomMastLineSelect.onHeadSearch(
            this.quoteRecordSearchModel
          );
        });
      }
    },
    // 询价记录按型号
    queryInquireSearchModel() {
      // 供应商报价记录查询
      if (!this.hasRight("StkInquireRecord")) {
        return false;
      }
      //只有一条明细时无法触发查询操作
      // if (
      //   this.inquireSearchModel.Model !==
      //     this.bomMasterDetail.searchModel.Model ||
      //   this.inquireSearchModel.BDLineGUID !==
      //     this.bomMasterDetail.searchModel.BDLineGUID
      // ) {
        this.inquireSearchModel = {
          Model: this.bomMasterDetail.searchModel.Model,
          BDLineGUID: this.bomMasterDetail.searchModel.BDLineGUID,
        };
        this.$nextTick(() => {
          this.$refs.StkInquireRecord.onHeadSearch(this.inquireSearchModel);
        });
      // }
    },
    queryStockSearchModel() {
      // 我的库存查询
      if (
        !_.isEqual(
          this.stockSearchModel.BDLineGUID,
          this.bomMasterDetail.searchModel.BDLineGUID
        ) &&
        this.hasRight("StockQuery")
      ) {
        // 这里不要按型号进行比较差异,因为有关联BDLineGUID
        this.bomMasterDetail.searchModel.BDLineGUID =
          this.bomMasterDetail.searchModel.BDLineGUID;
        this.bomMasterDetail.searchModel.NeedAdoptQty =
          this.bomMasterDetail.searchModel.Qty;
        this.stockSearchModel = this.bomMasterDetail.searchModel;
        this.$nextTick(() => {
          this.$refs.StockQuery.onSearch(this.stockSearchModel);
        });
      }
    },
    queryStockvmiMiniModel() {
      // 供应商库存查询
      if (this.hasRight("StockQuery")) {
        this.stockVMISearchModel = this.bomMasterDetail.searchModel;
        this.$nextTick(() => {
          this.$refs.stockVMIMini.onHeadSearch(this.stockVMISearchModel, {
            BDLineGUID: this.stockVMISearchModel.BDLineGUID,
            Qty: this.stockVMISearchModel.Qty
          });
        });
      }
    },
    queryPubStockSearchModel() {
      // 推广的库存查询
      if (
        !_.isEqual(
          this.PubStockSearchModel.Model,
          this.bomMasterDetail.searchModel.Model
        ) &&
        this.hasRight("PubStkStockMini")
      ) {
        this.PubStockSearchModel = this.bomMasterDetail.searchModel;
        this.$nextTick(() => {
          this.$refs.PubStkStockMini.onHeadSearch(this.PubStockSearchModel);
        });
      }
    },
    queryBomAssistQuotedSearchModel() {
      // 采购报价反馈查询
      if (
        !_.isEqual(
          this.bomAssistQuotedSearchModel.BDLineGUID,
          this.bomMasterDetail.searchModel.BDLineGUID
        ) &&
        this.hasRight("BomAssistQuoted")
      ) {
        this.bomAssistQuotedSearchModel.BDLineGUID =
          this.bomMasterDetail.searchModel.BDLineGUID;
        this.bomAssistQuotedSearchModel.IsSubmit = true;
        this.bomAssistQuotedSearchModel.CanBomAssistQuoted = true;
        this.$nextTick(() => {
          this.$refs.BomAssistQuoted.onHeadSearch(
            this.bomAssistQuotedSearchModel
          );
        });
      }
    },
    onBomLineCellClick(item) {
      this.bomMasterDetail.searchModel = {
        Model: item.Model,
        BDLineGUID: item.BDLineGUID,
        Qty: item.Qty,
      };
      this.btnPattern(item);
    },
    btnPattern(item) {
      var isHasModel = this.hasValue(this.bomMasterDetail.searchModel.Model);
      if (this.activeName === "CloundPrice") {
        this.cloundPriceInitData = true;
        // 云价格
        isHasModel && this.queryCloundPriceSearchModel();
      }
      if (this.activeName === "PastStkOut") {
        this.stkOutDetailsInitData = true;
        // 出库记录
        isHasModel && this.querySalesSearchModel();
      }
      if (this.activeName === "PastStkIn") {
        this.stkInDetailsInitData = true;
        // 采购记录
        isHasModel && this.queryPurchaseSearchModel();
      }
      if (this.activeName === "BomMastLineSelect") {
        // 报价记录
        this.bomDetailsInitData = true;
        isHasModel && this.queryQuoteRecordSearchModel();
      }
      if (this.activeName === "StockQuery") {
        // 我的库存
        isHasModel && this.queryStockSearchModel();
      }
      if (this.activeName === "StockVMIMini") {
        this.stockVMIMiniInitData = true;
        // 供应商库存
        isHasModel && this.queryStockvmiMiniModel();
      }
      if (this.activeName === "PubStkStockMini") {
        this.pubStockInitData = true;
        // 推广的库存
        isHasModel && this.queryPubStockSearchModel();
      }
      if (this.activeName === "StkInquireRecord") {
        this.stkInquireRecordInitData = true;
        // 询价记录
        isHasModel && this.queryInquireSearchModel();
      }
      if (this.activeName === "BomAssistQuoted") {
        this.purchaseQuoteInitData = true;
        // 采购报价反馈
        isHasModel && this.queryBomAssistQuotedSearchModel();
      }
    },
    switchToHide(isHide, index, Obj) {
      if (!isHide) {
        this.multiplerows[index].isHide = true;
      } else {
        this.multiplerows[index].isHide = false;
      }
      if (index === 0) {
        // BOM主单 BOM管理
        this.$refs.BomMaster.saveStatus(isHide);
        this.activeBomMaster = isHide;
      }
      if (index === 1) {
        // 需求物料
        this.$refs.BomDetailLine.saveStatus(isHide);
        this.activeBomDetailLine = isHide;
      }
      if (index === 2) {
        // 云价格
        this.$refs.CloundPrice.saveStatus(isHide);
      }
      if (index === 3) {
        // 出库记录
        this.$refs.PastStkOut.saveStatus(isHide);
      }
      if (index === 4) {
        // 入库记录
        this.$refs.PastStkIn.saveStatus(isHide);
      }
      if (index === 5) {
        // 给客户的报价记录
        this.$refs.BomMastLineSelect.saveStatus(isHide);
      }
      if (index === 6) {
        // 向供应商的询价记录
        this.$refs.StkInquireRecord.saveStatus(isHide);
      }
      if (index === 7) {
        // 我的库存
        this.$refs.StockQuery.saveStatus(isHide);
      }
      if (index === 8) {
        this.$refs.stockvmiMini.saveStatus(isHide);
      }
      if (index === 9) {
        // 推广的库存
        this.$refs.PubStkStockMini.saveStatus(isHide);
      }
      if (index === 10) {
        // 采购报价反馈
        this.$refs.BomAssistQuoted.saveStatus(isHide);
      }
    },
    pageSearch() {
      this.btnPattern(this.tabObject);
    },
    hasRight(code) {
      return _.some(this.pageData, (page) => page.code === code);
    },
    emitDatas(row) {
      // 供应商列表采纳
      this.$refs.BomDetailLine.onInquire(row);
      this.$refs.BomMaster.onInquire(row);
    },
    saveSuccess(val) {
      this.$refs.BomMaster.updateBomAmount(val);
    },
    hasValue(val) {
      if (
        val === null ||
        val === undefined ||
        val === "" ||
        (typeof val === "string" && val.trim() === "")
      ) {
        return false;
      }
      return true;
    },
    QuotePriceText(row) {
      this.$refs.bomManage.QuotePriceText(row);
    },
    onChkStkGUID(stkGUID) {
      this.$refs.BomDetailLine.onAdopt(stkGUID); // 调用子模块的方法
    },
    onAdoptEnd(stkGUID, updateType) {
      // 修改成功后回传guid，修改页面显示值
      this.$refs.StkInquireRecord.onAdoptEnd(stkGUID);
    },
    onSaveBomAssistSuccess(val) {
      this.$refs.BomDetailLine.adoptBomAssistCallback(val);
    },
    onChkVMIGUID(VMGUID) {
      // 采纳操作
    },
    onStockVmidblclick(row) {
      // 采纳操作
    },
    // 切换到旧版
    async oldEdition() {
      await switchToLegacyPage("Bom");
    },
  },
  watch: {
    //监控窗口大小改变，因而计算组件的大小
    "$store.state.domRect.clientHeight": function (val) {
      this.$nextTick(() => {
        this.heightComputed("BomDetailLine", true);
        this.$refs.BomDetailLine.$refs.flexGrid.gridHeightValue =
          this.bomDetailHeightValue - 38;
        // this.$refs.BomDetailLine.$refs.flexGrid.height  = this.bomDetailHeightValue - 38;
      });
    },
  },

  async created() {
    this.isErpClient = isClient();
    this.onGetPageConfig(CONFIG.pageCode, true)
      .then((data) => {
        this.$nextTick(() => {
          this.pageData = data.RoleMenu;
          // 取消执行initRight函数,由filterPageConfig控制权限
          this.heightComputed("BomDetailLine", true);
        });
      })
      .catch((e) => {});
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
// .BomManage .BomManageModule{
//   height:auto !important;
// }
// .BomManage .znl-table-plug{
//   height:auto !important;
// }
// .BomManage .base-flex-grid{
//   height:auto !important;
// }

.p-bom-manage {
  .nav-common-ui .el-tab-pane {
    min-height: 200px;
  }

  .BomManage {
    height: 100% !important;
  }
  .BomManageModule {
    height: 100% !important;
  }
  .znl-table-plug {
    height: 100% !important;
  }
  .split {
    overflow: hidden;
  }

  .znl-table-body-wrapper {
    overflow: hidden;
    height: 100% !important;
    box-sizing: border-box;
  }

  .el-table__body-wrapper {
    // height:100% !important;
    // box-sizing: border-box;
    // padding-bottom:40px;
  }

  .table-container {
    height: 100% !important;
  }

  .base-flex-grid {
    height: 100% !important;
  }

  .sts-purchase-quote .v-table-views {
    // height:100% !important;
  }
}
.p_StockVMI_Mini {
  .znl-content .isFieldsSearch {
    display: none;
  }
}
.znl-layout-multiple.bommanage-box {
  height: 100% !important;
  //  overflow-y: auto;
}
.BomManage {
  width: 100%;
  .znl-lists
    .znl-list-header.erp_list_header
    ul.header-yul
    > li
    .header-xul
    .list-header {
    background-color: #525a66;
    color: #fff;
    border-right: 1px solid #e6e6e6;
  }
  .StockVMI_mini {
  }
}
.bommanage-box {
  .rightbtns .el-button {
    background-color: transparent;
  }
  .znl-save-revocation.hasEditRows .znl-btn-save,
  .znl-btn-close.isActive:hover {
    background-color: #ed9e00;
  }
  .znl-save-revocation.hasEditRows .znl-btn-save:hover {
    background-color: #e70;
  }
  .znl-table-body-wrapper {
    overflow-x: auto;
  }
  .old-edition {
    right: 150px;
  }
  .setPageGrid {
    right: 220px;
  }
}

.BomManage_show {
  &
    > .znl-content
    > .isFieldsSearch
    .znl-header-pagination
    .el-pagination__jump
    .el-input {
    // background-color: #e6e6e6;
  }
  > div > .znl-content > .isFieldsSearch,
  .znl-header-pagination .el-pagination__jump .el-input {
    background-color: #e6e6e6;
  }
  .znl-tree {
    overflow: hidden;
  }
}
.m-bom-multiple-tab .tab-btnstyle-box {
  padding-right: 0;
  min-height: 100px;
  .znl-content {
    border-bottom: none;
  }
  .el-tabs__content .el-tab-pane {
    border-bottom: none;
  }
}
.fixed-bottom {
  // border: 1px solid #C0C0C0;
  // position: absolute;
  z-index: 200;
  bottom: 0;
  // width: 100%;
}
.znl-lists .znl-list-footer.erp_list_footer.right {
  background: #f2f2f2;
}
</style>
