<template>
  <znl-gridmodule
    ref="flexGrid"
    header="已备货物料"
    height="170px"
    gridtype="action"
    loading-type="animation"
    :columns="columns"
    :item-source="itemSource"
    :column-colors="columnColors"
    :is-multi-rows-check="false"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :is-show-rightbtns="false"
    :users="$store.state.users"
    :on-init="onInit"
    :show-save-btn="false"
    :is-init="isInit"
    :loading="loading"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :is-activity="false"
    @cell-click="onCellClick"
    @selected-stock-Line="
      (d) => {
        $emit('selected-stock-Line', d);
      }
    "
    @edit:cell-ended="onCellEdited"
  >
    <div slot="heading-slot">
      <el-checkbox
        v-model="isAllReserve"
        size="small"
        @change="onChangedIsAllReserve"
        >显示所有已备货</el-checkbox
      >
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";
import { openLinkAll } from "@scripts/methods/common";
import TrackVue from "../Ord/Track.vue";
const CONFIG = {
  searchURL: "Reserve/Search",
  saveURL: "Reserve/Save",
  autoStockUrl: "Reserve/AutomatchStock",
};

export default {
  name: "SaleOrderStock",
  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll, common],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      Size: 10,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      loading: true,
      autoSaleOrderLine: [],
      // 辅助集合
      tempItemSource: [],
      // 当前销售明细的待备货量
      currentUnReserveQty: 0,
      isAllReserve: true, //显示所有已备货数据（默认勾选，第一次查出所有备货数据，后续不再请求接口，否则会有问题）
    };
  },
  watch: {},
  computed: {
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      },
    },
  },
  props: {
    isInit: Boolean,
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    SOGuid: null, //销售主单Guid
  },
  methods: {
    async onInit() {
      let gridConfig = [
        {
          CTitle: "JGUID",
          BindField: "JGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "SRGUID",
          BindField: "SRGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "StkGUID",
          BindField: "StkGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "SOLineGUID",
          BindField: "SOLineGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          width: 10,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "型号",
          BindField: "Model",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 180,
          width: 10,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "品牌",
          BindField: "Brand",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 120,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "封装",
          BindField: "Packaging",
          IsShow: true,
          IsReadonly: true,
          minWidth: 100,
          width: 10,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "年份",
          BindField: "MakeYear",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 100,
          width: 10,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "仓库",
          BindField: "InvPosition",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "库位",
          BindField: "StorageName",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "销售参考价",
          BindField: "Price",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 8,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "已发货量",
          BindField: "SalesOutQty",
          IsShow: true,
          IsReadonly: true,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 11,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "备货量",
          BindField: "Qty",
          IsShow: true,
          IsReadonly: false,
          // ColumnWidth: 80,
          width: 10,
          DisplayIndex: 10,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
      ];
      let columns = this.flexGridColumnsHandler(gridConfig);
      columns.push({
        name: "log",
        dataType: 5,
        binding: "log",
        width: 60,
        header: "操作",
        visible: true,
        allowSorting: false,
        isReadOnly: true,
        editable: false,
        isFieldSearch: true,
        isSystem: true,
        buttons: [
          {
            className: "znl-table-btn",
            when: (row) => {
              if (this.hasValue(row.SalesOutQty) && row.SalesOutQty > 0) {
                return false;
              } else {
                return true;
              }
            },
            content: "<a>删除</a>",
            click: (row) => {
              this.onDelete(row);
            },
          },
        ],
      });
      this.columns = columns;
      this.initData && this.onBindData();
    },
    async onBindData(pageIndex = 1) {
      if (this.searchFields !== undefined && this.searchFields !== null) {
        this.currentUnReserveQty = this.searchFields.Qty;
      }
      let param = {};
      let filterData = [];
      //显示所有已备货数据
      if (this.isAllReserve) {
        let SOGUID = this.SOGuid;
        param.SOGUID = SOGUID;
        filterData = _.filter(this.tempItemSource, (d) => d.SOGUID === SOGUID);
      } else {
        var soLineGUID = this.searchFields.SOLineGUID;
        param.SOLineGUID = soLineGUID;
        // this.currentUnReserveQty = this.searchFields.Qty;
        filterData = _.filter(
          this.tempItemSource,
          (d) => d.SOLineGUID === soLineGUID
        );
      }
      if (
        filterData === undefined ||
        filterData === null ||
        filterData.length === 0
      ) {
        this.$post(CONFIG.searchURL, param, (data) => {
          _.each(data, (item) => {
            // 给每一条数据赋上临时的GUID,(前端生成)
            item.JGUID = this.getNewGuid();
            item.IsDel = false;
            this.tempItemSource.push(item);
          });
          this.setItemSource();
        }).finally(() => {
          this.loading = false;
          this.$emit("pageloading", false);
          this.resetTableH();
        });
      } else {
        this.setItemSource();
        this.loading = false;
        this.$emit("pageloading", false);
      }
    },
    //已备货物料
    resetTableH() {
      let data = this.resetTableHeight({
        dom: ".sale-order-reserve-stock .znl-grid-component",
        parentDom: ".sale-order-reserve-stock",
        brother: [
          // ".m-znl-stock-select .isFieldsSearch",
          // ".m-znl-stock-select .footer",
        ],
        heightSet: true,
        surplus: 10,
      });

      this.resetTableHeight({
        dom: ".sale-order-reserve-stock .el-table",
        parentDom: ".sale-order-reserve-stock",
        brother: [".sale-order-reserve-stock .znl-gridModuleHeader"],
        heightSet: true,
        surplus: 0,
      });
    },
    // 选择库存给备货库存新加一行
    StockCheckRow(item) {
      if (item.UsableQty <= 0) {
        return this.$message({
          message: "库存型号【" + item.Model + "】可用量为0,不可备货",
          type: "warning",
        });
      }
      if (this.currentUnReserveQty <= 0) {
        return this.$message({
          message: "当前销售型号的待备货量为0,不可再备货",
          type: "warning",
        });
      }

      let soLineGUID = this.searchModel.SOLineGUID;
      let soGUID = this.searchModel.SOGUID;
      let stkGUID = item.StkGUID;
      // let source = _.filter(this.itemSource, function (info) {
      //   return info.SOLineGUID === soLineGUID && info.StkGUID === stkGUID
      // })
      // if (source.length > 0) {
      //   return this.$message({ message: '同一条销售明细不能对同条库存型号【' + item.Model + '】进行重复备货!', type: 'warning' })
      // }

      let UsableQty = 0;
      // 同一条库存，多次点击，需要减去本次已备货数量
      let qtySum = 0;
      _.each(this.tempItemSource, (m) => {
        if (m._isNewData && !m.IsDel && m.StkGUID === item.StkGUID) {
          qtySum += m.Qty;
        }
      });
      //本次还可以备的数量
      UsableQty = item.UsableQty - qtySum;
      if (UsableQty <= 0) {
        return this.$message({
          message: "库存型号【" + item.Model + "】可用量为不足,不可备货",
          type: "warning",
        });
      }

      // 库存可用量和待备货量的比较
      if (item.UsableQty >= this.currentUnReserveQty) {
        UsableQty = this.currentUnReserveQty;
      } else {
        UsableQty = item.UsableQty;
      }

      item.Price = item.SalesPrice;
      var info = {
        _isNewData: true,
        JGUID: this.getNewGuid(),
        IsDel: false,
        StkGUID: stkGUID,
        SOLineGUID: soLineGUID,
        SOGUID: soGUID,
        Qty: UsableQty,
        InvQty: UsableQty,
        Model: item.Model,
        Brand: item.Brand,
        InvPosition: item.InvPosition,
        Packaging: item.Packaging,
        MakeYear: item.MakeYear,
        Quality: item.Quality,
        StorageName: item.StorageName,
        Remark: item.Remark,
        Remark1: item.Remark2,
        Remark2: item.Remark3,
        Explain: item.Explain,
      };
      // 累计 减去备货量
      this.currentUnReserveQty = this.currentUnReserveQty - UsableQty;
      this.tempItemSource.push(info);
      this.setItemSource();
      this.onChangedQty();
    },

    // 提交备货
    onSubmitReserve(val, callback = null) {
      let paramData = this.tempItemSource;
      // 保存当前页面的值
      this.$emit("loading", true);
      return this.$post(CONFIG.saveURL, paramData, (datas, logdata) => {
        if (logdata.code === 200) {
          this.$emit("close");
          this.$message({ message: "保存成功", type: "success" });
        } else {
          this.$message({ message: logdata.msg, type: "error" });
        }
      }).finally((e) => {
        this.$emit("loading", false);
        callback && callback();
      });
    },

    // 单元格结束触发事件
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      let rowsData = gridobj.rows;
      let sumQty = 0;
      let qty = 0;
      if (
        colname === "Qty" &&
        !_.isUndefined(aftervalue) &&
        !_.isNull(aftervalue) &&
        aftervalue !== beforevalue
      ) {
        let invQty = rowsData[rowindex].InvQty;
        qty = this.toInt(aftervalue);
        let model = rowsData[rowindex].Model;
        let jGUID = rowsData[rowindex].JGUID; // 临时的JGUID
        let outQty = rowsData[rowindex].SalesOutQty; // 已出库量

        if (this.hasValue(outQty) && outQty > 0) {
          qty = beforevalue;
          this.$message({
            message:
              "型号:【" + model + "】已发货，如要修改，请先撤销删除发货单",
            type: "warning",
          });
          _.each(this.tempItemSource, (item) => {
            if (item.JGUID === jGUID) {
              item.Qty = qty;
            }
          });
          return;
        }

        _.each(this.itemSource, (item, index) => {
          if (
            (!this.isAllReserve && _.isUndefined(item.SSRGUID)) ||
            (this.isAllReserve &&
              _.isUndefined(item.SSRGUID) &&
              this.searchFields !== undefined &&
              this.searchFields !== null &&
              this.searchFields.SOLineGUID === item.SOLineGUID)
          ) {
            sumQty += this.toInt(item.Qty);
          }
        });
        // 判断当前输入的备货量是否大于当前待备货量
        // if (sumQty > this.searchFields.Qty) {
        //   qty = beforevalue
        //   this.$message({ message: '型号:【' + model + '】的备货量不能大于当前待备货量', type: 'warning' })
        // }
        // 判断输入的备货量是否大于当前库存可用量
        if (qty > invQty) {
          qty = beforevalue;
          this.$message({
            message: "型号:【" + model + "】的备货量不能大于当前库存的可用量",
            type: "warning",
          });
        }

        _.each(this.tempItemSource, (item) => {
          if (item.JGUID === jGUID) {
            item.Qty = qty;
          }
        });
      }
      this.setItemSource();
      this.onChangedQty();
      this.currentUnReserveQty = this.searchFields.Qty - sumQty;
    },
    // 删除,先删除临时的集合,再重新设置数据源
    onDelete(row) {
      if (this.hasValue(row.SalesOutQty) && row.SalesOutQty > 0) {
        this.$message({
          message:
            "型号:【" + row.Model + "】已发货，如要修改，请先撤销删除发货单",
          type: "warning",
        });
        return;
      } else {
        _.each(this.tempItemSource, (item, i) => {
          if (item.JGUID === row.JGUID) {
            item.IsDel = true;
            return;
          }
        });

        // 删除后 加上备货量
        this.currentUnReserveQty = this.currentUnReserveQty + row.Qty;
        this.setItemSource();
        this.onChangedQty();
      }
    },
    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      return this.onBindData(1);
    },
    // 设置显示的数据源
    setItemSource() {
      let soLineGUID = this.searchModel.SOLineGUID;
      let source = [];
      if (this.isAllReserve) {
        source = _.filter(this.tempItemSource, (item) => item.IsDel !== true);
      } else
        source = _.filter(
          this.tempItemSource,
          (item) => item.SOLineGUID === soLineGUID && item.IsDel !== true
        );
      // 禁用编辑单元格
      for (var i = 0; i < source.length; i++) {
        if (!_.isUndefined(source[i].SSRGUID)) {
          source[i].isReadOnly = true;
        } else {
          source[i].isReadOnly = false;
        }
      }
      this.itemSource = source;
    },
    // 汇总备货量,抛出事件
    onChangedQty() {
      if (this.isAllReserve) {
        //要更新的数据
        let updateData = [];
        _.each(this.tempItemSource, (item) => {
          let temp = {
            SOLineGUID: item.SOLineGUID,
            Qty: item.IsDel !== true ? item.Qty : 0, //已删除的数量设置为0
          };

          let index = _.findIndex(
            updateData,
            (update) => update.SOLineGUID === item.SOLineGUID
          );
          if (index === -1) {
            updateData.push(temp);
          } else {
            updateData[index].Qty += this.toInt(temp.Qty);
          }
        });
        //更新备货数量
        _.each(updateData, (update) => {
          this.$emit("on-changed-qty", update.SOLineGUID, update.Qty);
        });
      } else {
        let soLineGUID = this.searchModel.SOLineGUID;
        let source = _.filter(this.tempItemSource, function (item) {
          return item.SOLineGUID === soLineGUID && item.IsDel !== true;
        });
        let sumQty = 0;
        _.each(source, (item, index) => {
          sumQty += this.toInt(item.Qty);
        });
        this.$emit("on-changed-qty", soLineGUID, sumQty);
      }
    },
    onCellClick(row, column, cell, event) {
      if (
        column.columnKey === "Model" ||
        column.columnKey === "Brand" ||
        column.columnKey === "Packaging" ||
        column.columnKey === "MakeYear"
      ) {
        this.$emit("cell-click", row);
      }
    },
    //显示所有已备货物料
    onChangedIsAllReserve() {
      this.onBindData(1);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
