<template>
  <!-- 抢单王设置 -->
  <div class="dialog-qdw-setup-box">
    <znl-dialog
      :visible="visible"
      title="抢单王设置"
      height="200px"
      width="410px"
      v-loading="dialogLoading"
      :footer-height="30"
      :close-on-click-modal="false"
      @close="()=>{$emit('close')}">

      <el-row v-if="accountEnabled" class="setup-row">
          <znl-input :border="true"
            v-model="QQ"
            title="QQ号码："
            :is-must-fill="true"
            title-width="100px"
            placeholder="">
          </znl-input>
      </el-row>
      <el-row v-if="accountEnabled" class="setup-row">
        <div class="znl-component-input znl-border-input znl-must-fill left">
          <label class="znl-input-title isMustFill box-title">
            库存量同步：</label>
          <el-checkbox
            v-model="isAutoSyncQty"
            tabindex="20"
            style="margin-left:0 !important"
          >
            开启自动同步
          </el-checkbox>
          <div class="setup-tips">开启后，后台将会把汇总库存的可用量，自动同步到抢单王库存的可售量，并自动上传到抢单王</div>
        </div>
      </el-row>
      <!-- <el-row v-if="accountEnabled" class="description">
        设置完QQ号后才能将库存上传到抢单王；目前QQ只能设置一个；如果QQ群有匹配到的物料将会发送到这个QQ号上。
      </el-row> -->
      <el-row v-if="!accountEnabled" class="error-message">
        <div v-if="!accountExpired">
          您还没购买抢单王，请切换到[腾讯·抢单王]页签，进行了解
        </div>
        <div v-if="accountExpired">
          您购买的抢单王<span style="color:red">已过期</span>，请先切换到[腾讯·抢单王]页签进行续费
        </div>
      </el-row>

      <el-row align="bottom"
        type="flex"
        slot="footer"
        class="dialog-footer">
        <el-col style="justify-content: flex-end;align-items: center">
          <znl-button @click="onSave"
            :height="30"
            :width="60"
            v-if="accountEnabled"
            style-type="main" :disabled="saveloading">保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  getSetupUrl: "TencentQDW/GetSetup",
  saveSetupUrl: "TencentQDW/SaveSetup"
}

export default {
  name: 'QDW_ConfigDialog',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  components: {},
  data () {
    return {
      dialogLoading: false,
      LoadingText: '',
      accountEnabled: false,
      accountExpired: false,
      saveloading: false,
      errorMessage: '',
      QQ: '',
      isAutoSyncQty: false
    }
  },
  props: {
    visible: Boolean
  },
  watch: {},
  methods: {
    onInit: async function () {
      await this.getData()
    },
    async getData () {
      let cfg, logic
      await this.$post(CONFIG.getSetupUrl, null, (data, l) => {
        cfg = data
        logic = l
      })
      if (logic && logic.code !== 200) {
        this.saveloading = true
        this.errorMessage = logic.msg
        this.accountEnabled = false
        if (logic.code === 40002) {
          this.accountExpired = true
        }

        this.$message({message: logic.msg, type: 'error'})
        this.QQ = ''
        this.isAutoSyncQty = false
      } else{
        this.QQ = cfg.QQ
        this.isAutoSyncQty = cfg.IsAutoSyncQty
        this.accountEnabled = true
      }
    },
    async onSave () {
      if (this.CheckData() === false) {
        return false
      }
      this.saveloading = true
      let param = { QQ: this.QQ, IsAutoSyncQty: this.isAutoSyncQty }
      this.$post(CONFIG.saveSetupUrl, param, (data, logic) => {
        if (data) {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('confirm', {QQ: this.QQ})
        } else {
          this.$message({ message: '保存失败！' +  logic.msg, type: 'error' })
        }
          this.saveloading = false
      })
    },
    CheckData () {
      if (!this.hasValue(this.QQ)) {
        this.$message({ message: 'QQ不能为空', type: 'error' })
        return false
      }
      return true
    },
    hasValue (val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    }
  },
  async created () {
    this.$nextTick(() => {
      this.onInit()
    })
  }
}
</script>

<style lang="scss">
.dialog-qdw-setup-box{
  .description {
    font-size: 12px;
    margin-top: 10px;
  }
  .error-message {
    height: 150px;
    margin-top: 30px;
  }
  .setup-row{
    margin-bottom: 4px;
  }
  .box-title {
    width: 100px;
  }
  .setup-tips{
    margin-left: 100px;
    color: #c2c2c2;
  }
}
</style>
