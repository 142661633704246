<template>
  <znl-layout-spa :page-loading="pageLoading">
    <div class="second-tab-div">
      <iframe :src="about_url" style="width:100%; height:100%; border:0;" ref="iframe" @load="onload"></iframe>
    </div>
  </znl-layout-spa>
</template>
<script>

import {
  getErpAuthInfo
} from '~/lib/native'

export default {
  name: "VTencentQDW_About",
  components: {
  },
  data() {
    return {
      about_url: 'https://mm.bom.ai/#/graborder?hidetop=1',
      pageData: [],
      pageLoading: false,
      accessToken: ''
    };
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    }
  },
  methods: {
    pageSearch() {},
    setClientSelectLoading(val) {
      this.pageLoading = val;
    },
    onload(){
      this.$refs.iframe.contentWindow.postMessage(
        JSON.stringify({
          token: this.accessToken
        }),
        '*'
      );
    }
  },
  async created() {
    let erpInfo = await getErpAuthInfo()
    if (erpInfo) {
      this.accessToken = erpInfo.AccessionToken
    }
    console.log(erpInfo, 'token', this.accessToken)
  }
};
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_variables.scss";
</style>
