<template>
  <!-- Open销售单 -->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      :totalField="['Amount','SaleGrossProfit']"
      @current-row-change="onCurrentRowChangeHandle"
      :is-fields-search="isShowFiedsSearch"
      checkboxBindingKey="OrderNo"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('DevOrder')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="select"
          :select-options="SelectStatusList"
          :disable="false"
          placeholder="状态"
          size="mini"
          layout="left"
          width="110px"
          inp-perc="100%"
          v-model="fieldsFilter.Status"
          :clearable="true"
          :border="true"
          type="text"
          @change="val=>statusChange(val)"
        ></znl-input>

        <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <!--
            <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
          -->
        </znl-button>

        <znl-button style-type="mac" :isShow="hasRes('Print')" type="multiple">
          <znl-button-menu @click="onPrint">
            <i class="iconfont icon-print_btn_ic"></i>
            <span>打印</span>
          </znl-button-menu>
          <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <znl-print
          :visible.sync="znlPrintVisible"
          :printInfo="printInfo"
          :loading="printLoading"
          :is-get-default-param="printInfo.isGetDefaultParam"
          print-type="ORD_SalesOrder"
          title="销售单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </div>
    </znl-table>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import { erpTableSetHandler } from "@scripts/methods/common";

const CONFIG = {
  PK: "MTGuid",
  configURL: "DevOrder/GetConfig",
  resetParamConfigURL: "DevOrder/ResetParamConfig",
  searchURL: "DevOrder/Search",
  SavePageParamURL: "PageParam/Save" // 保存页面参数配置
};
export default {
  name: "DevOrder",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    ZnlPrint
  },
  data() {
    return {
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      fieldsFilter: {
        Status: ""
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      parameterConfig: [],
      ParamJosn: [],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "ORD_SalesOrder" // 打印模板类型
      }, // end 打印模块参数
      isShowPrint: false,
      loading: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      currencyList: [],
      StatusList: [
        { key: "处理中", value: 1 },
        { key: "已支付", value: 2 },
        { key: "已发货", value: 3 },
        { key: "已完成", value: 4 },
        { key: "已取消", value: 5 }
      ],
      SelectStatusList: [
        { key: 1, value: "处理中" },
        { key: 2, value: "已支付" },
        { key: 3, value: "已发货" },
        { key: 4, value: "已完成" },
        { key: 5, value: "已取消" }
      ]
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        };
      }
    }
  },
  async created() {
    this.currencyList = _.map(await this.getCurrencyCodeListAsync, function(
      obj,
      index
    ) {
      return { key: obj.value, value: obj.key };
    });
  },
  async mounted() {
    this.onInit();
  },
  methods: {
    onInit: async function(config) {
      this.loading = true;
      if (!config || config === undefined) {
        config = await this.isSaveStorage(
          "devOrder",
          this.$options.config.configURL
        );
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;
      let colIndex = _.findIndex(
        columns,
        item => item.BindField === "Currency"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 100,
          options: this.currencyList
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          isReadOnly: true,
          width: 100,
          options: this.StatusList
        });
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      this.paramConfiguration();

      //处理特殊权限
      // let noSeeProfit = this.getSpecialResourceByCode('NoSeeProfit');
      // if (noSeeProfit) {
      //   _.remove(columns, item => {
      //     if (item.BindField == "SaleGrossProfit")
      //       return true;
      //   })
      // }
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.columns = columns;

      this.$refs.table.init();
      if (this.initData) {
        this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
      let datas = _.find(this.ParamJosn, function(paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "DevOrder", ParamJson: [datas] },
          data => {
            localStorage.removeItem("devOrder");
          }
        );
      }
    },
    onResetSetting() {
      localStorage.removeItem("devOrder");
      return this.$post(CONFIG.resetConfigURL);
    },
    onResetParams(val) {
      localStorage.removeItem("devOrder");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    // 获取参数配置
    paramConfiguration() {},
    onPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.SOGuid)) {
        return this.$message({
          message: "请选中要打印的销售单",
          type: "error"
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）

      if (!this.hasValue(tableName)) {
        this.$message({ message: "先设置打印模板后再操作!", type: "error" });
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: row.SOGuid,
          SubCompanyId: titleId
        };
        console.log(printParm, "start to print sales order");
        this.printSalesOrder(printParm, () => {});
      }
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          Status: this.fieldsFilter.Status
        }
      );
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.loading = false;
      });
    },
    statusChange(data) {
      if (data) this.fieldsFilter.Status = data.key;
      else this.fieldsFilter.Status = "";
      this.onSearch();
    },
    onSearch(params = null) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    onRefresh() {
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },
    hasRes(code) {
      if (!this.resourceList) {
        return;
      }
      return _.includes(this.resourceList, code);
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.OrderNo)) {
        this.$emit("selection-changed", row);
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("DevOrder", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("DevOrder", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("DevOrder", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("DevOrder");
    }
  }
};
</script>
<style lang="scss">
</style>

