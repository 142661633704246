<template>
  <!-- 入库单 -->
  <!-- :totalField="['PayablesAmount']" -->
  <div class="module-wrapper m-stk-in-order">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-stk-in-order"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      checkboxBindingKey="StkInGUID"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      @click-link="onCellClickLink"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      :totalField="['PayablesAmount']"
      @current-row-change="onCurrentRowChangeHandle"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StkIn')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
      @dbclick="onDbclick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="型号"
            size="mini"
            layout="left"
            width="110px"
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="fieldsFilter.InputModel"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            placeholder="采购单号"
            size="mini"
            layout="left"
            width="110px"
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="fieldsFilter.POBillNO"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="入库单号"
            size="mini"
            layout="left"
            width="110px"
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="fieldsFilter.BillNo"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <div class="m-accurate-checbox znl-component-input">
            <znl-input
              from-type="input"
              :disable="false"
              class="box_input_checkbox"
              placeholder="供应商名称"
              size="mini"
              layout="left"
              width="160px"
              inp-perc="100%"
              v-model="fieldsFilter.SupplierName"
              :clearable="false"
              :border="true"
              @keyup.enter.native="onSearch()"
              type="text"
            ></znl-input>
            <el-checkbox
              v-model="isLikeSearch"
              class="accurate-checbox"
              size="small"
              >精确</el-checkbox
            >
          </div>
          <znl-input
            from-type="input"
            :disable="false"
            placeholder="采购员"
            size="mini"
            layout="left"
            width="85px"
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="fieldsFilter.Receiver"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="入库时间从"
            size="mini"
            value-format="yyyy-MM-dd"
            layout="left"
            width="95px"
            :clearable="true"
            :border="true"
            inp-perc="100%"
            v-model="fieldsFilter.StartReceiveTime"
          ></znl-input
          >-
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="入库时间止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="95px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="fieldsFilter.EndReceiveTime"
          ></znl-input>
          <!-- 选择状态 -->
          <znl-input
            layout="left"
            form-type="select"
            :disabled="false"
            :border="true"
            width="100px"
            :height="22"
            placeholder="-选择状态-"
            popper-class="popperClass"
            :clearable="false"
            :multiple="true"
            :tags-clear="false"
            :select-options="filterEx.StatusSelectOptions"
            @change="
              (val) => {
                filterEx.FilterStatus = val;
              }
            "
            v-model="filterEx.FilterStatus"
          ></znl-input>

          <znl-input
            from-type="input"
            :disable="false"
            class="box_input_checkbox"
            placeholder="搜索关键值"
            size="mini"
            layout="left"
            width="100px"
            inp-perc="100%"
            v-model="filterEx.Keywords"
            :clearable="false"
            :border="true"
            @keyup.enter.native="onSearch()"
            type="text"
          ></znl-input>
          <znl-input
            title-width="70px"
            layout="left"
            ref="CurrencyCode"
            form-type="select"
            width="70px"
            placeholder="-关键字-"
            popper-class="popperClass"
            :border="true"
            :clearable="true"
            :select-options="filterEx.KeywordsFieldOptions"
            v-model="filterEx.KeywordsField"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="制单时间从"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="100px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterEx.StartCreateTime"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            :disabled="false"
            placeholder="制单时间止"
            value-format="yyyy-MM-dd"
            size="mini"
            layout="left"
            width="100px"
            :border="true"
            :clearable="true"
            inp-perc="100%"
            v-model="filterEx.EndCreateTime"
          ></znl-input>
        </el-row>

        <el-row>
          <znl-button
            type="multiple"
            style-type="mac"
            class="box-left-btn search-btn"
          >
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu
              :width="20"
              tip="高级搜索"
              @click="onShowFiedsSearch()"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button style-type="main" @click="onStkinAdd" v-if="isShowAdd">
            <i class="iconfont icon-add_btn_ic"></i>
            <span>添加</span>
          </znl-button>
          <znl-button
            style-type="mac"
            @click="onPostDelete()"
            v-if="hasRes('Delete')"
            :disabled="postStatus.delete"
          >
            <i class="iconfont icon-delete_btn_ic"></i>
            <span>删除选中行</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onPostStorage()"
            v-if="hasRes('In')"
            :disabled="postStatus.stkIn"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>入库</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onPostCancelAnOrder()"
            v-if="isMainAccount || hasRes('Cancel')"
            :disabled="postStatus.cancel"
          >
            <i class="iconfont icon-backout_btn_ic"></i>
            <span>撤单</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onBatchPay"
            v-if="isShowPay"
            :disabled="postStatus.payment"
          >
            <i class="iconfont icon-table_money_ic"></i>
            <span>批量付款</span>
          </znl-button>

          <znl-button style-type="mac" @click="onImport" v-if="isShowImport">
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>导入</span>
          </znl-button>

          <znl-button style-type="mac" v-if="isShowPrint" type="multiple">
            <znl-button-menu @click="onPrint">
              <i class="iconfont icon-print_btn_ic"></i>
              <span>打印</span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onPostAudit()"
            v-if="hasRes('Audit')"
            :disabled="postStatus.audit"
          >
            <i class="iconfont icon-admin_user_ic"></i>
            <span>审核</span>
          </znl-button>

          <znl-button
            style-type="mac"
            @click="onPostUnAudit()"
            v-if="hasRes('UnAudit')"
            :disabled="postStatus.unAudit"
          >
            <i
              class="iconfont icon-backout_btn_ic"
              style="font-weight: bold"
            ></i>
            <span>反审</span>
          </znl-button>

          <!-- <znl-button style-type="mac" @click="onImport" v-if="isShowImport">
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>加入报关单</span>
          </znl-button>-->

          <znl-button style-type="mac" @click="onEditOtherFees">
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>增加其它费用</span>
          </znl-button>

          <!-- <znl-button
            style-type="mac"
            @click="onApplyLoanService"
            v-if="hasLoanService && hasRes('LoanService')"
          >
            <i class="iconfont table_money_ic icon-table_money_ic"></i>
            <span>申请正能量月结服务</span>
          </znl-button>

          <span
            style="margin-right: 8px; color: #ee7700; cursor: pointer"
            title="什么是月结服务？"
            v-if="hasLoanService && hasRes('LoanService')"
            @click="
              () => {
                monthlyServiceIntroVisible = true;
              }
            "
          >
            <i class="iconfont icon-table_error_ic"></i>
          </span> -->

          <znl-button @click="escrow">申请担保交易</znl-button>
        </el-row>
      </div>
    </znl-table>

    <!-- 打印设置 -->
    <znl-print
      :visible.sync="znlPrintVisible"
      :printInfo="printInfo"
      :loading="printLoading"
      :is-get-default-param="printInfo.isGetDefaultParam"
      print-type="STK_In"
      title="入库单打印设置"
      @set-print-param="setPrintParam"
      @confirm-print="confirmPrint"
    ></znl-print>

    <!-- 操作日志 -->
    <operate-log
      ref="OperateLog"
      :visible="operateLogVisible"
      v-if="operateLogVisible"
      :orderGuid="orderGuid"
      @close="operateLogVisible = false"
      :refreshLog="refreshLog"
    ></operate-log>

    <!-- 批量付款 -->
    <stk-batch-pay
      ref="StkBatchPay"
      :visible="payVisible"
      v-if="payVisible"
      @close="
        () => {
          (payVisible = false), (this.stkInGUIDS = null);
        }
      "
      :isFinRecPay="isFinRecPay"
      :stkInGUIDS="stkInGUIDS"
      :companyAmount="companyAmount"
      @confirm="
        (val) => {
          val && (payVisible = false),
            this.onRefresh(),
            (this.stkInGUIDS = null);
        }
      "
    ></stk-batch-pay>

    <!-- 采购入库 -->
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      :title="editType == 'add' ? '新增入库单' : '编辑入库单'"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @confirm="stkInEditVisible = false"
      @close="stkInEditVisible = false"
    ></stk-in-edit>

    <!-- 其他费用 -->
    <stk-other-fees
      :editvisible="stkOtherFeesVisible"
      :BillNo="OtherFeesBillNo"
      v-if="stkOtherFeesVisible"
      :OrderType="0"
      :InTypes="'2,3'"
      ref="StkOtherFees"
      @confirm="stkOtherFeesVisible = false"
      @close="stkOtherFeesClose"
    ></stk-other-fees>

    <!--采购退货-->
    <ord-purchase-return-edit
      :editvisible="OrdPurchaseRetrunVisible"
      v-if="OrdPurchaseRetrunVisible"
      :edit-type="editType"
      @save-success="
        () => {
          this.onRefresh();
        }
      "
      @confirm="OrdPurchaseRetrunVisible = false"
      @close="OrdPurchaseRetrunVisible = false"
    ></ord-purchase-return-edit>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />

    <!-- 月结服务介绍 -->
    <monthly-service-intro
      v-if="monthlyServiceIntroVisible"
      @close="
        () => {
          monthlyServiceIntroVisible = false;
        }
      "
    ></monthly-service-intro>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import StkBatchPay from "@c_modules/Fin/StkBatchPay";
import OperateLog from "@c_modules/Log/OperateLog";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import OrdPurchaseReturnEdit from "@c_modules/Purchase/OrderReturnEdit";
import {
  stockInToSCM,
  openLoanService,
} from "~/assets/scripts/methods/clientTabPage";
import { erpTableSetHandler } from "@scripts/methods/common";
import StkOtherFees from "@c_modules/Stk/StkOtherFees";
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import MonthlyServiceIntro from "@c_modules/Pub/MonthlyServiceIntro.vue";

const apiDomain = urls.getErpApiBaseUrl();

const CONFIG = {
  PK: "StkInGUID",
  configURL: "StkIn/GetConfig",
  resetParamConfigURL: "StkIn/ResetParamConfig",
  searchURL: "StkIn/Search",
  onExamineURL: "StkIn/Examine",
  onAntiTrialURL: "StkIn/AntiTrial",
  onCancelAnOrderURL: "StkIn/CancelAnOrder",
  onStorageURL: "StkIn/Storage",
  onDeleteURL: "StkIn/DeleteList",
  searchSumAmountURL: "StkBatchPay/SumAmount",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置,
  otherFeeAmountBindUrl: "StkIn/InOtherFeesBind",
  WebPrintURL: "WebPrint/StkInPrint",
  DownloadPrintFile: "WebPrint/DownloadPrintFile",
};

export default {
  name: "StkInOrder",
  config: CONFIG,
  mixins: [getCommonDataMixin, erpTableSetHandler, printInfoMixin],
  components: {
    StkBatchPay,
    OperateLog,
    StkInEdit,
    StkOtherFees,
    ZnlPrint,
    OrdPurchaseReturnEdit,
    MonthlyServiceIntro,
  },
  data() {
    return {
      isShowFiedsSearch: false,
      editType: "add",
      stkInEditVisible: false,
      OrdPurchaseRetrunVisible: false,
      InputModel: "",
      BatchPaymentVisible: false,
      isLikeSearch: false,
      stkInGUID: "", // 需要传递给编辑页面的GUID
      stkIn: {
        StartReceiveTime: "",
        EndReceiveTime: "",
        StartPaidTime: "",
        EndPaidTime: "",
        searchModel: {
          data: {},
          extra: {},
        },
      },
      stkOtherFeesVisible: false,
      OtherFeesBillNo: [],
      columns: [],
      itemSource: [],
      fieldsFilter: {
        BillNo: "",
        Receiver: "",
        StartReceiveTime: "",
        EndReceiveTime: "",
        StartPaidTime: "",
        EndPaidTime: "",
        InputModel: "",
        POBillNO: "",
      },
      filterEx: {
        FilterStatus: [], // 请选择状态
        Keywords: "", // 关键字
        KeywordsField: "", // 关键字对应的列
        CreatedTimeStart: "", // 制单时间
        CreatedTimeEnd: "", // 制单时间
        KeywordsFieldOptions: [
          { key: "BillNo", value: "单号" },
          { key: "Payer", value: "付款人" },
          { key: "PaymentType", value: "付款方式" },
          { key: "TaxRate", value: "税率" },
          { key: "CurrencyCode", value: "币种" },
          { key: "Remark", value: "备注" },
          { key: "CreatedByName", value: "制单人" },
          { key: "TicketNo", value: "发票号" },
          { key: "PurCompany", value: "采购公司" },
          { key: "Contact", value: "联系人" },
          { key: "Telephone", value: "联系电话" },
          { key: "Address", value: "地址" },
          { key: "Brand", value: "品牌", other: true },
          { key: "Packaging", value: "封装", other: true },
          { key: "MakeYear", value: "年份", other: true },
          { key: "MPQ", value: "包装", other: true },
          { key: "Partner", value: "合伙人", other: true },
          { key: "ModelRemark", value: "型号备注", other: true },
          { key: "ModelRemark3", value: "备注3", other: true },
          { key: "InvPosition", value: "仓库", other: true },
          { key: "MarkingCode", value: "MarkingCode", other: true },
        ],
        StatusSelectOptions: [
          { key: "已入库", value: "已入库" },
          { key: "未入库", value: "未入库" },
          { key: "已撤单", value: "已撤单" },
          { key: "已付款", value: "已付款" },
          { key: "部分付款", value: "部分付款" },
          { key: "未付款", value: "未付款" },
          { key: "已开票", value: "已开票" },
          { key: "部分开票", value: "部分开票" },
          { key: "未开票", value: "未开票" },
          { key: "含税", value: "含税" },
          { key: "未税", value: "未税" },
          { key: "已审核", value: "已审核" },
          { key: "未审核", value: "未审核" },
        ],
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      operateLogVisible: false,
      payVisible: false,
      orderGuid: "",
      stkInGUIDS: [],
      refreshLog: true,
      showstkBatchPay: false,
      isFinRecPay: false,
      companyAmount: 0,
      parameterConfig: [],
      ParamJosn: [],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "STK_In", // 打印模板类型
      }, // end 打印模块参数
      isShowPrint: false,
      isShowAdd: false,
      isShowPay: false,
      isShowImport: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      loading: false,
      currencyList: [],
      userList: [],
      taxRateList: [],
      auditedOptions: [
        { key: "未审", value: false },
        { key: "已审", value: true },
      ],
      postStatus: {
        // post状态控制，记录后端请求是否已响应
        stkIn: false, // 入库
        delete: false, // 删除
        payment: false, // 付款
        audit: false, // 审核
        unAudit: false, // 反审
        cancel: false, // 撤单
      },
      totals: [],
      hasLoanService: false,
      monthlyServiceIntroVisible: false,
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false,
    },
    initStkInBillNo: {
      type: String,
      default: "",
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {},
        };
      },
    },
  },

  async mounted() {
    this.currencyList = _.map(
      await this.getCurrencyCodeListAsync,
      function (obj, index) {
        return { key: obj.value, value: obj.key };
      }
    );
    this.userList = _.map(await this.selectOptionsAllUserURL, function (obj) {
      return { key: obj.value, value: obj.value };
    });
    this.taxRateList = await this.getTaxRateListAsync;
    this.hasLoanService = await this.hasLoanServiceAsync;

    await this.onInit();
  },
  methods: {
    escrow() {
      window.open("https://www.bom.ai/escrow");
    },
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage("StkIn", CONFIG.configURL);
      }
      let columns = config.ColumnConfigs;
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;

      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }
      // 设置打印
      this.printInfo.template = this.getPrintTemplate(config.ParamJosn);
      this.printInfo.title = this.getPrintTitle(config.ParamJosn);

      this.isShowPrint = this.isMainAccount || this.hasRes("Print");
      this.isShowAdd = this.isMainAccount || this.hasRes("Add");
      this.isShowPay = this.isMainAccount || this.hasRes("BatchPay");
      this.isShowImport = this.isMainAccount || this.hasRes("OnImport");

      columns = this.addMoreButton(columns);
      this.columnInit(columns);

      this.columns = columns;
      this.$refs.table.init();
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      if (this.initStkInBillNo != "") {
        this.fieldsFilter.POBillNO = this.initStkInBillNo;
      }
      this.setDefaultSearch();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },
    onCellClickLink(event) {
      let row = event.row;
      if (event.field === "OtherFeeAmount") {
        this.OtherFeesBillNo = [];
        this.OtherFeesBillNo.push(event.row.BillNo);
        this.$nextTick(function () {
          this.stkOtherFeesVisible = true;
          this.$refs.table.init();
        });
      } else if (event.field === "IsAppliedLoanService") {
        if (row.IsAppliedLoanService === "是") {
          if (this.hasRes("LoanService")) {
            openLoanService();
          } else {
            this.$message({
              message: "您没有查看月结服务的权限",
              type: "error",
            });
          }
        }
      }
    },
    // add more button
    addMoreButton(columns) {
      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Edit")) {
        mouseBtn.push({
          title: "编辑",
          name: "Edit",
          click: (row) => {
            this.onStkinEdit(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除",
          name: "Delete",
          click: (row) => {
            this.onPostDelete([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("In")) {
        mouseBtn.push({
          title: "入库",
          name: "In",
          click: (row) => {
            this.onPostStorage(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Cancel")) {
        mouseBtn.push({
          title: "撤单",
          name: "Cancel",
          click: (row) => {
            this.onPostCancelAnOrder(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Audit")) {
        mouseBtn.push({
          title: "审核",
          name: "Audit",
          click: (row) => {
            this.onPostAudit([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("UnAudit")) {
        mouseBtn.push({
          title: "反审",
          name: "UnAudit",
          click: (row) => {
            this.onPostUnAudit([row]);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Return")) {
        mouseBtn.push({
          title: "退货",
          name: "Return",
          click: (row) => {
            this.onReturn(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("OperateLog")) {
        mouseBtn.push({
          title: "日志",
          name: "OperateLog",
          click: (row) => {
            this.operateLogVisible = true;
            this.$nextTick(() => {
              this.orderGuid = row.StkInGUID;
              this.refreshLog = !this.refreshLog;
            });
          },
        });
      }
      if (this.isMainAccount || this.hasRes("AddCustomsOrder")) {
        mouseBtn.push({
          title: "报关",
          name: "AddCustomsOrder",
          click: (row) => {
            stockInToSCM(row.StkInGUID);
          },
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
          canSearch: false,
        },
      ];

      columns = tempConfig.concat(columns);
      return columns;
    },

    columnInit(columns) {
      _.extend(
        _.find(columns, function (column) {
          return column.BindField === "OtherFeeAmount";
        }),
        {
          isReadOnly: true,
          event: "click-link",
          className: "v-link",
        }
      );

      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CurrencyCode"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.currencyList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Receiver");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList,
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "CreatedByName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          options: this.userList,
        });
      }
      // colIndex = _.findIndex(columns, item => item.BindField === 'TaxRate')
      // if (colIndex > -1) {
      //   _.extend(columns[colIndex], {
      //     type: 'select',
      //     width: 50,
      //     options: this.taxRateList
      //   })
      // }
      colIndex = _.findIndex(columns, (item) => item.BindField === "IsAudited");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: this.auditedOptions,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Status");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 50,
          options: [
            { key: "未入", value: 1 },
            { key: "已入", value: 3 },
            { key: "已撤", value: 9 },
          ],
        });
      }

      // 月结服务
      if (this.hasLoanService) {
        colIndex = _.findIndex(
          columns,
          (item) => item.BindField === "IsAppliedLoanService"
        );
        if (colIndex > -1) {
          _.extend(columns[colIndex], {
            IsShow: true,
            event: "click-link",
            className: "v-link",
          });
        }
      }
    },

    setDefaultSearch() {
      let defDate = this.getOrderSearchDefaultDate();
      if (defDate) {
        defDate.start && (this.filterEx.StartCreateTime = defDate.start);
        defDate.end && (this.filterEx.EndCreateTime = defDate.end);
      }
    },

    setBillNo(billNo) {
      this.fieldsFilter = {
        StartReceiveTime: "",
        EndReceiveTime: "",
        StartPaidTime: "",
        EndPaidTime: "",
        InputModel: "",
        POBillNO: billNo,
      };
      this.onBindData(1);
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryBarStatus") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "StkIn", ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkIn");
          }
        );
      }
    },
    stkOtherFeesClose() {
      this.stkOtherFeesVisible = false;
      this.$nextTick(() => {
        this.$refs.table.init();
      });
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
      this.printInfo.isGetDefaultParam = true;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onPrint() {
      //网页版打印
      if (!isClient()) {
        this.onWebPrint();
        return;
      }

      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选中要打印的入库单",
          type: "error",
        });
      }
      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）

      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
      } else if (!this.printInfo.isGetDefaultParam) {
        // 通知打印模板模块，获取打印所需参数
        this.printInfo.isGetDefaultParam = true;
      } else {
        this.printStkIn({
          TableName: tableName,
          Guid: row.StkInGUID,
          SubCompanyId: titleId,
        });
      }
    },

    getSearchWhere(pageIndex = 1) {
      let param = {
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        // 外部搜索条件
        StartReceiveTime: this.fieldsFilter.StartReceiveTime,
        EndReceiveTime: this.fieldsFilter.EndReceiveTime,
        StartPaidTime: this.fieldsFilter.StartPaidTime,
        EndPaidTime: this.fieldsFilter.EndPaidTime,
        InputModel: this.fieldsFilter.InputModel,
        POBillNO: this.fieldsFilter.POBillNO,
        AllowViewFollowBill:
          this.getCompanySetupValue("AllowViewFollowBill") === "1",
        StartCreateTime: this.filterEx.StartCreateTime,
        EndCreateTime: this.filterEx.EndCreateTime,
      };

      let filterLast = {};
      if (this.filterEx.BillNo) {
        filterLast.BillNo = this.filterEx.BillNo;
      }
      //关键字搜索
      const { Keywords, KeywordsField, KeywordsFieldOptions, FilterStatus } =
        this.filterEx;
      if (Keywords && KeywordsField) {
        // 主单的关键字，放在 FieldWhereString 里面搜索，其他的放在外部搜索条件
        let current = KeywordsFieldOptions.find(
          (item) => item.key === KeywordsField
        );
        if (current) {
          if (current.other) {
            param[KeywordsField] = Keywords;
          } else {
            filterLast[KeywordsField] = Keywords;
          }
        }
      }

      // 状态
      if (FilterStatus && FilterStatus.length > 0) {
        param.Status = FilterStatus;
      }
      filterLast = _.extend({}, this.fieldsFilter, filterLast);
      param.FieldWhereString = JSON.stringify(filterLast);

      return param;
    },

    async onBindData(pageIndex = 1) {
      this.loading = true;
      let param = this.getSearchWhere(pageIndex);
      return await this.$post(CONFIG.searchURL, param, (data) => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        if (this.$refs.table) this.$refs.table.cleanClickRow();
        //this.otherFeeAmountBind(data.ResultList);
      }).finally(() => {
        this.$emit("page-loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(this.pageIndex);
    },

    // 操作项  验证数据
    checkData(row, state) {
      // 1.删除 2.撤单 3.审核 4.反审 5.入库
      if (state === 1) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审，不能删除",
            type: "warning",
          });
          return false;
        }
        if (row.Status === 3 || row.Status === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已入库或部分入库，不能删除",
            type: "warning",
          });
          return false;
        }
      } else if (state === 2) {
        if (row.Status === 9) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已撤销，不能重复撤单",
            type: "warning",
          });
          return false;
        }
        if (row.Status === 1) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未入库，不能撤单",
            type: "warning",
          });
          return false;
        }
        if (row.ReturnStatus === 3 || row.ReturnStatus === 2) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已退货，不能撤单",
            type: "warning",
          });
          return false;
        }
      } else if (state === 3) {
        if (row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已审，不能重复审核",
            type: "warning",
          });
          return false;
        }
      } else if (state === 4) {
        if (!row.IsAudited) {
          this.$message({
            message: "单号:【" + row.BillNo + "】未审核，不能反审",
            type: "warning",
          });
          return false;
        }
      } else if (state === 5) {
        if (row.Status === 3) {
          this.$message({
            message: "单号:【" + row.BillNo + "】已全部入库，不能重复入库",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },

    // 审核请求
    onPostAudit(rows = null) {
      if (this.postStatus.audit) {
        return false;
      }
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (rows === null || rows === undefined || rows.length == 0) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      let ispass = true;
      rows.some((row) => {
        if (!this.checkData(row, 3)) {
          ispass = false;
          return true;
        }
      });
      if (!ispass) {
        return false;
      }

      let param = {
        ListStkinGuid: rows.map((r) => r.StkInGUID),
      };
      this.postStatus.audit = true;
      this.$post(CONFIG.onExamineURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "审核成功", type: "success" });
          this.$refs.table.cleanAllCheck();
          this.onBindData(this.pageIndex);
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
        return data;
      }).finally(() => {
        this.postStatus.audit = false;
      });
    },

    // 反审核请求
    onPostUnAudit(rows = null) {
      if (this.postStatus.unAudit) {
        return false;
      }
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (rows === null || rows === undefined || rows.length == 0) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      let ispass = true;
      rows.some((row) => {
        if (!this.checkData(row, 4)) {
          ispass = false;
          return true;
        }
      });
      if (!ispass) {
        return false;
      }

      let param = {
        ListStkinGuid: rows.map((r) => r.StkInGUID),
      };
      this.postStatus.unAudit = true;
      this.$post(CONFIG.onAntiTrialURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "反审核成功", type: "success" });
          this.$refs.table.cleanAllCheck();
          this.onBindData(this.pageIndex);
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      }).finally(() => {
        this.postStatus.unAudit = false;
      });
    },

    // 入库请求
    onPostStorage(row = null) {
      if (this.postStatus.stkIn) {
        return false;
      }
      if (row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      if (this.checkData(row, 5)) {
        this.postStatus.stkIn = true;
        this.$post(
          CONFIG.onStorageURL,
          { StkInGUID: row.StkInGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "入库成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        ).finally(() => {
          this.postStatus.stkIn = false;
        });
      }
    },

    // 撤单请求数据
    onPostCancelAnOrder(row = null) {
      if (this.postStatus.cancel) {
        return false;
      }
      if (row === null) {
        let rows = this.$refs.table.getCheckedRows();
        if (rows && rows.length > 0) {
          row = rows[0];
        }
      }
      if (!this.hasValue(row)) {
        return this.$message({
          message: "请选择要操作的入库单",
          type: "error",
        });
      }
      if (this.checkData(row, 2)) {
        this.postStatus.cancel = true;
        this.$post(
          CONFIG.onCancelAnOrderURL,
          { StkInGUID: row.StkInGUID },
          (data, logic) => {
            if (logic.code === 200) {
              this.$message({ message: "撤单成功", type: "success" });
              this.onRefresh();
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
            return data;
          }
        ).finally(() => {
          this.postStatus.cancel = false;
        });
      }
    },
    //编辑
    onEditOtherFees() {
      var rows = this.$refs.table.getCheckedRows();
      if (rows.length == 0) {
        this.$message({
          message: "请选择需要添加其它费用的入库单",
          type: "error",
        });
        return;
      }
      this.OtherFeesBillNo = [];
      _.each(rows, (item) => {
        this.OtherFeesBillNo.push(item.BillNo);
      });
      this.$store.state.visibled = true;
      this.$nextTick(function () {
        this.stkOtherFeesVisible = true;
      });
    },
    // 新增
    onStkinAdd() {
      this.$store.state.visibled = false;
      this.$nextTick(function () {
        this.editType = "add";
        this.stkInEditVisible = true;
      });
    },

    // 编辑
    onStkinEdit(row) {
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选中要编辑的入库单",
          type: "error",
        });
      }
      if (!this.isMainAccount && !this.hasRes("Edit")) {
        return false;
      }
      let stkInGUID = row.StkInGUID;
      let paramOrder = { stkInGUID: stkInGUID };
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function () {
        this.editType = "edit";
        this.stkInEditVisible = true;
      });
    },

    // 删除请求
    onPostDelete(rows = null) {
      if (rows === null) {
        rows = this.$refs.table.getCheckedRows();
      }
      if (!this.hasValue(rows) || rows.length === 0) {
        return this.$message({
          message: "请选中要删除的入库单",
          type: "error",
        });
      }
      let isPass = true;
      rows.forEach((row) => {
        if (isPass) {
          isPass = this.checkData(row, 1);
        }
      });
      if (!isPass) {
        return false;
      }

      let billNo = rows.map((a) => a.BillNo).join(", ");
      let param = {
        ListStkinGuid: rows.map((item) => item.StkInGUID),
      };
      this.$confirm("确认删除 单号:" + billNo + " 的入库单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(CONFIG.onDeleteURL, param, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "删除成功", type: "success" });
            this.onRefresh();
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        });
      });
    },
    // 退货
    onReturn(row) {
      if (row.Status !== 2 && row.Status !== 3) {
        return this.$message({
          message: "单号:【" + row.BillNo + "】的入库单没有入库，不能退货!",
          type: "warning",
        });
      }
      if (row.ReturnStatus === 3) {
        return this.$message({
          message:
            "单号:【" + row.BillNo + "】的入库单已全部退货，不能继续退货!",
          type: "warning",
        });
      }
      // 退货
      this.$store.state.visibled = true;
      let data = { stkInGUID: row.StkInGUID, IsReturn: true };
      this.$store.commit("setParamOrder", { stkInReturnInfo: data });
      this.$nextTick(function () {
        this.editType = "edit";
        this.OrdPurchaseRetrunVisible = true;
      });
    },
    onRefresh() {
      this.onBindData(this.pageIndex);
      this.onCurrentRowChangeHandle();
    },
    // 验证操作权限
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    onBatchPay() {
      // 付款
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length === 0) {
        this.$message({ message: "请勾选上要操作的入库单", type: "warning" });
        return false;
      }
      let guid = _.map(rows, (item) => {
        return item.StkInGUID;
      });
      var lastCustomerName = rows[0].SupplierName;
      var isDiff = false;
      var isAllOut = false;
      var isPay = false;
      var isCancel = false;
      _.each(rows, (r) => {
        if (lastCustomerName !== r.SupplierName) {
          isDiff = true;
        }
        if (r.StatusName === "未入库") {
          isAllOut = true;
        }
        if (r.StatusName === "已撤消") {
          isCancel = true;
        }
        if (r.PayablesAmount <= r.PaidAmount + r.DiscountAmount) {
          isPay = true;
        }
      });
      if (isCancel) {
        return this.$message({
          message: "您选择的单据中存在已撤消的订单",
          type: "error",
        });
      }
      if (isPay) {
        return this.$message({
          message: "你选择的单据中存在付款完成的订单",
          type: "error",
        });
      }
      if (isDiff) {
        this.$confirm("你选择的入库单包含多个供应商, 是否继续付款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          if (isAllOut) {
            this.$confirm("你选择了还未入库的入库单, 是否继续付款?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              if (guid.length > 0) {
                return this.onBatchPayment(guid);
              }
            });
          } else {
            if (guid.length > 0) {
              return this.onBatchPayment(guid);
            }
          }
        });
      } else if (isAllOut) {
        this.$confirm("你选择了还未入库的入库单, 是否继续付款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          if (guid.length > 0) {
            return this.onBatchPaymentFinRec(guid, lastCustomerName);
          }
        });
      } else {
        if (guid.length > 0) {
          return this.onBatchPaymentFinRec(guid, lastCustomerName);
        }
      }
    },
    onBatchPayment(guid) {
      // 付款
      this.payVisible = true;
      this.$nextTick(() => {
        this.isFinRecPay = false;
        this.stkInGUIDS = guid;
        this.companyAmount = 0;
        this.$refs.table.cleanAllCheck();
      });
    },
    onBatchPaymentFinRec(guid, val) {
      // 付款
      if (this.postStatus.payment) {
        return false;
      }
      let respaysumAmount = 0;
      this.payVisible = true;
      // console.log('获取公司预收款：' + val)
      this.postStatus.payment = true;
      this.$post(
        CONFIG.searchSumAmountURL,
        { SearchType: 3, CompanyName: val },
        (data) => {
          respaysumAmount = data.Expenditure;
          if (respaysumAmount && respaysumAmount > 0) {
            this.isFinRecPay = true;
          } else {
            this.isFinRecPay = false;
          }
          this.$nextTick(() => {
            // console.log(respaysumAmount)
            this.isFinRecPay = val && this.isFinRecPay;
            this.companyAmount = respaysumAmount;
            this.stkInGUIDS = guid;
            this.$refs.table.cleanAllCheck();
          });
        }
      ).finally(() => {
        this.postStatus.payment = false;
      });
    },
    onImport: async function () {
      // 导入入库单
      this.$nextTick(function () {
        this.importData({ importType: "stkin" });
      });
    },

    // 双击事件
    onDbclick(e) {
      if (e.column.BindField === "BillNo") {
        this.onStkinEdit(e.rowData);
      }
    },

    // 保存并提交到委托报关单
    async onSwitchToSCM() {
      this.isSwitchToSCM = false;
      stockInToSCM();
    },
    onCurrentRowChangeHandle(val) {
      let row = null;
      if (val) {
        row = val;
      } else {
        row = this.$refs.table.getActiveRow();
      }
      if (row && this.hasValue(row.StkInGUID)) {
        this.$emit("selection-changed", row);
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkIn", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkIn", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkIn", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkIn");
    },
    //网页版打印
    async onWebPrint() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row) || !this.hasValue(row.StkInGUID)) {
        return this.$message({
          message: "请选中要打印的发货单",
          type: "error",
        });
      }

      var tableName = this.printInfo.template; // 模板ID
      var titleId = this.printInfo.title; // 子公司id（抬头）
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return;
      }
      let param = {
        TableName: tableName,
        SubCompanyID: titleId,
        OrderGuid: row.StkInGUID,
      };

      this.$post(CONFIG.WebPrintURL, param, (data, logic) => {
        if (logic.code === 200) {
          //文件下载路径
          if (data) {
            let downloadUrl = `${apiDomain}${CONFIG.DownloadPrintFile}?fileName=${data}`;
            window.open(downloadUrl); // 下载文件
          }
        } else {
          this.$message({ message: logic.msg, type: "error" });
        }
      });
    },

    // 申请月结
    onApplyLoanService() {
      // 付款
      let rows = this.$refs.table.getCheckedRows();
      if (!this.hasValue(rows) || rows.length === 0) {
        this.$message({ message: "请勾选上要操作的入库单", type: "warning" });
        return false;
      }
      const { BillNo, TaxRate, CurrencyCode } = rows[0];
      if (!this.hasValue(BillNo)) {
        this.message({ message: "单号不能为空", type: "error" });
        return false;
      }

      if (TaxRate !== 13) {
        this.$message({
          message: "税率为13的单据才能申请正能量月结服务",
          type: "error",
        });
        return false;
      }

      if (CurrencyCode !== "RMB") {
        this.$message({
          message: "币种为人民币的单据才能申请正能量月结服务",
          type: "error",
        });
        return false;
      }

      openLoanService(BillNo);
    },
  },
};
</script>

<style lang="scss">
.m-stk-in-order {
}
</style>
