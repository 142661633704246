<template>
  <!-- 供应商库存列表 -->
  <div class="module-wrapper m-supplier-stock">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :gridtype="gridtype"
      :header-menus="getResetTableHeaderMenu('StockVMI')"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :show-title-menus="true"
      :showCheck="this.isOperate"
      :total-count="totalCount"
      box-class="m-supplier-stock"
      checkboxBindingKey="VMGUID"
      element-loading-text="数据加载中,请稍后..."
      @select-change="selectChange"
      @on-supplierName-click="onSupplierNameCellClick"
      @on-adopt-click="e => {onAdopt(e.row)}"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div v-if="this.isOperate" slot="header" class="v-table-toolbar">
        <znl-button
          v-if="this.hasRes('StorageOut')"
          :width="74"
          class="minor-self-btn"
          style-type="mac"
          @click="stkVMIOutAddRows()"
        >
          <i class="iconfont icon-sell-wh_btn_ic"></i>
          <span>销售出库</span>
        </znl-button>

        <znl-button
          v-if="this.hasRes('StorageIn')"
          :width="74"
          class="minor-self-btn"
          style-type="mac"
          @click="stkVMIInAddRows()"
        >
          <i class="iconfont icon-buy-wh_btn_ic"></i>
          <span>采购入库</span>
        </znl-button>

        <znl-button
          v-if="hasRes('Quote')"
          :width="50"
          class="minor-self-btn"
          style-type="mac"
          @click="QuotedPrice()"
        >
          <i class="iconfont icon-edit_btn_ic"></i>
          <span>报价</span>
        </znl-button>

        <znl-button
          v-if="hasRes('Inquiry')"
          :width="50"
          class="minor-self-btn"
          style-type="mac"
          @click="StkVMIInquiry()"
        >
          <i class="iconfont icon-edit_btn_ic"></i>
          <span>询价</span>
        </znl-button>

        <znl-button v-if="this.hasRes('StkSign')" style-type="mac" @click="onMarking()">
          <i class="iconfont icon-float_edit_ic"></i>
          <span>打标记</span>
        </znl-button>

        <znl-float-list v-show="isShowMoreBtn(moreBtns)" :lists="moreBtns">
          <znl-button style-type="mac">
            <i class="iconfont icon-left_nav_show_btn"></i>
            <span>更多操作</span>
          </znl-button>
        </znl-float-list>

        <znl-button
          v-if="isShowBtnSave"
          class="save-self-btn"
          style-type="main"
          @click="onBatchSave()"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存修改</span>
        </znl-button>

        <a
          style="text-decoration:underline; margin-left:3px;margin-right:3px;"
          title="已选中条数"
          @click="onCheckInfo()"
        >已选择 ({{ selectedRows.length }}) 条</a>
        <a
          style="text-decoration:underline; margin-left:5px;"
          title="清除选中条数"
          @click="onCleaSelectCount()"
        >清除</a>
      </div>
    </znl-table>
    <!--备货编辑页面-->
    <stk-stock-reserve-add
      v-if="showstkReserveAdd"
      :IsShowLock="false"
      :isRefreshVMI="refreshStkReserve"
      :stkVMIInfo="stkVMIReserveModel"
      :visible="showstkReserveAdd"
      @close="()=>{showstkReserveAdd=false}"
      @confirm="(val)=>{val&&(showstkReserveAdd=false),this.onRefresh(),this.onCleaSelectCount()}"
    ></stk-stock-reserve-add>

    <!--打标记-->
    <stk-stock-marking
      v-if="setMarkingVisible"
      ref="StkStockMarking"
      :isRefreshVMI="!refreshSetMarking"
      :mark-names-selected="selectedMarkNames"
      :stkVMIGUIDS="VMGUIDS"
      :stockVMIMarkParam="searchCondition"
      :visible="setMarkingVisible"
      height="50%"
      markingType="stkVMI"
      @close="()=>{setMarkingVisible=false}"
      @confirm="(val)=>{(setMarkingVisible=false),this.onRefresh(),this.onCleaSelectCount()}"
    ></stk-stock-marking>

    <stk-in-edit
      v-if="stkInEditVisible"
      :editvisible="stkInEditVisible"
      height="500px"
      @close="stkInEditVisible=false"
      @confirm="stkInEditVisible=false"
    ></stk-in-edit>

    <stk-out-edit
      v-if="stkOutEditVisible"
      :editvisible="stkOutEditVisible"
      height="500px"
      @close="stkOutEditVisible=false"
      @confirm="stkOutEditVisible=false"
    ></stk-out-edit>

    <!-- 新增客户需求 -->
    <znl-bom-master-edit
      v-if="bomMasterDetailEditVisibleTwo"
      :adddata="bomMasterDetail"
      :editvisible="bomMasterDetailEditVisibleTwo"
      edit-type="addNew"
      @close="bomMasterDetailEditVisibleTwo=false"
      @confirm="bomMasterDetailEditVisibleTwo=false"
    ></znl-bom-master-edit>

    <!-- 验证码密码 -->
    <confirm-password
      v-if="stkCheckPwdVisible"
      ref="ConfirmPassword"
      :isRefresh="stkCheckPwdVisible"
      :visible="stkCheckPwdVisible"
      @close="()=>{stkCheckPwdVisible=false}"
      @confirm="onChangeEndCheckPwd"
    ></confirm-password>
    <!--已选择库存列表-->
    <stk-check-info
      v-if="showStkCheckInfo"
      ref="stkCheckInfo"
      :visible="showStkCheckInfo"
      @checkInfo="(val)=>{this.onSeletedInfo(val)}"
      @close="()=>{showStkCheckInfo=false}"
    ></stk-check-info>
    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>

<script>
import StkStockReserveAdd from '@c_modules/Stk/StkStockReserveAdd'
import StkStockMarking from '@c_modules/Stk/StkStockMarking'
import {mixin as getCommonDataMixin} from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import ZnlBomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import ConfirmPassword from '@c_modules/Stk/ConfirmPassword'
import StkCheckInfo from '@c_modules/Stk/StkCheckInfo'
import {erpTableSetHandler} from '@scripts/methods/common'
import EnquiryView from "@c_modules/Enquiry/index"
import {isClient} from "~/lib/runtime";

const CONFIG = {
  PK: 'VMGUID',
  configURL: 'StockVMI/GetConfig',
  searchURL: 'StockVMI/Search',
  addURL: 'StockVMI/Add',
  saveRowsURL: 'StockVMI/BatchSave',
  deleteURL: 'StockVMI/Del',
  DropDownURL: 'DictItem/GetItemValue',
  onClearURL: 'StockVMI/Clear',
  deleteByConditionURL: 'StockVMI/DeleteByCondition',
  checkAllowImport: 'StockVMI/checkAllowImport', // 检查供应商库存是否超出最大拥有供应商数量
  saveByAdoptInfoUrl: "InquireAdopt/SaveByAdoptInfo", // 采纳供应商库存
}

export default {
  name: 'StockVMI',
  mixins: [getCommonDataMixin, erpTableSetHandler],
  config: CONFIG,
  components: {
    StkStockReserveAdd,
    StkStockMarking,
    StkInEdit,
    ZnlBomMasterEdit,
    StkOutEdit,
    ConfirmPassword,
    StkCheckInfo,
    EnquiryView
  },
  data() {
    return {
      stkInEditVisible: false,
      stkOutEditVisible: false,
      moreBtns: [], // 更多操作
      showstkReserveAdd: false,
      showstkVMIMarking: false,
      refreshStkReserve: false,
      stkVMIReserveModel: {}, // 需要备货的供应商库存
      VMGUIDS: [], // 多个guid
      columns: [],
      itemSource: [],
      filterFields: {},
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      SupplierName: '', // 供应商名称
      stkInquiry: {}, // 询价参数传递
      stockVMIMarkParam: {},
      resourceList: [],
      searchFields: {
        Model: ''
      },
      loading: true,
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: [],
      stkCheckPwdVisible: false, // 是否显示密码验证页面
      isClear: false, // 判断是否是清除库存
      selectedRows: [], // 选中的数据
      showStkCheckInfo: false,  // 是否显示已选择库存列表
      setMarkingVisible: false, // 是否显示打标记页面
      refreshSetMarking: false, // 刷新
      qtyUnitList: [],   // 库存单位集合
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      isShowBtnSave: true,
      opParams: {
        BDLineGUID: ''
      }
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    gridtype: {
      type: String,
      default: 'action'
    }, // 表格类型。 action: 可编辑， base: 不可编辑
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isOperate: {
      type: Boolean,
      default: true
    },
    isAdopt: { // 是否显示采纳按钮
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.qtyUnitList = await this.selectOptionsQtyUnit
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    hasRes(code) {
      return _.includes(this.resourceList, code)
    },
    // 刷新
    async onRefresh(e) {
      await this.onBindData(this.pageIndex)
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchFields),
        BDLineGUID: this.opParams.BDLineGUID,
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      return await this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
      }).finally(() => {
        this.$emit('page-loading', false)
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params)
      }
      return this.onBindData(1)
    },
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('stockVMI_v2', CONFIG.configURL)
      }
      // 员工列表
      let userList = _.map(this.selectOptionsAllUserURL, function (obj) {
        return {key: obj.value, value: obj.value}
      })
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      let columns = config.ColumnConfigs

      if (this.isOperate) {
        let tempConfig = []
        if (this.hasRes('StorageOut')) {
          tempConfig.push({
            width: 30,
            type: 'button',
            title: '出',
            btnTxt: '出',
            visible: true,
            isDeal: false,
            isFrozen: true,
            click: row => {
              this.onStkOutadd([row.VMGUID])
            }
          })
        }
        if (this.hasRes('StorageIn')) {
          tempConfig.push({
            width: 30,
            type: 'button',
            title: '入',
            btnTxt: '入',
            visible: true,
            isDeal: false,
            isFrozen: true,
            click: row => {
              this.onStkInadd([row])
            }
          })
        }
        tempConfig.push({
            width: 30,
            type: 'button',
            title: '报',
            btnTxt: '报',
            visible: true,
            isDeal: false,
            isFrozen: true,
            click: row => {
              this.onQuotedPrice([row])
            }
          },
          {
            width: 30,
            type: 'button',
            title: '备',
            btnTxt: '备',
            visible: true,
            isDeal: false,
            isFrozen: true,
            click: row => {
              this.onAddStkReserve(row)
            }
          },
          {
            width: 30,
            type: 'button',
            title: '删',
            btnTxt: '删',
            visible: true,
            isDeal: false,
            isFrozen: true,
            click: row => {
              this.onDeleteRow([row])
            }
          })
        columns = tempConfig.concat(columns)
      }

      // 是否显示采纳按钮
      if (this.isAdopt) {
        columns.push({
          BindField: "VMID",
          width: 60,
          title: '采纳',
          visible: true,
          isFrozen: true,
          className: 'v-cell-link',
          event: "on-adopt-click",
          formatter (rowData) {
            if (rowData.IsAdoptStk) {
              return "<div class='adopted'>已采纳</div>";
            } else {
              return "<div class='no-adopt'>采纳</div>";
            }
          }
        })
      }

      // 表格值的点击 start
      let index = _.findIndex(columns, item => item.BindField === 'SupplierName')
      if (index > -1) {
        _.extend(columns[index], {
          event: 'on-supplierName-click',
          className: 'v-cell-link'
        })
      }
      index = _.findIndex(columns, item => item.BindField === 'Unit')
      if (index > -1) {
        _.extend(columns[index], {
          type: 'select',
          width: 58,
          options: this.qtyUnitList
        })
      }
      index = _.findIndex(columns, item => item.BindField === 'Buyer')
      if (index > -1) {
        _.extend(columns[index], {
          type: 'select',
          width: 72,
          options: userList
        })
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn
      if (!this.IsMainAccount) {
        this.setHideColumns(columns)
      }
      this.columns = columns
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.$refs.table.init()
      this.initMoreBtns()
      if (this.initData) {
        await this.onBindData()
      }
      this.loading = false
      this.$emit('page-loading', false)
    },

    onSupplierNameCellClick(e) {
      if (e.field !== 'SupplierName') {
        return
      }
      if (this.hasValue(e.row.SupplierName)) {
        let row = e.row
        this.onStkVMIInquiry([row])
      }
    },
    // 列的初始化
    setHideColumns(columns) {
      /** **********权限控制start*************/
        // 单价权限控制UnitPrice
      let viewBuyPrice = this.getSpecialResourceByCode('ViewBuyPrice')

      // 权限控制 SalesPrice销售价和FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode('ViewSalesPrice')

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')

      let onlyEditModelQty = false
      if (this.hasRes('Save')) {
        if (this.hasRes('EditModelQty')) { // 修改型号&数量
          onlyEditModelQty = true
        } else {
          this.isShowBtnSave = false
        }
      } else {
        this.isShowBtnSave = false
      }
      _.remove(columns, item => {
        if (!viewBuyPrice && item.BindField === 'UnitPrice') {
          return true
        }
        if (!viewSalesPrice && (item.BindField === 'SalesPrice' || item.BindField === 'FollowPrice')) {
          return true
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === 'Remark' && !this.hasRes('ViewRemark1')) {
          return true
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === 'Remark1' && !this.hasRes('ViewRemark2')) {
          return true
        }
        // 看备注3  没有这个权限隐藏列
        if (item.BindField === 'Remark2' && !this.hasRes('ViewRemark3')) {
          return true
        }
        // 只能编辑型号&数量
        if (onlyEditModelQty && item.BindField !== 'Model' && item.BindField !== 'InvQty') {
          item.isReadOnly = true
        }
        // 不看供应商资料
        if (noSeeSupplier && item.BindField === 'SupplierName') {
          return true
        }
      })
    },
    // 操作按钮初始化
    initMoreBtns(columns) {
      this.moreBtns = [
        {
          name: '删除选中',
          isShow: this.hasRes('Delete'),
          click: () => {
            this.onMulitDeleteRow()
          }
        },
        {
          name: '按查询条件删除',
          isShow: this.isMainAccount && this.hasRes('Delete'),
          click: () => {
            this.onDeleteByCondition()
          }
        },
        {
          name: '清除供应商库存',
          isShow: this.isMainAccount && this.hasRes('Delete'),
          click: () => {
            this.onClearInventory()
          }
        },
        {
          name: '按查询条件导出',
          isShow: this.hasRes('Export'),
          click: () => {
            this.onstkExport()
          }
        },
        {
          name: '导入供应商库存',
          isShow: this.hasRes('Import'),
          click: () => {
            this.onstkVMIImport()
          }
        }
      ]
    },
    onHeadSearch(params, opParams) {
      // this.$refs.table.init()

      if (opParams) {
        this.opParams = Object.assign(this.opParams, opParams);
      }

      let p = {
        Model: params.Model || null,
      }

      let rows = this.$refs.table.getChangedData()
      if (rows.length > 0) {
        this.$confirm('当前数据已发生修改, 是否继续操作?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.searchFields = p
          return this.onBindData(1)
        })
      } else {
        this.searchFields = p
        return this.onBindData(1)
      }
    },
    // 报价
    QuotedPrice() {
      let rows = this.selectedRows
      if (rows.length > 0) {
        this.onQuotedPrice(rows)
        this.clearSelectedRows()
      } else {
        this.$message({message: '请选择一条数据进行操作', type: 'error'})
      }
    },
    onQuotedPrice(rows) {
      if (rows !== null && rows.length > 0) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows)
      }
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, (data) => {
          if (data >= 0) {
            this.$refs.table.cleanEdit()
            this.$message({message: '保存成功', type: 'success'})
          } else {
            this.$message({message: '保存失败', type: 'error'})
          }
        })
      }
    },
    onDeleteRow(rows) {
      if (rows === null || rows.length <= 0) {
        return this.$message({message: '请勾选上要删除的数据', type: 'error'})
      }
      let guid = _.map(rows, item => {
        return item.VMGUID
      })

      this.$confirm('删除确认, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post(CONFIG.deleteURL, {VMGUIDS: guid}, (datas) => {
          if (datas > 0) {
            this.$message({message: '删除成功', type: 'success'})
            this.onCleaSelectCount()
            this.onSearch()
          } else {
            this.$message({message: '删除失败', type: 'error'})
          }
        })
      })
    },
    // 按查询条件删除
    onDeleteByCondition() {
      // 判断查询条件  如果查询条件为null给出提示  后面完善
      this.isClear = false
      return this.$confirm('您确定要按查询条件删除库存吗?', '按查询条件删除库存', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let filterFields = {
          // 外部搜索条件
          isNegative: this.isNegative, // 负库存
          isZero: this.isZero, // 零库存
          MatchOperation: this.matchOperation
        }
        let param = {}
        param = _.extend({}, {fieldWhereString: JSON.stringify(this.searchFields)}, filterFields)
        this.$post(CONFIG.deleteByConditionURL, param, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({message: logic.msg, type: 'error'})
          } else {
            if (data > 0) {
              this.$message({message: '删除成功', type: 'success'})
              this.onBindData(this.pageIndex)
            } else {
              this.$message({message: '删除失败', type: 'error'})
            }
          }
        })
      })
    },
    // 清除库存
    onClearInventory() {
      this.isClear = true
      return this.$confirm('所有库存清除之后不可恢复，您确定要清除吗?', '清除库存', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.stkCheckPwdVisible = true
        this.isRefreshCheckPwd = !this.isRefreshCheckPwd
      })
    },
    // 验证密码过后清除库存和按查询条件清除
    onChangeEndCheckPwd(val) {
      this.stkCheckPwdVisible = false
      let param = {}
      if (val) {
        this.$post(CONFIG.onClearURL, param, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({message: logic.msg, type: 'error'})
          } else {
            // 没有库存数据的时候data等于0，其实已经是删除成功了
            // if (data > 0) {
            this.$message({message: '删除成功', type: 'success'})
            this.onBindData(this.pageIndex)
            // } else {
            //   this.$message({ message: '删除失败', type: 'error' })
            // }
          }
        })
      }
    },
    isShowMoreBtn(btns) {
      let isShow = false
      _.each(btns, btn => {
        if (btn.isShow) {
          isShow = true
          return true
        }
      })
      return isShow
    },
    clearSelectedRows() {
      this.onCleaSelectCount()
    },
    onStkInadd(rows) { // 采购入库传值方法
      // 从供应商库存点击入库 不需要数量
      _.each(rows, items => {
        this.$set(items, 'InvQty', '')
        this.$set(items, 'Qty', '')
        this.$set(items, 'UsableQty', '')
      })
      let order = {stockLine: rows}
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onStkOutadd(guid) { // 销售出库传值方法
      let order = {VMGUIDS: guid}
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.onCleaSelectCount()
        this.stkOutEditVisible = true
      })
    },
    onMulitDeleteRow() {
      let rows = this.selectedRows
      this.onDeleteRow(rows)
    },
    stkVMIOutAddRows() { // 供应商库存批量出库
      let rows = this.selectedRows
      if (rows.length > 0) {
        let guids = _.map(rows, item => {
          return item.VMGUID
        })
        this.onStkOutadd(guids)
      }
    },
    stkVMIInAddRows() {
      let rows = this.selectedRows
      if (rows.length > 0) {
        this.onStkInadd(rows)
        this.clearSelectedRows()
      }
    },
    onOfferPrice(guids) { // 报价
      this.VMGUIDS = guids
      if (guids[0] + '' === 'undefined') { // 选中行
        return this.$message({message: '请至少选择一条数据进行操作', type: 'error'})
      }
      let order = {VMGUIDS: guids}
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.onCleaSelectCount()
        this.bomMasterDetailEditVisible = true
      })
    },
    onAddStkReserve(row) { // 备货
      this.stkVMIReserveModel = row
      this.showstkReserveAdd = true
      this.refreshStkReserve = !this.refreshStkReserve
    },
    // 询价
    StkVMIInquiry() {
      let rows = this.selectedRows
      if (rows.length > 0) {
        this.onStkVMIInquiry(rows)
        this.clearSelectedRows()
      } else {
        this.$message({message: '请选择一条数据进行操作', type: 'error'})
      }
    },

    onStkVMIInquiry: async function (rows) { // 询价
      const data = rows[0]
      if (isClient()) {
        await this.onInquiry(data)
      } else {
        const mapData = [
          'SupplierName',
          'Model',
          'Brand',
          'Packaging',
          'MakeYear',
          'Quality',
          'InvQty',
          'MPQ',
          'Qty',
        ]
        let editItem = {}
        mapData.forEach(key => editItem[key] = data[key])
        editItem.DeliveryDate = data.Leadtime
        this.$refs.enquiryView.onOpen(editItem)
      }
    },
    onConfirm(data) {
      console.log(data)
    },
    onInquiry: async function (data) { // 询价
      this.stkInquiry.SupplierName = data.SupplierName
      this.stkInquiry.Model = data.Model
      this.stkInquiry.Brand = data.Brand
      this.stkInquiry.Packaging = data.Packaging
      this.stkInquiry.MakeYear = data.MakeYear
      this.stkInquiry.Quality = data.Quality
      this.stkInquiry.InvQty = data.InvQty
      this.stkInquiry.Qty = data.InvQty
      this.stkInquiry.MPQ = data.MPQ
      this.stkInquiry.Qty = data.InvQty
      this.stkInquiry.DeliveryDate = data.Leadtime
      // this.stkInquiry.Price = data.BuyPrice
      this.stkInquiry.QuantityPhrase = data.InvQty
      this.addInquire(this.stkInquiry, function (msg) {
        if (msg === '不能向自己询价') {
          this.$message({message: msg, type: 'warning'})
        }
      })
      this.clearSelectedRows()
    },
    // 打标记
    onMarking() {
      this.checkedRows = this.selectedRows
      this.selectedMarkNames = ''
      if (!_.isUndefined(this.checkedRows) && this.checkedRows.length > 0) {
        this.VMGUIDS = _.map(this.checkedRows, m => m.VMGUID)
        if (this.checkedRows.length === 1) {
          this.selectedMarkNames = this.checkedRows[0].InvMarkNames
        }
      } else {
        this.VMGUIDS = []
      }
      this.searchCondition = {
        // 外部搜索条件
        FieldWhereString: JSON.stringify(this.searchFields) // 表头搜索
      }
      this.setMarkingVisible = true
      //this.clearSelectedRows()
    },
    onstkVMIImport: async function () { // 导入供应商库存
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({message: '您的供应商库存量已超出最大库存量，不能再导入！', type: 'error'})
        }
        this.importData({importType: 'stkvmi'})
      })
    },

    // 勾选选择框事件
    selectChange(rows) {
      //this.selectedRows = rows
      _.each(rows, a => {
        a.pageIndex = this.pageIndex
      })
      if (this.selectedRows.length <= 0) this.selectedRows = rows
      else {
        //过滤当前页数据
        this.selectedRows = _.filter(this.selectedRows, a => {
          return a.pageIndex != this.pageIndex;
        })
        //添加当前页数据
        this.selectedRows = this.selectedRows.concat(rows);
        //从小到大 排序
        this.selectedRows.sort(function (a, b) {
          return a.pageIndex - b.pageIndex
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = []
      this.$refs.table.cleanAllCheck()
    },

    onBatchSave() {
      let rows = this.$refs.table.getChangedData()
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({message: '数据没有发生变化', type: 'warning'})
      } else {
        this.onSaveRows(rows)
      }
    },
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({message: '至少选择一条数据', type: 'warning'})
      } else {
        _.each(this.selectedRows, item => {
          item.CheckGUID = item.VMGUID
        })
        let order = {checkInfo: this.selectedRows}
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.showStkCheckInfo = true
        })
      }
    },
    // 采纳供应商库存（BOM配单模块）
    onAdopt (row) {
      let info = {
        Model: row.Model,
        BDLineGUID: this.opParams.BDLineGUID,
        Qty: Math.min(row.InvQty, this.opParams.Qty), // 需求量以及库存量，取最小值
        Price: row.UnitPrice,
        StkVMIGuid: row.VMGUID,
        Brand: row.Brand,
        Packaging: row.Packaging,
        MPQ: row.MPQ,
        MakeYear: row.MakeYear,
        Quality: row.Quality,
        DeliveryDate: row.DeliveryDate || null,
        // ASGUID: row.ASGUID,
        IsSingle: true,
      };
      if (info.BDLineGUID == null || info.BDLineGUID == undefined || info.BDLineGUID == '') {
        this.$message({message: "采纳失败，请刷新重试", type: 'error'})
        return false;
      }
      this.loading = true;
      return this.$post(CONFIG.saveByAdoptInfoUrl, info, (datas, logic) => {
        if (logic.code === 200) {
          this.loading = false;
          this.$emit("save-success", datas);
          this.onBindData(this.pageIndex);
          this.$message({ message: "采纳成功", type: "success" });
          this.$emit("onStockVMIAdopt", info);
        } else {
          this.loading = false;
          this.$message({ message: logic.msg, type: "error" });
        }
      }).catch((e) => {
        this.loading = false;
      });
    },
    onSeletedInfo(val) {
      this.selectedRows = val
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StockVMI", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("StockVMI", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("StockVMI", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StockVMI")
    }
  }

}
</script>

<style lang="scss">
.m-supplier-stock{
  .adopted, .no-adopt {
    color:#1e90ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

