<template>
  <znl-layout-spa :page-loading="pageLoading" znl-loading-text="页面加载中,请稍后...">
    <el-tabs
      value="VSalesOrderManage"
      class="nav-common-ui tab-btnstyle-box tab-manage-tab"
      v-model="activeName"
      v-show="!pageLoading"
      @tab-click="pageSearch"
    >
      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->
      <!-- v-if="hasRight('SalesOrder') -->
      <el-tab-pane label="销售合同" name="SalesOrder" height="100%" v-if="hasRight('SalesOrder')">
        <Split direction="vertical" v-if="salesOrderInitData" @onDragEnd="onDragEndBySales">
          <SplitArea ref="SplitAreaSalesOrder" :size="65" :minSize="65">
            <!-- sales-order -->
            <sales-order
              :init-data="salesOrderInitData"
              @selection-changed="SalesOrderSelectionChange"
              @page-loading="setClientSelectLoading"
              @tab-change="tabJumpByTemplate"
              ref="salesOrder"
            ></sales-order>
          </SplitArea>
          <SplitArea :size="35" :minSize="35">
            <sales-order-line ref="salesOrderLine"></sales-order-line>
          </SplitArea>
        </Split>
      </el-tab-pane>

      <el-tab-pane label="发货单" name="StkOut" height="100%" v-if="hasRight('StkOut')">
        <Split direction="vertical" v-if="stkOutInitData" @onDragEnd="onDragEndByStkOut">
          <SplitArea :size="65" :minSize="65">
            <znl-stk-out
              :init-data="stkOutInitData"
              @selection-changed="stkOutSelectionChange"
              @page-loading="setClientSelectLoading"
              :initStkOutBillNo="initStkOutBillNo"
              ref="stkOut"
            ></znl-stk-out>
          </SplitArea>
          <SplitArea :size="35" :minSize="35">
            <znl-stk-out-line ref="stkOutLine"></znl-stk-out-line>
          </SplitArea>
        </Split>
      </el-tab-pane>

      <el-tab-pane label="销售退货单" name="SalesReturn" height="100%" v-if="hasRight('SalesReturn')">
        <Split direction="vertical" v-if="salesReturnInitData" @onDragEnd="onDragEndBySOReturn">
          <SplitArea :size="65" :minSize="65">
            <znl-sales-order-return
              :init-data="salesReturnInitData"
              @selection-changed="SalesReturnOrderSelectionChange"
              @page-loading="setClientSelectLoading"
              :initSalesReturnBillNo="initSalesReturnBillNo"
              ref="salesReturn"
            ></znl-sales-order-return>
          </SplitArea>
          <SplitArea :size="35" :minSize="35">
            <znl-sales-order-return-line ref="salesReturnLine"></znl-sales-order-return-line>
          </SplitArea>
        </Split>
      </el-tab-pane>

      <el-tab-pane label="销售对账单" name="StatementOrder" v-if="hasRight('SalesStatement')">
        <statement-order
          v-if="statementInitData"
          :init-data="statementInitData"
          @page-loading="setClientSelectLoading"
          ref="orders"
        ></statement-order>
      </el-tab-pane>

      <!-- hasRight('SalesOrder') -->
      <!-- <el-tab-pane label="商城销售合同" name="DevOrder" height="100%" v-if="false">
        <znl-layout-topbottom top-height="62%" v-if="devOrderInitData">
          <dev-order
            slot="znlTop"
            class="znlTop"
            :init-data="devOrderInitData"
            @selection-changed="DevOrderSelectionChange"
            @page-loading="setClientSelectLoading"
          ></dev-order>
          <dev-order-line ref="devOrderLine" slot="znlBottom" class="znlBottom"></dev-order-line>
        </znl-layout-topbottom>
      </el-tab-pane>-->
    </el-tabs>
  </znl-layout-spa>
</template>
<script>

import SalesOrder from "@c_modules/SPM/SalesOrder";
import SalesOrderLine from "@c_modules/SPM/SalesOrderLine";
import ZnlStkOut from "@c_modules/Stk/StkOutOrder";
import ZnlStkOutLine from "@c_modules/Stk/StkOutOrderLine";
import ZnlSalesOrderReturn from "@c_modules/SPM/SalesOrderReturn";
import ZnlSalesOrderReturnLine from "@c_modules/SPM/SalesOrderReturnLine";
import StatementOrder from "@c_modules/SPM/StatementOrder";
import DevOrder from "@c_modules/SPM/DevOrder";
import DevOrderLine from "@c_modules/SPM/DevOrderLine";
import { isClient } from "~/lib/runtime";

export default {
  name: "VSalesOrderManage",
  components: {
    ZnlStkOut,
    ZnlStkOutLine,
    SalesOrder,
    SalesOrderLine,
    ZnlSalesOrderReturn,
    ZnlSalesOrderReturnLine,
    StatementOrder,
    DevOrder,
    DevOrderLine
  },
  watch: {
    pageLoading(val) {
      if (!val) {
        this.$el.classList.add("finishLoading");
      } else {
        this.$el.classList.remove("finishLoading");
      }
    },
    $route: "routeChangeFn",
    //监控窗口大小改变，因而计算组件的大小
    '$store.state.domRect.clientHeight': function(val){
      this.resize()
    }
  },
  data() {
    return {
      pageData: [],
      pageLoading: true,
      stkOutInitData: false,
      salesOrderInitData: false,
      salesReturnInitData: false,
      statementInitData: false,
      activeName: "SalesOrder",
      devOrderInitData: false,
      initStkOutBillNo: "",
      initSalesReturnBillNo: null
    };
  },
  methods: {
    resize(){
      this.$nextTick(() => {
        if(this.activeName === 'SalesOrder'){
          this.onDragEndBySales();
        } else if(this.activeName === 'StkOut'){
          this.onDragEndByStkOut();
        } else if(this.activeName === 'SalesReturn'){
          this.onDragEndBySOReturn();
        } else if(this.activeName === 'StatementOrder'){
          this.$refs.orders.$refs.table.boxHeight =
            (isClient()
            ? this.$store.state.domRect.clientHeight - 46
            : this.$store.state.domRect.clientHeight - 50 - 46);
          this.$refs.orders.$refs.table.$forceUpdate();
        }

        // if(this.$refs.orders){ // 设定销售对账单高度
        //   let h =
        //   this.$refs.orders.$refs.table.boxHeight =
        //     (isClient()
        //     ? this.$store.state.domRect.clientHeight - 46
        //     : this.$store.state.domRect.clientHeight - 50 - 46);
        // }
      })
    },
    routeChangeFn(to, from) {
      if (to.name == "Sales/OrderManage" && to.params.poNumber != undefined) {
        if (this.hasRight("SalesOrder")) {
          this.activeName = "SalesOrder";
          if (!this.salesOrderInitData) {
            this.salesOrderInitData = true;
          } else {
            this.$refs.SalesOrder.setPoNumber(to.params.poNumber);
          }
        }
      }
    },
    tabJumpByTemplate(data) {
      this.activeName = data.tabName;
      if (data.tabName == "StkOut") {
        if (this.hasRight("StkOut")) {
          this.initStkOutBillNo = data.billNo;
          if (!this.stkOutInitData) {
            this.stkOutInitData = true;
          } else {
            this.$refs.StkOut.setBillNo(data.billNo);
          }
        }
      } else if ((data.tabName = "SalesReturn")) {
        if (this.hasRight("SalesReturn")) {
          this.initSalesReturnBillNo = data.billNo;
          if (!this.salesReturnInitData) {
            this.salesReturnInitData = true;
          } else {
            this.$refs.SalesReturn.setBillNo(data.billNo);
          }
        }
      }
    },
    setClientSelectLoading(val) {
      this.pageLoading = val;
    },
    stkOutSelectionChange(item) {
      this.$refs.stkOutLine.onHeadSearch(item.StkOutGUID);
    },
    SalesOrderSelectionChange(item) {
      this.$refs.salesOrderLine.onHeadSearch(item.SOGuid);
    },
    SalesReturnOrderSelectionChange(item) {
      this.$refs.salesReturnLine.onHeadSearch(item.StkOutGUID);
    },
    DevOrderSelectionChange(item) {
      this.$refs.devOrderLine.onHeadSearch(item.OrderNo);
    },
    hasRight(code) {
      return _.includes(this.pageData, code);
    },
    pageSearch() {
      // RowGUID 辅助作用(没实际作用),用于改变条件,触发起始搜索
      if (this.activeName === "SalesOrder" && !this.salesOrderInitData) {
        this.salesOrderInitData = true;
      } else if (this.activeName === "StkOut" && !this.stkOutInitData) {
        this.stkOutInitData = true;
      } else if (
        this.activeName === "SalesReturn" &&
        !this.salesReturnInitData
      ) {
        this.salesReturnInitData = true;
      } else if (
        this.activeName === "StatementOrder" &&
        !this.statementInitData
      ) {
        this.statementInitData = true;
      } else if (this.activeName === "DevOrder" && !this.devOrderInitData) {
        this.devOrderInitData = true;
      }
      this.resize();
    },

    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VSalesOrderManage" });
    },
    // 设置默认选中选项卡
    defaultSelectedTab() {
      this.activeName = "SalesOrder";
      if (this.hasRight("SalesOrder")) {
        this.activeName = "SalesOrder";
      } else if (this.hasRight("StkOut")) {
        this.activeName = "StkOut";
      } else if (this.hasRight("SalesReturn")) {
        this.activeName = "SalesReturn";
      } else if (this.hasRight("SalesStatement")) {
        this.activeName = "StatementOrder";
      } else if (this.hasRight("SalesOrder")) {
        this.activeName = "DevOrder";
      }
    },
    // 默认起始查询数据
    defaultInitData() {
      if (this.activeName === "SalesOrder" && !this.salesOrderInitData) {
        this.salesOrderInitData = true;
      } else if (this.activeName === "StkOut" && !this.stkOutInitData) {
        this.stkOutInitData = true;
      } else if (
        this.activeName === "SalesReturn" &&
        !this.salesReturnInitData
      ) {
        this.salesReturnInitData = true;
      } else if (
        this.activeName === "StatementOrder" &&
        !this.statementInitData
      ) {
        this.statementInitData = true;
      } else if (this.activeName === "DevOrder" && !this.devOrderInitData) {
        this.devOrderInitData = true;
      }
    },
    onDragEndByStkOut() {
      this.$refs.stkOut && this.$refs.stkOut.$refs.table.init();
      this.$refs.stkOutLine && this.$refs.stkOutLine.$refs.table.init();
    },
    onDragEndBySales() {
      this.$refs.salesOrder && this.$refs.salesOrder.$refs.table.init();
      this.$refs.salesOrderLine && this.$refs.salesOrderLine.$refs.table.init();
    },
    onDragEndBySOReturn() {
      this.$refs.salesReturn && this.$refs.salesReturn.$refs.table.init();
      this.$refs.salesReturnLine && this.$refs.salesReturnLine.$refs.table.init();
    }
  },
  async created() {
    this.getPageData()
      .then(data => {
        this.$nextTick(() => {
          if (data && data.length > 0) {
            this.pageData = data.map(item => item.Code);
          }
          this.defaultSelectedTab();
          this.defaultInitData();
        });
      })
      .catch(e => {});
  }
};
</script>
<style lang="scss" scoped>
</style>
