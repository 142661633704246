<template>
  <div>
    <!-- 销售对账单新增_编辑 -->
    <znl-dialog
      title=" 销售对账单"
      :visible="accountVisible"
      height="640px"
      width="1025px"
      class="dialog-statement-box p-statement-order-edit"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      :append-to-body="true"
      element-loading-text="数据加载中,请稍后..."
      @close="val=>{$emit('close', val), onResetSalesStatement()}"
    >
      <znl-gridmodule
        ref="flexGrid"
        header="销售对账单"
        height="100%"
        gridtype="base"
        class="grid-data-statement-box m-border-box"
        layout-type="other"
        :has-znl-btns="false"
        :from-height="150"
        element-loading-text="正在保存,请稍等..."
        :from-btns="actionBtns"
        :is-multi-rows-check-fixed="false"
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :show-summary="true"
        :summary-method="billBySum"
        :summary-columns="summaryColumns"
        :table-row-class-name="tableRowClassName"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :on-init="onInit"
        :is-multi-rows-check="true"
        :users="$store.state.users"
        :is-alter-ver="true"
        :loading="loading"
        @page-loading="d=>{$emit('page-loading', d)}"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style=" border: 1px solid #d8d8d8;height: 94px">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style=" height: 40px;line-height: 40px;"
          >
            <el-col :span="8">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :clearable="true"
                :border="true"
                title="公司名称："
                width="100%"
                v-model="SalesStatement.CustomerName"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="5" class="min-preboderleftnone-box">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="true"
                placeholder="默认自动生成"
                size="mini"
                layout="left"
                :clearable="true"
                :border="true"
                title="对账单号："
                width="96%"
                v-model="SalesStatement.BillNo"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="4">
              <znl-input
                title-width="65px"
                layout="left"
                ref="CurrencyCode"
                form-type="select"
                :disabled="true"
                width="98%"
                placeholder="请选择"
                popper-class="popperClass"
                title="币 种："
                :border="true"
                :is-must-fill="false"
                :clearable="true"
                :select-options="selectOptionsCurrencyCode"
                v-model="SalesStatement.CurrencyCode"
              ></znl-input>
            </el-col>
            <el-col :span="7">
              <znl-input
                form-type="datepicker"
                title-width="70px"
                :disabled="true"
                placeholder="对账日期起"
                title="对账日期："
                size="mini"
                value-format="yyyy-MM-dd"
                layout="left"
                width="57%"
                :clearable="true"
                :border="true"
                v-model="SalesStatement.StatementDateStart"
              ></znl-input>
              <span style="font-size:12px">到</span>
              <znl-input
                form-type="datepicker"
                :disabled="true"
                placeholder="对账日期止"
                value-format="yyyy-MM-dd"
                size="mini"
                layout="left"
                width="92px"
                :border="true"
                :clearable="true"
                v-model="SalesStatement.StatementDateEnd"
              ></znl-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder="备注"
                size="mini"
                layout="left"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                title="备注信息："
                width="100%"
                v-model="SalesStatement.Remark"
                :rows="2"
                type="textarea"
              ></znl-input>
            </el-col>
            <el-col :span="5" class="min-preboderleftnone-box">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                title="实收金额："
                v-model="SalesStatement.Amount"
                width="96%"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="4" class="min-preboderleftnone-box">
              <znl-input
                title-width="65px"
                form-type="input"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                title="已收金额："
                v-model="SalesStatement.RecPayAmount"
                width="98%"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="4" class="min-preboderleftnone-box">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="true"
                placeholder
                size="mini"
                layout="left"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                title="未收金额："
                v-model="SalesStatement.UnRecPayAmount"
                width="100%"
                type="text"
              ></znl-input>
            </el-col>
          </el-row>
        </div>
        <div slot="from-content">
          <span style="font-size:12px;color:red;margin-right: 5px;">只有含税的型号才可以开票！</span>
          <znl-button style-type="mac" :height="22" :isShow="hasRes('Print')" type="multiple">
            <znl-button-menu @click="onPrint">
              <span>
                <i class="iconfont icon-print_btn_ic"></i>打印
              </span>
            </znl-button-menu>
            <znl-button-menu :width="16" tip="打印设置" @click="znlPrintVisible=true">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
          <!-- <znl-button
            style-type="mac"
            :height="22"
            :width="45"
            @click="onInvoiceEdit()"
            :disabled="true"
            tip="审核后才可开发票"
            v-if="hasRes('Invoice')"
          >
            <span>
              <i class="iconfont"></i>开票
            </span>
          </znl-button> -->
          <znl-button
            style-type="minor"
            :disabled="isDisabled"
            @click="statementSave(false)"
            :height="22"
          >
            <span>
              <i class="iconfont icon-save_btn_ic"></i>保存
            </span>
          </znl-button>
          <znl-button
            style-type="main"
            :height="22"
            :disabled="isDisabled"
            @click="statementSave(true)"
            :isShow="true"
          >
            <span>
              <i class="iconfont icon-save_btn_ic"></i>保存并关闭
            </span>
          </znl-button>
        </div>
        <znl-print
          :visible.sync="znlPrintVisible"
          :print-info="printInfo"
          :loading="printLoading"
          :init-param="false"
          :is-get-default-param="printInfo.isGetDefaultParam"
          :print-type="printInfo.template"
          title="对账单打印设置"
          page-code="StsSalesOut"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
        <!-- 销售发票新增_编辑 -->
        <fin-so-invoice-edit
          :isReturninv="isReturninvoice"
          v-if="invoiceVisible"
          :accountVisible="invoiceVisible"
          :title="invoiceTitle"
          @close="invoiceVisible=false"
          @save-success="onRefresh"
        ></fin-so-invoice-edit>
      </znl-gridmodule>
    </znl-dialog>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import FinSoInvoiceEdit from '@c_modules/Fin/SalesInvoice/InvoiceEdit'

import ZnlPrint from '@c_common/znlGrid/commonComponents/printTemplates'

const CONFIG = {
  PK: 'SSLineID',
  configURL: 'SalesStatementLine/GetConfig',
  configsURL: 'SalesStatement/GetConfig',
  createLine: 'SalesStatementLine/CreateSOStatementLine',
  saveURL: 'SalesStatementAdd/Save',
  searchBySSGUIDURL: 'SalesStatementLine/SearchBySSGUID'

}
export default {
  name: 'StatementOrderEdit',
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin],
  components: {
    ZnlPrint,
    FinSoInvoiceEdit
  },
  data () {
    return {
      columns: [],
      resourceList: [],
      itemSource: [],
      invoiceVisible: false, // 销售发票
      isReturninvoice: false, // 是否冲红发票
      invoiceTitle: '',
      btnMouseEnterMenus: {
        // 操作列按钮
        List: []
      },
      SalesStatement: {
        SSID: 0,
        SSGUID: '',
        BillNo: '',
        CustomerGUID: '',
        CustomerName: '',
        StatementDateStart: '',
        StatementDateEnd: '',
        CurrencyCode: '',
        CurrencyName: '',
        Remark: '',
        UnRecPayAmount: '',
        RecPayAmount: '',
        Amount: '',
        LineList: [] // 出库单详情
      },
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      editRowIndex: -1,
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      users: [],
      loading: false,
      actionBtns: [],
      isAdd: true,
      dialogLoading: true,
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: 'Statement' // 打印模板类型
      } // end 打印模块参数

    }
  },
  props: {
    isDisabled: {
      type: Boolean,
      defaule: false
    },
    accountVisible: {  // 接收显示组件
      type: Boolean,
      defaule: false
    },
  },
  watch: {
    dialogLoading (val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.flexGrid.doLayout()
        })
      }
    },
    itemSource () {
    }
  },
  methods: {
    // 初始化
    onInit: async function () {
      let config = await this.isSaveStorage('salesStatementLine', CONFIG.configURL)
      // 用于获取页面的权限
      let configs = await this.isSaveStorage('salesStatemen', CONFIG.configsURL)
      this.resourceList = configs.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.columns = columns
      this.pageSize = config.PageSize
      this.actionBtns = [
        {
          name: '删除选择行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.deteledRow()
          }
        }
      ]
      this.fromSalesOrderOpen = false
      if (this.$store.state.paramOrder) {
        if (this.$store.state.paramOrder.SSGUID) {
          this.IsstkOutGUIDLine = true
          let info = this.$store.state.paramOrder.SSGUID
          this.$store.commit('setParamOrder', {})
          this.loadStatementOrderInfo(info)
        }
      }
      this.$nextTick(() => {
        this.dialogLoading = false
      })
    },
    onRefresh () {
      this.loadStatementOrderInfo(this.SalesStatement.SSGUID)
      this.$emit('save-success', true)
    },
    // 主单查看和编辑
    loadStatementOrderInfo: async function (info) {
      this.dialogLoading = true
      return this.$post(CONFIG.searchBySSGUIDURL, { SSGUID: info }, (data, logic) => {
        if (logic.code === 200) {
          this.SalesStatement = data
          _.each(this.SalesStatement.LineList, (item, i) => {
            if (item.Amount === undefined) { item.Amount = '' }
            if (item.ExtendedAmount === undefined) { item.ExtendedAmount = '' }
            if (item.UnReceivedAmount === undefined) { item.UnReceivedAmount = '' }
            if (item.ReceiveAmount === undefined) { item.ReceiveAmount = '' }
            if (item.DiscountAmount === undefined) { item.DiscountAmount = '' }
            if (item.Qty === undefined) { item.Qty = '' }
          })
          this.itemSource = this.SalesStatement.LineList
        } else {
          return this.$message({ message: logic.msg, type: 'error' })
        }
      }).finally(() => {
        this.$emit('page-loading', false)
        this.dialogLoading = false
      })
    },

    // 从销售出库统计弹框编辑
    loadSalesOutInfo: async function (info) {
      this.dialogLoading = true
      let param = {
        CustomerName: info.CustomerName,
        CurrencyCode: info.CurrencyCode,
        StatementDateStart: info.StartOutOn,
        StatementDateEnd: info.EndOutOn
      }
      return this.$post(CONFIG.createLine, param, (data, logic) => {
        if (logic.code === 200) {
          this.SalesStatement = data
          _.each(this.SalesStatement.LineList, (item, i) => {
            if (item.Amount === undefined) { item.Amount = '' }
            if (item.ExtendedAmount === undefined) { item.ExtendedAmount = '' }
            if (item.UnReceivedAmount === undefined) { item.UnReceivedAmount = '' }
            if (item.ReceiveAmount === undefined) { item.ReceiveAmount = '' }
            if (item.DiscountAmount === undefined) { item.DiscountAmount = '' }
            if (item.Qty === undefined) { item.Qty = '' }
          })
          this.itemSource = this.SalesStatement.LineList
        } else {
          return this.$message({ message: logic.msg, type: 'error' })
        }
      }).finally(() => {
        this.$emit('page-loading', false)
        this.dialogLoading = false
      })
    },
    // 审核后开票
    onInvoiceEdit () {
      if (this.SalesStatement.InvoicedStatus === 3) {
        this.$message({ message: '已全部开票不能继续开票', type: 'warning' })
      } else if (this.SalesStatement.CurrencyCode !== 'RMB') {
        this.$message({ message: '币种不是人民币类型，不能开发票', type: 'warning' })
      } else {
        this.isReturninvoice = false   // 不是冲红发票
        let data = { SSGUID: this.SalesStatement.SSGUID, StatementBillNO: this.SalesStatement.BillNo }
        this.$store.commit('setParamOrder', { SSInfo: data })
        this.$nextTick(function () {
          this.invoiceTitle = '新增销售发票'
          this.invoiceVisible = !this.invoiceVisible
        })
      }
    },
    deteledRow () {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
      let Amount = 0
      let UnReceivedAmount = 0
      let ReceivedAmount = 0
      _.each(this.itemSource, (item, i) => {
        if (item.Amount === undefined) { item.Amount = '' }
        if (item.UnReceivedAmount === undefined) { item.UnReceivedAmount = '' }
        if (item.ReceiveAmount === undefined) { item.ReceiveAmount = '' }
        Amount += Number(item.Amount)
        UnReceivedAmount += Number(item.UnReceivedAmount)
        ReceivedAmount += Number(item.ReceiveAmount)
      })
      this.SalesStatement.Amount = Amount
      this.SalesStatement.UnRecPayAmount = UnReceivedAmount
      this.SalesStatement.RecPayAmount = ReceivedAmount
    },
    // 打印参数，选中的打印模板
    setPrintParam (printInfo) {
      this.printInfo.title = printInfo.title
      this.printInfo.template = printInfo.template
    },
    confirmPrint (data) {
      this.onPrint()
    },
    // 打印模板
    onPrint () {
      var tableName = this.printInfo.template // 模板ID
      var titleId = this.printInfo.title // 子公司id（抬头）
      var SSGUID = this.SalesStatement.SSGUID // 对账单ID
      if (!this.hasValue(tableName)) {
        this.$message({ message: '先设置打印模板后再操作', type: 'error' })
        return false
      } else if (SSGUID === '' || SSGUID === null || SSGUID === undefined) {
        this.$message({ message: '先保存后才能打印', type: 'error' })
        return false
      } else {
        return this.$post(CONFIG.searchBySSGUIDURL, { SSGUID: SSGUID }, (data, logic) => {
          this.printSOStatement({
            TableName: tableName,
            Guid: SSGUID,
            SubCompanyId: titleId,
            MainJson: JSON.stringify(data),
            LineJson: JSON.stringify(data.LineList)
          })
        })
      }
    },
    billBySum (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value)) && (column.property === 'Amount' || column.property === 'UnReceivedAmount' || column.property === 'DiscountAmount' ||
          column.property === 'ReceiveAmount' ||
          column.property === 'ExtendedAmount' ||
          column.property === 'Qty')) { // Amount：金额
          sums[index] = values.reduce((prev, curr) => {
            let value = Number(curr)
            if (isNaN(value)) {
              value = 0
            }
            if (isNaN(curr)) {
              curr = 0
            }
            if (!isNaN(value) && !isNaN(curr)) {
              return prev + curr
            } else {
              return 0
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 保存
    statementSave (isClose) {
      var vm = this
      vm.loading = true
      return this.$post(CONFIG.saveURL, this.SalesStatement, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.SalesStatement = data
          this.itemSource = data.LineList
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('save-success', true)
          if (isClose) {
            this.$emit('close')
          }
        }
        vm.loading = false
      }).finally(() => {
        vm.loading = false
      })
    },
    // 重置页面 清除数据
    onResetSalesStatement () {
      this.SalesStatement.UnRecPayAmount = ''
      this.SalesStatement.RecPayAmount = ''
      this.SalesStatement.Amount = ''
      this.SalesStatement.SSGUID = ''
      this.SalesStatement.BillNo = ''
      this.SalesStatement.CustomerGUID = ''
      this.SalesStatement.CustomerName = ''
      this.SalesStatement.StatementDateStart = ''
      this.SalesStatement.StatementDateEnd = ''
      this.SalesStatement.CurrencyCode = ''
      this.SalesStatement.CurrencyName = ''
      this.SalesStatement.Remark = ''
      this.SalesStatement.LineList = []
      this.itemSource = []
    },
    hasRes (code) {
      if (!this.resourceList) { return }
      return _.includes(this.resourceList, code)
    },
    tableRowClassName (obj) {
      if (obj.row.SStatusName === '退货') {
        return 'cloundPriceClass'
      } else {
        return ''
      }
    }
  },
  mounted () {
  },

  created () {
  }
}
</script>
<style lang="scss">
.dialog-statement-box {
  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0 15px 30px;
  }
  .flex-between {
    padding-top: 6px;
  }

  .grid-data-statement-box .has-gutter tr th {
    background: #ebeef5;
  }
  .znl-dialog.el-dialog .el-dialog__header {
    height: 100%;
  }

  .znl-content .base-flex-grid {
    padding: 0;
    background: #fff;
  }

  .el-pagination button,
  .footer .el-pager li {
    background: transparent;
  }
  .form_el-radio {
    width: 90%;
  }
  .el-radio__label {
    font-size: 12px;
  }
  .cloundPriceClass {
    .znl-tablecell {
      color: Red;
    }
  }
}

.p-statement-order-edit {
  .el-table {
    display: flex;
    flex-direction: column;
  }
  .el-table__body-wrapper {
    flex: 1;
  }
}
</style>

