<template>
  <znl-layout-spa :page-loading="pageLoading">
    <div class="second-tab-div">
      <znl-stock-summary
        ref="summaryStock"
        :init-data="false"
        @page-loading="
          (d) => {
            pageLoading = d;
          }
        "
      ></znl-stock-summary>
    </div>
  </znl-layout-spa>
</template>

<script>
import ZnlStockSummary from "@c_modules/StkSum/StockSummary";
// 窗口大小监控mixin
import Resize from "~assets/scripts/mixins/resize";
import { isClient } from "~/lib/runtime";

export default {
  name: "VZnlStockSummary",
  components: {
    ZnlStockSummary,
  },
  mixins: [Resize],
  data() {
    return {
      StockVMI: {},
      pageData: [],
      pageLoading: true,
    };
  },
  methods: {
    onresize() {
      //从store取得domRect，在计算
      const height = isClient()
        ? this.$store.state.domRect.clientHeight
        : this.$store.state.domRect.clientHeight - 50;

      if (this.$refs.summaryStock) {
        this.$refs.summaryStock.$refs.table.boxHeight = height - 10;
      }
    },
  },
  async created() {},
};
</script>
