， <template>
  <div class="p-goods-search">
    <div   v-loading="isLogin"
           element-loading-text="数据拼命加载中....."
           element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%;height: 100%">
      <iframe :id="id" ref="iframe" :src="iframeSrc" height="100%" width="100%"></iframe>
    </div>

    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>
  </div>
</template>
<script>
import {mapState} from "vuex";
import EnquiryView from "@c_modules/Enquiry/index"

const isProduction = process.env.NODE_ENV === "production";
//
const defaultBomAiSrc = isProduction ? 'https://www.bom.ai' : 'https://wxapp.bom.ai'
// // const defaultBomAiSrc = 'https://www.bom.ai'
export default {
  name: 'GoodsSearch',
  data() {
    return {
      iframeSrc: '',
      iframeType: 'default',
      iframeWin: '',
      loading: false,
      token: '',
      iframeHeight: 0,
      routeData: {},
      isLogin: false
    }
  },
  components: {EnquiryView},
  computed: {
    ...mapState(["useInfo", "webData"]),
  },
  watch: {
    $route(value) {
      if(value.name === 'GoodsSearch') {
        this.onInit()
      }
    }
  },
  created() {
    this.token = this.useInfo['AccessToken']
    this.onInit()
  },
  mounted() {
    this.iframeWin = this.$refs.iframe.contentWindow;
    window.addEventListener('message', this.handleMessage);
  },
  methods: {
    async onInit() {
      this.isLogin = true

      console.log('页面开始初始化')
      const {query} = this.$route;

      var href = window.location.href;
      if(href.indexOf('http://test.znlbd.com') > -1)
      {
        this.iframeSrc = "http://test.bom.ai/"
      }
      else{
        this.iframeSrc = defaultBomAiSrc
      }
      const params = query.params


      if (params) {
        const newParams = JSON.parse(params)
        console.log(newParams)
        // this.iframeSrc = `${defaultBomAiSrc}/ic/${newParams.partNo}.html`
        this.iframeSrc = `${this.iframeSrc}/ic/${newParams.partNo}.html`

        this.routeData = newParams
      }

      const num = Math.random()
      this.id = `iframe${this.iframeType}_${num}`
      // const _this = this
      // this.$nextTick(() => {
      //   const iframe = document.getElementById(this.id)
      //   if (iframe.attachEvent) {
      //     iframe.attachEvent('onload', function () {
      //       _this.sendMessage()
      //     })
      //   } else {
      //     iframe.onload = function () {
      //       _this.sendMessage()
      //     }
      //   }
      // })

      if(this.webData.isBomAi) {
        this.isLogin = false
      }
      setTimeout(() => {
        this.isLogin = false
      }, 10000)
    },

    // sendMessage() {
    //   console.log(this.token, 'token传入', new Date())
    //
    //   this.iframeWin.postMessage(JSON.stringify({
    //     isErp: false,
    //     source: 'BomBuySearch',
    //     token: this.token,
    //     partNo: this.partNo,
    //     id: this.id
    //   }), '*')
    // },

    handleMessage(v) {
      if (v.data && typeof v.data == 'string') {
        let data = JSON.parse(v.data)
        if (data.source === "BomAi") {
          if (data.offerData) {
            data.offerData.Model = data.offerData.Model.split('\n')[0]
            this.onOpenEnquiry(data.offerData)
          }
          console.log('token已经写入 返回信息', new Date())
          this.isLogin = false
        }
      }
    },

    onOpenEnquiry(data) {
      let editItem = {
        SupplierID: data.SupplierID, // 供应商ID
        SupplierName: data.SupplierName, // 供应商名称
        Telephone: data.Telephone, // 电话
        QQ: data.QQ,  // QQ
        CurrencyCode: data.CurrencyCode,
        TaxRate: data.TaxRate,
        Qty: this.routeData ? this.routeData.qty : data.Qty, // 需求量
        Quality: data.Quality, // 品质
        Model: data.Model,  // 型号
        Brand: data.Brand, // 品牌
        Packaging: data.Packaging, // 封装
        MakeYear: data.MakeYear, // 年份
        MPQ: data.MPQ, // 包装
        InvQty: data.InvQty, // 库存量
        DeliveryDate: data.DeliveryDate, // 货期
        DataFrom: data.DataFrom, // 来源
        Contact: data.Contact, // 联系人
        Remark: data.Remark, // 备注

        //  不确定字段
        MarkingCode: data.MarkingCode,// 打字
        Price: data.Price,// 报价含税


        SourceType: this.routeData.SourceType ? this.routeData.SourceType : null, // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
        SourceGuid: this.routeData.SourceGuid ? this.routeData.SourceGuid : null, // 需求明细GUID
        SourceId: this.routeData.SourceId ? this.routeData.SourceId : null,  // 需求明细ID，
      }

      this.$refs.enquiryView.onOpen(editItem)
    },
    onConfirm() {
    }
  },

}
</script>
<style lang="scss">
.p-goods-search {
  height: 100%;
  width: 100%;


  .el-loading-mask {
    background: rgba(126, 125, 125, 0.8) !important;
  }
}
</style>
