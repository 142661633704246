<template>
  <znl-dialog
    width="850px"
    height="600px"
    :visible="visible"
    :loading="sendloading"
    :is-footer-show="false"
    :loading-text="sendLoadingText"
    class="dialog-ord-purchase-notice-box"
    title="通知采购"
    :append-to-body="true"
    :lock-scroll="true"
    @close="
      () => {
        $emit('close');
      }
    "
  >
    <znl-gridmodule
      ref="waitNoticeFlexGrid"
      height="100%"
      :max-height="280"
      gridtype="action"
      :has-znl-actions="true"
      :has-znl-btns="false"
      :columns="waitNoticeColumns"
      layout-type="other"
      :item-source="waitNoticeDataSource"
      :is-fields-search="false"
      :is-multi-rows-check="true"
      :search-fields="searchModel"
      :is-action-form="true"
      :on-init="onInitWaitNotice"
      :is-init="true"
      :show-save-row-right-menu="false"
      :show-delete-row-right-menu="false"
      :grid-show="gridShow"
      :on-refresh="onBindWaitNoticeData"
      @select-change="onWaitNoticeSelectChange"
    >
      <div slot="action-form" class="noticeSlot">
        <span class="title">待通知列表</span>
        <znl-input
          layout="left"
          form-type="select"
          :disabled="false"
          width="100px"
          :height="22"
          :border="true"
          placeholder="请选择采购员"
          popper-class="popperClass"
          :clearable="true"
          :select-options="selectOptions"
          :filterable="true"
          @change="onRowSetBuyer"
          v-model="BuyEmpID"
        ></znl-input>
        <znl-button style-type="mac" @click="setBuyer">
          <i class="iconfont icon-table_set_btn_n"></i>
          <span>设为采购员</span>
        </znl-button>
        <znl-button style-type="main" @click="onSave">
          <i class="iconfont icon-notice_btn_ic"></i>
          <span>发送通知</span>
        </znl-button>
      </div>
    </znl-gridmodule>

    <znl-gridmodule
      ref="noticedFlexGrid"
      height="100%"
      :max-height="280"
      gridtype="action"
      :has-znl-actions="true"
      :has-znl-btns="false"
      :columns="noticedColumns"
      layout-type="other"
      :item-source="noticedDataSource"
      :is-fields-search="false"
      :is-multi-rows-check="true"
      :search-fields="searchModel"
      :is-action-form="true"
      :on-init="onInitNoticed"
      :is-init="true"
      :show-save-row-right-menu="false"
      :show-delete-row-right-menu="false"
      :grid-show="gridShow"
      :on-refresh="onBindNoticedData"
    >
      <div slot="action-form" class="noticedSlot">
        <span class="title">已通知列表</span>
        <znl-button style-type="mac" @click="cancelNotice()" v-if="true">
          <i class="iconfont icon-close_btn"></i>
          <span>取消通知</span>
        </znl-button>
      </div>
    </znl-gridmodule>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { renderRequiredColumn } from "~assets/scripts/methods/common";

const CONFIG = {
  getWaitNoticeListUrl: "OrdPurchaseNotice/GetWaitNoticeList",
  getNoticedListUrl: "OrdPurchaseNotice/GetNoticedList",
  saveUrl: "OrdPurchaseNotice/UpdateBuyEmp",
  cancelNoticeUrl: "OrdPurchaseNotice/CancelNotice",
};
export default {
  name: "NoticeList",
  mixins: [znlMethodsMixin, getCommonDataMixin, renderRequiredColumn],
  config: CONFIG,
  components: {},
  data() {
    return {
      waitNoticeColumns: [], //待通知表格列配置
      waitNoticeDataSource: [], //待通知列表数据源
      noticedColumns: [], //已通知表格列配置
      noticedDataSource: [], //已通知列表数据源
      gridShow: false,
      BuyEmpID: null,
      BuyEmpList: [],
      BuyEmpName: "",
      selectOptions: [],
      sendloading: true,
      sendLoadingText: "正在加载...",
      UserID: this.$store.state.accountInfo.UserID, // 当前用户ID
      SelectedRows: [], //选中行，用组件的获取选中行事件会有选中行丢失问题
    };
  },
  props: {
    soGUIDList: String, // 销售单GUID
    soLineGUIDList: String, // 销售单明细GUID
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isRefresh: Boolean, // 是否刷新列表
    visible: Boolean,
  },
  watch: {
    soGUIDList(value) {
      if (!_.isEmpty(value)) {
        this.onBindWaitNoticeData();
        this.onBindNoticedData();
      }
    },
    soLineGUIDList(value) {
      if (!_.isEmpty(value)) {
        this.onBindWaitNoticeData();
        this.onBindNoticedData();
      }
    },
  },
  methods: {
    onInit: async function () {},
    //初始化待通知列表
    onInitWaitNotice() {
      this.onInitWaitNoticeColumns();
      this.onBindWaitNoticeData();
    },
    //初始化已通知列表
    onInitNoticed() {
      this.onInitNoticedColumns();
      this.onBindNoticedData();
    },
    //初始化待通知列配置
    onInitWaitNoticeColumns() {
      let columnConfigs = [
        {
          CTitle: "SOLineGUID",
          BindField: "SOLineGUID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: false,
        },
        {
          CTitle: "型号",
          BindField: "Model",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "品牌",
          BindField: "Brand",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 3,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "下单量",
          BindField: "SOLineQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 5,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "备货量",
          BindField: "ReserveQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 50,
          DisplayIndex: 5,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "已通知量",
          BindField: "AlreadyQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 7,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "已采购入库量",
          BindField: "NoticedStkInQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 90,
          DisplayIndex: 7,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "未通知量",
          BindField: "UnNoticeQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "本次通知量",
          BindField: "NoticeQty",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 2,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "采购员ID",
          BindField: "BuyEmpID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "采购员",
          BindField: "BuyEmpName",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: true,
        },
        {
          CTitle: "采购说明/备注",
          BindField: "Remark",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 10,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "货期要求",
          BindField: "DeliveryDate",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "客户料号",
          BindField: "CustomerPartNo",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 50,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "产品类型",
          BindField: "ProductCategory",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 51,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
      ];
      let columns = this.flexGridColumnsHandler(columnConfigs);
      this.selectOptions = _.map(
        this.selectOptionsAllUserURL,
        function (obj, index) {
          return { key: obj.key, value: obj.value };
        }
      );
      _.extend(
        _.find(columns, (col) => {
          return col.binding === "BuyEmpName";
        }),
        { dataMap: this.selectOptions, showDropDown: true }
      );
      columns = this.renderRequiredColumn(columns);
      this.waitNoticeColumns = columns;
    },
    //初始化已通知表格列配置
    onInitNoticedColumns() {
      let columnConfigs = [
        {
          CTitle: "型号",
          BindField: "Model",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "品牌",
          BindField: "Brand",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 3,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "下单量",
          BindField: "SOLineQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 5,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "备货量",
          BindField: "ReserveQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 50,
          DisplayIndex: 5,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "通知量",
          BindField: "NoticeQty",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 8,
          DataType: 2,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "采购员ID",
          BindField: "BuyEmpID",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "采购员",
          BindField: "BuyEmpName",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "采购说明/备注",
          BindField: "Remark",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 10,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "货期要求",
          BindField: "DeliveryDate",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "客户料号",
          BindField: "CustomerPartNo",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 50,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
        {
          CTitle: "产品类型",
          BindField: "ProductCategory",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 51,
          DataType: 1,
          IsSystem: false,
          IsRequired: false,
        },
      ];
      let columns = this.flexGridColumnsHandler(columnConfigs);
      columns.unshift({
        name: "delete",
        dataType: 5,
        binding: "delete",
        width: 70,
        header: "操作",
        visible: true,
        isReadOnly: true,
        buttons: [
          {
            content: "取消通知",
            className: "znl-table-btn-success",
            click: (row) => {
              this.cancelNotice(row);
            },
          },
        ],
      });
      this.selectOptions = _.map(
        this.selectOptionsAllUserURL,
        function (obj, index) {
          return { key: obj.key, value: obj.value };
        }
      );
      _.extend(
        _.find(columns, (col) => {
          return col.binding === "BuyEmpName";
        }),
        { dataMap: this.selectOptions, showDropDown: true }
      );
      columns = this.renderRequiredColumn(columns);
      this.noticedColumns = columns;
    },
    //待通知列表选中行事件
    onWaitNoticeSelectChange(rows) {
      this.SelectedRows = rows;
    },
    //绑定待通知采购
    onBindWaitNoticeData() {
      let param = {
        SOGUIDList: JSON.parse(this.soGUIDList),
      };
      this.sendloading = true;
      return this.$post(CONFIG.getWaitNoticeListUrl, param, (data, logic) => {
        this.waitNoticeDataSource = data === null ? [] : data;
      }).finally((e) => {
        this.gridShow = true;
        this.showPage = true;
        this.$emit("page-loading", false);
        this.sendloading = false;
      });
    },
    //绑定已通知采购
    onBindNoticedData() {
      let param = {
        SOGUIDList: JSON.parse(this.soGUIDList),
      };
      this.sendloading = true;
      return this.$post(CONFIG.getNoticedListUrl, param, (data, logic) => {
        this.noticedDataSource = data === null ? [] : data;
      }).finally((e) => {
        this.gridShow = true;
        this.showPage = true;
        this.$emit("page-loading", false);
        this.sendloading = false;
      });
    },
    //行内设置采购员
    onRowSetBuyer(item) {
      if (item.key === 0) {
        this.BuyEmpID = 0;
        this.BuyEmpName = "";
      } else {
        this.BuyEmpID = item.key;
        this.BuyEmpName = item.value;
      }
    },
    //外部设置采购员
    setBuyer() {
      if (this.BuyEmpID === null || this.BuyEmpID === 0) {
        this.$message({ message: "请选择采购员", type: "error" });
        return;
      }
      let selectedRows = this.$refs.waitNoticeFlexGrid.getSelectedRows();
      if (selectedRows && selectedRows.length > 0) {
        _.each(selectedRows, (select) => {
          _.each(this.waitNoticeDataSource, (item) => {
            if (select.SOLineGUID === item.SOLineGUID) {
              this.$set(item, "BuyEmpName", this.BuyEmpName);
              this.$set(item, "BuyEmpID", this.BuyEmpID);
            }
          });
        });
      } else {
        if (this.waitNoticeDataSource && this.waitNoticeDataSource.length > 0) {
          _.each(this.waitNoticeDataSource, (item) => {
            this.$set(item, "BuyEmpName", this.BuyEmpName);
            this.$set(item, "BuyEmpID", this.BuyEmpID);
          });
        }
      }
    },
    //发送通知
    onSave() {
      let request = [];
      let rowindex = 0; // 第几行
      let next = false; // 是否提示
      _.each(this.SelectedRows, (item, index) => {
        let m = {};
        // 单元格选择采购员时 处理赋值字段矫正
        if (
          !item.BuyEmpID ||
          (typeof item.BuyEmpName === "number" &&
            typeof item.BuyEmpID === "number")
        )
          item.BuyEmpID = item.BuyEmpName;
        if (this.hasValue(item.BuyEmpID) && item.BuyEmpID > 0) {
          m.NoticedEmpList = _.filter(this.selectOptions, (a) => {
            return a.key === item.BuyEmpID;
          });
        }
        m.SOGuid = item.SOGuid;
        m.SOLineGUID = item.SOLineGUID;
        m.Model = item.Model;
        m.ProductCategory = item.ProductCategory;
        m.Brand = item.Brand;
        m.Packaging = item.Packaging;
        m.SOLineQty = item.SOLineQty;
        m.StockQty = item.StockQty;
        m.AlreadyQty = item.AlreadyQty;
        m.NoticeQty = item.NoticeQty;
        m.Remark = item.Remark;
        m.DeliveryDate = item.DeliveryDate;
        request.push(m);
        if (
          item.NoticeQty >
          this.toFloat(item.SOLineQty) -
            this.toFloat(item.AlreadyQty) -
            this.toFloat(item.ReserveQty) +
            this.toFloat(item.NoticedStkInQty)
        ) {
          next = true;
          rowindex = index + 1;
        }
      });
      if (!this.validationData(request)) {
        return;
      }

      if (next) {
        // 本次通知数量>下单数量-库存数量-已通知数量 提醒出来 不做严格控制
        this.$confirm(
          "通知采购明细第" +
            rowindex +
            "条数据,本次通知数量 > 下单数量 - 备货数量 - 已通知数量 + 已通知采购入库数量 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.onSumit(request);
        });
      } else {
        this.onSumit(request);
      }
    },
    //提交
    onSumit(request) {
      this.sendloading = true;
      this.sendLoadingText = "正在发送通知...";
      return this.$post(
        CONFIG.saveUrl,
        { SOLineList: request },
        (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "通知成功", type: "success" });
            this.$emit("confirm", true);
          }
        }
      ).finally(() => {
        this.sendloading = false;
      });
    },
    //验证数据
    validationData(val) {
      if (val === undefined || val.length <= 0) {
        this.$message({ message: "请勾选要通知采购的型号", type: "warning" });
        return false;
      }
      for (let i = 0; i < val.length; i++) {
        let item = val[i];
        if (!this.hasValue(item.NoticeQty) || item.NoticeQty <= 0) {
          this.$message({
            message: "型号：" + item.Model + " 本次通知量必须大于0",
            type: "warning",
          });
          return false;
        }

        if (
          !this.hasValue(item.NoticedEmpList) ||
          item.NoticedEmpList.length <= 0
        ) {
          this.$message({
            message: "型号：" + item.Model + " 没有指定采购员!",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },
    //取消通知（传入指定行表示点击行取消）
    cancelNotice(row) {
      let NoticeGUIDs = [];
      if (row) {
        NoticeGUIDs.push(row.NoticeGUID);
      } else {
        let selectedRow = this.$refs.noticedFlexGrid.getSelectedRows();
        if (!selectedRow || selectedRow.length === 0) {
          this.$message({ message: "请勾选通知项!", type: "warning" });
          return;
        }
        _.each(selectedRow, (m) => {
          NoticeGUIDs.push(m.NoticeGUID);
        });
      }
      return this.$post(
        CONFIG.cancelNoticeUrl,
        { NoticeGUIDs: NoticeGUIDs },
        (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "已成功取消通知！", type: "success" });
            this.onBindWaitNoticeData();
            this.onBindNoticedData();
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-ord-purchase-notice-box {
  .znl-action-form {
    .el-table-box > div {
      margin-left: 5px;
    }
    & > div {
      // height: 30px !important;
      // position: relative;
      float: right;
    }
  }
}

.noticeSlot,
.noticedSlot {
  width: 100%;
  .title {
    font-weight: bold;
    color: red;
    font-size: large;
  }
}
</style>

