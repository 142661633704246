<template>
  <div>
    <znl-dialog
      :title="Title"
      :visible="Visible"
      width="600px"
      height="400px"
      class="dialog-znl"
      :close-on-click-modal="false"
      :is-footer-show="false"
      element-loading-text="正在加载中,请稍候..."
      @close="closeDialog"
    >
      <znl-gridmodule
        ref="flexGrid"
        gridtype="action"
        height="100%"
        class="m-border-box"
        from="OrderAttachment"
        layout-type="other"
        element-loading-text="正在加载中,请稍候..."
        :from-height="120"
        :columns="columns"
        :item-source="itemSource"
        :is-multi-rows-check="true"
        :on-init="onBindData"
        :showSaveBtn="false"
        :isShowRightbtns="false"
        :isShowFrombtns="false"
      >
        <div slot="from-module">
          <div class="headAreaDiv">
            <div class="vUploadDiv">
              <vUpload
                ref="vUpload"
                width="400px"
                select-tips="选择文件"
                :accept="accept"
                :isMultiple="true"
                :folder="folder"
                :refresh.sync="isRefresh"
                :refresh-click.sync="refreshClick"
                @upload-before="onUploadClick"
                @upload-completed="onUploadCompleted"
              />
            </div>
            <div class="headBtnDiv">
              <znl-button class="delBtn" style-type="mac" @click="onDelete()">
                <i class="iconfont icon-delete_btn_ic"></i>
                <span>删除选中</span>
              </znl-button>
            </div>
          </div>
        </div>
      </znl-gridmodule>
    </znl-dialog>

    <el-dialog title="图片预览" :visible.sync="imagePreviewVisible" width="10%">
      <img :src="imageSrc" width="200px" />
    </el-dialog>
  </div>
</template>

<script>
import vUpload from "@c_modules/StkExt/Upload";
import ShowImage from "@c_modules/StkSum/ShowImage";

const CONFIG = {
  GetPageListUrl: "SysOrderAttachment/GetPageList",
  GetListUrl: "SysOrderAttachment/GetList",
  AddUrl: "SysOrderAttachment/Add",
  DeleteUrl: "SysOrderAttachment/Delete",
};

export default {
  name: "OrderAttachment",
  config: CONFIG,
  mixins: [],
  components: { vUpload, ShowImage },
  data() {
    return {
      maxFileCount: 50, //单次上次最大文件数量
      imagePreviewVisible: false, //预览图片弹窗
      imageSrc: "", //预览图片路径
      loading: false,
      itemSource: [],
      columnConfig: [
        {
          CTitle: "Id",
          BindField: "Id",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "公司Id",
          BindField: "CompanyId",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "单据类型",
          BindField: "OrderType",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "单据Id",
          BindField: "OrderId",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 120,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "文件",
          BindField: "FileName",
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 200,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: true,
          IsRequired: false,
        },
        {
          CTitle: "文件路径",
          BindField: "FileUrl",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 200,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: true,
          IsRequired: false,
        },
        {
          CTitle: "创建人Id",
          BindField: "CreatedById",
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: true,
          IsRequired: true,
        },
        {
          CTitle: "上传人",
          BindField: "CreatedByName",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: true,
          IsRequired: false,
        },
        {
          CTitle: "上传时间",
          BindField: "CreatedTime",
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 150,
          DisplayIndex: 5,
          DataType: 4,
          IsSystem: true,
          IsRequired: false,
        },
      ],
      columns: [],
      accept:
        ".jpg,.jpeg,.bmp,.png,.gif,.ico,.zip,.7z,.txt,.xls,.xlsx,.pdf,.doc,.docx",
      folder: "",
      isRefresh: false, //上传成功后刷新
      refreshClick: false,
    };
  },
  props: {
    //弹窗标题
    Title: {
      type: String,
      default: () => {
        return "单据附件";
      },
    },
    //单据类型（销售单：1，采购单：2）
    OrderType: {
      type: Number,
    },
    //单据主单Guid
    OrderId: {
      type: String,
    },
    //是否显示
    Visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  watch: {},
  methods: {
    //页面加载方法
    async onInit() {
      this.initColumns();
      this.initFolder();
    },
    //绑定数据
    async onBindData() {
      let param = {
        OrderType: this.OrderType,
        OrderId: this.OrderId,
      };
      await this.$post(CONFIG.GetListUrl, param, (data) => {
        this.itemSource = data ? data : [];
      });
    },
    //初始化列信息
    initColumns() {
      let columns = this.flexGridColumnsHandler(this.columnConfig);

      let _this = this;
      //处理文件列
      _.extend(
        _.find(columns, (item) => {
          return item.binding === "FileName";
        }),
        {
          buttons: [
            {
              content: (row) => {
                let fileName = row.FileName.toLowerCase();
                let index = fileName.lastIndexOf(".");
                if (index > 0) {
                  fileName = fileName.substr(index);
                }
                let imgIndex = _.indexOf(
                  [".jpg", ".jpeg", ".bmp", ".png", ".gif", ".ico"],
                  fileName
                );
                if (imgIndex === -1) {
                  return `<span>${row["FileName"]}</span><i class="iconfont icon-linkall_attachment_i"></i>`;
                } else {
                  return `<span>${row["FileName"]}</span><i class="iconfont icon-pic_btn_ic"></i>`;
                }
              },
              className: "znl-file-name",
              click: (row) => {
                _this.ondownload(row);
              },
            },
            {
              content: (row) => {
                let fileName = row.FileName.toLowerCase();
                let index = fileName.lastIndexOf(".");
                if (index > 0) {
                  fileName = fileName.substr(index);
                }

                let imgIndex = _.indexOf(
                  [".jpg", ".jpeg", ".bmp", ".png", ".gif", ".ico"],
                  fileName
                );

                if (imgIndex !== -1) {
                  return `<span>预览</span>`;
                }
              },
              className: "znl-file-name",
              click: (row) => {
                _this.imagePreviewVisible = true;
                _this.imageSrc = row.FileUrl;
              },
            },
          ],
        }
      );

      this.columns = columns;
    },
    //初始化文件夹名称
    initFolder() {
      let d = new Date();
      let yy = d.getFullYear().toString();
      let MM = d.getMonth() + 1;
      let dd = d.getDate();
      if (MM < 10) {
        MM = "0" + MM.toString();
      }
      if (dd < 10) {
        dd = "0" + dd.toString();
      }
      let dstr = yy + MM.toString() + dd.toString();
      this.folder =
        "erp-order-attachment/" +
        this.$store.state.accountInfo.CompanyID +
        "/" +
        dstr;
    },
    // 单击上传按钮的时候
    onUploadClick(fileName, fileSize, filesArry) {
      if (fileName === "" || fileName === null || _.isUndefined(fileName)) {
        return this.$message({
          message: "请至少选择一个文件",
          type: "warning",
        });
      }
      if (filesArry.length >= this.maxFileCount) {
        return this.$message({
          message: `单次上传文件数量不能大于${this.maxFileCount}!`,
          type: "warning",
        });
      }
      let isPass = true;
      _.each(filesArry, (file) => {
        if (!this.hasValue(file.fileName)) {
          isPass = false;
          this.$message({ message: "请至少选择一个文件", type: "warning" });
          return false;
        }
        let name = file.fileName.toLowerCase();
        let index = name.lastIndexOf(".");
        if (index > 0) {
          name = name.substr(index);
        }
        if (_.indexOf(this.accept.split(","), name) === -1) {
          isPass = false;
          this.$message({
            message: "选中的文件中有不支持上传的文件",
            type: "warning",
          });
          return false;
        }
      });
      if (!isPass) {
        return;
      }
      this.refreshClick = !this.refreshClick;
    },
    // 文件上传完成之后
    onUploadCompleted(fileName, fileSize, fileUrl, filesArry) {
      this.isRefresh = !this.isRefresh;

      let param = _.map(filesArry, (m) => {
        return {
          OrderType: this.OrderType,
          OrderId: this.OrderId,
          FileName: m.fileName,
          FileUrl: m.fileUrl,
        };
      });
      //插入单据附件表
      this.$post(CONFIG.AddUrl, param, (data) => {
        if (data) {
          this.$message({ message: "文件上传成功", type: "success" });
          this.onBindData();
        } else {
          this.$message({ message: "文件上传失败", type: "error" });
        }
      });
    },
    //删除附件信息
    async onDelete() {
      //获取选中行
      var selectedRows = this.$refs.flexGrid.getCheckedRows();
      if (selectedRows.length === 0) {
        this.$message({ message: "请选择要删除的行！", type: "warning" });
        return;
      }

      //删除确认
      await this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });

      //删除
      let ids = _.map(selectedRows, (m) => m.Id);
      this.$post(CONFIG.DeleteUrl, { Ids: ids }, (data) => {
        if (data && data > 0) {
          this.$message({ message: "删除成功！", type: "success" });
          this.onBindData();
        } else {
          this.$message({ message: "删除失败！", type: "error" });
        }
      });
    },
    //下载文件
    ondownload(row) {
      this.downFile({ FileName: row.FileName, FileUrl: row.FileUrl });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("close");
    },
  },
  created() {},
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="scss" scoped>

</style>

